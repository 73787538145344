import { UploadimgService } from './../../../../_services/upload-img.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService, ToursService } from '../../../../_services';
import { environment } from './../../../../../environments/environment';
import * as znznValidators from '../../../../_models';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatCheckboxChange, MatStepper } from '@angular/material';
import swal from 'sweetalert2';
import { ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
// import { google } from '@agm/core/services/google-maps-types';


class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'app-create-branch',
  templateUrl: './create-branch.component.html',
  styleUrls: ['./create-branch.component.scss']
})
export class CreateBranchComponent implements OnInit {
  access_token = JSON.parse(localStorage.getItem('currentUser'))[
    'access_token'
  ];
  createBranchFormGroup: FormGroup;
  setTimeSlotsSDFormGroup: FormGroup;
  setTimeSlotsEDFormGroup: FormGroup;
  workFlowFormGroup: FormGroup;
  znznValiditors = znznValidators['znzn-form-validation'];
  timeSlotFlag = '';
  isEditable = true;
  isOptional = true;
  latitude = 30.0594838;
  longitude = 31.223359;
  // google maps zoom level
  zoom = 8;
  placeName: string;
  marker: { latitude: number; longitude: number };
  imageSrc = 'http://placehold.it/120';
  workflows;
  weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  cities: any;
  paymentMethods: any;
  branches: any;
  days: any;
  distircts: any;
  isDistircts = false;
  editphone = false;
  spinner = false;
  branch_phones: any[] = [];
  phone = '';
  newUpdatedPone: any;
  imageName: any;
  areas_addes: any[] = [];
  selectedFile: FileList;
  imagesSources: any[] = [];
  newBranchId;
  DaysNames: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  // '15 Min','1 Hrs', '2 Hrs', '3 Hrs','30 Min' ,'4 Hrs' , '5 Hrs','6 Hrs'
  timeIntrenval: any[] = [
    { key: '15 Min', value: 0 },
    { key: '30 Min', value: 4 },
    { key: '1 Hrs', value: 1 },
    { key: '2 Hrs', value: 2 },
    { key: '3 Hrs', value: 3 },
    { key: '4 Hrs', value: 5 },
    { key: '5 Hrs', value: 6 },
    { key: '6 Hrs', value: 7 }];
  DaysISONames: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  currentWorkFlow: any;
  errorMessage: string;
  daypush: any[] = [];
  createdbarnch: any[] = [];
  haspaymentId: any;
  hasPayments: any;
  weekDaysww: any;
  daysofWeeks: any[] = [];
  viewcancelation = false;
  dayss: any;
  PoliciesToSave = [];
  cancelationForm: {};
  periodd: string;
  deductiond: string;
  periodmsg: boolean;
  dedutionmsg: boolean;
  perioderrorMsg: string;
  deductionerrorMsg: string;
  Policies: any[] = [];
  viewCancelation: boolean;
  policies: any[] = [];
  policiesToSave: any[] = [];
  periodMsg: boolean;
  SelectAllDays: boolean;
  show = true;
  selectedd: any;
  selectedFiles: ImageSnippet;
  image_name: string;
  facebook = false;
  insta = false;
  whats = false;
  mob = false;
  Em = false;
  address: string;
  private geoCoder;
  @ViewChild('search') public searchElementRef: ElementRef;
  @ViewChild('serchCode') public input: ElementRef;
  showfresh = false;

  constructor(
    private _http: HttpClient,
    private _formBuilder: FormBuilder,
    private _httpService: HttpService,
    private _toastr: ToastrService,
    private _awsUploadImage: UploadimgService,
    private _router: Router,
    private tours: ToursService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.getData();
  }

  ngOnInit() {
    this.getWeekDays();
    // this.getPlaceName();
    this.createBranchFormGroup = this._formBuilder.group({
      placeName: [
        { value: '', disabled: false },
        Validators.compose([Validators.required, Validators.minLength(2)])
      ],
      city: [this.cities, Validators.required],
      area: [{ value: '', disabled: true }, Validators.required],
      address: ['', Validators.compose([Validators.required])],
      has_note: [false],
      note: [''],


      phone: [
        '',
        Validators.compose([
          Validators.pattern(/^-?([0-9]\d*)?$/),
          Validators.maxLength(15)
        ])
      ],
      paymentMethod: ['', Validators.required],
      threshold: ['', [Validators.max(100), Validators.min(1)]],

      branchAreaName: [''],
      branchAreaNoOfTable: [
        '',
        Validators.compose([Validators.pattern(/^-?([0-9]\d*)?$/)])
      ],
      branchAreaNoOfSeats: [''],
      branchAreaCostPerSeats: [
        '',
        Validators.compose([Validators.pattern(/^-?([1-9]\d*)?$/)])
      ],
      addImage: ['']
    });
    this.setTimeSlotsEDFormGroup = this._formBuilder.group({
      // fromcontrollers
      working_hours_from: ['', Validators.required],
      working_hours_to: ['', Validators.required],
      intvaral1: [''],
      interval: ['', Validators.required]
    });
    this.setTimeSlotsSDFormGroup = this._formBuilder.group({
      timeslots: this._formBuilder.array([
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        })
      ])
    });
    this.workFlowFormGroup = this._formBuilder.group({
      workflows: ['', Validators.required]
    });
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }


  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  getData() {
    // get cities
    this._httpService
      .getData(`${environment.apiEndPoint}/vendor/city`, {
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
        access_token: this.access_token
      })
      .subscribe(cities => (this.cities = cities['cities']));
    console.log(this.cities)
    // get payment_methods
    this._httpService
      .getData(`${environment.apiEndPoint}/payment/methods`)
      .subscribe(
        paymentMethods =>
          (this.paymentMethods = paymentMethods['payment_methods'])
      ); // {"id":1,"name":"Pay on Arrival"},
    // get Branches
    this._httpService
      .getData(`${environment.apiEndPoint}/vendor/restaurant/branch/`, {
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
        access_token: this.access_token
      })
      .subscribe(branches => {
        this.branches = branches['branches'];
        // this.createBranchFormGroup.controls['placeName'].enable();
      });
    // get Days
    this._httpService
      .getData(`${environment.apiEndPoint}/daysofweek/`, {
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
        access_token: this.access_token
      })
      .subscribe(days => (this.days = days['days'])); // "id":1,"number":1,"name":"Sunday","iso":"Sun"}
    // get Workflows
    this._httpService
      .getData(`${environment.apiEndPoint}/vendor/listworkflows/`, {
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
        access_token: this.access_token
      })
      .pipe(
        map(resp => {
          const workflows = resp['workflows'];
          this.workflows = [];
          // "name":"Restaurant: reserve with form","guid":"c3bdbc67-4d60-4c42-9c46-4f6ae9ea66d2"
          for (let i = 0; i < workflows.length; i++) {
            this.workflows.push({
              name: workflows[i]['name'],
              guid: workflows[i]['guid']
            });
          }
        })
      )
      .subscribe();
  }
  checkBranchName(event) {
    const temp = event['target']['value'];
    if (this.branches !== undefined) {
      const branchesLen = this.branches.length || 0;
      for (let i = 0; i < branchesLen; i++) {
        const branch = this.branches[i];
        if (temp === branch['branch_name']) {
          return this.createBranchFormGroup.controls['placeName'].setErrors({
            validateBranchName: true
          });
        }
      }
    }
    return null;
  }
  addPhone(phone) {
    // http://staging.zabatnee.com/api/vendor/phoneexists/12332312312/
    this._httpService
      .getData(`${environment.apiEndPoint}/vendor/phoneexists/${phone}/`)
      .subscribe(resp => {
        if (resp['code'] === '10034') {
          this.branch_phones.push(phone);
          this.addValiation();
          this.createBranchFormGroup.controls.phone.reset();
        } else if (resp['code'] === '10016') {
          this._toastr.error('Phone already exists in the system.', 'Error');
        }
      });
  }
  removePhone(phoneIndex) {
    this.branch_phones.splice(phoneIndex, 1);
  }
  editPhone() {
    this.editphone = !this.editphone;
  }
  savePhone($event) {
    const reg = /^\d+$/;
    const phone = $event.target.value;
    if (reg.test(phone)) {
      this.newUpdatedPone = phone;
    } else if (!reg.test(phone)) {
      this._toastr.error('mobile phone must be number', 'Error');
    }
  }
  updatePhone(phoneIndex, currentPhone) {
    this.branch_phones[phoneIndex] = this.newUpdatedPone || currentPhone;
    this.editphone = !this.editphone;
  }
  closeUpdate() {
    this.editphone = !this.editphone;
  }

  getSelectedCity(city_id) {
    // get Days
    this._httpService
      .getData(`${environment.apiEndPoint}/distirct/city/${city_id}/`, {
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
        access_token: this.access_token
      })
      .subscribe(distircts => {
        // {"name":"Down Town","cityname":"Cairo","id":4}
        if (distircts['distircts'] && distircts['distircts'].length) {
          console.log(this.distircts)
          this.createBranchFormGroup.controls['area'].enable();
          return (this.distircts = distircts['distircts']);
        }
        this.distircts = [];
        this._toastr.warning(
          'Sorry There are no areas in this city',
          'no areas'
        );
        return this.createBranchFormGroup.controls['area'].setErrors({
          required: true
        });
      });
  }
  mapClicked($event: MouseEvent) {
    // {"coords":{"lat":30.163218889001502,"lng":30.161410466523535}}
    console.log($event)
    const latitude = $event['coords']['lat'];
    const longitude = $event['coords']['lng'];
    this.latitude = latitude;
    this.longitude = longitude;
    this.marker = {
      latitude: this.latitude,
      longitude: this.longitude
    };
  }
  upload() {
    const path = 'branch/area/card';
    const file = this.selectedFile;
    this._awsUploadImage.uploadFile(file, path);
    // this.selectedFiles.forEach((value, index) => {
    //   if (value === undefined) {
    //     this.selectedFiles.splice(index, 1);
    //   }
    // });
    // for (let index = 0; index < files.length; index++) {
    //   let imageName = new Date().getTime().toString() + '_' + files[index][0][0].name.trim();
    //   let file = files[index][0].item(0);
    //   console.log(file, imageName);
    //   // this._awsUploadImage.uploadFile(file, path, imageName);
    // }
  }

  selectFile(imageInput: any) {
    // this.selectedFile = event.target.files;
    // if (event.target.files && event.target.files[0] && event !== undefined) {
    // 	const file = event.target.files[0];
    // 	this.imageName = file['name'];
    // 	const reader = new FileReader();
    // 	reader.readAsDataURL(file); // read file as data url
    // 	reader.onload = onload => {
    // 		// called once readAsDataURL is completed
    // 		this.imagesSources.push(onload.target['result']);
    // 	};
    // 	const path = 'branch/area/card';
    // 	const file1 = this.selectedFile.item(0);
    // 	console.log('POP', file1);
    // 	this._awsUploadImage.uploadFile(file1, path);

    // 	// this.selectedFiles.push(targetFile);
    // 	// const fileee = Array.from(targetFile);
    // 	// this.selectedFiles.push(fileee);
    // }

    const file: File = imageInput.files[0];
    console.log(file)
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFiles = new ImageSnippet(event.target.result, file);

      console.log(
        this.selectedFile)
      var cardName = Date.now();
      console.log(this.selectedFile, 'fafas')
      var sazvz = this.dataURLtoFile(this.selectedFiles.src, cardName + '.jpg');
      this.imageName = sazvz.name
      console.log(sazvz.name, 'gfafafa');
      const path = 'branch/area/card';
      // let file1 = this.img_o.item(0);
      // console.log(file1 , 's')
      // console.log('POP', file1);
      this._awsUploadImage.uploadFile(sazvz, path);
    });


    reader.readAsDataURL(file);
    reader.onload = onload => {
      // called once readAsDataURL is completed
      this.imagesSources.push(onload.target['result']);
    };
  }
  dataURLtoFile(dataurl, filename) {
    console.log("data in image", dataurl, filename);
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  addAreas() {
    const form = this.createBranchFormGroup;
    const name = form.get('branchAreaName');
    const cost_per_seat = form.get('branchAreaCostPerSeats');
    const seats_number = form.get('branchAreaNoOfSeats');
    const tables_number = form.get('branchAreaNoOfTable');
    if (name.value === '' || name.value === null) {
      swal('please add area Name', 'error', 'error');
    } else if (tables_number.value === '' || tables_number.value === null) {
      swal('please add table number', 'error', 'error');
    } else if (seats_number.value === '' || seats_number.value === null) {
      swal('please add seats number', 'error', 'error');
    } else if (this.imageName === undefined) {
      swal('please add image', 'error', 'error');
    } else {
      console.log(cost_per_seat, ' aa');
      this.areas_addes.push({
        name: name.value,
        cost_per_seat: cost_per_seat.value || 0,
        seats_number: seats_number.value,
        tables_number: tables_number.value,
        photo: this.imageName
      });
      console.log(this.areas_addes);
      name.reset();
      cost_per_seat.reset();
      seats_number.reset();
      tables_number.reset();
      this.imageName = '';
    }

    // this.addValiation();
  }
  removeAreaAdded(areaIndex) {
    this.areas_addes.splice(areaIndex, 1);
    this.imageName = ""
    this.imagesSources = [];
  }
  uploadImage() {
    this.upload();
  }
  addValiation() {
    // if (this.branch_phones.length === 0) {
    // 	this.createBranchFormGroup.controls['phone'].setErrors(
    // 		Validators.required
    // 	);
    // 	return false;
    // }
    if (this.areas_addes.length === 0) {
      this.createBranchFormGroup.controls['branchAreaName'].setErrors(
        Validators.required
      );
      this.createBranchFormGroup.controls['branchAreaCostPerSeats'].setErrors(
        Validators.required
      );
      this.createBranchFormGroup.controls['branchAreaNoOfSeats'].setErrors(
        Validators.required
      );
      this.createBranchFormGroup.controls['branchAreaNoOfTable'].setErrors(
        Validators.required
      );
      return false;
    }
    return true;
  }
  create(formValue, stepper: MatStepper) {
    const valid = this.addValiation();
    if (this.createBranchFormGroup.invalid) {
      return this._toastr.error(
        'Please fill remain data to create branch',
        'Error'
      );
    }
    // POST: http://staging.zabatnee.com/api/vendor/restaurant/branch/
    if (this.longitude === 31.223359 && this.latitude === 30.0594838) {
      this.longitude = 0;
      this.latitude = 0;
    }
    const newBranch = {
      branch_phones: this.branch_phones,
      branch_areas: this.areas_addes,
      branch_name: formValue['placeName'],
      has_note: formValue['has_note'],
      note: formValue['note'],
      branch_district_id: formValue['area'],
      branch_address: formValue['address'],
      payment_method_id: formValue['paymentMethod'],
      is_active: false,
      longitude: this.longitude,
      latitude: this.latitude,
      threshold: formValue['threshold']
    };
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/restaurant/branch/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        newBranch
      )
      .subscribe(
        resp => {
          console.log(resp, 'ssssss');
          if (resp['code'] === '10000') {
            const name = resp['branch']['branch_name'];
            this.newBranchId = resp['branch']['branch_id'];
            this._toastr.success(
              `Now Your barnch name is ${name}`,
              'Branch Created'
            );
            stepper.next();
            this.createdbarnch.push(newBranch);
          }
          // {"code":"10016","detail":"Phone already exists in the system."}
          if (resp['code'] === '10016') {
            const errorDetials = resp['detial'];
            this._toastr.error(`${errorDetials}`, 'Branch Exists');
          }
        },
        error => {
          this._toastr.error(error['detail'], 'Error');
          console.log(error)
        }
      );
  }
  addTimeslotsnewdays(formValue, stepper: MatStepper) {
    const working_hours_from = formValue['working_hours_from'];
    const working_hours_to = formValue['working_hours_to'];
    const interval = formValue['interval'];
    const days = [];
    for (let index = 0; index < this.DaysNames.length; index++) {
      days.push({
        id: index + 1,
        number: index + 1,
        name: this.DaysNames[index],
        iso: this.DaysISONames[index],
        working_hours_from: working_hours_from,
        working_hours_to: working_hours_to,
        interval: interval,
        day_id: index + 1
      });
    }
    this.daypush.push(days);
    console.log(this.daypush, 'days');

    const slots = {
      slots: [
        {
          branch_id: this.newBranchId || '',
          days: days
        }
      ]
    };
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/timeslot/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        slots
      )
      .subscribe(res => {
        console.log('asdasd', res);
        if (res['code'] === '10000') {
          // stepper.next();
          this.setTimeSlotsEDFormGroup.reset()
          this._toastr.success('Add New Slots')


        }
      });
  }
  addTimeslotsnew(formValue, stepper: MatStepper) {
    const timeslotsArr = formValue['timeslots'];
    const days = [];
    const regTime = /^\d{1,2}:\d{2}([ap]m)?$/;

    for (let index = 0; index < this.DaysNames.length; index++) {
      // regular expression to match required time format
      const working_hours_from = timeslotsArr[index]['working_hours_from'];
      const working_hours_to = timeslotsArr[index]['working_hours_to'];
      const interval = timeslotsArr[index]['interval'];
      if (
        working_hours_from !== '' &&
        regTime.test(working_hours_from) &&
        working_hours_to !== '' &&
        regTime.test(working_hours_to) &&
        interval !== ''
      ) {
        days.push({
          id: index + 1,
          number: index + 1,
          name: this.DaysNames[index],
          iso: this.DaysISONames[index],
          working_hours_from: working_hours_from,
          working_hours_to: working_hours_to,
          interval: interval,
          day_id: index + 1
        });
        this.daypush.push(days);
        console.log(this.daypush, 'days');
      }
    }
    if (this.newBranchId === '') {
      return this._toastr.error('you must create branch first', 'ERROR');
    }
    const slots = {
      slots: [
        {
          branch_id: this.newBranchId || '',
          days: days
        }
      ]
    };
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/timeslot/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        slots
      )
      .subscribe(res => {
        console.log('asdasd', res);
        this.setTimeSlotsSDFormGroup.reset()
        this._toastr.success('Add New Slots')

        // stepper.next();
      });
    // stepper.next();
  }
  addTimeSlotsSpecifiDays(formValue, stepper: MatStepper) {
    const timeslotsArr = formValue['timeslots'];
    const days = [];
    const regTime = /^\d{1,2}:\d{2}([ap]m)?$/;

    for (let index = 0; index < this.DaysNames.length; index++) {
      // regular expression to match required time format
      const working_hours_from = timeslotsArr[index]['working_hours_from'];
      const working_hours_to = timeslotsArr[index]['working_hours_to'];
      const interval = timeslotsArr[index]['interval'];
      if (
        working_hours_from !== '' &&
        regTime.test(working_hours_from) &&
        working_hours_to !== '' &&
        regTime.test(working_hours_to) &&
        interval !== ''
      ) {
        days.push({
          id: index + 1,
          number: index + 1,
          name: this.DaysNames[index],
          iso: this.DaysISONames[index],
          working_hours_from: working_hours_from,
          working_hours_to: working_hours_to,
          interval: interval,
          day_id: index + 1
        });
        this.daypush.push(days);
        console.log(this.daypush, 'days');
      }
    }
    if (this.newBranchId === '') {
      return this._toastr.error('you must create branch first', 'ERROR');
    }
    const slots = {
      slots: [
        {
          branch_id: this.newBranchId || '',
          days: days
        }
      ]
    };
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/timeslot/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        slots
      )
      .subscribe(res => {
        console.log('asdasd', res);
        stepper.next();
      });
    // stepper.next();
  }
  addTimeSlotsEveryDay(formValue, stepper: MatStepper) {
    // {working_hours_from: "01:01", working_hours_to: "01:01", interval: 2}
    const working_hours_from = formValue['working_hours_from'];
    const working_hours_to = formValue['working_hours_to'];
    const interval = formValue['interval'];
    const days = [];
    for (let index = 0; index < this.DaysNames.length; index++) {
      days.push({
        id: index + 1,
        number: index + 1,
        name: this.DaysNames[index],
        iso: this.DaysISONames[index],
        working_hours_from: working_hours_from,
        working_hours_to: working_hours_to,
        interval: interval,
        day_id: index + 1
      });
    }
    this.daypush.push(days);
    console.log(this.daypush, 'days');

    const slots = {
      slots: [
        {
          branch_id: this.newBranchId || '',
          days: days
        }
      ]
    };
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/timeslot/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        slots
      )
      .subscribe(res => {
        console.log('asdasd', res);
        if (res['code'] === '10000') {
          stepper.next();
        }
      });
    // stepper.next();
  }
  /**
   * get the marker place name when user chage it.
   */
  getPlaceName() {
    const header = new HttpHeaders();
    const other_header = header.append('withCredentials', 'false');
    this._http
      .get(
        `${environment.googleMapsApiEndPoint}?key=${environment.googleMapsApiKey
        }&sensor=true&latlng=${this.latitude},${this.longitude}`,
        {
          headers: other_header
        }
      )
      .subscribe(data => console.log(data));
  }

  getSelectedWorkFlow(index) {
    const guid = [];
    this.workflows.forEach(branch => {
      guid.push(branch['guid']);
    });
    this.currentWorkFlow = guid[index];
  }
  finish(stepper: MatStepper) {
    // {"workflows":[{"branch_id":"21054f61-704e-4729-bd3c-39f0ae757513","wf_id":"c82e5f28-89c1-4cb4-ad16-852accc28e42"}]}
    const workflows = {
      workflows: [
        {
          branch_id: this.newBranchId,
          wf_id: this.workFlowFormGroup.get('workflows').value
        }
      ]
    };
    const cancelation = {
      policies: [
        {
          branch_id: this.newBranchId,
          days: this.policies
        }
      ]
    };
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/workflow/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        workflows
      )
      // {"code":"10000","detail":"Success."}
      .subscribe(resp => {
        console.log(resp, 'sssss');
        if (resp['code'] === '10000') {
          // this._route.navigate(['/R/branches']);
          this._toastr.success('Branch Created', 'Welcome');
          // this._router.navigate(['/R/branches']);
          stepper.next();

        }
      });
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/cancelationpolicy/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        cancelation
      )
      // {"code":"10000","detail":"Success."}
      .subscribe(resp => {
        console.log(resp, 'sssss');
        if (resp['code'] === '10000') {
          // this._route.navigate(['/R/branches']);
          // this._toastr.success('Branch Created', 'Welcome');
          // this._router.navigate(['/R/branches']);
          stepper.next();
        }
      });
  }

  createAndPublish(stepper: MatStepper) {
    const workflows = {
      workflows: [
        {
          branch_id: this.newBranchId,
          wf_id: this.workFlowFormGroup.get('workflows').value
        }
      ]
    };
    const cancelation = {
      policies: [
        {
          branch_id: this.newBranchId,
          days: this.policies
        }
      ]
    };
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/workflow/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        workflows
      )
      // {"code":"10000","detail":"Success."}
      .subscribe(resp => {
        console.log(resp, 'sssss');
        if (resp['code'] === '10000') {
          // this._route.navigate(['/R/branches']);
          this._toastr.success('Branch Created', 'Welcome');
          this._httpService
            .postData(
              `${environment.apiEndPoint}/vendor/restaurant/branch/${this.newBranchId
              }/switchactivation/`
            )
            .subscribe(response => {
              switch (response['code']) {
                case '10000':
                  swal('Done', 'The Barnch is published', 'success');
                  this._router.navigate(['/R/branches']);

                  break;
              }
            });
        }
        this._httpService
          .postData(
            `${environment.apiEndPoint}/vendor/cancelationpolicy/`,
            {
              client_id: environment.clientId,
              client_secret: environment.clientSecret,
              access_token: this.access_token
            },
            cancelation
          )
          // tslint:disable-next-line: no-shadowed-variable
          .subscribe(resp => {
            console.log(resp, 'sssss');
            if (resp['code'] === '10000') {
              // this._route.navigate(['/R/branches']);
              // this._toastr.success('Branch Created', 'Welcome');
              // this._router.navigate(['/R/branches']);
              stepper.next();
            }
          });
      });
  }
  getSelectedPaymentMehod(payment_method_id) {
    this.areas_addes = [];
    const cost_per_seat = this.createBranchFormGroup.controls[
      'branchAreaCostPerSeats'
    ];
    if (payment_method_id === 0) {
      cost_per_seat.setValue('0');
      this.viewcancelation = false;
      return cost_per_seat.disable();
    }
    cost_per_seat.setValue('');
    this.viewcancelation = true;
    return cost_per_seat.enable();
  }
  getSelectedInterval(selectedInterval) {
    this.setTimeSlotsEDFormGroup.controls['interval'].setValue(
      (this.selectedd = selectedInterval)
    );
    console.log(this.selectedd, ' intet');

    // this.tours.getPaymentMethod().subscribe((data: any) => {
    // 	this.paymentMethods = data.payment_methods;
    // });
    // this.tours.getWorkFlowPayment(this.haspaymentId).subscribe((data: any) => {
    // 	this.hasPayments = data.workflows;
    // });
  }
  getWeekDays() {
    this.tours.getWeekDayscancel().subscribe((data: any) => {
      //   console.log(data,'week')
      this.weekDaysww = data.days;
      // console.log(this.weekDaysww, '2ololol');

      for (let i = 0; i < this.weekDaysww.length; i++) {
        this.daysofWeeks.push({
          id: this.weekDaysww[i].id,
          name: this.weekDaysww[i].name,
          selected: false,
          isSet: false
        });
      }
    });
  }

  changePayment(paymentid) {
    this.areas_addes = [];
    const cost_per_seat = this.createBranchFormGroup.controls[
      'branchAreaCostPerSeats'
    ];
    console.log(paymentid, 'SS');
    if (paymentid === 1) {
      // pay on arrival
      this.viewCancelation = false;
      this.haspaymentId = 2;
      this.showfresh = false;
      cost_per_seat.setValue('0');
      cost_per_seat.disable();
    } else {
      // 2 credit card
      this.viewCancelation = true;
      this.haspaymentId = 1;
      this.showfresh = true;

      cost_per_seat.setValue('');
      cost_per_seat.enable();
    }

    this.tours.getWorkFlowPayment(this.haspaymentId).subscribe((data: any) => {
      this.hasPayments = data.workflows;
      console.log(this.hasPayments);
    });
  }

  AddRestaurantPolicy() {
    if (this.periodd === undefined) {
      this.periodMsg = true;
      this.perioderrorMsg = 'please enter period';
    } else if (this.deductiond === undefined) {
      this.dedutionmsg = true;
      this.deductionerrorMsg = 'please enter deduction';
    } else {
      this.periodMsg = false;
      this.dedutionmsg = false;
      const policyObj = {};
      this.dayss = [];
      for (let j = 0; j < this.daysofWeeks.length; j++) {
        if (this.daysofWeeks[j].selected === true) {
          this.dayss.push({
            period: this.periodd,
            deduction: this.deductiond,
            day_name: this.daysofWeeks[j].name,
            day_id: this.daysofWeeks[j].id
          });
          this.policies.push({
            period: this.periodd,
            deduction: this.deductiond,
            day_name: this.daysofWeeks[j].name,
            day_id: this.daysofWeeks[j].id.toString()
            // id: ''
          });
        }
      }
      this.policiesToSave.push({ days: this.dayss });
      const policyy = {};
      policyy['periodd'] = '';
      policyy['deductiond'] = '';
      this.cancelationForm = {};
      this.periodd = '';
      this.deductiond = '';
      console.log(this.policiesToSave);
    }
  }
  removePolicy(index) {
    this.policiesToSave.splice(index, 1);
    this.policies = [];
    this.dayss.splice(index, 1);
  }
  BranchtoggleAll(flag) {
    if (flag === 'day') {
      this.show = false;
      const toggleStatus = this.SelectAllDays;
      this.daysofWeeks.forEach(bar => {
        bar.selected = true;
        console.log(bar);
      });
    }
  }
  checknumber(ss) {
    if (ss > 100) {
      swal('cant set number > 100 le ', 'error', 'error');
    }
  }
  googlepluscode(event: any) {
    this.spinner = true;
    const plusCode = this.input.nativeElement.value;
    this.tours.getGooglePulsCode(plusCode).subscribe((data: any) => {
      // console.log();
      console.log(data);
      this.spinner = false;
      this.latitude = data.plus_code.geometry.location.lat;
      this.longitude = data.plus_code.geometry.location.lng
      this.zoom = 15;
    });
  }
  contact_person: any[] = [];
  contacts = {
    contact_methods: []
  }
  selectFacebook(e: MatCheckboxChange) {
    console.log(e)
    if (e.checked == true) {
      this.contacts['contact_methods'].push(1)
    }
  }
  selectInstagram(e: MatCheckboxChange) {
    console.log(e)
    if (e.checked == true) {
      this.contacts['contact_methods'].push(2)
    }
  }
  selectWhatsapp(e: MatCheckboxChange) {
    console.log(e)
    if (e.checked == true) {
      this.contacts['contact_methods'].push(3)
    }
  }
  selectMob(e: MatCheckboxChange) {
    console.log(e)
    if (e.checked == true) {
      this.contacts['contact_methods'].push(4)
    }
  }
  selectEm(e: MatCheckboxChange) {
    console.log(e)
    if (e.checked == true) {
      this.contacts['contact_methods'].push(5)
    }
  }

  Textpersong;
  addPerson(ss) {
    console.log(ss)
    this.contact_person.push(ss);
    this.Textpersong = ''
  }
  removedate(data: any) {
    console.log(data)
    const index = this.contact_person.indexOf(data);

    if (index >= 0) {
      this.contact_person.splice(index, 1);
    }
    console.log(this.contact_person);

  }
  saveContact(data) {
    this.contacts['branch_guid'] = this.newBranchId;
    this.contacts['contact_from'] = this.contacts['contact_from'] + ':' + '00'
    this.contacts['contact_to'] = this.contacts['contact_to'] + ':' + '00'
    this.contacts['contact_person'] = this.contact_person;
    console.log(data, this.contact_person, this.contacts.contact_methods)

    this.tours.addContactDataBranch(data).subscribe(data => {
      console.log(data);
      swal('Contact Data Was send', '', 'success');
      this._router.navigate(['/R/branches']);
    }, err => {
      console.log(err)
      swal('Contact Data Was not send', '', 'error');

    })

  }
}
