import { Component, OnInit, Inject } from '@angular/core';
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
	MatSnackBar
} from '@angular/material';
import swal from 'sweetalert2';
import { TourListService, HttpService } from '../../../_services';
import { ToastrService } from 'ngx-toastr';
import { environment } from './../../../../environments/environment';
import { SportsListService } from './../../../_services/sports.service';

@Component({
	selector: 'app-member-ship',
	templateUrl: 'create-mem-ship.html',
	styleUrls: ['./member-ship-list.component.scss']
})
export class CreateMemberShipComponent implements OnInit {
	phone: string;
	getData: any;
	userlist: any;
	fristname: any;
	userid: any;
	lastname: any;
	branches: any;
	selectedbranchId: any;
	showcontant = false;
	classss: any;
	coursss: any;
	selectedcouress: any;
	trainner: any;
	selectedtrainner: any;
	selectedclasss: any;
	packageList: any;
	cost: any;
	selectedTypeId: any;
	duration: any;
	invitations: any;
	datas: any;
	notes: string;
	mobile: any;
	reservation: any;
	pageNum: any;
	pageSize: number;
	totalItems: any;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any = [],
		public dialogRef: MatDialogRef<CreateMemberShipComponent>,
		private tourListService: TourListService,
		private toastr: ToastrService,
		private _http: HttpService,
		private Sports: SportsListService,
		public snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.getBranch();
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	searchPhoneExists(phone) {
		const testRegular = /^[a-zA-Z]+$/;
		if (testRegular.test(this.phone)) {
			swal('attention', 'the phone shloud be number And Not Null', 'error');
		} else if (phone === null || phone === '') {
			swal(' phone', 'Enter phone', 'error');
		} else {
			this.tourListService.getPhoneExsist(phone).subscribe(phones => {
				if (phones.code === '10000') {
					this.showcontant = true;
					this.getData = phones;
					this.userlist = phones.user;
					this.userid = this.userlist.id;
					// console.log('user phone', this.userlist);
					this.fristname = this.userlist.first_name;
					this.lastname = this.userlist.last_name;
					this.toastr.success(
						this.userlist.first_name + this.userlist.last_name,
						'welcome back'
					);
				} else if (phones.code === '10010') {
					this.fristname = '';
					this.lastname = '';
					this.showcontant = true;
					this.toastr.warning('User mobile not found in system!', 'Oops!');
				}
			});
		}
	}
	getBranch() {
		this._http
			.getData(
				`${environment.apiEndPoint}/vendor/fitness/branch/`
				// {
				// 	client_id: environment.clientId,
				// 	client_secret: environment.clientSecret,
				// 	access_token: this.access_token
				// }
			)
			.subscribe(bran => (this.branches = bran['branches']));
	}
	tripDateChange() {
		// console.log(this.selectedbranchId, ' guid Branch');

		this.Sports.getBranch(this.selectedbranchId).subscribe((barnch: any) => {
			// this.isLoading = false;
			this.coursss = barnch.courses;
			// this.times = Date.trips.packages;
			// console.log('tour dates', this.coursss);
		});
		this.selectedTypeId = '';
	}
	listtrainner() {
		// console.log(this.selectedcouress, 's');
		const data = {
			branchId: this.selectedbranchId,
			courseId: this.selectedcouress
		};
		this.Sports.gettrainner(data).subscribe((tran: any) => {
			this.trainner = tran.Trainers;
			// this.capacity = this.TimeF[0].remainCapacity;
			// console.log('tour datesfafafsafafsafsafsafasfaafa', this.trainner);
		});
		this.selectedTypeId = '';
	}
	listclasss() {
		// console.log(this.selectedtrainner, ' trainer');
		const data = {
			courseId: this.selectedcouress,
			branchId: this.selectedbranchId,
			trainerId: this.selectedtrainner
		};
		this.Sports.getclaasse(data).subscribe((tran: any) => {
			this.classss = tran.classes;
			// this.capacity = this.TimeF[0].remainCapacity;
			console.log('tour datesfafafsafafsafsafsafasfaafa', this.classss);
		});
		this.selectedTypeId = '';
	}

	listclasssType(tranid) {
		// console.log(this.selectedclasss, ' class id');
		this.selectedclasss = tranid;
		// console.log('yes1', this.selectedclasss);
		for (let i = 0; i < this.classss.length; i++) {
			if (this.classss[i].guid === tranid) {
				//   console.log("yes");
				this.packageList = this.classss[i].duration;
				this.cost = this.packageList.cost;
			}
			// console.log('sfsafasfas', this.packageList);
		}
		this.selectedTypeId = '';
	}
	typeName(id) {
		// console.log(id, '<>w');
		// console.log(this.selectedTypeId, 'wfawfa');
		this.selectedTypeId = id;
		// console.log('yes1', this.selectedTypeId);
		for (let i = 0; i < this.packageList.length; i++) {
			if (this.packageList[i].guid === id) {
				//   console.log("yes");
				this.duration = this.packageList[i];
				this.cost = this.duration.cost;
				this.invitations = this.duration.invitations;
			}
			console.log(this.duration, ' fsa');
		}
	}
	createresevation() {
		// swal(this.fristname + this.phone.substr(1) || '' , this.lastname , 'info')
		this.tourListService.getPhoneExsist(this.phone).subscribe((phone: any) => {
			this.getData = phone;
			this.userlist = phone.user;
			//   console.log(phone, 'zz');
			if (phone.code === '10000') {
				this.datas = {
					userId: this.userid,
					classId: this.selectedclasss,
					subscriptionId: this.selectedTypeId,
					notes: this.notes || ''
				};
				console.log('data', this.datas);
			} else if (phone.code === '10010') {
				this.datas = {
					firstName: this.fristname,
					lastName: this.lastname,
					mobile: this.phone.substr(1) || '',
					classId: this.selectedclasss,
					subscriptionId: this.selectedTypeId,
					notes: this.notes || ''
				};
				console.log('var data', this.datas);
			}

			this.Sports.createsubscription(this.datas).subscribe(
				create => {
					this.snackBar.open(
						'Mr(s)' + this.fristname + '-' + this.lastname,
						'Resevation Create',
						{
							duration: 6000
						}
					);
					this.dialogRef.close();
					console.log(create, 'sata');
				},
				error => {
          console.log(error  , ' ')
					const errorStatus = error.error.code;
					switch (errorStatus) {
						case '10001':
							swal('Please provide missing parameter(s)', 'Oops...', 'error');
							break;
						case '11047':
							swal('Time Is left Cant Selected It', 'Oops...!', 'error');
							break;
					}
				}
			);
		});
		this.Sports.getSubscription({
			page: 1,
			page_size: '10',
			sort_type: 'asc'
		}).subscribe(reservation => {
			console.log('AZ', reservation['reservations']);
			this.reservation = reservation['reservations']['reservations'];
			this.pageNum = reservation['reservations']['current_page'];
			this.pageSize = 10;
			this.totalItems = reservation['reservations']['total_pages'];
		});
	}
}

@Component({
	selector: 'app-details-member-ship',
	templateUrl: 'details-member-ship.html',
	styleUrls: ['./member-ship-list.component.scss']
})
export class DetailsMemberShipComponent implements OnInit {
	res_details: any;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any = [],
		public dialogRef: MatDialogRef<DetailsMemberShipComponent>
	) {}

	ngOnInit() {
		console.log(this.data, ' data o');
		this.res_details = this.data;
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
}

@Component({
	selector: 'app-member-ship-list',
	templateUrl: './member-ship-list.component.html',
	styleUrls: ['./member-ship-list.component.scss']
})
export class MemberShipListComponent implements OnInit {
	periodList = ['Week', 'Month', 'Quarter', 'Year'];
	res_data_from: any ;
	res_date_to: any;
	phone: string;
	getData: any;
	userlist: any;
	fristname: any;
	userid: any;
	lastname: any;
	branches: any;
	selectedbranchId: any;
	showcontant = false;
	selectedThrough: any;
	classss: any;
	coursss: any;
	selectedcouress: any;
	trainner: any;
	selectedtrainner: any;
	selectedclasss: any;
	packageList: any;
	cost: any;
	selectedTypeId: any;
	duration: any;
	invitations: any;
	datas: any;
  notes: string;
  filterdate = {
    from : '',
    to: ''
  }
	channels = [
		{
			id: '',
			name: 'Through (ALL)'
		},
		{
			id: 0,
			name: 'App'
		},
		{
			id: 1,
			name: 'Call'
		}
	];
	filterObject = {
		page: '1',
		branchId: '',
		// status: '',
		through_id: '',
		page_size: '10',
		// trip_date: '',
		// trip_time: '',
		classId: '',
		courseId: '',
		phone: '',
		trainerId: '',
		// sort_column: '',
		sort_type: 'asc',
		isActive: ''
	};
	mobile: any;
	reservation: any;
	reservationDetails: any;
	Resload = true;
	noReservations = false;
	checked;
	pageNum: any;
	pageSize: number;
	totalItems: any;
	activeclasss = 'Active Class';
	pagego: any;
	insight: any = [];
	constructor(
		public dialog: MatDialog,
		private tourListService: TourListService,
		private toastr: ToastrService,
		private _httpService: HttpService,
		private Sports: SportsListService
	) {}

	ngOnInit() {
		this.getBranch();
		this.getResvation();

		this.Sports.getinsights().subscribe((insights: any) => {
			this.insight = insights.insight;
			console.log(this.insight);
			// this.capacity = this.TimeF[0].remainCapacity;
			// console.log('tour datesfafafsafafsafsafsafasfaafa', this.classss);
		});
	}

	openCreate(): void {
		//     console.log("tour data", this.tours);
		this.dialog.open(CreateMemberShipComponent, {
			width: '40vw',
			height: '80%'
		});
	}
	openDialogsportsEventDetails(id): void {
		// console.log(id)
		for (let i = 0; i < this.reservation.length; i++) {
			if (this.reservation[i].guid === id) {
				this.reservationDetails = this.reservation[i];
				// console.log('done' , this.reservationDetails)
			}
		}
		this.dialog.open(DetailsMemberShipComponent, {
			width: '40vw',
			height: '80%',
			data: this.reservationDetails
		});
	}
	setDate(date) {
		const dateISO = new Date(date);
		this.res_data_from =
			dateISO.getFullYear() +
			'-' +
			(dateISO.getMonth() + 1) +
			'-' +
			dateISO.getDate();

		// console.log("DATESZZFAFASSFA ", this.res_from)
	}
	setDate2(date) {
		const dateISO = new Date(date);
		this.res_date_to =
			dateISO.getFullYear() +
			'-' +
			(dateISO.getMonth() + 1) +
			'-' +
			dateISO.getDate();
		// console.log("apapppapap ", this.res_to)
	}
	SubmitDate() {

   this.filterdate = {
      from : this.res_data_from,
      to : this.res_date_to
    }

		this.Sports.getDashboardMemberShip(this.filterdate).subscribe((insights: any) => {
      this.insight = insights.insight;
		  });
		console.log(this.res_data_from, 'date from', 'date to', this.res_date_to);
  }

	getBranch() {
		this._httpService
			.getData(
				`${environment.apiEndPoint}/vendor/fitness/branch/`
				// {
				// 	client_id: environment.clientId,
				// 	client_secret: environment.clientSecret,
				// 	access_token: this.access_token
				// }
			)
			.subscribe(bran => (this.branches = bran['branches']));
	}
	tripDateChange() {
		// console.log(this.selectedbranchId, ' guid Branch');

		this.Sports.getBranch(this.selectedbranchId).subscribe((barnch: any) => {
			// this.isLoading = false;
			this.coursss = barnch.courses;
			// this.times = Date.trips.packages;
			// console.log('tour dates', this.coursss);
		});
	}
	listtrainner() {
		// console.log(this.selectedcouress, 's');
		const data = {
			branchId: this.selectedbranchId,
			courseId: this.selectedcouress
		};
		this.Sports.gettrainner(data).subscribe((tran: any) => {
			this.trainner = tran.Trainers;
			// this.capacity = this.TimeF[0].remainCapacity;
			// console.log('tour datesfafafsafafsafsafsafasfaafa', this.trainner);
		});
	}
	listclasss() {
		// console.log(this.selectedtrainner, ' trainer');
		const data = {
			courseId: this.selectedcouress,
			branchId: this.selectedbranchId,
			trainerId: this.selectedtrainner
		};
		this.Sports.getclaasse(data).subscribe((tran: any) => {
			this.classss = tran.classes;
			// this.capacity = this.TimeF[0].remainCapacity;
			// console.log('tour datesfafafsafafsafsafsafasfaafa', this.classss);
		});
	}

	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	setbranch(branch) {
		this.filterObject.branchId = branch;
	}
	setcourse(course) {
		this.filterObject.courseId = course;
	}
	settariner(trainer) {
		this.filterObject.trainerId = trainer;
	}
	setclasss(classs) {
		this.filterObject.classId = classs;
	}
	setthrough(throg) {
		this.filterObject.through_id = throg;
	}
	reset() {
		this.activeclasss = 'Active Class';
    this.noReservations = false;
    this.mobile = '';
		this.filterObject = {
			page: '1',
			branchId: '',
			// status: '',
			through_id: '',
			page_size: '10',
			// trip_date: '',
			// trip_time: '',
			classId: '',
			courseId: '',
			phone: '',
			trainerId: '',
			// sort_column: '',
			sort_type: 'asc',
			isActive: ''
		};
		this.clearsearch();
		this.Sports.getSubscription({
			page: '1',
			page_size: '10',
			sort_type: 'asc'
		}).subscribe(reservation => {
			console.log('Serach parmater', reservation);
			this.reservation = reservation['reservations'];
			this.pageNum = reservation['current_page'];
			this.pageSize = 10;
			this.totalItems = reservation['total_pages'];
			if (this.reservation.length === 0) {
				this.Resload = false;
				this.noReservations = true;
			} else if (this.reservation.length > 0) {
				this.Resload = false;
				this.noReservations = false;
			}
		});
	}
	clearsearch() {
		this.selectedTypeId = '';
		this.selectedbranchId = '';
		this.selectedclasss = '';
		this.selectedtrainner = '';
		this.selectedcouress = '';
		this.checked = '';
		this.selectedThrough = '';
		this.pagego = '';
	}
	// gopageNum(pagego) {
	// 	if (pagego === null || pagego > this.totalItems || pagego < 0 || pagego === undefined) {
	// 		pagego = 1;
	// 		this.filterObject.page = pagego;
	// 		swal('Please Enter The Page Correctly', 'number of page not contain in pagination pages', 'error');
	// 	} else {
	// 		this.filterObject.page = pagego;
	// 		console.log(pagego);
	// 		this.Sports.getSubscription(this.filterObject).subscribe(reservation => {
	// 			console.log('Serach parmater', reservation);
	// 			this.reservation = reservation['reservations'];
	// 			this.pageNum = reservation['current_page'];
	// 			this.pageSize = 10;
	// 			this.totalItems = reservation['total_pages'];
	// 			if (this.reservation.length === []) {
	// 				this.Resload = false;
	// 				this.noReservations = true;
	// 			} else if (this.reservation.length > 0) {
	// 				this.Resload = false;
	// 				this.noReservations = false;
	// 			}
	// 		});
	// 	}
	// }
	onKeydown(event) {
		if (event.key === 'Enter' || event.key === 'Tab') {
			// console.log(event  , this.pagego , 'fu');
			if (
				this.pagego === null ||
				this.pagego > this.totalItems ||
				this.pagego < 0 ||
				this.pagego === undefined
			) {
				this.pagego = 1;
				this.filterObject.page = this.pagego;
				// swal('Please Enter The Page Correctly', 'number of page not contain in pagination pages', 'error');
				this.toastr.warning(
					'Please Enter The Page Correctly',
					'Number The Page Not Contain In Pagination Pages'
				);
			} else {
				this.filterObject.page = this.pagego;
				console.log(this.pagego);
				this.Sports.getSubscription(this.filterObject).subscribe(
					reservation => {
						console.log('Serach parmater', reservation);
						this.reservation = reservation['reservations'];
						this.pageNum = reservation['current_page'];
						this.pageSize = 10;
						this.totalItems = reservation['total_pages'];
						if (this.reservation.length === []) {
							this.Resload = false;
							this.noReservations = true;
						} else if (this.reservation.length > 0) {
							this.Resload = false;
							this.noReservations = false;
						}
					}
				);
			}
		}
	}
	search() {
		this.Resload = true;
		if (this.mobile !== '' || this.mobile !== undefined) {
			this.filterObject.phone = this.mobile;
		}
		if (this.filterObject.phone === undefined) {
			this.filterObject.phone = '';
		}
		if (this.checked === true) {
			this.filterObject.isActive = 'True';
			this.activeclasss = 'Active Class';
		}
		if (this.checked === false) {
			this.filterObject.isActive = 'False';
			this.activeclasss = 'In Active  Class';
		}
		if (this.checked === undefined) {
			this.filterObject.isActive = '';
		}
		console.log(this.filterObject, 'filterdata');
		if (this.pageNum !== '1') {
			this.filterObject.page = '1';
			// this.filterObject.state_id = '';
			// this.filterObject.status = '';
			this.filterObject.sort_type = '';
			// this.filterObject.wf_status = '';
			// this.filterObject.sort_column = '';
			this.Sports.getSubscription(this.filterObject).subscribe(reservation => {
				console.log('Serach parmater', reservation['reservations']);
				this.reservation = reservation['reservations'];
				this.pageNum = reservation['current_page'];
				this.pageSize = 10;
				this.totalItems = reservation['total_pages'];
				if (this.reservation.length === []) {
					this.Resload = false;
					this.noReservations = true;
				} else if (this.reservation.length > 0) {
					this.Resload = false;
					this.noReservations = false;
				}
			});
		} else {
			console.log(this.filterObject, ' filter');
			this.Sports.getSubscription(this.filterObject).subscribe(reservation => {
				console.log('Serach parmater', reservation);
				this.reservation = reservation['reservations'];
				this.pageNum = reservation['current_page'];
				this.pageSize = 10;
				this.totalItems = reservation['total_pages'];
				if (this.reservation.length === 0) {
					this.Resload = false;
					this.clearsearch();
					this.filterObject = {
						page: '1',
						branchId: '',
						// status: '',
						through_id: '',
						page_size: '10',
						// trip_date: '',
						// trip_time: '',
						classId: '',
						courseId: '',
						phone: '',
						trainerId: '',
						// sort_column: '',
						sort_type: 'asc',
						isActive: ''
					};
					this.noReservations = true;
				} else if (this.reservation.length > 0) {
					this.Resload = false;
					this.noReservations = false;
				}
			});
		}
	}
	getResvation() {
		this.Resload = true;
		this.pageNum = 1;
		this.Sports.getSubscription({
			page: this.pageNum,
			page_size: '10',
			sort_type: 'asc'
		}).subscribe(reservation => {
			console.log('AZ', reservation);
			this.reservation = reservation['reservations'];
			this.pageNum = reservation['current_page'];
			this.pageSize = 10;
			this.totalItems = reservation['total_pages'];
			if (this.reservation.length === 0) {
				this.Resload = false;
				this.noReservations = true;
				this.clearsearch();
			} else if (this.reservation.length > 0) {
				this.Resload = false;
				this.noReservations = false;
			}
		});
	}

	pageChange(event) {
		this.Resload = true;
		this.pageNum = event;
		this.filterObject.page = this.pageNum;
		console.log(event);
		// this.isLoading = true;
		console.log(this.filterObject, 'data before sent');
		this.Sports.getSubscription(this.filterObject).subscribe(reservation => {
			this.Resload = false;
			console.log('wz', reservation);
			this.reservation = reservation['reservations'];
			//    this.totalItems = reservation['total_pages'];
			//    this.pageNum = reservation['current_page'];
			// this.isLoading = false;
		});
	}
}
