import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { MouseEvent } from '@agm/core';
import { ToursService, UploadimgService } from '../../../../../_services';

@Component({
  selector: 'app-tour-details',
  templateUrl: './tour-details.component.html',
  styleUrls: ['./tour-details.component.scss']
})
export class TourDetailsComponent implements OnInit {
  id: string;
  tour: any;
  tripDetails: any;
  slots: any[] = [];
  packages: any[] = [];
  participants: any[] = [];
  participant: any = {};
  instr: any = '';
  inclu: any = '';
  enclu: any = '';
  showpackages;
  edit = true;
  packagescostlist: any[] = [];
  costForm: {};
  costlist: any[];
  name: any;
  cost: any;
  check: any;
  myForm: any;
  packagename: any;
  desc: any;
  packagesadd: any[] = [];
  pickid: any;
  packcost = [];
  packageData: any[];
  part = {};
  paymentMethods: any;
  viewcancelation: boolean;
  maxcollection: number;
  mincollection: number;
  flagcollection: boolean;
  flagcollectionnot: boolean;
  zerocollection: boolean;
  msgcollectionzero: string;
  haspaymentId: number;
  msgcollectionnotZero: string;
  hasPayments: any;
  collection_requirement: any;
  PoliciesToSave = [];
  daysofWeek = [];
  periodmsg: boolean;
  perioderrorMsg: string;
  dedutionmsg: boolean;
  deductionerrorMsg: string;
  deductiond: any;
  periodd: any;
  dayss: any[] = [];
  Policies: any[] = [];
  cancelationForm;
  workflow_id: any;
  payment_method_id: any;
  Tourid: any;
  dataWF: {};
  policyWithId: any;
  selectedPayment: any;
  selectedColl: any;
  weekDays: any;
  selectedWf: any;
  exedit: any;
  incedit: any;
  insedit: any;
  show = true;
  buttonName: any = 'Show';
  zoom = 8;
  lat: any = 30.038710488299724;
  lng = 31.21996034750498;
  lang: any;
  latng: any;
  markers: marker[] = [];
  weekDaysww: any;
  daysofWeeks: any[] = [];
  selectname: any;
  cancelactionpolist: any[] = [];
  editField: any;
  packagessend: any[] = [];
  participantssend: any[] = [];
  card: string;
  CoverImage: any;
  selectedFiles: any;
  imgcover: any;
  cardImg: any;
  selecCover: any;
  gallery: any[] = [];
  galleryImages: FileList;
  img_o: FileList;
  img_c: FileList;
  imageSrc: string | ArrayBuffer;
  imageSrcc: string | ArrayBuffer;
  imageSrcs: string | ArrayBuffer;
  imsaa: string;
  iamaa: string;
  iaaaz: string;
  paymes: number;
  loadnew = false;
  extend: any;

  constructor(
    private route: ActivatedRoute,
    private tours: ToursService,
    private uploadimgService: UploadimgService,
    private routers: Router
  ) { }
  toggle() {
    this.show = !this.show;
  }
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.tours.getTourDetails(this.id).subscribe((data: any) => {
      this.tour = data.tours['0'];
      this.paymes = this.tour.payment_methods[0].id;
      console.log('Trip Dates', this.paymes);

      this.lat = parseInt(this.tour.meetingPoint[0].latitude);
      this.lng = parseInt(this.tour.meetingPoint[0].longitude);
      console.log('tour data is', this.tour);
      this.pickid = data.tours['0'].tripDetails['0'].guid.replace(/-/g, '');
      this.tripDetails = data.tours['0'].tripDetails['0'];
      this.slots = this.tripDetails.slots;
      //  console.log('Trip Dates', this.tour);
      console.log('Trip Dates', this.packages);
      //  for (var i = 0 ; i < this.tripDetails.slots.length ; i++){
      //      this.slots.push(this.tripDetails.slots[i]);
      //  }
      for (let i = 0; i < this.tripDetails.packages.length; i++) {
        this.packages.push(this.tripDetails.packages[i]);
      }
      for (let i = 0; i < this.packages.length; i++) {
        this.participants.push(this.packages[i].participants);
      }
      for (let i = 0; i < this.participants.length; i++) {
        this.participant = this.participants[i];
      }
      this.instr = this.tour.additionalInfo[0].instructions;
      this.inclu = this.tour.additionalInfo[0].inclusions;
      this.enclu = this.tour.additionalInfo[0].exclusions;
      this.cancelactionpolist = this.tour.cancellationPolicy;
      this.instr = this.instr.split('|').join('\n');
      this.enclu = this.enclu.split('|').join('\n');
      this.inclu = this.inclu.split('|').join('\n');
      this.selectedPayment = this.tour.payment_methods[0].id;
      this.selectedColl = this.tour.collection_requirements;
      this.selectedWf = this.tour.workflow.guid;
      this.selectname = this.tour.workflow.name;
      console.log(this.selectedWf);
      if (this.paymes === 1) {
        this.haspaymentId = 2;

        // this.viewcancelation = false;
      }
      if (this.paymes === 2) {
        this.haspaymentId = 1;
        // this.viewcancelation = false;
      }
      this.getPayment();

      // console.log("details",this.instr,this.instr,this.enclu,this.inclu);
    });

    this.show = true;
    this.getWeekDays();
    console.log('lat', this.lat, this.lng);
    this.markers.push({
      lat: this.lat.toString(),
      lng: this.lng.toString()
    });
    console.log(this.markers.length, 'ss');
  }

  loadbasicinfo() {
    this.tours.getTourDetails(this.id).subscribe((data: any) => {
      this.tour = data.tours['0'];
      // tslint:disable-next-line: radix
      this.lat = parseInt(this.tour.meetingPoint[0].latitude);
      // tslint:disable-next-line: radix
      this.lng = parseInt(this.tour.meetingPoint[0].longitude);
    });
  }
  loadAPiDetials() {
    this.tours.getTourDetails(this.id).subscribe((data: any) => {
      this.tour = data.tours['0'];
      // tslint:disable-next-line: radix
      this.lat = parseInt(this.tour.meetingPoint[0].latitude);
      // tslint:disable-next-line: radix
      this.lng = parseInt(this.tour.meetingPoint[0].longitude);
      console.log('tour data is', this.tour);
      this.pickid = data.tours['0'].tripDetails['0'].guid.replace(/-/g, '');
      this.tripDetails = data.tours['0'].tripDetails['0'];
      this.slots = this.tripDetails.slots;
      //  console.log('Trip Dates', this.tour);
      console.log('Trip Dates', this.packages);
      for (let i = 0; i < this.tripDetails.slots.length; i++) {
        this.slots.push(this.tripDetails.slots[i]);
      }
      for (let i = 0; i < this.tripDetails.packages.length; i++) {
        this.packages.push(this.tripDetails.packages[i]);
      }
      for (let i = 0; i < this.packages.length; i++) {
        this.participants.push(this.packages[i].participants);
      }
      for (let i = 0; i < this.participants.length; i++) {
        this.participant = this.participants[i];
      }
      this.instr = this.tour.additionalInfo[0].instructions;
      this.inclu = this.tour.additionalInfo[0].inclusions;
      this.enclu = this.tour.additionalInfo[0].exclusions;
      this.instr = this.instr.split('|').join('\n');
      this.enclu = this.enclu.split('|').join('\n');
      this.inclu = this.inclu.split('|').join('\n');
      this.selectedPayment = this.tour.payment_methods[0].id;
      this.selectedColl = this.tour.collection_requirements;
      this.selectedWf = this.tour.workflow.guid;
      console.log(this.selectedWf);
      // console.log("details",this.instr,this.instr,this.enclu,this.inclu);
    });
  }
  mapClicked($event: MouseEvent) {
    let newPlace = [];
    newPlace = [
      {
        lat: $event.coords.lat.toString(),
        lng: $event.coords.lng.toString()
      }
    ];
    this.markers = newPlace;
    console.log('lat', this.markers);
  }

  getWeekDays() {
    this.tours.getWeekDays().subscribe((data: any) => {
      //  console.log(data,'week')
      this.weekDays = data.days;

      for (let i = 0; i < this.weekDays.length; i++) {
        this.daysofWeek.push({
          id: this.weekDays[i].id,
          name: this.weekDays[i].name,
          selected: false,
          isSet: false
        });
      }
    });
    console.log(this.daysofWeek, this.weekDays, 'sfsafsafasfasfasffas');
    this.tours.getWeekDayscancel().subscribe((data: any) => {
      //   console.log(data,'week')
      this.weekDaysww = data.days;
      console.log(this.weekDaysww, '2ololol');

      for (let i = 0; i < this.weekDaysww.length; i++) {
        this.daysofWeeks.push({
          id: this.weekDaysww[i].id,
          name: this.weekDaysww[i].name,
          selected: false,
          isSet: false
        });
      }
    });
  }
  editada() {
    this.edit = !this.edit;
  }
  selectFile(event) {
    this.img_o = event.target.files;
    console.log(this.img_o[0].name);
    //   this.img1 = this.img_o[0].name;
    //   this.img1 = Date.now() + '.jpg';
    //   console.log(this.img1, "lfa")
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => (this.imageSrc = reader.result);
      reader.readAsDataURL(file);
    }
    const path = 'tour/card';
    const file1 = this.img_o.item(0);
    console.log('POP', file1);
    this.uploadimgService.uploadFile(file1, path);

    this.imsaa = this.img_o.item(0).name;
    this.tour.card = this.img_o.item(0).name;
  }

  selectCover(event) {
    this.img_c = event.target.files;
    //   this.img_2 = this.img_c[0].name;
    //   this.img_2 = Date.now() + '.jpg';
    console.log(this.img_c[0].name, 'Wfa');
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => (this.imageSrcc = reader.result);
      reader.readAsDataURL(file);
    }
    const path = 'tour/card';
    const file = this.img_c.item(0);
    console.log('POP2', file);
    this.uploadimgService.uploadFile(file, path);
    this.iamaa = this.img_c.item(0).name;

    this.tour.card_small = this.img_c.item(0).name;
  }
  selectgalary(event) {
    this.galleryImages = event.target.files;
    console.log(this.galleryImages, 'gala');
    for (let i = 0; i < this.galleryImages.length; i++) {
      //   this.g1 = this.galleryImages[i].name;
      //   this.g1 = Date.now();
      // this.file = this.dataURLtoFile(
      // 	this.galleryImages[i].name,
      // 	Date.now() + '_' + i + '.jpg'
      // );
      this.iaaaz = this.galleryImages.item(0).name;

      this.gallery.push(this.galleryImages.item(i).name);
    }
    console.log(this.gallery, 's');
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => (this.imageSrcs = reader.result);
      reader.readAsDataURL(file);
    }

    for (let i = 0; i < this.galleryImages.length; i++) {
      const path2 = 'tour/gallery';
      const file23 = this.galleryImages.item(i);
      console.log('POsP', file23);
      this.uploadimgService.uploadFile(file23, path2);
    }
    this.tour.gallery = this.gallery;
  }
  checkresev(e) {
    console.log(e)
    if (e.checked) {
      this.extend = true;
    }
    else if (e.checked == false) {
      this.extend = false;

    }
  }
  addtour() {
    if (this.imsaa === undefined) {
      this.tour.card = '';
    }
    if (this.iamaa === undefined) {
      this.tour.card_small = '';
    }
    if (this.iaaaz === undefined) {
      this.tour.gallery = [];
    }

    console.log(
      'tor0',
      this.tour.meetingPoint[0].latitude,
      this.tour.meetingPoint[0].longitude
    );
    const blbl = [
      {
        name: this.tour.name,
        meeting_point: {
          lat: this.markers[0].lat.toString(),
          long: this.markers[0].lng.toString(),
          name: this.tour.meetingPoint[0].address
        },
        desc: this.tour.description,
        program: this.tour.program,
        gallery: this.tour.gallery,
        small_card: this.tour.card_small,
        card: this.tour.card,
        district_id: this.tour.district,
        extend: this.extend,

      }
    ];
    this.tours.editTourBasicInfo(blbl, this.tour.id).subscribe((data: any) => {
      console.log(data);
      if (data.code === '10000') {
        swal('Data is Sucess update', 'Done', 'success');
        this.loadbasicinfo();
        this.iaaaz = undefined;
        this.iamaa = undefined;
        this.imsaa = undefined;
        this.gallery = [];
      } else {
        swal('Data have errors', 'sorry', 'error');
      }
    });
    console.log(blbl, 'tourdata');
  }
  addinfo() {
    this.exedit = this.enclu.replace(/(?:\r\n|\r|\n)/g, '|');
    this.incedit = this.instr.replace(/(?:\r\n|\r|\n)/g, '|');
    this.insedit = this.inclu.replace(/(?:\r\n|\r|\n)/g, '|');
    const lol = {
      exclusions: this.exedit,
      inclusions: this.incedit,
      instructions: this.insedit
    };
    this.tours.editTourDetailsADD(this.tour.id, lol).subscribe((data: any) => {
      console.log(data);
      if (data.code === '10000') {
        swal('information are updated', 'done', 'success');
      } else {
        swal('error', 'error', 'error');
      }
    });
    console.log(lol);
  }

  addPackages() {
    if (this.packagescostlist.length < 5) {
      if (this.check === undefined) {
        this.packagescostlist.push({
          type: this.name,
          Cost: this.cost,
          isCounted: false
        });
      } else {
        this.packagescostlist.push({
          type: this.name,
          Cost: this.cost,
          isCounted: this.check
        });
      }
      console.log(this.packagescostlist, 'pd');
      this.costlist = this.packagescostlist;
    } else {
      swal('You have Already Five Packages!', '', 'error');
    }
    this.name = '';
    this.cost = '';
  }
  addPackagelist() {
    console.log('list length', this.costlist.length);
    this.packagescostlist.length;
    if (this.packagename === undefined || this.desc === undefined) {
      swal('please enter missing parameters', '', 'error');
    } else if (this.packagescostlist.length < 1) {
      swal('please enter missing parameters', '', 'error');
    } else {
      this.packagesadd.push({
        package_name: this.packagename,
        desc: this.desc,
        participants: this.packagescostlist
      });
      console.log(this.packagesadd, 'DD');
    }
    this.packagescostlist = [];
    this.packagename = '';
    this.desc = '';
    this.tours
      .addNewtourpacakge(this.pickid, this.packagesadd)
      .subscribe((data: any) => {
        console.log(data);
        if (data.code === '10000') {
          swal('new package are added', 'done', 'success');
          // this.loadAPiDetials();
          this.loadbasicinfo();
        } else {
          swal('check ur data please', 'error', 'error');
        }
      });
  }

  deletePackage(item) {
    const index = this.packagesadd.indexOf(item);
    this.packagesadd.splice(index, 1);
  }
  deletePackages(id, item) {
    console.log(item);

    this.tours.deletepakcage(item).subscribe((data: any) => {
      console.log(data);
      if (data.code === '10000') {
        swal('package are deleted', 'done', 'success');
        const index = this.packages.indexOf(id);
        this.packages.splice(index, 1);
      } else {
        swal('error', 'error', 'error');
      }
    });
  }

  sendPackages(packages) {
    for (let op = 0; op < packages.length; op++) {
      console.log(op, 'count pack');
      this.participantssend = [];
      for (let n = 0; n < packages[op].participants.length; n++) {
        console.log(n, 'count part');
        this.participantssend.push({
          type: packages[op].participants[n].name,
          Cost: packages[op].participants[n].cost,
          id: packages[op].participants[n].id,
          type_id: packages[op].participants[n].type_id,
          isCounted: packages[op].participants[n].is_counted
        });
      }
      this.packagessend.push({
        id: packages[op].id,
        package_name: packages[op].name,
        desc: packages[op].desc,
        participants: this.participantssend
      });
      //  console.log(this.packagessend , 'before send')
    }
    console.log(this.packagessend, 'will send');
    this.tours
      .addNewtourpacakge(this.pickid, this.packagessend)
      .subscribe((data: any) => {
        // this.packagessend = [] ;
        console.log(data);
        if (data.code === '10000') {
          swal('package are edited', 'done', 'success');
          this.packagessend = [];
        } else {
          swal('error', 'error', 'error');
          this.packagessend = [];
        }
      });
    this.packagessend = [];

    this.tours.getTourDetails(this.id).subscribe((data: any) => {
      this.tour = data.tours['0'];
    });
  }
  getPayment() {
    this.tours.getPaymentMethod().subscribe((data: any) => {
      this.paymentMethods = data.payment_methods;
      console.log('payment', this.paymentMethods);
    });
    console.log('payment', this.paymes);

    console.log(this.haspaymentId, 'id payment sssss');
    this.tours.getWorkFlowPayment(this.haspaymentId).subscribe((data: any) => {
      // console.log('data',data);
      this.hasPayments = data.workflows;
    });
  }
  changepayment(paymentid) {
    console.log('payment id', paymentid);
    if (paymentid === 1) {
      // pay on arrival
      this.viewcancelation = false;
      this.mincollection = 0;
      this.maxcollection = 0;
      this.selectedColl = 0;
      this.collection_requirement = 0;
      this.flagcollection = true;
      this.zerocollection = true;
      this.flagcollectionnot = false;
      this.msgcollectionzero = 'the collection must be zero';
      this.haspaymentId = 2;
      console.log('tt', this.mincollection, this.maxcollection);
    } else {
      // 2 credit card
      this.viewcancelation = true;
      this.mincollection = 1;
      this.zerocollection = false;
      this.selectedColl = 1;
      this.collection_requirement = '';
      this.maxcollection = 100;
      this.flagcollection = false;
      this.flagcollectionnot = true;
      this.msgcollectionnotZero = "the collection can't be zero";
      this.haspaymentId = 1;
      console.log('tt', this.mincollection, this.maxcollection);
    }
    console.log('payment after if', this.haspaymentId);
    this.tours.getWorkFlowPayment(this.haspaymentId).subscribe((data: any) => {
      // console.log('data',data);
      this.hasPayments = data.workflows;
    });
  }
  AddTourPolicy(policyy) {
    if (this.periodd === undefined) {
      this.periodmsg = true;
      this.perioderrorMsg = 'please enter period';
    } else if (this.deductiond === undefined) {
      this.dedutionmsg = true;
      this.deductionerrorMsg = 'please enter deduction';
    } else {
      this.periodmsg = false;
      this.dedutionmsg = false;
      const policy = {};
      this.dayss = [];
      for (let j = 0; j < this.daysofWeeks.length; j++) {
        if (this.daysofWeeks[j].selected === true) {
          this.dayss.push({
            period: this.periodd,
            deduction: this.deductiond,
            day_name: this.daysofWeeks[j].name,
            day_id: this.daysofWeeks[j].id
          });
          this.Policies.push({
            period: this.periodd,
            deduction: this.deductiond,
            day_id: this.daysofWeeks[j].id.toString(),
            id: ''
          });
        }
        console.log(this.dayss, ',vzvz');
        console.log(this.Policies, ',sz');
      }
      this.PoliciesToSave.push({ days: this.dayss });
      console.log(this.PoliciesToSave, ',ww');
      policyy = {};
      policyy.periodd = '';
      policyy.deductiond = '';
      this.cancelationForm = {};
      this.periodd = '';
      this.deductiond = '';
    }
  }

  finish() {
    for (let i = 0; i < this.cancelactionpolist.length; i++) {
      if (
        this.cancelactionpolist[i].period === undefined ||
        this.cancelactionpolist[i].deduction === undefined
      ) {
        swal('please provide missing parameters!', '', 'error');
      } else {
        this.Policies.push({
          id: this.cancelactionpolist[i].id,
          day_id: this.cancelactionpolist[i].day,
          period: this.cancelactionpolist[i].period,
          deduction: this.cancelactionpolist[i].deduction,
          day_name: this.cancelactionpolist[i].day_name
        });
      }
    }
    console.log('valid collection', this.selectedPayment);

    if (this.selectedPayment === 2) {
      console.log('length', this.Policies.length);
      if (this.Policies === [] || this.Policies.length === 0) {
        swal('Please add cancelation policies!', '', 'error');
      } else if (
        this.selectedWf === undefined ||
        this.selectedColl === undefined ||
        this.selectedPayment === undefined
      ) {
        swal('Please add missing parameters!', '', 'error');
      } else {
        console.log('localstorage', localStorage.tour);
        this.Tourid = localStorage.tour;
        console.log('item policyyy', this.Policies.length);
        console.log('item policyyy', this.Policies[0]);
        this.dataWF = {
          payment_method_id: this.selectedPayment,
          // tslint:disable-next-line: radix
          collection_requirements: parseInt(this.selectedColl),
          workflow_id: this.selectedWf,
          policies: this.Policies
        };
      }
    } else {
      console.log('length', this.Policies.length);
      if (
        this.selectedWf === undefined ||
        this.selectedColl === undefined ||
        this.selectedPayment === undefined
      ) {
        swal('Please add missing parameters!', '', 'error');
      } else if (this.selectedColl !== 0) {
        console.log('valid collection', this.selectedColl);
        swal('Please enter valid value for collection!', '', 'error');
      } else {
        console.log('localstorage', localStorage.tour);
        this.dataWF = {
          payment_method_id: this.selectedPayment,
          // tslint:disable-next-line: radix
          collection_requirements: parseInt(this.selectedColl),
          workflow_id: this.selectedWf
        };
      }
    }
    this.tours.addWorkFlow(this.dataWF, this.tour.id).subscribe((data: any) => {
      if (data.code === '10000') {
        swal('Wrokflow are updated', 'done', 'success');
      } else {
        swal('error', 'error', 'error');
      }
      console.log(data);
    });
    console.log('data', this.dataWF);
  }
  removePolicy(index) {
    this.PoliciesToSave.splice(index, 1);
    this.Policies = [];
    this.dayss.splice(index, 1);
  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
  }
  updateList(id: number, property: any, event: any) {
    const editField = event.target.textContent;
    // tslint:disable-next-line: radix
    this.cancelactionpolist[id][property] = parseInt(editField);
    console.log(this.cancelactionpolist, 'push');

    this.Policies = this.cancelactionpolist;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
  }
  editPolicy(a) {
    if (a === '') {
      swal('Please enter missing paramters!', '', 'error');
    }
    for (let i = 0; i < a.length; i++) {
      if (a[i].period === '' || a[i].deduction === '') {
        swal('Please enter missing paramters!', '', 'error');
      } else {
        swal('changes has been done', 'done', 'success');
      }
    }
  }
  checknumber(ss) {
    if (ss > 100) {
      swal('cant set number > 100 le ', 'error', 'error');
    }
  }
  loadbutton = false;
  postmonth(id) {
    this.loadbutton = true;
    console.log(id);
    this.tours.postmonth(id).subscribe(data => {
      console.log(data)
      this.loadnew = true;
      this.loadbutton = false;
      if (data.code === "10000") {
        console.log('amda', data.tour.id)
        // this.routers.navigate(['/tours'])
        this.loadnew = false;
        this.routers.navigate(['/tour/tourDetails', data.tour.id]);
        this.loadapi(data.tour.id);
        this.loadbutton = false;

        // setTimeout(() => {
        //   location.reload();
        // }, 3000);
      }
    }, err => {
      swal('Error in extend', '', 'error')
    })
  }


  loadapi(id) {
    this.id = id
    this.tours.getTourDetails(this.id).subscribe((data: any) => {
      this.tour = data.tours['0'];
      this.paymes = this.tour.payment_methods[0].id;
      console.log('Trip Dates', this.paymes);

      this.lat = parseInt(this.tour.meetingPoint[0].latitude);
      this.lng = parseInt(this.tour.meetingPoint[0].longitude);
      console.log('tour data is', this.tour);
      this.pickid = data.tours['0'].tripDetails['0'].guid.replace(/-/g, '');
      this.tripDetails = data.tours['0'].tripDetails['0'];
      this.slots = this.tripDetails.slots;
      //  console.log('Trip Dates', this.tour);
      console.log('Trip Dates', this.packages);
      //  for (var i = 0 ; i < this.tripDetails.slots.length ; i++){
      //      this.slots.push(this.tripDetails.slots[i]);
      //  }
      for (let i = 0; i < this.tripDetails.packages.length; i++) {
        this.packages.push(this.tripDetails.packages[i]);
      }
      for (let i = 0; i < this.packages.length; i++) {
        this.participants.push(this.packages[i].participants);
      }
      for (let i = 0; i < this.participants.length; i++) {
        this.participant = this.participants[i];
      }
      this.instr = this.tour.additionalInfo[0].instructions;
      this.inclu = this.tour.additionalInfo[0].inclusions;
      this.enclu = this.tour.additionalInfo[0].exclusions;
      this.cancelactionpolist = this.tour.cancellationPolicy;
      this.instr = this.instr.split('|').join('\n');
      this.enclu = this.enclu.split('|').join('\n');
      this.inclu = this.inclu.split('|').join('\n');
      this.selectedPayment = this.tour.payment_methods[0].id;
      this.selectedColl = this.tour.collection_requirements;
      this.selectedWf = this.tour.workflow.guid;
      this.selectname = this.tour.workflow.name;
      console.log(this.selectedWf);
      if (this.paymes === 1) {
        this.haspaymentId = 2;

        // this.viewcancelation = false;
      }
      if (this.paymes === 2) {
        this.haspaymentId = 1;
        // this.viewcancelation = false;
      }
      this.getPayment();

      // console.log("details",this.instr,this.instr,this.enclu,this.inclu);
    });

    this.show = true;
    this.getWeekDays();
    console.log('lat', this.lat, this.lng);
    this.markers.push({
      lat: this.lat.toString(),
      lng: this.lng.toString()
    });
    console.log(this.markers.length, 'ss');
  }
}

interface marker {
  lat: string;
  lng: string;
}
