import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class TourListService {
	constructor(private http: HttpClient) {}
	getTourData(res_from, res_to) {
		const dates = 'res_from=' + res_from + '&' + 'res_to=' + res_to;
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/vendor/reservation/insights/?${dates}`,
				{
					headers: new HttpHeaders(`${environment.httpHeader}`)
				}
			)
			.pipe(
				map(dash => {
					return dash;
				})
			);
	}
	loadTour() {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/tour/`, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(tours => {
					return tours;
				})
			);
	}
	getDate(tourId) {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/vendor/gettrip/?tour_id=${tourId} `,
				{
					headers: new HttpHeaders(`${environment.httpHeader}`)
				}
			)
			.pipe(
				map(Date => {
					//    console.log("<><><><><><>",Date);
					return Date;
				})
			);
	}

	getTimeSlots(object) {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/vendor/gettimeslots/?tour_id=${
					object.tourid
				}&date=${object.date} `,
				{
					headers: new HttpHeaders(`${environment.httpHeader}`)
				}
			)
			.pipe(
				map(Times => {
					//  console.log("<><><><><><>",Times);
					return Times;
				})
			);
	}

	getTourReservation(pageNum) {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/vendor/tour/reservations/?page=${pageNum}`,
				{
					headers: new HttpHeaders(`${environment.httpHeader}`)
				}
			)
			.pipe(
				map(tour => {
					// console.log("<><><><><><>",tour);
					return tour;
				})
			);
	}

	getTourReservationDetails(tourid) {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/vendor/tour/reservations/${tourid}/`,
				{
					headers: new HttpHeaders(`${environment.httpHeader}`)
				}
			)
			.pipe(
				map(Details => {
					console.log('()()()()()()', Details);
					return Details;
				})
			);
	}

	getPhoneExsist(phone) {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/vendor/searchuser/searchterm/${phone}/`,
				{
					headers: new HttpHeaders(`${environment.httpHeader}`)
				}
			)
			.pipe(
				map(phone => {
					console.log('_==_==_', phone);
					return phone;
				})
			);
	}
	createReservtionTour(data) {
		return this.http
			.post<any>(`${environment.apiEndPoint}/vendor/reservation/`, data)
			.pipe(
				map(reservationscreate => {
					return reservationscreate;
				})
			);
	}

	getReservationTour(queryParams?: object) {
		let params = new HttpParams();
		for (const key in queryParams) {
			if (queryParams.hasOwnProperty(key) && queryParams[key] !== '') {
				if (!params.has(key)) {
					params = params.set(key, queryParams[key]);
					// console.log("dp",params);
				}
			}
		}
		return this.http.get(
			`${
				environment.apiEndPoint
			}/vendor/tour/reservations/?${params.toString()}`
		);
	}
	// omarhmaad edit
	GEEetReservationTour() {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/tour/reservations/`, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(Details => {
					console.log('()()()()()()', Details);
					return Details;
				})
			);
	}

	updateReservationConfirmation(data) {
		return this.http
			.post<any>(
				`${environment.apiEndPoint}/vendor/reservation/${
					data.reservationId
				}/update/`,
				data
			)
			.pipe(
				map(updatereservation => {
					// console.log("TTTT",updatereservation)
					return updatereservation;
				})
			);
	}
	cancelTrips(data) {
		console.log(data);
		return this.http
			.post<any>(`${environment.apiEndPoint}/vendor/tour/cancelslots/`, data)
			.pipe(
				map(reservationscreate => {
					return reservationscreate;
				})
			);
	}
}
