import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { UploadimgService, GetProfileService } from '../../_services';
import swal from 'sweetalert2';

@Component({
	selector: 'app-global-setting',
	templateUrl: './global-setting.component.html',
	styleUrls: ['./global-setting.component.scss']
})
export class GlobalSettingComponent implements OnInit {
	selectedFiles: FileList;
	imageSrc;
	firstFormGroup: FormGroup;
	selected: any;
	SavedInfo: any = {};
	BasicInfo: any = {};
	phones: any = [];
	phone: any;
	logoImage: any;
	visible: any;
	newPhone: any;
	editphone = false;
	category: any;
	touredit = false;
	restedit = false;
	card: any;
	card_small: any;
	dressCodes = [
		{ id: 1, name: 'formal' },
		{ id: 2, name: 'Semi Formal' },
		{ id: 3, name: 'Casual' }
	];
	gallery: any[] = [];
	menu: any;
	mobile: any;
	file: any;
	imageSrcs: string | ArrayBuffer;
	editphoness: any;
	galleryImages: any;
	imageSrcsw: string | ArrayBuffer;
	galleryImagesmenu: FileList;
	menus: any[] = [];
	editField: any;
	constructor(
		private uploadimgService: UploadimgService,
		private getProfileService: GetProfileService,
		private router: Router
	) {
		this.getProfileService.getProfile().subscribe((man: any) => {
			this.category = man.vendor.category_id;
			if (this.category === 2) {
				console.log('tour', this.category);
				this.touredit = true;
			} else if (this.category === 1) {
				this.restedit = true;
				console.log('rest', this.category);
			}
		});
		this.SetData();
	}

	ngOnInit() {
		this.getProfileService.getBasicInfo().subscribe((data: any) => {
			this.SavedInfo = data.BasicInfo;
			this.SetData();

			console.log(this.SavedInfo);
			// console.log(this.SavedInfo.phones , 'phone');
		});
		// this.BasicInfo.phones = this.SavedInfo.phones
		// console.log(this.SavedInfo.phones)
	}
	SetData() {
		if (this.category === 2) {
			this.BasicInfo.name = this.SavedInfo.name;
			this.BasicInfo.phones = this.SavedInfo.phones;
			this.BasicInfo.website = this.SavedInfo.website;
			this.BasicInfo.facebook = this.SavedInfo.facebook;
			this.BasicInfo.address = this.SavedInfo.address;
			this.BasicInfo.description = this.SavedInfo.description;
			this.BasicInfo.logo = '';
			this.logoImage = this.SavedInfo.logo;
			console.log(this.BasicInfo.phones, 'phones');
		} else if (this.category === 1) {
			this.BasicInfo.name = this.SavedInfo.name;
			this.BasicInfo.phones = this.SavedInfo.phones;
			this.BasicInfo.website = this.SavedInfo.website;
			this.BasicInfo.facebook = this.SavedInfo.facebook;
			this.BasicInfo.address = this.SavedInfo.address;
			this.BasicInfo.description = this.SavedInfo.description;
			this.BasicInfo.logo = '';
			this.BasicInfo.menu = [];
			this.BasicInfo.gallery = [];
			this.logoImage = this.SavedInfo.logo;
			this.card = this.SavedInfo.card;
			this.card_small = this.SavedInfo.card_small;
			this.BasicInfo.dress_code = this.SavedInfo.dress_code.id;
			this.galleryImages = this.SavedInfo.gallery;
			this.galleryImagesmenu = this.SavedInfo.menu;
		}
		// else if (this.category === 3) {
		// 	this.BasicInfo.name = this.SavedInfo.name;
		// 	this.BasicInfo.phones = this.SavedInfo.phones;
		// 	this.BasicInfo.website = this.SavedInfo.website;
		// 	this.BasicInfo.facebook = this.SavedInfo.facebook;
		// 	this.BasicInfo.address = this.SavedInfo.address;
		// 	this.BasicInfo.description = this.SavedInfo.description;
		//   this.BasicInfo.logo = '';
		//   this.BasicInfo.menu = [];
		//   this.BasicInfo.gallery = [];
		// 	this.logoImage = this.SavedInfo.logo;
		// 	this.card = this.SavedInfo.card;
		// 	this.card_small = this.SavedInfo.card_small;
		// 	this.BasicInfo.dress_code = this.SavedInfo.dress_code.id;
		// 	this.gallery = this.SavedInfo.gallery;
		// 	this.menu = this.SavedInfo.menu;
		// }
	}
	upload() {
		const path = 'vendor/logo';
		const file = this.selectedFiles.item(0);
		console.log(this.selectedFiles);
		console.log(file);
		this.uploadimgService.uploadFile(file, path);
	}

	selectFile(event) {
		/* files: FileList
          0: File(4956)
          { name: "zab.jpg",
            lastModified: 1540237663463,
            lastModifiedDate: Mon Oct 22 2018 21:47:43 GMT+0200 (Eastern European Standard Time),
            webkitRelativePath: "", size: 4956, …}
    */
		this.logoImage = event.target.files;
		const path = 'vendor/logo';
		const file = this.logoImage.item(0);
		this.uploadimgService.uploadFile(file, path);
		this.BasicInfo.logo = file.name;
		console.log(this.selectFile);
		if (event.target.files && event.target.files[0]) {
			const h = event.target.files[0];
			const reader = new FileReader();
			reader.onload = e => (this.logoImage = reader.result);
			reader.readAsDataURL(h);
		}
	}
	selectcard(event) {
		this.card = event.target.files;
		const path = 'restaurant/card';
		const file = this.card.item(0);
		this.uploadimgService.uploadFile(file, path);
		this.BasicInfo.card = file.name;
		console.log(this.selectcard);
		if (event.target.files && event.target.files[0]) {
			const t = event.target.files[0];
			const reader = new FileReader();
			reader.onload = e => (this.card = reader.result);
			reader.readAsDataURL(t);
		}
	}
	selectsmallcard(event) {
		this.card_small = event.target.files;
		const path = 'restaurant/card';
		const file = this.card.item(0);
		this.uploadimgService.uploadFile(file, path);
		this.BasicInfo.card_small = file.name;
		console.log(this.selectcard);
		if (event.target.files && event.target.files[0]) {
			const w = event.target.files[0];
			const reader = new FileReader();
			reader.onload = e => (this.card_small = reader.result);
			reader.readAsDataURL(w);
		}
	}
	selectgalary(event) {
		this.galleryImages = event.target.files;
		console.log(this.galleryImages, 'gala');
		for (let i = 0; i < this.galleryImages.length; i++) {
			//   this.g1 = this.galleryImages[i].name;
			//   this.g1 = Date.now();
			// this.file = this.dataURLtoFile(
			// 	this.galleryImages[i].name,
			// 	Date.now() + '_' + i + '.jpg'
			// );

			this.BasicInfo.gallery.push(this.galleryImages.item(i).name);
		}
		// console.log(this.gallery, 's');
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = e => (this.imageSrcs = reader.result);
			reader.readAsDataURL(file);
		}
		for (let i = 0; i < this.galleryImages.length; i++) {
			const path2 = 'restaurant/gallery';
			const file23 = this.galleryImages.item(i);
			console.log('POsP', file23);
			this.uploadimgService.uploadFile(file23, path2);
		}
		// this.BasicInfo.gallery = this.gallery;
	}
	selectmenu(event) {
		this.galleryImagesmenu = event.target.files;
		console.log(this.galleryImages, 'gala');
		for (let i = 0; i < this.galleryImagesmenu.length; i++) {
			//   this.g1 = this.galleryImages[i].name;
			//   this.g1 = Date.now();
			// this.file = this.dataURLtoFile(
			// 	this.galleryImages[i].name,
			// 	Date.now() + '_' + i + '.jpg'
			// );

			this.BasicInfo.menu.push(this.galleryImagesmenu.item(i).name);
		}
		// console.log(this.gallery, 's');
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = e => (this.imageSrcsw = reader.result);
			reader.readAsDataURL(file);
		}
		for (let i = 0; i < this.galleryImagesmenu.length; i++) {
			const path2 = 'restaurant/menu';
			const file23 = this.galleryImagesmenu.item(i);
			console.log('POsP', file23);
			this.uploadimgService.uploadFile(file23, path2);
		}
		// this.BasicInfo.menu = this.menus;
	}
	// geallay(event) {
	// 	this.menu = event.target.files;
	// 	// const path = 'restaurant/gallery';
	// 	const file = this.menu.item(0);
	// 	// this.uploadimgService.uploadFile(file, path);
	// 	// this.BasicInfo.menu=file.name;
	// 	// console.log(this.selectcard)
	// 	// if (event.target.files && event.target.files[0]) {
	// 	//   const file = event.target.files[0];
	// 	//   const reader = new FileReader();
	// 	//   reader.onload = e => (this.menu = reader.result);
	// 	//   reader.readAsDataURL(file);
	// 	// }
	// 	this.menu = event.target.files;
	// 	console.log(this.menu, 'gala');
	// 	for (let i = 0; i < this.menu.length; i++) {
	// 		//   this.g1 = this.galleryImages[i].name;
	// 		//   this.g1 = Date.now();
	// 		this.file = this.dataURLtoFile(
	// 			this.menu[i].name,
	// 			Date.now() + '_' + i + '.jpg'
	// 		);

	// 		this.gallery.push(this.menu.item(i).name + '_' + i);
	// 	}
	// 	console.log(this.gallery, 's');
	// 	if (event.target.files && event.target.files[0]) {
	// 		const file = event.target.files[0];
	// 		const reader = new FileReader();
	// 		reader.onload = e => (this.imageSrcs = reader.result);
	// 		reader.readAsDataURL(file);
	// 	}
	// 	for (let i = 0; i < this.menu.length; i++) {
	// 		const path = 'restaurant/gallery';
	// 		const file23 = this.menu.item(i).name + '_' + i;
	// 		console.log('POsP', file23);
	// 		this.uploadimgService.uploadFile(file23, path);
	// 		this.BasicInfo.gallery = this.menu[i].name;
	// 	}
	// }
	// dataURLtoFile(dataurl, filename) {
	// 	console.log('data in image', dataurl, filename);
	// 	const arr = dataurl;
	// 	const mime = arr[0];
	// 	const bstr = arr[1];
	// 	let n = bstr;
	// 	const u8arr = new Uint8Array(n);

	// 	while (n--) {
	// 		u8arr[n] = bstr.charCodeAt(n);
	// 	}
	// 	return new File([u8arr], filename, { type: mime });
	// }
	readURL(event): void {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = e => (this.logoImage = reader.result);
			reader.readAsDataURL(file);
		}
	}

	getTemplate(phone) {
		if (phone === this.selected) {
			return 'edit';
		} else {
			return 'display';
		}
	}
	removeContact(index) {
		this.SavedInfo.phones.splice(index, 1);
	}
	reset = function() {
		this.selected = '';
	};
	editPhone() {
		this.editphone = true;
	}
	updatephone(i) {
		console.log(i);
		this.SavedInfo.phones[i] = this.editphoness;
		console.log(this.editphoness);
	}
	saveContact(selected, index) {
		this.BasicInfo.phones[index] = selected;
		this.reset();
	}

	checkPhoneExists(phone) {
		if (phone === null) {
			Swal('the phone is null', 'error', 'error');
		} else {
			this.getProfileService.checkPhoneExists(phone).subscribe((data: any) => {
				if (data.code === '10034') {
					this.BasicInfo.phones.push(phone);
					this.visible = false;
					this.newPhone = '';
					this.newPhone = null;
					console.log(this.BasicInfo.phones, 'fsafsa');
				} else {
					Swal(data.detail, 'error', 'error');
				}
			});
		}
	}
	SubmitBasicInfo(BasicInfo) {
		// console.log(BasicInfo, 'fas');
		if (this.category === 2) {
			this.getProfileService.editBasicInfoTour(BasicInfo).subscribe(
				data => {
					Swal('Done', 'Success', 'success');
					this.router.navigate(['/globalSettings']);
					console.log('data', data);
				},
				error => {
					console.log(error, ' error');
					Swal('error in data', 'error', 'error');
				}
			);
		} else if (this.category === 1) {
			this.getProfileService.getBasicInfoResturant(BasicInfo).subscribe(
				data => {
					console.log(BasicInfo, 'rest');
					Swal('Done', 'Success', 'success');
					this.router.navigate(['/R/branches']);
					console.log('data', data);
				},
				error => {
					console.log(error, ' error');
					Swal('error in data', 'error', 'error');
				}
			);
		}
	}
	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	changeValue(id: number, property: string, event: any) {
		this.editField = event.target.textContent;
	}
	updateList(id: number, property: any, event: any) {
		const editField = event.target.textContent;
		this.BasicInfo[property][id] = editField;
		if (editField.length > 11) {
			swal('please insert correct number', 'enter number agian', 'error');
		}
		// console.log(this.BasicInfo)
	}

	dataURItoBlob(dataURI) {
		const byteString = atob(dataURI);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
		return blob;
	}
	dataURLtoFile(dataurl, filename) {
		// tslint:disable-next-line: prefer-const
		let arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			// tslint:disable-next-line: prefer-const
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}
}
