import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertFrom24To12Format' })
export class TimeFormat implements PipeTransform {
	transform(time: any): any {
		let hour = time.split(':')[0];
		//  console.log(hour)
		let min = time.split(':')[1];
		//  console.log(min)
		const part = hour > 12 ? 'PM' : 'AM';
		//  console.log(part)
		min = (min + '').length === 1 ? `0${min}` : min;
		hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length === 1 ? `0${hour}` : hour;

		return `${hour}:${min} ${part}`;
	}
}
