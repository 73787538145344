import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from '../../../../../_services';
import { environment } from '../../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-c-e-event-details',
	templateUrl: './c-e-event-details.component.html',
	styleUrls: ['./c-e-event-details.component.scss']
})
export class CEEventDetailsComponent implements OnInit, OnDestroy {
	currentEvent: any;
	shows: any;
	constructor(private _http: HttpService, private _toaster: ToastrService) {
		this.currentEvent = JSON.parse(localStorage.getItem('event'));
		console.log(this.currentEvent, 's');
	}

	ngOnInit() {
		const showDates = this.currentEvent['eventDates']
			.split(',')
			.filter(function(el) {
				return el !== '';
			});
		console.log(new Date(showDates[0]).toDateString());
	}

	ngOnDestroy(): void {
		localStorage.removeItem('event');
	}
	myFilter = (d: Date): boolean => {
		const showDates = this.currentEvent['eventDates']
			.split(',')
			.filter(function(el) {
				return el !== '';
			});
		return (
			showDates.findIndex(
				showDate => d.toDateString() === new Date(showDate).toDateString()
			) >= 0
		);
	};
	updateImageOnError($event) {
		$event['srcElement']['src'] = 'https://fillmurray.com/100/100';
	}

	getSelectedConcertDate(date: any) {
		const targetDate =
			new Date(date).getFullYear() +
			'-' +
			(new Date(date).getMonth() + 1) +
			'-' +
			new Date(date).getDate();
		const params = {
			event_guid: this.currentEvent['guid'],
			date: targetDate
		};
		this._http
			.getData(`${environment.apiEndPoint}/vendor/concert/show/`, params)
			.subscribe(
				res => {
					this.shows = res['shows'];
					console.log(this.shows, 'shpw');
				},
				error => {
					if (error['error']['code'] === '15005') {
						return this._toaster.error(error['error']['detail'], 'Error');
					}
				}
			);
	}
}
