import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from './../../../../../_services/http.service';
import { environment } from './../../../../../../environments/environment';
import * as znznValidators from '../../../../../_models';
@Component({
	selector: 'app-c-e-basic-info',
	templateUrl: './c-e-basic-info.component.html',
	styleUrls: ['./c-e-basic-info.component.scss']
})
export class CEBasicInfoComponent implements OnInit {
	logo: any;
	f_basicInfo: FormGroup;
	phones = [];
	znznValiditors = znznValidators['znzn-form-validation'];
	editphone = false;
	newUpdatedPone: any;
	@Output() isCompleted = new EventEmitter();
	constructor(
		private _fb: FormBuilder,
		private _httpService: HttpService,
		private _toastr: ToastrService
	) {}

	ngOnInit() {
		this.f_basicInfo = this._fb.group({
			name: ['', Validators.compose([Validators.required])],
			phone: [
				'',
				Validators.compose([
					Validators.pattern(/^-?([0-9]\d*)?$/),
					Validators.maxLength(15)
				])
			],
			website: ['', Validators.compose([Validators.required])],
			facebook: ['', Validators.compose([Validators.required])]
		});
	}
	addPhone(phone) {
		// http://staging.zabatnee.com/api/vendor/phoneexists/12332312312/
		if (phone === '' || phone === undefined) {
			console.log(phone);
			return this._toastr.error('Please Enter Phone First.', 'Error');
		}
		this._httpService
			.getData(`${environment.apiEndPoint}/vendor/phoneexists/${phone}/`)
			.subscribe(resp => {
				if (resp['code'] === '10034') {
					this.phones.push(phone);
					this.f_basicInfo.controls.phone.reset();
				} else if (resp['code'] === '10016') {
					this._toastr.error('Phone already exists in the system.', 'Error');
				}
			});
	}
	removePhone(phoneIndex) {
		this.phones.splice(phoneIndex, 1);
	}
	editPhone() {
		this.editphone = !this.editphone;
	}
	savePhone($event) {
		const reg = /^\d+$/;
		const phone = $event.target.value;
		if (reg.test(phone)) {
			this.newUpdatedPone = phone;
		} else if (!reg.test(phone)) {
			this._toastr.error('mobile phone must be number', 'Error');
		}
	}
	updatePhone(phoneIndex, currentPhone) {
		this.phones[phoneIndex] = this.newUpdatedPone || currentPhone;
		this.editphone = !this.editphone;
	}
	closeUpdate() {
		this.editphone = !this.editphone;
	}
	// convenience getter for easy access to form fields
	// @ts-ignore
	get f() {
		return this.f_basicInfo.controls;
	}
	selectFile(event) {
		if (
			event.target['files'] &&
			event.target['files'][0] &&
			event !== undefined
		) {
			const file = event.target['files'][0];
			const reader = new FileReader();
			reader.readAsDataURL(file); // read file as data url
			reader.onload = onload => {
				// called once readAsDataURL is completed
				this.logo = onload.target['result'];
			};
		}
	}
	saveBasicInfo() {
		const payload = {
			logo: '',
			name: this.f['name'].value,
			phones: this.phones,
			website: this.f['website'].value,
			facebook: this.f['facebook'].value
		};
		this._httpService
			.postData(
				`${environment.apiEndPoint}/vendor/tour/basicInfo/`,
				{},
				payload
			)
			.subscribe(resp => {
				if (resp['code'] === '10000') {
					return this._toastr.success('Global Settings Updated .', 'Done');
				}
				this._toastr.error('Add more Info.', 'Error');
			});
	}
}
