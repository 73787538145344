import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../_services';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-concerts-events',
	templateUrl: './concerts-events.component.html',
	styleUrls: ['./concerts-events.component.scss']
})
export class ConcertsEventsComponent implements OnInit {
	events: any;
	constructor(private _http: HttpService, private _toaster: ToastrService) {}

	ngOnInit() {
		this.getEventList();
	}

	/**
	 * @description get event list
	 */
	private getEventList() {
		this._http
			.getData(`${environment.apiEndPoint}/vendor/concert/event`)
			.subscribe((response: Response) => {
				if (response['code'] === '10000') {
					this.events = response['events'];
					console.log(this.events, 'Events');
				}
			});
	}
	/**
	 *
	 * @param eventGUID
	 */
	switchActivation(eventGUID) {
		const payload = { event_guid: eventGUID };
		this._http
			.postData(
				`${environment.apiEndPoint}/vendor/concert/event/publishtoggle`,
				{},
				payload
			)
			.subscribe(res => {
				if (res['code'] === '10000') {
					return this._toaster.success('Publish state updated', 'Success');
				}
				return this._toaster.error('Publish State Not Updated', 'Error');
			});
	}
}
