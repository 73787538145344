import swal from 'sweetalert2';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from './../../../_services/http.service';
import { environment } from './../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material';
import { ToursService } from '../../../_services';
import * as znznValidators from '../../../_models';
import { UploadimgService } from '../../../_services';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SportsListService } from './../../../_services/sports.service';

export interface TrainerData {
	Trainer_name: string;
	Branch: string;
	Classes: string[];
}
const Deeemeo: TrainerData[] = [
	{
		Branch: 'Zamalek',
		Classes: ['Kick Boxing course'],
		Trainer_name: 'mostafa akram'
	},
	{
		Branch: 'Giza',
		Classes: ['Kick Boxing course'],
		Trainer_name: 'omar hamad'
	},
	{
		Branch: 'hram',
		Classes: ['Cross fit'],
		Trainer_name: 'ahmed saaed'
	},
	{
		Branch: 'tharir',
		Classes: ['Kick Boxing course', 'fafasfasff'],
		Trainer_name: 'shady hafaz'
	},
	{
		Branch: 'cairo',
		Classes: ['kartia', 'fasfasfafasfsa'],
		Trainer_name: 'amir samir'
	},
	{
		Branch: 'ss',
		Classes: ['afsaasf', 'fsafsafsafwa'],
		Trainer_name: 'ali'
	},
	{
		Branch: '',
		Classes: ['safwafwa'],
		Trainer_name: 'mostafa akram'
	},
	{
		Branch: '',
		Classes: ['f'],
		Trainer_name: 'bol'
	},
	{
		Branch: 'bolaq',
		Classes: ['fsafsafsafasfasfsasafafsa'],
		Trainer_name: 'f'
	},
	{
		Branch: 'home',
		Classes: ['va', 'fsa'],
		Trainer_name: 'mahmoud'
	}
];

@Component({
	selector: 'app-trainers',
	templateUrl: './trainers.component.html',
	styleUrls: ['./trainers.component.scss']
})
export class TrainersComponent implements OnInit {
	toursCount: number;
	isLoading = false;
  // displayedColumns = ['Trainer_name', 'Classes', 'Branch', 'schedule', 'View'];
  displayedColumns = ['name' , 'View'];
	Trainersnum;
	// dataSource = new MatTableDataSource<TrainerData>(Deeemeo);
	dataSource: MatTableDataSource<{}>;
	@ViewChild(MatPaginator)
	paginator: MatPaginator;
	@ViewChild(MatSort)
	sort: MatSort;
	secondFormGroup: FormGroup;
  trainners: any;

  constructor(private router: Router,
    private Sports: SportsListService) {}

	ngOnInit() {
    this.Sports.loadTrainners().subscribe((data: any) => {
			this.trainners = data['Trainers'];
			this.isLoading = false;
			console.log(this.trainners);
      this.trainTable();
			this.trainners = this.trainners.length;
		});
		this.Trainersnum = Deeemeo.length;
	}

	trainTable() {
		// Matrial Datatable angular
		this.dataSource = new MatTableDataSource(this.trainners);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		console.log(this.dataSource);
	}
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	opencreate() {
		this.router.navigate(['g/trainers/create_trainer']);
  }
  viewTrainerData(id){
    console.log(id)
    this.router.navigate(['g/trainers/trainerdetails', id]);

  }

}
