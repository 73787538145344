import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { HttpService, AuthenticationService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
	selector: 'app-cars',
	templateUrl: './cars.component.html',
	styleUrls: ['./cars.component.scss']
})
export class CarsComponent implements OnInit {
	fn: any;
	ls: any;
	ge: any;
	mb: any;
	em: any;
	ou: any;
	fb: any;
	sucuss = false;
	constructor(
		private _httpService: HttpService,
		private toastr: ToastrService,
		private auth: AuthenticationService,
		private _router: Router
	) {}

	ngOnInit() {}

	public resolved(captchaResponse: string) {
		console.log(`Resolved captcha with response ${captchaResponse}:`);
		if (captchaResponse) {
			this.sucuss = true;
		}
	}
	refresh(): void {
		window.location.reload();
	}

	done() {
		// this.sucuss = false;
		if (this.fn === '' || this.fn === undefined) {
			// alert('error in Frist name');
			// swal('error in Frist name', 'Error', 'error');
			this.toastr.error('error in Frist name.', 'Oops!');
		} else if (this.ls === '' || this.ls === undefined) {
			// swal('error in Last name', 'Error', 'error');
			this.toastr.error('error in Last name.', 'Oops!');
		} else if (this.em === '' || this.em === undefined) {
			// swal('error in Email', 'Error', 'error');
			this.toastr.error('error in Email.', 'Oops!');
		} else if (this.ge === '' || this.ge === undefined) {
			this.toastr.error('error in Gender.', 'Oops!');
		} else if (this.mb === '' || this.mb === undefined) {
			this.toastr.error('error in Mobile.', 'Oops!');
		} else if (this.ou === '' || this.ou === undefined) {
			this.toastr.error('error in Occupation.', 'Oops!');
		} else {
			this.sucuss = false;
			const data = {
				first_name: this.fn,
				last_name: this.ls,
				country_code: '+20',
				gender: this.ge,
				mobile: this.mb.substr(1),
				email: this.em,
				occupation: this.ou,
				facebook: this.fb
			};
			console.log(data, 's');
			this.auth.postCars(data).subscribe(
				create => {
					if (create.code === '10000') {
						// this.toastr.success('thanks', 'sucess')
						swal(
							'Thank you for filling out the visitors registration form. You will be receiving your confirmation code for the 7th Cairo Classic Meet 2019 soon..',
							'thanks',
							'success'
						);
						console.log(create);
						this.sucuss = true;
						this._router.navigate(['/cars']);
						this.fn = '';
						this.em = '';
						this.ge = '';
						this.mb = '';
						this.ou = '';
						this.ls = '';
						this.fb = '';
					}
				},
				error => {
					console.log(error);
					if (error) {
						this.toastr.error(error.error.detail, 'error');
						this.sucuss = true;
					}
				}
			);
		}
	}
	keyPress(event: any) {
		const pattern = /[0-9\+\-\ ]/;

		const inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode !== 8 && !pattern.test(inputChar)) {
			event.preventDefault();
		}
	}
}
