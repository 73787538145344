import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { AuthenticationService, GetProfileService } from '../../_services';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment.prod';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  matcher = new MyErrorStateMatcher();
  returnUrl: string;
  category: any;
  loginForm = new FormGroup({
    emailFormControl: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    passwordFormControl: new FormControl('', [Validators.required])
  });

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private getProfileService: GetProfileService
  ) { }

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  /*Temp Function because I am Lazy*/
  // loginRestaurant() {
  //   this.authenticationService
  //     .login('dabdelmoniem@noor.net', 'ABC123')
  //     .pipe(first())
  //     .subscribe(
  //       () => this.userRouting(),
  //       error => {
  //         const errorStatus = error.status;
  //         switch (errorStatus) {
  //           case 401:
  //             Swal('Oops...', 'Unauthorized', 'error');
  //             break;
  //           case 400:
  //             Swal(
  //               'Oops...',
  //               'Bad request, so the Data  Not Accessed!',
  //               'error'
  //             );
  //             break;
  //           case 500:
  //             Swal('Oops...', 'Internal Server Error', 'error');
  //             break;
  //         }
  //       }
  //     );
  // }
  /*Temp Function because I am Lazy*/
  // loginTour() {
  //   this.authenticationService
  //     .login('tour@tour.com', 'ABC123')
  //     .pipe(first())
  //     .subscribe(
  //       () => this.userRouting(),
  //       error => {
  //         const errorStatus = error.status;
  //         switch (errorStatus) {
  //           case 401:
  //             Swal('Oops...', 'Unauthorized', 'error');
  //             break;
  //           case 400:
  //             Swal(
  //               'Oops...',
  //               'Bad request, so the Data  Not Accessed!',
  //               'error'
  //             );
  //             break;
  //           case 500:
  //             Swal('Oops...', 'Internal Server Error', 'error');
  //             break;
  //         }
  //       }
  //     );
  // }
  // logingym() {
  //   this.authenticationService
  //     .login('fitness@Zabatnee.com', 'miette123')
  //     .pipe(first())
  //     .subscribe(
  //       () => this.userRouting(),
  //       error => {
  //         const errorStatus = error.status;
  //         switch (errorStatus) {
  //           case 401:
  //             Swal('Oops...', 'Unauthorized', 'error');
  //             break;
  //           case 400:
  //             Swal(
  //               'Oops...',
  //               'Bad request, so the Data  Not Accessed!',
  //               'error'
  //             );
  //             break;
  //           case 500:
  //             Swal('Oops...', 'Internal Server Error', 'error');
  //             break;
  //         }
  //       }
  //     );
  // }
  // loginConcerts() {
  //   this.authenticationService
  //     .login('concertvendor@zabatnee.com', 'ABC123')
  //     .pipe(first())
  //     .subscribe(
  //       () => this.userRouting(),
  //       error => {
  //         const errorStatus = error.status;
  //         switch (errorStatus) {
  //           case 401:
  //             Swal('Oops...', 'Unauthorized', 'error');
  //             break;
  //           case 400:
  //             Swal(
  //               'Oops...',
  //               'Bad request, so the Data  Not Accessed!',
  //               'error'
  //             );
  //             break;
  //           case 500:
  //             Swal('Oops...', 'Internal Server Error', 'error');
  //             break;
  //         }
  //       }
  //     );
  // }
  registerData = new FormData();

  onFormSubmit(username, password) {
    this.registerData.append('grant_type', 'password');
    this.registerData.append('username', username);
    this.registerData.append('password', password);
    this.registerData.append('client_id', environment.clientId);
    this.registerData.append('client_secret', environment.clientSecret);


    console.log(username, password);
    this.authenticationService
      .login(this.registerData)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data)
          this.registerData = new FormData();

          this.userRouting();
        },
        error => {
          this.registerData = new FormData();

          const errorStatus = error.status;
          switch (errorStatus) {
            case 401:
              Swal('Oops...', 'Unauthorized', 'error');
              break;
            case 400:
              Swal(
                'Oops...',
                'Bad request, so the Data  Not Accessed!',
                'error'
              );
              break;
            case 500:
              Swal('Oops...', 'Internal Server Error', 'error');
              break;
          }
        }
      );
  }
  gotoseats() {
    this.router.navigate(['/seats']);
  }
  userRouting() {
    this.getProfileService.getProfile().subscribe((man: any) => {
      console.log(man)
      this.category = man.vendor.category_id;
      if (this.category === 2) {
        this.router.navigate(['/dashboard']);
      } else if (this.category === 1) {
        this.router.navigate(['/R/dashboard']);
      } else if (this.category === 4) {
        this.router.navigate(['/g/dashboard']);
      } else if (this.category === 3) {
        this.router.navigate(['/concerts/dashboard']);
      }
    });
  }
}
