import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpService, ErrorHandlerService } from '../../../../_services';
import { environment } from '../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
	selector: 'app-create-reservation',
	templateUrl: './create-reservation.component.html',
	styleUrls: ['./create-reservation.component.scss']
})
export class CreateReservationComponent implements OnInit {
	branches: any[];
	areas: any[] = [];
	time_slots: any[] = [];
	minDate = new Date(new Date());
	current_branch_id;
	days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	todayDayName = null;
	availableSeats = null;
	foundTimeSlots = false;
	current_area_id;
	current_branch_seq_id;
	foundUserData = false;
	// form validation
	isValidFormSubmitted = false;
	minNum = 0;
	numberOfSeates = '';
	hasAvailableSeats = false;
	remainSeats = 0;
	allSeats = 0;
	// form controllers
	model = {
		mobile_number: '',
		first_name: '',
		last_name: '',
		area_id: '',
		date: '',
		time_slots: '',
		number_of_people: '',
		notes: '',
		branch: ''
	};
	checkNumbers = false;
	current_time_slot: any;
	current_user_id: any;
	x: string;
	oo: any;

	constructor(
		// get a reference to this data object in CreateReservationComponent
		// by using the MAT_DIALOG_DATA injectable
		private dialogRef: MatDialogRef<CreateReservationComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private httpService: HttpService,
		private toastr: ToastrService,
		private errorHandlerServices: ErrorHandlerService
	) {
		this.branches = data['branches'];
	}

	ngOnInit() {
		this.x = Date()
			.toString()
			.slice(15, -48);
		console.log(this.x);
		this.oo = new Date().getDate();
	}

	getUserData() {
		const reg = /^\d+$/;
		const current_mobile_number = this.model.mobile_number;
		if (this.model.mobile_number && !reg.test(current_mobile_number)) {
			this.checkNumbers = true;
		}
		if (this.model.mobile_number && reg.test(current_mobile_number)) {
			this.checkNumbers = false;
			this.foundUserData = false;
			this.model.first_name = '';
			this.model.last_name = '';
			this.httpService
				.getData(
					`${environment.apiEndPoint}/vendor/searchuser/searchterm/${
						this.model.mobile_number
					}/`
				)
				.subscribe(userData => {
					if (!userData.hasOwnProperty('user')) {
						return this.toastr.success(userData['detail'], 'User Not Found');
					}
					this.model.first_name = userData['user']['first_name'];
					this.model.last_name = userData['user']['last_name'];
					this.current_user_id = userData['user']['id'];
					if (
						userData['user'].hasOwnProperty('first_name') &&
						userData['user']['first_name'] !== '' &&
						userData['user'].hasOwnProperty('last_name') &&
						userData['user']['last_name'] !== ''
					) {
						this.foundUserData = true;
					}
				});
		}
	}
	getSelectedBranch(branch_id) {
		this.model.branch = branch_id;
		this.current_branch_id = branch_id;
		this.getAreasData(branch_id);
	}
	getAreasData(branch_id) {
		this.areas = [];
		this.branches.map(branch => {
			if (branch['branch_id'] === branch_id) {
				for (let i = 0; i < branch['branch_areas'].length; i++) {
					this.areas.push(branch['branch_areas'][i]['name']);
				}
			}
		});
	}
	getSelectedArea(area_id) {
		this.model.area_id = area_id;
		for (let i = 0; i < this.branches.length; i++) {
			if (this.branches[i]['branch_id'] === this.current_branch_id) {
				this.current_area_id = this.branches[i]['branch_areas'][area_id][
					'guid'
				];
			}
		}
	}
	getSelectedDate(date) {
		const dateISO = new Date(date);
		this.todayDayName = this.days[dateISO.getDay()];
		this.model.date =
			dateISO.getFullYear() +
			'-' +
			(dateISO.getMonth() + 1) +
			'-' +
			dateISO.getDate();
		this.getTimeSlotsData(this.todayDayName);
	}
	getTimeSlotsData(today_name) {
		const access_token = JSON.parse(localStorage.getItem('currentUser'))[
			'access_token'
		];
		this.httpService
			.getData(
				`${environment.apiEndPoint}/vendor/timeslot/branch/${
					this.current_branch_id
				}/day/${today_name}/`,
				{
					client_id: environment.clientId,
					client_secret: environment.clientSecret,
					access_token: access_token
				}
			)
			.subscribe(
				timeSlots => {
					const responseCode = timeSlots['code'];
					if (responseCode === '11014') {
						this.toastr.error(timeSlots['detail'], 'Error');
					} else if (responseCode === '10000') {
						this.foundTimeSlots = true;
						this.time_slots = timeSlots['time_slot']['slots'];
					}
					console.log(timeSlots, ' sfa');
				},
				error => {
					if (error['error']['code'] === '11007') {
						this.toastr.error(error['error']['detail'], 'Error');
					}
				}
			);
	}
	getSelectedTimeSlot(currentTimeSlot) {
		this.model.time_slots = currentTimeSlot;
		this.getNumberOfAvailableSeatsData(currentTimeSlot);
	}
	getNumberOfAvailableSeatsData(currentTimeSlot) {
		this.current_time_slot = currentTimeSlot;
		this.httpService
			.getData(
				`${environment.apiEndPoint}/vendor/area/${
					this.current_area_id
				}/timeslot/`,
				{
					from_date_time: this.model.date + ' ' + currentTimeSlot[0],
					to_date_time: this.model.date + ' ' + currentTimeSlot[1]
				}
			)
			.subscribe(
				seats => {
					this.hasAvailableSeats = true;
					this.availableSeats = seats['seats'];
					this.remainSeats = this.availableSeats;
					this.allSeats = this.availableSeats;
				},
				error => {
					if (error['error']['code'] === '11006') {
						this.toastr.error(error['error']['detail'], 'Error');
					}
				}
			);
	}
	getNumberOfSeatsFromUser(number_of_people) {
		this.model.number_of_people = number_of_people;
	}

	/**
	 * close the dialog
	 */
	close() {
		this.dialogRef.close();
	}

	/**
	 * save the date and parse it to parent component
	 * {
	 *   area_id: "31d80f7b-e673-4a33-86fb-f702e5a0a271"
	 *   branch_id: "7131cdc2-a6bd-40e1-9cca-f2bfd38afb91"
	 *   channel: 1
	 *   from_time: "2018-10-24 06:00:00"
	 *   notes: "sdasdsadasd"
	 *   number_of_people: 1
	 *   reserve_full_capacity: 0
	 *   to_time: "2018-10-24 07:00:00"
	 * }
	 */
	save(form: NgForm) {
		// console.log(parseInt(this.oo) , 'safasfasfsafasf' ,parseInt( this.model.date.slice(7)))

		// console.log(parseInt(this.current_time_slot[0].slice(0, -3)), ':', parseInt(this.x))
		// tslint:disable-next-line: radix
		if (parseInt(this.oo) === parseInt(this.model.date.slice(7))) {
			console.log('es');
      // tslint:disable-next-line: radix
       if(this.current_time_slot === undefined){
        swal('please select Time slots' , '' , 'error')
      }
		else if (parseInt(this.x) > parseInt(this.current_time_slot[0].slice(0, -3))) {
				swal('This time has passed', 'error', 'warning');
      }
       else {
				// this.isValidFormSubmitted = false;
				// if (form.invalid) {
				// 	return;
				// }
        // this.isValidFormSubmitted = true;
        if(this.model.mobile_number === '' ){
          swal('please add number of mobile' , '' , 'error')
        }
        else if(this.model.first_name === ''){
          swal('please add first name ' , '' , 'error')
        }
        else if(this.model.last_name === ''){
          swal('please add last name ' , '' , 'error')
        }
        else if(this.current_branch_id === undefined){
          swal('please select the branch' , '' , 'error')
        }
        else if(this.current_area_id === undefined){
          swal('please select the area' , '' , 'error')
        }
        else if(this.model.date === '' ){
          swal('please select the date' , '' , 'error')
        }

        else if(this.model.number_of_people === '' ){
            swal('please add number of people' , '' , 'error')
        }
        else{
				const createQueryParams = {
					user_id: this.current_user_id,
					branch_id: this.current_branch_id,
					area_id: this.current_area_id,
					from_time: this.model.date + ' ' + this.current_time_slot[0],
					to_time: this.model.date + ' ' + this.current_time_slot[1],
					number_of_people: this.model.number_of_people,
					reserve_full_capacity: 0,
					notes: form.value['notes'],
					channel: 1,
					mobile: ''
        };
        console.log(createQueryParams )
				const access_token = JSON.parse(localStorage.getItem('currentUser'))[
					'access_token'
				];
				this.httpService
					.postData(
						`${environment.apiEndPoint}/vendor/reservation/`,
						{
							client_id: environment.clientId,
							client_secret: environment.clientSecret,
							access_token: access_token
						},
						createQueryParams
					)
					.subscribe(
						next => {
              swal('Reservation is Created', ' Done ', 'success');
              this.dialogRef.close();
							return next;
						},
						_error => {
              swal('Reservation dont create ', '' , 'error')
							if (_error['status'] === 500) {
								this.toastr.error(_error['statusText'], 'Error :D');
							}
							this.errorHandlerServices.handleError(_error);
						}
					);
      }
    }
		} else {
			// this.isValidFormSubmitted = false;
			// if (form.invalid) {
			// 	return;
			// }
			// console.log(this.current_time_slot)
			if(this.model.mobile_number === '' ){
				swal('please add number of mobile' , '' , 'error')
			}
			else if(this.model.first_name === ''){
				swal('please add first name ' , '' , 'error')
			}
			else if(this.model.last_name === ''){
				swal('please add last name ' , '' , 'error')
			}
			else if(this.current_branch_id === undefined){
				swal('please select the branch' , '' , 'error')
			}
			else if(this.current_area_id === undefined){
				swal('please select the area' , '' , 'error')
      }
      else if(this.model.date === '' ){
        swal('please select the date' , '' , 'error')
      }
			else if(this.current_time_slot === undefined){
				swal('please select Time slots' , '' , 'error')
      }
      else if(this.model.number_of_people === '' ){
          swal('please add number of people' , '' , 'error')
			}
      else{
			// this.isValidFormSubmitted = true;
			const createQueryParams = {
				user_id: this.current_user_id,
				branch_id: this.current_branch_id,
				area_id: this.current_area_id,
				from_time: this.model.date + ' ' + this.current_time_slot[0],
				to_time: this.model.date + ' ' + this.current_time_slot[1],
				number_of_people: this.model.number_of_people,
				reserve_full_capacity: 0,
				notes: form.value['notes'],
				channel: 1,
				mobile: ''
      };

      console.log(createQueryParams )

			const access_token = JSON.parse(localStorage.getItem('currentUser'))[
				'access_token'
			];
			this.httpService
				.postData(
					`${environment.apiEndPoint}/vendor/reservation/`,
					{
						client_id: environment.clientId,
						client_secret: environment.clientSecret,
						access_token: access_token
					},
					createQueryParams
				)
				.subscribe(
					next => {
            swal('Reservation is Created', ' Done ', 'success');
            this.dialogRef.close();
						return next;
					},
					_error => {
            swal('Reservation dont create ', '' , 'error')
						if (_error['status'] === 500) {
							this.toastr.error(_error['statusText'], 'Error');
						}
						this.errorHandlerServices.handleError(_error);
					}
				);
		}
  }
}
}
