import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpService, ErrorHandlerService } from '../../../../_services';
import { environment } from '../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';

@Component({
	selector: 'app-anonymous-reservation',
	templateUrl: './anonymous-reservation.component.html',
	styleUrls: ['./anonymous-reservation.component.scss']
})
export class AnonymousReservationComponent implements OnInit {
	branches: any[];
	areas: any[] = [];
	time_slots: any[] = [];
	minDate = new Date(new Date());
	current_branch_id;
	days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	todayDayName = null;
	availableSeats = null;
	foundTimeSlots = false;
	current_area_id;
	current_branch_seq_id;
	foundUserData = false;
	// form validation
	isValidFormSubmitted = false;
	minNum = 0;
	numberOfSeates = '';
	hasAvailableSeats = false;
	remainSeats = 0;
	allSeats = 0;
	// form controllers
	checkNumbers = false;
	current_time_slot: any;
	todayDate: string;
	number_of_people;
	numberOfSeatsAvailable: any;
	x: string;
	oo: any;
	constructor(
		// get a reference to this data object in CreateReservationComponent
		// by using the MAT_DIALOG_DATA injectable
		private dialogRef: MatDialogRef<AnonymousReservationComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private httpService: HttpService,
		private toastr: ToastrService,
		private errorHandlerServices: ErrorHandlerService
	) {
		this.branches = data['branches'];
	}

	ngOnInit() {
		this.getTodayDate();
		this.x = Date()
			.toString()
			.slice(15, -48);
		console.log(this.x);
		this.oo = new Date().getDate();
	}

	getSelectedBranch(branch_id) {
		this.current_branch_id = branch_id;
		this.getAreasData(branch_id);
	}
	getAreasData(branch_id) {
		this.areas = [];
		this.branches.map(branch => {
			if (branch['branch_id'] === branch_id) {
				for (let i = 0; i < branch['branch_areas'].length; i++) {
					this.areas.push(branch['branch_areas'][i]['name']);
				}
			}
		});
	}
	getSelectedArea(area_id) {
		for (let i = 0; i < this.branches.length; i++) {
			if (this.branches[i]['branch_id'] === this.current_branch_id) {
				this.current_area_id = this.branches[i]['branch_areas'][area_id][
					'guid'
				];
			}
		}
		this.getTimeSlotsData(this.todayDayName);
	}
	getTodayDate() {
		const dateISO = new Date();
		this.todayDayName = this.days[dateISO.getDay()];
		this.todayDate =
			dateISO.getFullYear() +
			'-' +
			(dateISO.getMonth() + 1) +
			'-' +
			dateISO.getDate();
	}
	getTimeSlotsData(today_name) {
		const access_token = JSON.parse(localStorage.getItem('currentUser'))[
			'access_token'
		];
		this.httpService
			.getData(
				`${environment.apiEndPoint}/vendor/timeslot/branch/${
					this.current_branch_id
				}/day/${today_name}/`,
				{
					client_id: environment.clientId,
					client_secret: environment.clientSecret,
					access_token: access_token
				}
			)
			.subscribe(
				timeSlots => {
					console.log(timeSlots, 'time is');
					const responseCode = timeSlots['code'];
					if (responseCode === '11014') {
						this.toastr.error(timeSlots['detail'], 'Error');
					} else if (responseCode === '10000') {
						this.foundTimeSlots = true;
						this.time_slots = timeSlots['time_slot']['slots'];
					}
				},
				error => {
					if (error['error']['code'] === '11007') {
						this.toastr.error(error['error']['detail'], 'Error');
					}
				}
			);
	}
	getSelectedTimeSlot(currentTimeSlot) {
		this.getNumberOfAvailableSeatsData(currentTimeSlot);
	}
	getNumberOfAvailableSeatsData(currentTimeSlot) {
		this.current_time_slot = currentTimeSlot;
		this.httpService
			.getData(
				`${environment.apiEndPoint}/vendor/area/${
					this.current_area_id
				}/timeslot/`,
				{
					from_date_time: this.todayDate + ' ' + currentTimeSlot[0],
					to_date_time: this.todayDate + ' ' + currentTimeSlot[1]
				}
			)
			.subscribe(
				seats => {
					this.hasAvailableSeats = true;
					this.availableSeats = seats['seats'];
					this.remainSeats = this.availableSeats;
					this.allSeats = this.availableSeats;
				},
				error => {
					if (error['error']['code'] === '11006') {
						this.toastr.error(error['error']['detail'], 'Error');
					}
				}
			);
	}

	/**
	 * close the dialog
	 */
	close() {
		this.dialogRef.close();
	}

	/**
	 * save the date and parse it to parent component
	 * {  "branch_id":"5d035b17-f7c7-4591-af43-a69d2c040c3c",
	 *    "area_id":"73e1ac7d-379f-4b51-83f7-fb037e2ba2c2",
	 *    "from_time":"2018-10-22 12:00:00",
	 *    "to_time":"2018-10-22 13:00:00",
	 *    "number_of_people":12,
	 *    "reserve_full_capacity":0,
	 *    "notes":"",
	 *    "channel":2
	 * }
	 */
	save(form: NgForm) {
		// tslint:disable-next-line: radix
		if (parseInt(this.x) > parseInt(this.current_time_slot[0].slice(0, -3))) {
			swal('This time has passed', 'error', 'warning');
		} else {
			this.isValidFormSubmitted = false;
			if (form.invalid) {
				return;
			}
			this.isValidFormSubmitted = true;
			const createQueryParams = {
				branch_id: this.current_branch_id,
				area_id: this.current_area_id,
				from_time: this.todayDate + ' ' + form.value['time_slot'][0],
				to_time: this.todayDate + ' ' + form.value['time_slot'][1],
				number_of_people: form.value['number_of_people'],
				reserve_full_capacity: 0,
				notes: form.value['notes'] || '',
				channel: 2,
				mobile: ''
			};
			console.log(createQueryParams);
			const access_token = JSON.parse(localStorage.getItem('currentUser'))[
				'access_token'
			];
			this.httpService
				.postData(
					`${environment.apiEndPoint}/vendor/reservation/`,
					{
						client_id: environment.clientId,
						client_secret: environment.clientSecret,
						access_token: access_token
					},
					createQueryParams
				)
				.subscribe(
					next => {
						console.log(next, ' ssss');
						swal('reservation are creatd', 'thanks', 'success');
						return next;
					},
					_error => {
						console.log(_error, 'eeeee');
						if (_error['status'] === 500) {
						}
						this.errorHandlerServices.handleError(_error);
					}
				);
			this.dialogRef.close();
		}
	}
}
