import { ConcertsListService } from './../../../_services/concerts.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpService } from '../../../_services';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

@Component({
	selector: 'app-concerts-dashboard',
	templateUrl: './concerts-dashboard.component.html',
	styleUrls: ['./concerts-dashboard.component.scss']
})
export class ConcertsDashboardComponent implements OnInit {
	isLoadingInsights = false;
	// Pie
	public pieChartLabels: string[] = ['1st Show', '1nd Show', '3rd Show'];
	public pieChartData: number[] = [100, 800, 100];
	public pieChartType = 'pie';
	res_to: string;
	res_from: string;
	eventname: any;
	eventid: any;
	insights: void;
	nocust: any;
	revenue: any;
	number_of_events: any;
	event_sold_tickets: any[] = [];
	recent_reservations: any = [];
	event_id: any;
	eventdata: any;
	dataSource;
	loadtikects = false;
	@ViewChild(MatPaginator)
	paginator: MatPaginator;
	paginator2: MatPaginator;
	@ViewChild(MatSort)
	sort: MatSort;
	sort2: MatSort;

	displayedColumns = ['fromTime', 'toTime', 'soldTickets', 'totalTickets'];
	displayedColumns2 = ['name', 'venue', 'totalTickets', 'soldTickets', 'all'];
	dataSource2: any = [];

	kkk: any[] = [];

	constructor(
		private httpService: HttpService,
		private clservuve: ConcertsListService
	) {}

	ngOnInit() {
		this.isLoadingInsights = true;
		this.clservuve.getBranch().subscribe((data: any) => {
			this.eventname = data['events'];
			// this.isLoading = false;1
			// console.log(this.eventname);
			// this.courseTable();
			// this.sportsCount = this.sportList.length;
		});
		this.clservuve.getinsghtis().subscribe((ingihts: any) => {
			// this.eventname = data['events'];
			// this.isLoading = false;
			this.insights = ingihts['insights'];
			this.nocust = ingihts['insights']['number_of_customers'];
			this.revenue = ingihts['insights']['revenue'];
			this.number_of_events = ingihts['insights']['number_of_events'];
			this.event_sold_tickets = ingihts['insights']['event_sold_tickets'];
			this.recent_reservations = ingihts['insights']['recent_reservations'];
			this.isLoadingInsights = false;
			// console.log(this.event_sold_tickets);
			// this.courseTable();
			// this.sportsCount = this.sportList.length;
			this.dataSource2 = new MatTableDataSource(this.event_sold_tickets);
			this.dataSource2.paginator = this.paginator;
			this.dataSource2.sort = this.sort;
			// console.log(this.dataSource2);
		});
	}

	getSelectedConcertDateFrom(value: any) {
		const dateISO = new Date(value);
		this.res_from =
			dateISO.getFullYear() +
			'-' +
			(dateISO.getMonth() + 1) +
			'-' +
			dateISO.getDate();
		// console.log("DATESZZFAFASSFA ", this.res_from)
	}

	getSelectedConcertDateTo(value: any) {
		const dateISO = new Date(value);
		this.res_to =
			dateISO.getFullYear() +
			'-' +
			(dateISO.getMonth() + 1) +
			'-' +
			dateISO.getDate();
		// console.log("apapppapap ", this.res_to)
	}
	search() {
		this.kkk = [];
		this.isLoadingInsights = true;
		const dataw = {
			date_to: this.res_to || '',
			date_from: this.res_from || '',
			event_guid: this.eventid || ''
		};
		this.clservuve.getinsghtis(dataw).subscribe((ingihts: any) => {
			// this.eventname = data['events'];
			// this.isLoading = false;
			this.insights = ingihts['insights'];
			this.nocust = ingihts['insights']['number_of_customers'];
			this.revenue = ingihts['insights']['revenue'];
			this.number_of_events = ingihts['insights']['number_of_events'];
			this.event_sold_tickets = ingihts['insights']['event_sold_tickets'];
			// this.kkk =  ingihts['insights']['event_sold_tickets']
			this.kkk.push(this.event_sold_tickets);
			this.dataSource2 = new MatTableDataSource(this.kkk);
			this.dataSource2.paginator = this.paginator;
			this.dataSource2.sort = this.sort;
			// console.log(this.dataSource2);
			this.isLoadingInsights = false;
			// console.log(this.kkk , 'sss');
			// this.courseTable();
			// this.sportsCount = this.sportList.length;
		});
		console.log(dataw);
	}

	public chartClicked(e: any): void {
		console.log(e);
	}

	public chartHovered(e: any): void {
		console.log(e);
	}

	eventchange() {
		// console.log(this.event_id)
		this.clservuve.gettikectsold(this.event_id).subscribe((data: any) => {
			this.eventdata = data['shows'];
			this.loadtikects = true;
			// this.isLoading = false;1
			// console.log(this.eventdata);
			// this.courseTable();
			// this.sportsCount = this.sportList.length;
			this.dataSource = new MatTableDataSource(this.eventdata);
			this.dataSource.paginator = this.paginator2;
			this.dataSource.sort = this.sort2;

			// console.log(this.dataSource);
		});
	}
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	applyFilter2(filterValue: string) {
		this.dataSource2.filter = filterValue.trim().toLowerCase();
	}
}
