import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-c-d-actions',
	templateUrl: './c-d-actions.component.html',
	styleUrls: ['./c-d-actions.component.scss']
})
export class CDActionsComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
