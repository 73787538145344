import { Injectable, ErrorHandler } from '@angular/core';
import Swal from 'sweetalert2';
import { Response } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
	// Because the ErrorHandler is created before the providers,
	// we’ll have to use the Injector to get them.
	constructor(private injector: Injector) {}

	handleError(error: Error | HttpErrorResponse) {
		enum ERROR_TYPE {
			BAD_REQUEST = 400,
			UNAUTHORIZED = 401,
			FORBIDDEN = 403,
			NOT_FOUND = 404,
			INTERNAL_SERVER_ERROR = 500
		}
		const toastr = this.injector.get(ToastrService);
		const router = this.injector.get(Router);
		if (error instanceof HttpErrorResponse) {
			// Server or connection error happened
			if (!navigator.onLine) {
				// Handle offline error
				toastr.error('No Internet Connection', 'Error');
			} else {
				const errorMsg = ', ' + error['error']['detail'];
				// Handle Http Error (error.status === 403, 404...)
				switch (error.status) {
					case ERROR_TYPE.BAD_REQUEST:
						toastr.error(`Bad Request ${errorMsg}`, 'Error');
						break;
					case ERROR_TYPE.UNAUTHORIZED:
						toastr.error(`Unauthorized ${errorMsg}`, 'Error');
						break;
					case ERROR_TYPE.NOT_FOUND:
						toastr.error(`Not Found ${errorMsg}`, 'Error');
						break;
					case ERROR_TYPE.FORBIDDEN:
						toastr.error(`Forbidden ${errorMsg}`, 'Error');
						break;
					case ERROR_TYPE.INTERNAL_SERVER_ERROR:
						toastr.error(`Internal Server Error ${errorMsg}`, 'Error');
						break;
				}
			}
		} else {
			// Handle Client Error (Angular Error, ReferenceError...)
			router.navigate(['/error'], { queryParams: { error: error } });
		}
		// Log the error anyway
		console.error('VRI Error happens: ', error);
	}
}
