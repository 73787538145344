import swal from 'sweetalert2';
import { PhonePipe } from './../../../../_pipes/phone.pipe';
import { Component, OnInit , Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from './../../../../_services/http.service';
import { environment } from './../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material';
import { ToursService } from '../../../../_services';
import * as znznValidators from '../../../../_models';
import { UploadimgService } from '../../../../_services';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SportsListService } from '../../../../_services/sports.service';
import { ErrorHandlerService } from '../../../../_services/error-handler.service';


//  =/=/=/=/=/=/=/=/=/=/  Create Branch Poup  /=/=/=/=/=/=/=/=/=/=  //

@Component({
	selector: 'app-show-date',
	templateUrl: 'create-branch-popup.html',
	styleUrls: ['./create-event.component.scss']
})
export class CreateBranchPopupComponent implements OnInit {

  access_token = JSON.parse(localStorage.getItem('currentUser'))[
		'access_token'
	];
	createBranch: FormGroup;
	secondFormGroup: FormGroup;
	thirdFormGroup: FormGroup;
	isEditable = true;
	isOptional = true;
	latitude = 30.0594838;
	longitude = 31.223359;
	// google maps zoom level
	znznValiditors = znznValidators['znzn-form-validation'];

	zoom = 8;
	cities: any;
	paymentMethods: any;
	branches: any;
	days: any;
	distircts: any;
	isDistircts = false;
	editphone = false;
	branch_phones: any[] = [];
	phone = '';
	newUpdatedPone: any;
	imageName: any;
	areas_addes: any[] = [];
	selectedFile: FileList;
	imagesSources: any[] = [];
	newBranchId;
	daysofWeek = [];
	DaysNames: string[] = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	];
	weekDayss = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
  ];
  DurationWeek = [
		{ id: 0, name: '1 Week' },
  ];
  DurationMonth = [
		{ id: 0, name: '1 Week' },
		{ id: 1, name: '1 Month' },
  ];
  DurationMonths = [
		{ id: 0, name: '1 Week' },
		{ id: 1, name: '1 Month' },
		{ id: 2, name: '3 Month' },
  ];
  DurationSemi = [
		{ id: 0, name: '1 Week' },
		{ id: 1, name: '1 Month' },
		{ id: 2, name: '3 Month' },
		{ id: 3, name: 'Semi annual' },
  ];
	DurationAnnual = [
		{ id: 0, name: '1 Week' },
		{ id: 1, name: '1 Month' },
		{ id: 2, name: '3 Month' },
		{ id: 3, name: 'Semi annual' },
		{ id: 4, name: 'Annual' }
	];
	Settings = [
		{ id: 0, name: 'One Time' },
		{ id: 2, name: 'Fixed Duration' },
		{ id: 1, name: 'On Going' }
	];
	weekDays: any[] = [];
	DaysISONames: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	currentWorkFlow: any;
	marker: { latitude: number; longitude: number };
	setTimeSlotsEDFormGroup: any;
	setTimeSlotsSDFormGroup: FormGroup;
	dayspush: any[] = [];
	trainersdata: any[] = [];
	workflows: any[] = [];
	imagebranchname: any;
	imagesbranchSources: any[] = [];
	imageSrc: string | ArrayBuffer;

	viewCancelation: boolean;
	maxcollection: number;
	mincollection: number;
	flagcollection: boolean;
	flagcollectionnot: boolean;
	zerocollection: boolean;
	msgcollectionzero: string;
	haspaymentId: number;
	msgcollectionnotZero: string;
	hasPayments: any;
	collection_requirement: any;
	policiesToSave = [];
	periodMsg: boolean;
	perioderrorMsg: string;
	dedutionmsg: boolean;
	deductionerrorMsg: string;
	deductiond: any;
	periodd: any;
	dayss: any[] = [];
	policies: any[] = [];
	cancelationForm;
	workflow_id: any;
	payment_method_id: any;
	Tourid: any;
	dataWF: {};
	policyWithId: any;
	selectedPayment: any;
	img_o: FileList;
	img_c: FileList;
	imageSrcss: string | ArrayBuffer;
	imageSrcc: string | ArrayBuffer;
	imageSrcs: string | ArrayBuffer;
	img1: any;
	img_2: any;
	g1: any;
	gallery: any[] = [];
	galleryImages: FileList;
	file: File;
	createclass: FormGroup;
	setTimeSlotsClassormGroup: FormGroup;
	hiddeen = false;
	daysshulade: any[] = [];
	classs: any;
	showfrom = false;
	showto = false;
	isshulda: any;
	trainers: any;
	subscriptions: any[] = [];
	f1class = true;
	f2class = false;
	imggalaary: any[] = [];
	urls: any = [];
	courseId: any;
  going = false;
  checkDate = false;
  errorDetails : any ;
  getDateF = '';
  getDateT : any;
  subDateF  : any;
  subDateT  : any;
  diffTime  ;
  diffDays ;
  zzzzzzz : any[] = [];

  constructor(	@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<CreateBranchPopupComponent>,
  private router: Router,
  private Sports: SportsListService,
  private _http: HttpService,
  private errorHandling: ErrorHandlerService,
  public dialog: MatDialog,
  private _fb: FormBuilder,
  private _httpService: HttpService,
  private _toastr: ToastrService,
  private tours: ToursService,
  private uploadimgService: UploadimgService,
){}

	ngOnInit() {
		this.getBranch();
    this.gettainner();
    this.getData();
		this.createBranch = this._fb.group({
			city: [this.cities],
			area: [{ value: '', disabled: true }],
			address: [''],
			// placeName: ['', Validators.compose([Validators.required])],
			phone: [
				'',
				Validators.compose([
					Validators.pattern(/^-?([0-9]\d*)?$/),
					Validators.maxLength(15)
				])
			]
			// addImages: ['']
    });

		this.setTimeSlotsEDFormGroup = this._fb.group({
			// fromcontrollers
			working_hours_from: ['', Validators.required],
			working_hours_to: ['', Validators.required]
		});
		this.setTimeSlotsSDFormGroup = this._fb.group({
			timeslots: this._fb.array([
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				})
			])
		});
		this.secondFormGroup = this._fb.group({
			Trainer_name: [''],
			About_Trainer: [''],
			Phone: [
				'',
				Validators.compose([
					Validators.pattern(/^-?([0-9]\d*)?$/),
					Validators.maxLength(15)
				])
			],
			addImage: ['']
		});
		this.thirdFormGroup = this._fb.group({
			course_name: [''],
			payment: [''],
			workflow: [''],
			Description: [''],
      addImage: [''],
      imageFile1: [''],
      imageFile2 : ['']
		});
		this.createclass = this._fb.group({
			branch_name: [''],
			// One_Time: [''],
			// Fixed: [''],
			// Ongoing: [''],
			Settings: [''],
			Date_from: [''],
			Date_to: [''],
			Captain_name: [''],
			Class_name: [''],
			Maximum_Capacity: [''],
			Duration: [''],
			Cost: [''],
			invtations: ['']
		});

		this.setTimeSlotsClassormGroup = this._fb.group({
			timeslots: this._fb.array([
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				})
			])
		});

		this.getWeekDays();
	}
	getData() {
		// get cities
		this._httpService
			.getData(`${environment.apiEndPoint}/vendor/city`, {
				client_id: environment.clientId,
				client_secret: environment.clientSecret,
				access_token: this.access_token
			})
			.subscribe(cities => (this.cities = cities['cities']));
		// get payment_methods
		this._httpService
			.getData(`${environment.apiEndPoint}/payment/methods`)
			.subscribe(
				paymentMethods =>
					(this.paymentMethods = paymentMethods['payment_methods'])
			); // {"id":1,"name":"Pay on Arrival"},
		// get Branches
		// get Days
		this._httpService
			.getData(`${environment.apiEndPoint}/daysofweek/`, {
				client_id: environment.clientId,
				client_secret: environment.clientSecret,
				access_token: this.access_token
			})
			.subscribe(days => (this.days = days['days'])); // "id":1,"number":1,"name":"Sunday","iso":"Sun"}
		// get Workflows
		// this._httpService
		// 	.getData(`${environment.apiEndPoint}/vendor/listworkflows/`, {
		// 		client_id: environment.clientId,
		// 		client_secret: environment.clientSecret,
		// 		access_token: this.access_token
		// 	})
		// 	.pipe(
		// 		map(resp => {
		// 			const workflows = resp['workflows'];
		// 			this.workflows = [];
		// 			// "name":"Restaurant: reserve with form","guid":"c3bdbc67-4d60-4c42-9c46-4f6ae9ea66d2"
		// 			for (let i = 0; i < workflows.length; i++) {
		// 				this.workflows.push({
		// 					name: workflows[i]['name'],
		// 					guid: workflows[i]['guid']
		// 				});
		// 			}
		// 		})
		// 	)
		// 	.subscribe();
	}

	getSelectedCity(city_id) {
		// get Days
		this._httpService
			.getData(`${environment.apiEndPoint}/distirct/city/${city_id}/`, {
				client_id: environment.clientId,
				client_secret: environment.clientSecret,
				access_token: this.access_token
			})
			.subscribe(distircts => {
				// {"name":"Down Town","cityname":"Cairo","id":4}
				if (distircts['distircts'] && distircts['distircts'].length) {
					this.createBranch.controls['area'].enable();
					return (this.distircts = distircts['distircts']);
				}
				this.distircts = [];
				this._toastr.warning(
					'Sorry There are no areas in this city',
					'no areas'
				);
				return this.createBranch.controls['area'].setErrors({
					required: true
				});
			});
	}
	mapClicked($event: MouseEvent) {
		// {"coords":{"lat":30.163218889001502,"lng":30.161410466523535}}
		const latitude = $event['coords']['lat'];
		const longitude = $event['coords']['lng'];
		this.latitude = latitude;
		this.longitude = longitude;
		this.marker = {
			latitude: this.latitude,
			longitude: this.longitude
    };
    console.log(this.latitude + '  ' +  this.longitude);
  }



	addTimeSlotsSpecifiDays(formValue) {

//     if ( this.timeslotsArr.controls['working_hours_from'].value === ''){
// alert('empty');
//     }
		const timeslotsArr = formValue['timeslots'];
		console.log('al3b ', timeslotsArr);
		const days = [];
		const regTime = /^\d{1,2}:\d{2}([ap]m)?$/;
		for (let index = 0; index < this.DaysNames.length; index++) {
			const working_hours_from = timeslotsArr[index]['working_hours_from'];
			const working_hours_to = timeslotsArr[index]['working_hours_to'];
			//   if ( working_hours_from >  working_hours_to){
			//     console.log('ya 7omar')
			// }
			if (
				working_hours_from !== '' &&
				regTime.test(working_hours_from) &&
				working_hours_to !== '' &&
				regTime.test(working_hours_to)
			) {
				this.dayspush.push({
					// id: index + 1,
					// number: index + 1,
					// name: this.DaysNames[index],
					// iso: this.DaysISONames[index],
					day_id: index,
					from: working_hours_from,
					to: working_hours_to
        });



			}
		}
    console.log(this.dayspush, 'days');
    if (this.dayspush.length !== 0) {
// tslint:disable-next-line: triple-equals
// tslint:disable-next-line: no-unused-expression
     this.checkDate = true;
     swal('Done', 'success', 'success');
      }
     else if (this.dayspush.length === 0) {
        // tslint:disable-next-line: triple-equals
        // tslint:disable-next-line: no-unused-expression
        this.checkDate = false;
        swal('Please Enter Start and End Time', 'warning', 'warning');
        }
	}
	addTimeSlotsEveryDay(formValue) {
		const working_hours_from = formValue['working_hours_from'];
		const working_hours_to = formValue['working_hours_to'];
    const days = [];
    for (let index = 0; index < this.DaysNames.length; index++) {
      this.dayspush.push({
        // id: index + 1,
        // number: index + 1,
        // name: this.DaysNames[index],
        // iso: this.DaysISONames[index],
        day_id: index,
        from: working_hours_from,
        to: working_hours_to
      });
    }
    if (this.dayspush.length !== 0) {
      // tslint:disable-next-line: triple-equals
      // tslint:disable-next-line: no-unused-expression
           this.checkDate = true;
           swal('Done', 'success', 'success');
            }
           else if (this.dayspush.length === 0) {
             console.log(this.dayspush,'123123');
              // tslint:disable-next-line: triple-equals
              // tslint:disable-next-line: no-unused-expression
              this.checkDate = false;
              swal('Please Enter Start and End Time', 'warning', 'warning');
              }

		console.log(this.dayspush, 'days');
	}

	addPhone(phone) {
    console.log(phone);
		if (phone === '') {
			swal('Add phone number please', 'Error', 'error');
    }
     else if (phone === null) {
			swal('Add phone number please', 'Error', 'error');
    }
   else {
			this._httpService
				.getData(`${environment.apiEndPoint}/vendor/phoneexists/${phone}/`)
				.subscribe(resp => {
          console.log(resp);
					if (resp['code'] === '10034') {
						this.branch_phones.push(phone);
            this.createBranch.controls.phone.reset();
           this._toastr.info("Info Phone not exists in the system.");
					} else if (resp['code'] === '10016') {
						this._toastr.error('Phone already exists in the system.', 'Error');
					}
				});
		}
		console.log('phone', phone);
	}
	removePhone(phoneIndex) {
		this.branch_phones.splice(phoneIndex, 1);
	}
	editPhone() {
		this.editphone = !this.editphone;
	}
	savePhone($event) {
		const reg = /^\d+$/;
		const phone = $event.target.value;
		if (reg.test(phone)) {
			this.newUpdatedPone = phone;
		} else if (!reg.test(phone)) {
			this._toastr.error('mobile phone must be number', 'Error');
		}
	}
	updatePhone(phoneIndex, currentPhone) {
		this.branch_phones[phoneIndex] = this.newUpdatedPone || currentPhone;
		this.editphone = !this.editphone;
		console.log(this.newUpdatedPone, 'new', 'old', phoneIndex);
	}
	closeUpdate() {
		this.editphone = !this.editphone;
	}

	createdata(stepper: MatStepper) {
		// if (this.dayspush = []){
		//   swal('Add Time Slots', 'Error', 'error')
		// }
		// else if (this.imagebranchname = "" ){
		//   swal('please add image branch', 'Error', 'error')
		// }
		// else {

console.log(this.createBranch.get('area').value , '123');
console.log(this.createBranch.get('address').value , '456');
    console.log(this.branch_phones.length, 'length');
    if (this.createBranch.get('city').value === null ) {
      return this._toastr.error(
				'Please Choose a City',
				'Error'
			);
    } else if (this.createBranch.get('area').value === '' ) {
      return this._toastr.error(
				'Please Choose an Area',
				'Error'
			);
    } else if (this.createBranch.get('address').value === '' ) {
      return this._toastr.error(
				'Please Write an Address',
				'Error'
			);
    } else if (this.checkDate === false) {
			return this._toastr.error(
				'Please fill Day data to create branch',
				'Error'
			);
    } else if (this.branch_phones.length === 0) {
			return this._toastr.error(
				'Please fill phone data to create branch',
				'Error'
			);
		}
    else {
			const branch = {
				// name: this.createBranch.get('placeName').value,
				district: this.createBranch.get('area').value,
				address: this.createBranch.get('address').value,
				latitude: 0,
				longitude: 0,
				photo: 'FitnessBranchpic',
				workingHours: this.dayspush,
				phone: this.branch_phones
				// latitude: this.marker.latitude,
				// longitude: this.marker.longitude,
				// city: this.createBranch.get('city').value,
			};

			this._httpService
				.postData(
					`${environment.apiEndPoint}/vendor/fitness/branch/`,
					{
						client_id: environment.clientId,
						client_secret: environment.clientSecret,
						access_token: this.access_token
					},
					branch
				)
				.subscribe(
					resp => {
						if (resp['code'] === '10000') {
							swal('Branch is created', 'Thanks', 'success');
							stepper.next();
							this.getBranch();
						}
						console.log(resp);
					},
					error => {
            // this.errorDetails = error.error['message'];
            // swal('Branch Not Created (' +  this.errorDetails + ')', 'Error', 'error');
            // console.log(error);
            // console.log(this.errorDetails);
						if (error['error']['code'] === '10001') {
							swal(' Branch Not Created Please provide missing parameter(s).', 'Error', 'error');
            }
            else if (error['error']['code'] === "90000") {
							swal('Branch Not Created Duplicate Data ( Phone Number )', 'Error', 'error');
						} else {
							swal('Branch Not Created', 'Error', 'error');
							console.log(error, 'error is');
						}
					}
				);
			console.log(branch, 'branch info');
			// }
		}
	}
	addnewtrainr() {
		const trainer = {
			name: this.secondFormGroup.get('Trainer_name').value,
			phone: this.secondFormGroup.get('Phone').value,
			about: this.secondFormGroup.get('About_Trainer').value,
			photo: this.imageName
		};
		this._httpService
			.postData(
				`${environment.apiEndPoint}/vendor/fitness/trainer/`,
				{
					client_id: environment.clientId,
					client_secret: environment.clientSecret,
					access_token: this.access_token
				},
				trainer
			)
			.subscribe(
				resp => {
					if (resp['code'] === '10000') {
						swal('Trainer is created', 'Thanks', 'success');
					}
					// s
					const path = 'sports/trainer';
					const file1 = this.selectedFile.item(0);
					console.log('POP', file1);
					this.uploadimgService.uploadFile(file1, path);
					// d
					console.log(resp);
					this.trainersdata.push(trainer);
					this.secondFormGroup.reset();
				},
				error => {
					//  const errphone = error['error']['error']['message'].split(',')
					if (error['error']['code'] === '90000') {
						const errphone = error['error']['error']['message']
							.split(',')[0]
							.split('(');
						if (errphone[1] === '1062') {
							console.log(errphone[1]);
							swal(
								'Trainer phone is already used in system',
								'Thanks',
								'error'
							);
						}
					} else if (error['error']['code'] === '10001') {
						swal('please enter all data', 'error', 'error');
					} else {
						swal('Trainer is Not created', 'Thanks', 'error');
					}
					console.log(error, 'ss');
					// console.log(error['error']['error']['message'].split(','), 'error is');
				}
			);
		console.log(trainer, 'branch info');
		console.log(this.trainersdata);
		// this.secondFormGroup.reset();
	}

	createtrainer(stepper: MatStepper) {

      const finaltrain = {
        trainner: this.trainersdata
      };
      console.log(finaltrain, 'train');
      stepper.next();
      this.getBranch();
      this.gettainner();


	}
	removeTrainerAdded(areaIndex) {
		this.trainersdata.splice(areaIndex, 1);
	}
	clearTrain() {
		this.secondFormGroup.reset();
	}

	selectFile(event) {
		this.selectedFile = event.target.files;
		if (event.target.files && event.target.files[0] && event !== undefined) {
			const file = event.target.files[0];
			this.imageName = file['name'];
			const reader = new FileReader();
			reader.readAsDataURL(file); // read file as data url
			reader.onload = onload => {
				// called once readAsDataURL is completed
				this.imagesSources.push(onload.target['result']);
			};
		}
	}

	selectFilebranch(event) {
		this.selectedFile = event.target.files;
		if (event.target.files && event.target.files[0] && event !== undefined) {
			const file = event.target.files[0];
			this.imagebranchname = file['name'];
			const reader = new FileReader();
			reader.readAsDataURL(file); // read file as data url
			reader.onload = onload => {
				// called once readAsDataURL is completed
				reader.onload = e => (this.imageSrc = reader.result);
				reader.readAsDataURL(file);
			};
		}
	}

	getWeekDays() {
		this.tours.getWeekDays().subscribe((data: any) => {
			// console.log(data,'week')
			this.weekDays = data.days;

			for (let i = 0; i < this.weekDays.length; i++) {
				this.daysofWeek.push({
					id: this.weekDays[i].id,
					name: this.weekDays[i].name,
					selected: false,
					isSet: false
				});
			}
		});
	}
	changePayment(paymentid) {
		console.log(paymentid, 'payment ');
		if (paymentid === 1) {
			// pay on arrival
			this.viewCancelation = false;
			this.haspaymentId = 2;
			// console.log("tt", this.mincollection, this.maxcollection);
		} else {
			// 2 credit card
			this.viewCancelation = true;
			this.haspaymentId = 1;
		}
		this.tours.getWorkFlowPayment(this.haspaymentId).subscribe((data: any) => {
			console.log(data, 'dsadsadasdasdasdasdas');
			this.hasPayments = data.workflows;
		});
	}

	AddBranchPolicy() {
		if (this.periodd === undefined) {
			this.periodMsg = true;
			this.perioderrorMsg = 'please enter period';
		} else if (this.deductiond === undefined) {
			this.dedutionmsg = true;
			this.deductionerrorMsg = 'please enter deduction';
		} else {
			this.periodMsg = false;
			this.dedutionmsg = false;
			const policyObj = {};
			this.dayss = [];
			for (let j = 0; j < this.daysofWeek.length; j++) {
				if (this.daysofWeek[j].selected === true) {
					this.dayss.push({
						period: this.periodd,
						deduction: this.deductiond,
						day_name: this.daysofWeek[j].name,
						day_id: this.daysofWeek[j].id
					});
					this.policies.push({
						period: this.periodd,
						deduction: this.deductiond,
						day_id: this.daysofWeek[j].id.toString(),
						id: ''
					});
				}
			}
			this.policiesToSave.push({ days: this.dayss });
			const policyy = {};
			policyy['periodd'] = '';
			policyy['deductiond'] = '';
			this.cancelationForm = {};
			this.periodd = '';
			this.deductiond = '';
			console.log(this.policiesToSave);
		}
	}

	removePolicy(index) {
		this.policiesToSave.splice(index, 1);
		this.policies.splice(index, 1);
		this.dayss.splice(index, 1);
	}

	dataURLtoFile(dataurl, filename) {
		console.log('data in image', dataurl, filename);
		const arr = dataurl;
		const mime = arr[0];
		const bstr = arr[1];
		let n = bstr;
		const u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}

	// selectCover(event) {
	// 	this.img_c = event.target.files;
	// 	//   this.img_2 = this.img_c[0].name;
	// 	//   this.img_2 = Date.now() + '.jpg';
	// 	console.log(this.img_c, 'Wfa');
	// 	if (event.target.files && event.target.files[0]) {
	// 		const file = event.target.files[0];
	// 		const reader = new FileReader();
	// 		reader.onload = e => (this.imageSrcc = reader.result);
	// 		reader.readAsDataURL(file);
	// 	}
	// }

	selectsFile(event) {
		this.img_o = event.target.files;
		console.log(this.img_o[0].name);
		//   this.img1 = this.img_o[0].name;
		//   this.img1 = Date.now() + '.jpg';
		//   console.log(this.img1, "lfa")
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = e => {
				this.imageSrcss = reader.result;
				console.log(this.imageSrcss.slice(23));
			};
			reader.readAsDataURL(file);
		}
	}
	selectgalary(event) {
		this.galleryImages = event.target.files;
		console.log(this.galleryImages, 'gala');
		for (let i = 0; i < this.galleryImages.length; i++) {
			//   this.g1 = this.galleryImages[i].name;
			//   this.g1 = Date.now();
			this.file = this.dataURLtoFile(
				this.galleryImages[i].name,
				Date.now() + '_' + i + '.jpg'
			);
			this.gallery.push(this.galleryImages.item(i).name + '_' + i + '.jpg');
		}
		console.log(this.gallery, 's');
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files;
			const reader = new FileReader();
			reader.onload = e => {
				this.imageSrcs = reader.result;
				// console.log(this.imageSrcs)
				this.imggalaary.push(this.imageSrcs);
				console.log(this.imggalaary);
			};
			reader.readAsDataURL(file);
		}
		//   if (event.target.files && event.target.files[0]) {
		//     const filesAmount = event.target.files.length;
		//     for (let i = 0; i < filesAmount; i++) {
		//             const reader = new FileReader();
		//             reader.onload = (event) => {
		//               // console.log(event.target.result);
		//                this.urls.push(event.target.result);
		//             }
		//             // console.log(reader)
		//             reader.readAsDataURL(event.target.files[i]);
		//     }
		//     console.log(this.urls)
		// }
	}

	saveCourse() {


    if (this.thirdFormGroup.get('course_name').value === '' || this.thirdFormGroup.get('Description').value === '' || this.thirdFormGroup.get('payment').value === '' || this.thirdFormGroup.get('workflow').value === ''  ) {
       swal('Please Fill All data', 'error ', 'error');
    } else if ( this.thirdFormGroup.get('imageFile1').value === null || this.thirdFormGroup.get('imageFile2').value === ''  ){
       swal('Please Import All Images', 'error ', 'error');
    }
	    else	if (this.thirdFormGroup.get('payment').value === 1) {
        const path = 'sports/courses/card ';
        const file1 = this.img_o.item(0);
        console.log('POP', file1);
        this.uploadimgService.uploadFile(file1, path);
			this.dataWF = {
				course_name: this.thirdFormGroup.get('course_name').value,
				desc: this.thirdFormGroup.get('Description').value,
				payment_methods: [this.thirdFormGroup.get('payment').value],
				// collection_requirements: '5',
				workFlow_Id: this.thirdFormGroup.get('workflow').value,
				// cancellationPolicy: this.policies,
				// workFlowId: 1,
				coverPhoto: this.img_o[0].name,
				// smallCardPhoto: this.img_c[0].name,
				gallery: this.gallery
			};
		} else if (this.thirdFormGroup.get('payment').value === 2) {
      const path = 'sports/courses/card ';
      const file1 = this.img_o.item(0);
      console.log('POP', file1);
      this.uploadimgService.uploadFile(file1, path);
			this.dataWF = {
				course_name: this.thirdFormGroup.get('course_name').value,
				desc: this.thirdFormGroup.get('Description').value,
				payment_methods: [this.thirdFormGroup.get('payment').value],
				// collection_requirements: '0',
				workFlow_Id: this.thirdFormGroup.get('workflow').value,
				// coverPhoto: this.img_o[0].name,
				// smallCardPhoto: this.img_c[0].name,
				// gallery: this.gallery
				cancellationPolicy: this.policies,
				// workFlowId: 1,
				// coverPhoto: this.imageSrcss.slice(23),
				coverPhoto: this.img_o[0].name,
				// smallCardPhoto: this.img_c[0].name,
				gallery: this.gallery
			};
		}
		this._httpService
			.postData(
				`${environment.apiEndPoint}/vendor/fitness/course/`,
				{
					client_id: environment.clientId,
					client_secret: environment.clientSecret,
					access_token: this.access_token
				},
				this.dataWF
			)
			.subscribe(
				resp => {
					if (resp['code'] === '10000') {
						swal('Your Courses is Created', 'Courses is Created ', 'success');
					}
					console.log(resp);
					this.courseId = resp['course']['id'];
					// console.log('course id' , this.courseId.replace(/(?:\r\n|\r|\n)/g, ''))
				},
				error => {
					this._toastr.error(error['detail'], 'Error');
				}
			);
		console.log(this.dataWF, 'Course info');
		// console.log(this.thirdFormGroup.get('payment').value , "work flow id")
	}

	addTimeSlotsSchedule(formValue) {
		const timeslotsArr = formValue['timeslots'];
		const days = [];
		const regTime = /^\d{1,2}:\d{2}([ap]m)?$/;
		for (let index = 0; index < this.DaysNames.length; index++) {
			const working_hours_from = timeslotsArr[index]['working_hours_from'];
			const working_hours_to = timeslotsArr[index]['working_hours_to'];
			if (
				working_hours_from !== '' &&
				regTime.test(working_hours_from) &&
				working_hours_to !== '' &&
				regTime.test(working_hours_to)
			) {
				this.daysshulade.push({
					// id: index + 1,
					// number: index + 1,
					// name: this.DaysNames[index],
					// iso: this.DaysISONames[index],
					from: working_hours_from,
					to: working_hours_to,
					day: index
				});
			}
		}
		console.log(this.daysshulade, 'days');
	}

	getBranch() {
		this._httpService
			.getData(
				`${environment.apiEndPoint}/vendor/fitness/branch/`
				// {
				// 	client_id: environment.clientId,
				// 	client_secret: environment.clientSecret,
				// 	access_token: this.access_token
				// }
			)
			.subscribe(bran => (this.branches = bran['branches']));
	}

	gettainner() {
		this._httpService
			.getData(
				`${environment.apiEndPoint}/vendor/fitness/trainer/`
				// {
				// 	client_id: environment.clientId,
				// 	client_secret: environment.clientSecret,
				// 	access_token: this.access_token
				// }
			)
			.subscribe(tannier => (this.trainers = tannier['Trainers']));
	}
	changedate(id) {
		this.isshulda = id;
		if (this.isshulda === 0) {
			console.log(this.isshulda, ' fafasfas');
			this.showfrom = true;
			this.showto = true;
			this.going = false;
		}
		if (this.isshulda === 2) { // fiixed Duration
			console.log(this.isshulda, ' fafasfas');
			this.showfrom = true;
			this.showto = true;
			this.going = false;
		}
		if (this.isshulda === 1) {
			console.log(this.isshulda, ' fafasfas');
			this.showfrom = true;
			this.showto = false;
			this.going = true;
		}
	}
	aaddsub() {
		if (this.isshulda === 0 || this.isshulda === 2) {
			this.subscriptions.push({
				durationId: this.createclass.get('Duration').value,
				cost: this.createclass.get('Cost').value,
				invitees: this.createclass.get('invtations').value
			});

			this.createclass.get('Duration').reset();
			this.createclass.get('Cost').reset();
			this.createclass.get('invtations').reset();
		} else if (this.isshulda === 1) {
			this.subscriptions.push({
				cost: this.createclass.get('Cost').value,
				invitees: this.createclass.get('invtations').value
			});

			this.createclass.get('Cost').reset();
			this.createclass.get('invtations').reset();
		}
		console.log(this.subscriptions, ' sfa');
	}
	saveclass() {
		if (this.isshulda === 0 || this.isshulda === 2) {
			if (this.daysshulade.length === 0) {
				return this._toastr.error(
					'Please fill Date data to create Class',
					'Error'
				);
			} else {
				this.classs = {
					branchId: this.createclass.get('branch_name').value,
					name: this.createclass.get('Class_name').value,
					classType: this.createclass.get('Settings').value,
					trainer: this.createclass.get('Captain_name').value,
					startDate: this.createclass.get('Date_from').value,
					endDate: this.createclass.get('Date_to').value,
					capacity: this.createclass.get('Maximum_Capacity').value,
					Schedule: this.daysshulade,
					subscriptions: this.subscriptions,
					courseId: this.courseId,
					is_published: false
				};
				this.daysshulade = [];
			}
		} else if (this.isshulda === 1) {
			if (this.daysshulade.length === 0) {
				return this._toastr.error(
					'Please fill Date data to create Class',
					'Error'
				);
			} else {
				this.classs = {
					branchId: this.createclass.get('branch_name').value,
					name: this.createclass.get('Class_name').value,
					classType: this.createclass.get('Settings').value,
					trainer: this.createclass.get('Captain_name').value,
					startDate: this.createclass.get('Date_from').value,
					capacity: this.createclass.get('Maximum_Capacity').value,
					Schedule: this.daysshulade,
					subscriptions: this.subscriptions,
					courseId: this.courseId,
					is_published: false
				};
			}
		}
		this._httpService
			.postData(
				`${environment.apiEndPoint}/vendor/fitness/class/`,
				{
					client_id: environment.clientId,
					client_secret: environment.clientSecret,
					access_token: this.access_token
				},
				this.classs
			)
			.subscribe(
				resp => {
					if (resp['code'] === '10000') {
						swal('Class is created', 'Thanks', 'success');
						this.setTimeSlotsClassormGroup.reset();
						this.createclass.reset();
					}
				},
				error => {
					//  const errphone = error['error']['error']['message'].split(',')
					// if (error['error']['code'] === "90000"){
					//   const errphone = error['error']['error']['message'].split(',')[0].split('(');
					//   if ( errphone[1] === "1062" ){
					//     console.log(errphone[1])
					//     swal('Trainer phone is already used in system', 'Thanks', 'error');
					//       }
					//    }
					if (error['error']['code'] === '10001') {
						swal('please enter all data', 'error', 'error');
					}
					swal('Class is Not created', 'Thanks', 'error');
					console.log(error, 'ss');
					// console.log(error['error']['error']['message'].split(','), 'error is');
				}
			);
		console.log(this.classs);
		this.daysshulade = [];
		this.subscriptions = [];

		this.f1class = false;
		this.f2class = true;
	}
	publishclass() {
		if (this.isshulda === 0 || this.isshulda === 2) {
			if (this.daysshulade.length === 0) {
				return this._toastr.error(
					'Please fill Date data to create Class',
					'Error'
				);
			} else {
				this.classs = {
					branchId: this.createclass.get('branch_name').value,
					name: this.createclass.get('Class_name').value,
					classType: this.createclass.get('Settings').value,
					trainer: this.createclass.get('Captain_name').value,
					startDate: this.createclass.get('Date_from').value,
					endDate: this.createclass.get('Date_to').value,
					capacity: this.createclass.get('Maximum_Capacity').value,
					Schedule: this.daysshulade,
					subscriptions: this.subscriptions,
					courseId: this.courseId,
					is_published: true
				};
				this.daysshulade = [];
			}
		} else if (this.isshulda === 1) {
			if (this.daysshulade.length === 0) {
				return this._toastr.error(
					'Please fill Date data to create Class',
					'Error'
				);
			} else {
				this.classs = {
					branchId: this.createclass.get('branch_name').value,
					name: this.createclass.get('Class_name').value,
					classType: this.createclass.get('Settings').value,
					trainer: this.createclass.get('Captain_name').value,
					startDate: this.createclass.get('Date_from').value,
					capacity: this.createclass.get('Maximum_Capacity').value,
					Schedule: this.daysshulade,
					subscriptions: this.subscriptions,
					courseId: this.courseId,
					is_published: true
				};
			}
		}
		this._httpService
			.postData(
				`${environment.apiEndPoint}/vendor/fitness/class/`,
				{
					client_id: environment.clientId,
					client_secret: environment.clientSecret,
					access_token: this.access_token
				},
				this.classs
			)
			.subscribe(
				resp => {
					if (resp['code'] === '10000') {
						swal('Class is created', 'Thanks', 'success');
						this.setTimeSlotsClassormGroup.reset();
						this.createclass.reset();
					}
				},
				error => {
					//  const errphone = error['error']['error']['message'].split(',')
					// if (error['error']['code'] === "90000"){
					//   const errphone = error['error']['error']['message'].split(',')[0].split('(');
					//   if ( errphone[1] === "1062" ){
					//     console.log(errphone[1])
					//     swal('Trainer phone is already used in system', 'Thanks', 'error');
					//       }
					//    }
					if (error['error']['code'] === '10001') {
						swal('please enter all data', 'error', 'error');
					}
					swal('Class is Not created', 'Thanks', 'error');
					console.log(error, 'ss');
					// console.log(error['error']['error']['message'].split(','), 'error is');
				}
			);
		console.log(this.classs);
		this.daysshulade = [];
		this.subscriptions = [];
		this.setTimeSlotsClassormGroup.reset();
		this.createclass.reset();
		this.f1class = false;
		this.f2class = true;
	}
	removeclassAdded(areaIndex) {
		this.subscriptions.splice(areaIndex, 1);
	}
	addantoher() {
		this.createclass.reset();
		this.setTimeSlotsClassormGroup.reset();
	}
	Finish() {
		this.courseId = '';
		this.router.navigate(['g/list_of_courses']);
  }

  keyPress(event: any){

    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
    }

  }

  showCreateBranch() : void {
    this.dialog.open( CreateBranchPopupComponent , {
       position: {
         top: '10px',
         right: '80px'
       },
       height: '98%',
       width: '140vm',
      panelClass: 'full-screen-modal',
    })

}



}




/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



@Component({
	selector: 'app-create-event',
	templateUrl: './create-event.component.html',
	styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {
	access_token = JSON.parse(localStorage.getItem('currentUser'))[
		'access_token'
	];
	createBranch: FormGroup;
	secondFormGroup: FormGroup;
	thirdFormGroup: FormGroup;
	isEditable = true;
	isOptional = true;
	latitude = 30.0594838;
	longitude = 31.223359;
	// google maps zoom level
	znznValiditors = znznValidators['znzn-form-validation'];

	zoom = 8;
	cities: any;
	paymentMethods: any;
	branches: any;
	days: any;
	distircts: any;
	isDistircts = false;
	editphone = false;
	branch_phones: any[] = [];
	phone = '';
	newUpdatedPone: any;
	imageName: any;
	areas_addes: any[] = [];
	selectedFile: FileList;
	imagesSources: any[] = [];
	newBranchId;
	daysofWeek = [];
	DaysNames: string[] = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	];
	weekDayss = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
  ];
  DurationWeek = [
		{ id: 0, name: '1 Week' },
  ];
  DurationMonth = [
		{ id: 0, name: '1 Week' },
		{ id: 1, name: '1 Month' },
  ];
  DurationMonths = [
		{ id: 0, name: '1 Week' },
		{ id: 1, name: '1 Month' },
		{ id: 2, name: '3 Month' },
  ];
  DurationSemi = [
		{ id: 0, name: '1 Week' },
		{ id: 1, name: '1 Month' },
		{ id: 2, name: '3 Month' },
		{ id: 3, name: 'Semi annual' },
  ];
	DurationAnnual = [
		{ id: 0, name: '1 Week' },
		{ id: 1, name: '1 Month' },
		{ id: 2, name: '3 Month' },
		{ id: 3, name: 'Semi annual' },
		{ id: 4, name: 'Annual' }
	];
	Settings = [
		{ id: 0, name: 'One Time' },
		{ id: 2, name: 'Fixed Duration' },
		{ id: 1, name: 'On Going' }
	];
	weekDays: any[] = [];
	DaysISONames: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
	currentWorkFlow: any;
	marker: { latitude: number; longitude: number };
	setTimeSlotsEDFormGroup: any;
	setTimeSlotsSDFormGroup: FormGroup;
	trainersdata: any[] = [];
	workflows: any[] = [];
	imagebranchname: any;
	imagesbranchSources: any[] = [];
	imageSrc: string | ArrayBuffer;

	viewCancelation: boolean;
	maxcollection: number;
	mincollection: number;
	flagcollection: boolean;
	flagcollectionnot: boolean;
	zerocollection: boolean;
	msgcollectionzero: string;
	haspaymentId: number;
	msgcollectionnotZero: string;
	hasPayments: any;
	collection_requirement: any;
	policiesToSave = [];
	periodMsg: boolean;
	perioderrorMsg: string;
	dedutionmsg: boolean;
	deductionerrorMsg: string;
	deductiond: any;
	periodd: any;
	dayss: any[] = [];
	policies: any[] = [];
	cancelationForm;
	workflow_id: any;
	payment_method_id: any;
	Tourid: any;
	dataWF: {};
	policyWithId: any;
	selectedPayment: any;
	img_o: FileList;
	img_c: FileList;
	imageSrcss: string | ArrayBuffer;
	imageSrcc: string | ArrayBuffer;
	imageSrcs: string | ArrayBuffer;
	img1: any;
	img_2: any;
	g1: any;
	gallery: any[] = [];
	galleryImages: FileList;
	file: File;
	createclass: FormGroup;
	setTimeSlotsClassormGroup: FormGroup;
	hiddeen = false;
	daysshulade: any[] = [];
	classs: any;
	showfrom = false;
	showto = false;
	isshulda: any;
	trainers: any;
	subscriptions: any[] = [];
	f1class = true;
	f2class = false;
	imggalaary: any[] = [];
	urls: any = [];
	courseId: any;
  going = false;
  errorDetails : any ;
  getDateF = '';
  getDateT : any;
  subDateF  : any;
  subDateT  : any;
  diffTime  ;
  diffDays ;
  zzzzzzz : any[] = [];

	constructor(public dialog: MatDialog,
		private _fb: FormBuilder,
		private _httpService: HttpService,
		private _toastr: ToastrService,
		private tours: ToursService,
		private uploadimgService: UploadimgService,
		private router: Router
	) {
		this.getData();
	}

	ngOnInit() {
		this.getBranch();
		this.gettainner();
		this.createBranch = this._fb.group({
			city: [this.cities],
			area: [{ value: '', disabled: true }],
			address: [''],
			// placeName: ['', Validators.compose([Validators.required])],
			phone: [
				'',
				Validators.compose([
					Validators.pattern(/^-?([0-9]\d*)?$/),
					Validators.maxLength(15)
				])
			]
			// addImages: ['']
    });

		this.setTimeSlotsEDFormGroup = this._fb.group({
			// fromcontrollers
			working_hours_from: ['', Validators.required],
			working_hours_to: ['', Validators.required]
		});
		this.setTimeSlotsSDFormGroup = this._fb.group({
			timeslots: this._fb.array([
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				})
			])
		});
		this.secondFormGroup = this._fb.group({
			Trainer_name: [''],
			About_Trainer: [''],
			Phone: [
				'',
				Validators.compose([
					Validators.pattern(/^-?([0-9]\d*)?$/),
					Validators.maxLength(15)
				])
			],
			addImage: ['']
		});
		this.thirdFormGroup = this._fb.group({
			course_name: [''],
			payment: [''],
			workflow: [''],
			Description: [''],
      addImage: [''],
      imageFile1: [''],
      imageFile2 : ['']
		});
		this.createclass = this._fb.group({
			branch_name: [''],
			// One_Time: [''],
			// Fixed: [''],
			// Ongoing: [''],
			Settings: [''],
			Date_from: [''],
			Date_to: [''],
			Captain_name: [''],
			Class_name: [''],
			Maximum_Capacity: [''],
			Duration: [''],
			Cost: [''],
			invtations: ['']
		});

		this.setTimeSlotsClassormGroup = this._fb.group({
			timeslots: this._fb.array([
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				}),
				this._fb.group({
					working_hours_from: [''],
					working_hours_to: ['']
				})
			])
		});

		this.getWeekDays();
	}
	getData() {
		// get cities
		this._httpService
			.getData(`${environment.apiEndPoint}/vendor/city`, {
				client_id: environment.clientId,
				client_secret: environment.clientSecret,
				access_token: this.access_token
			})
			.subscribe(cities => (this.cities = cities['cities']));
		// get payment_methods
		this._httpService
			.getData(`${environment.apiEndPoint}/payment/methods`)
			.subscribe(
				paymentMethods =>
					(this.paymentMethods = paymentMethods['payment_methods'])
			); // {"id":1,"name":"Pay on Arrival"},
		// get Branches
		// get Days
		this._httpService
			.getData(`${environment.apiEndPoint}/daysofweek/`, {
				client_id: environment.clientId,
				client_secret: environment.clientSecret,
				access_token: this.access_token
			})
			.subscribe(days => (this.days = days['days'])); // "id":1,"number":1,"name":"Sunday","iso":"Sun"}
		// get Workflows
		// this._httpService
		// 	.getData(`${environment.apiEndPoint}/vendor/listworkflows/`, {
		// 		client_id: environment.clientId,
		// 		client_secret: environment.clientSecret,
		// 		access_token: this.access_token
		// 	})
		// 	.pipe(
		// 		map(resp => {
		// 			const workflows = resp['workflows'];
		// 			this.workflows = [];
		// 			// "name":"Restaurant: reserve with form","guid":"c3bdbc67-4d60-4c42-9c46-4f6ae9ea66d2"
		// 			for (let i = 0; i < workflows.length; i++) {
		// 				this.workflows.push({
		// 					name: workflows[i]['name'],
		// 					guid: workflows[i]['guid']
		// 				});
		// 			}
		// 		})
		// 	)
		// 	.subscribe();
	}

	getSelectedCity(city_id) {
		// get Days
		this._httpService
			.getData(`${environment.apiEndPoint}/distirct/city/${city_id}/`, {
				client_id: environment.clientId,
				client_secret: environment.clientSecret,
				access_token: this.access_token
			})
			.subscribe(distircts => {
				// {"name":"Down Town","cityname":"Cairo","id":4}
				if (distircts['distircts'] && distircts['distircts'].length) {
					this.createBranch.controls['area'].enable();
					return (this.distircts = distircts['distircts']);
				}
				this.distircts = [];
				this._toastr.warning(
					'Sorry There are no areas in this city',
					'no areas'
				);
				return this.createBranch.controls['area'].setErrors({
					required: true
				});
			});
	}
	mapClicked($event: MouseEvent) {
		// {"coords":{"lat":30.163218889001502,"lng":30.161410466523535}}
		const latitude = $event['coords']['lat'];
		const longitude = $event['coords']['lng'];
		this.latitude = latitude;
		this.longitude = longitude;
		this.marker = {
			latitude: this.latitude,
			longitude: this.longitude
    };
    console.log(this.latitude + '  ' +  this.longitude);
	}


	addPhone(phone) {
    console.log(phone);
		if (phone === '') {
			swal('Add phone number please', 'Error', 'error');
    }
     else if (phone === null) {
			swal('Add phone number please', 'Error', 'error');
    }
   else {
			this._httpService
				.getData(`${environment.apiEndPoint}/vendor/phoneexists/${phone}/`)
				.subscribe(resp => {
          console.log(resp);
					if (resp['code'] === '10034') {
						this.branch_phones.push(phone);
            this.createBranch.controls.phone.reset();
           this._toastr.info("Info Phone not exists in the system.");
					} else if (resp['code'] === '10016') {
						this._toastr.error('Phone already exists in the system.', 'Error');
					}
				});
		}
		console.log('phone', phone);
	}
	removePhone(phoneIndex) {
		this.branch_phones.splice(phoneIndex, 1);
	}
	editPhone() {
		this.editphone = !this.editphone;
	}
	savePhone($event) {
		const reg = /^\d+$/;
		const phone = $event.target.value;
		if (reg.test(phone)) {
			this.newUpdatedPone = phone;
		} else if (!reg.test(phone)) {
			this._toastr.error('mobile phone must be number', 'Error');
		}
	}
	updatePhone(phoneIndex, currentPhone) {
		this.branch_phones[phoneIndex] = this.newUpdatedPone || currentPhone;
		this.editphone = !this.editphone;
		console.log(this.newUpdatedPone, 'new', 'old', phoneIndex);
	}
	closeUpdate() {
		this.editphone = !this.editphone;
	}


	addnewtrainr() {
		const trainer = {
			name: this.secondFormGroup.get('Trainer_name').value,
			phone: this.secondFormGroup.get('Phone').value,
			about: this.secondFormGroup.get('About_Trainer').value,
			photo: this.imageName
		};
		this._httpService
			.postData(
				`${environment.apiEndPoint}/vendor/fitness/trainer/`,
				{
					client_id: environment.clientId,
					client_secret: environment.clientSecret,
					access_token: this.access_token
				},
				trainer
			)
			.subscribe(
				resp => {
					if (resp['code'] === '10000') {
						swal('Trainer is created', 'Thanks', 'success');
					}
					// s
					const path = 'sports/trainer';
					const file1 = this.selectedFile.item(0);
					console.log('POP', file1);
					this.uploadimgService.uploadFile(file1, path);
					// d
					console.log(resp);
					this.trainersdata.push(trainer);
					this.secondFormGroup.reset();
				},
				error => {
					//  const errphone = error['error']['error']['message'].split(',')
					if (error['error']['code'] === '90000') {
						const errphone = error['error']['error']['message']
							.split(',')[0]
							.split('(');
						if (errphone[1] === '1062') {
							console.log(errphone[1]);
							swal(
								'Trainer phone is already used in system',
								'Thanks',
								'error'
							);
						}
					} else if (error['error']['code'] === '10001') {
						swal('please enter all data', 'error', 'error');
					} else {
						swal('Trainer is Not created', 'Thanks', 'error');
					}
					console.log(error, 'ss');
					// console.log(error['error']['error']['message'].split(','), 'error is');
				}
			);
		console.log(trainer, 'branch info');
		console.log(this.trainersdata);
		// this.secondFormGroup.reset();
	}

	createtrainer(stepper: MatStepper) {

      const finaltrain = {
        trainner: this.trainersdata
      };
      console.log(finaltrain, 'train');
      stepper.next();
      this.getBranch();
      this.gettainner();


	}
	removeTrainerAdded(areaIndex) {
		this.trainersdata.splice(areaIndex, 1);
	}
	clearTrain() {
		this.secondFormGroup.reset();
	}

	selectFile(event) {
		this.selectedFile = event.target.files;
		if (event.target.files && event.target.files[0] && event !== undefined) {
			const file = event.target.files[0];
			this.imageName = file['name'];
			const reader = new FileReader();
			reader.readAsDataURL(file); // read file as data url
			reader.onload = onload => {
				// called once readAsDataURL is completed
				this.imagesSources.push(onload.target['result']);
			};
		}
	}

	selectFilebranch(event) {
		this.selectedFile = event.target.files;
		if (event.target.files && event.target.files[0] && event !== undefined) {
			const file = event.target.files[0];
			this.imagebranchname = file['name'];
			const reader = new FileReader();
			reader.readAsDataURL(file); // read file as data url
			reader.onload = onload => {
				// called once readAsDataURL is completed
				reader.onload = e => (this.imageSrc = reader.result);
				reader.readAsDataURL(file);
			};
		}
	}

	getWeekDays() {
		this.tours.getWeekDays().subscribe((data: any) => {
			// console.log(data,'week')
			this.weekDays = data.days;

			for (let i = 0; i < this.weekDays.length; i++) {
				this.daysofWeek.push({
					id: this.weekDays[i].id,
					name: this.weekDays[i].name,
					selected: false,
					isSet: false
				});
			}
		});
	}
	changePayment(paymentid) {
		console.log(paymentid, 'payment ');
		if (paymentid === 1) {
			// pay on arrival
			this.viewCancelation = false;
			this.haspaymentId = 2;
			// console.log("tt", this.mincollection, this.maxcollection);
		} else {
			// 2 credit card
			this.viewCancelation = true;
			this.haspaymentId = 1;
		}
		this.tours.getWorkFlowPayment(this.haspaymentId).subscribe((data: any) => {
			console.log(data, 'dsadsadasdasdasdasdas');
			this.hasPayments = data.workflows;
		});
	}

	AddBranchPolicy() {
		if (this.periodd === undefined) {
			this.periodMsg = true;
			this.perioderrorMsg = 'please enter period';
		} else if (this.deductiond === undefined) {
			this.dedutionmsg = true;
			this.deductionerrorMsg = 'please enter deduction';
		} else {
			this.periodMsg = false;
			this.dedutionmsg = false;
			const policyObj = {};
			this.dayss = [];
			for (let j = 0; j < this.daysofWeek.length; j++) {
				if (this.daysofWeek[j].selected === true) {
					this.dayss.push({
						period: this.periodd,
						deduction: this.deductiond,
						day_name: this.daysofWeek[j].name,
						day_id: this.daysofWeek[j].id
					});
					this.policies.push({
						period: this.periodd,
						deduction: this.deductiond,
						day_id: this.daysofWeek[j].id.toString(),
						id: ''
					});
				}
			}
			this.policiesToSave.push({ days: this.dayss });
			const policyy = {};
			policyy['periodd'] = '';
			policyy['deductiond'] = '';
			this.cancelationForm = {};
			this.periodd = '';
			this.deductiond = '';
			console.log(this.policiesToSave);
		}
	}

	removePolicy(index) {
		this.policiesToSave.splice(index, 1);
		this.policies.splice(index, 1);
		this.dayss.splice(index, 1);
	}

	dataURLtoFile(dataurl, filename) {
		console.log('data in image', dataurl, filename);
		const arr = dataurl;
		const mime = arr[0];
		const bstr = arr[1];
		let n = bstr;
		const u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}

	// selectCover(event) {
	// 	this.img_c = event.target.files;
	// 	//   this.img_2 = this.img_c[0].name;
	// 	//   this.img_2 = Date.now() + '.jpg';
	// 	console.log(this.img_c, 'Wfa');
	// 	if (event.target.files && event.target.files[0]) {
	// 		const file = event.target.files[0];
	// 		const reader = new FileReader();
	// 		reader.onload = e => (this.imageSrcc = reader.result);
	// 		reader.readAsDataURL(file);
	// 	}
	// }

	selectsFile(event) {
		this.img_o = event.target.files;
		console.log(this.img_o[0].name);
		//   this.img1 = this.img_o[0].name;
		//   this.img1 = Date.now() + '.jpg';
		//   console.log(this.img1, "lfa")
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = e => {
				this.imageSrcss = reader.result;
				console.log(this.imageSrcss.slice(23));
			};
			reader.readAsDataURL(file);
		}
	}
	selectgalary(event) {
		this.galleryImages = event.target.files;
		console.log(this.galleryImages, 'gala');
		for (let i = 0; i < this.galleryImages.length; i++) {
			//   this.g1 = this.galleryImages[i].name;
			//   this.g1 = Date.now();
			this.file = this.dataURLtoFile(
				this.galleryImages[i].name,
				Date.now() + '_' + i + '.jpg'
			);
			this.gallery.push(this.galleryImages.item(i).name + '_' + i + '.jpg');
		}
		console.log(this.gallery, 's');
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files;
			const reader = new FileReader();
			reader.onload = e => {
				this.imageSrcs = reader.result;
				// console.log(this.imageSrcs)
				this.imggalaary.push(this.imageSrcs);
				console.log(this.imggalaary);
			};
			reader.readAsDataURL(file);
		}
		//   if (event.target.files && event.target.files[0]) {
		//     const filesAmount = event.target.files.length;
		//     for (let i = 0; i < filesAmount; i++) {
		//             const reader = new FileReader();
		//             reader.onload = (event) => {
		//               // console.log(event.target.result);
		//                this.urls.push(event.target.result);
		//             }
		//             // console.log(reader)
		//             reader.readAsDataURL(event.target.files[i]);
		//     }
		//     console.log(this.urls)
		// }
	}

	saveCourse() {


    if (this.thirdFormGroup.get('course_name').value === '' || this.thirdFormGroup.get('Description').value === '' || this.thirdFormGroup.get('payment').value === '' || this.thirdFormGroup.get('workflow').value === ''  ) {
       swal('Please Fill All data', 'error ', 'error');
    } else if ( this.thirdFormGroup.get('imageFile1').value === null || this.thirdFormGroup.get('imageFile2').value === ''  ){
       swal('Please Import All Images', 'error ', 'error');
    }
	    else	if (this.thirdFormGroup.get('payment').value === 1) {
        const path = 'sports/courses/card ';
        const file1 = this.img_o.item(0);
        console.log('POP', file1);
        this.uploadimgService.uploadFile(file1, path);
			this.dataWF = {
				course_name: this.thirdFormGroup.get('course_name').value,
				desc: this.thirdFormGroup.get('Description').value,
				payment_methods: [this.thirdFormGroup.get('payment').value],
				// collection_requirements: '5',
				workFlow_Id: this.thirdFormGroup.get('workflow').value,
				// cancellationPolicy: this.policies,
				// workFlowId: 1,
				coverPhoto: this.img_o[0].name,
				// smallCardPhoto: this.img_c[0].name,
				gallery: this.gallery
			};
		} else if (this.thirdFormGroup.get('payment').value === 2) {
      const path = 'sports/courses/card ';
      const file1 = this.img_o.item(0);
      console.log('POP', file1);
      this.uploadimgService.uploadFile(file1, path);
			this.dataWF = {
				course_name: this.thirdFormGroup.get('course_name').value,
				desc: this.thirdFormGroup.get('Description').value,
				payment_methods: [this.thirdFormGroup.get('payment').value],
				// collection_requirements: '0',
				workFlow_Id: this.thirdFormGroup.get('workflow').value,
				// coverPhoto: this.img_o[0].name,
				// smallCardPhoto: this.img_c[0].name,
				// gallery: this.gallery
				cancellationPolicy: this.policies,
				// workFlowId: 1,
				// coverPhoto: this.imageSrcss.slice(23),
				coverPhoto: this.img_o[0].name,
				// smallCardPhoto: this.img_c[0].name,
				gallery: this.gallery
			};
		}
		this._httpService
			.postData(
				`${environment.apiEndPoint}/vendor/fitness/course/`,
				{
					client_id: environment.clientId,
					client_secret: environment.clientSecret,
					access_token: this.access_token
				},
				this.dataWF
			)
			.subscribe(
				resp => {
					if (resp['code'] === '10000') {
						swal('Your Courses is Created', 'Courses is Created ', 'success');
					}
					console.log(resp);
					this.courseId = resp['course']['id'];
					// console.log('course id' , this.courseId.replace(/(?:\r\n|\r|\n)/g, ''))
				},
				error => {
					this._toastr.error(error['detail'], 'Error');
				}
			);
		console.log(this.dataWF, 'Course info');
		// console.log(this.thirdFormGroup.get('payment').value , "work flow id")
	}

	addTimeSlotsSchedule(formValue) {
		const timeslotsArr = formValue['timeslots'];
		const days = [];
		const regTime = /^\d{1,2}:\d{2}([ap]m)?$/;
		for (let index = 0; index < this.DaysNames.length; index++) {
			const working_hours_from = timeslotsArr[index]['working_hours_from'];
			const working_hours_to = timeslotsArr[index]['working_hours_to'];
			if (
				working_hours_from !== '' &&
				regTime.test(working_hours_from) &&
				working_hours_to !== '' &&
				regTime.test(working_hours_to)
			) {
				this.daysshulade.push({
					// id: index + 1,
					// number: index + 1,
					// name: this.DaysNames[index],
					// iso: this.DaysISONames[index],
					from: working_hours_from,
					to: working_hours_to,
					day: index
				});
			}
		}
		console.log(this.daysshulade, 'days');
	}

	getBranch() {
		this._httpService
			.getData(
				`${environment.apiEndPoint}/vendor/fitness/branch/`
				// {
				// 	client_id: environment.clientId,
				// 	client_secret: environment.clientSecret,
				// 	access_token: this.access_token
				// }
			)
			.subscribe(bran => (this.branches = bran['branches']));
	}

	gettainner() {
		this._httpService
			.getData(
				`${environment.apiEndPoint}/vendor/fitness/trainer/`
				// {
				// 	client_id: environment.clientId,
				// 	client_secret: environment.clientSecret,
				// 	access_token: this.access_token
				// }
			)
			.subscribe(tannier => (this.trainers = tannier['Trainers']));
	}
	changedate(id) {
		this.isshulda = id;
		if (this.isshulda === 0) {
			console.log(this.isshulda, ' fafasfas');
			this.showfrom = true;
			this.showto = true;
			this.going = false;
		}
		if (this.isshulda === 2) { // fiixed Duration
			console.log(this.isshulda, ' fafasfas');
			this.showfrom = true;
			this.showto = true;
			this.going = false;
		}
		if (this.isshulda === 1) {
			console.log(this.isshulda, ' fafasfas');
			this.showfrom = true;
			this.showto = false;
			this.going = true;
		}
	}
	aaddsub() {
		if (this.isshulda === 0 || this.isshulda === 2) {
			this.subscriptions.push({
				durationId: this.createclass.get('Duration').value,
				cost: this.createclass.get('Cost').value,
				invitees: this.createclass.get('invtations').value
			});

			this.createclass.get('Duration').reset();
			this.createclass.get('Cost').reset();
			this.createclass.get('invtations').reset();
		} else if (this.isshulda === 1) {
			this.subscriptions.push({
				cost: this.createclass.get('Cost').value,
				invitees: this.createclass.get('invtations').value
			});

			this.createclass.get('Cost').reset();
			this.createclass.get('invtations').reset();
		}
		console.log(this.subscriptions, ' sfa');
	}
	saveclass() {
		if (this.isshulda === 0 || this.isshulda === 2) {
			if (this.daysshulade.length === 0) {
				return this._toastr.error(
					'Please fill Date data to create Class',
					'Error'
				);
			} else {
				this.classs = {
					branchId: this.createclass.get('branch_name').value,
					name: this.createclass.get('Class_name').value,
					classType: this.createclass.get('Settings').value,
					trainer: this.createclass.get('Captain_name').value,
					startDate: this.createclass.get('Date_from').value,
					endDate: this.createclass.get('Date_to').value,
					capacity: this.createclass.get('Maximum_Capacity').value,
					Schedule: this.daysshulade,
					subscriptions: this.subscriptions,
					courseId: this.courseId,
					is_published: false
				};
				this.daysshulade = [];
			}
		} else if (this.isshulda === 1) {
			if (this.daysshulade.length === 0) {
				return this._toastr.error(
					'Please fill Date data to create Class',
					'Error'
				);
			} else {
				this.classs = {
					branchId: this.createclass.get('branch_name').value,
					name: this.createclass.get('Class_name').value,
					classType: this.createclass.get('Settings').value,
					trainer: this.createclass.get('Captain_name').value,
					startDate: this.createclass.get('Date_from').value,
					capacity: this.createclass.get('Maximum_Capacity').value,
					Schedule: this.daysshulade,
					subscriptions: this.subscriptions,
					courseId: this.courseId,
					is_published: false
				};
			}
		}
		this._httpService
			.postData(
				`${environment.apiEndPoint}/vendor/fitness/class/`,
				{
					client_id: environment.clientId,
					client_secret: environment.clientSecret,
					access_token: this.access_token
				},
				this.classs
			)
			.subscribe(
				resp => {
					if (resp['code'] === '10000') {
						swal('Class is created', 'Thanks', 'success');
						this.setTimeSlotsClassormGroup.reset();
						this.createclass.reset();
					}
				},
				error => {
					//  const errphone = error['error']['error']['message'].split(',')
					// if (error['error']['code'] === "90000"){
					//   const errphone = error['error']['error']['message'].split(',')[0].split('(');
					//   if ( errphone[1] === "1062" ){
					//     console.log(errphone[1])
					//     swal('Trainer phone is already used in system', 'Thanks', 'error');
					//       }
					//    }
					if (error['error']['code'] === '10001') {
						swal('please enter all data', 'error', 'error');
					}
					swal('Class is Not created', 'Thanks', 'error');
					console.log(error, 'ss');
					// console.log(error['error']['error']['message'].split(','), 'error is');
				}
			);
		console.log(this.classs);
		this.daysshulade = [];
		this.subscriptions = [];

		this.f1class = false;
		this.f2class = true;
	}
	publishclass() {
		if (this.isshulda === 0 || this.isshulda === 2) {
			if (this.daysshulade.length === 0) {
				return this._toastr.error(
					'Please fill Date data to create Class',
					'Error'
				);
			} else {
				this.classs = {
					branchId: this.createclass.get('branch_name').value,
					name: this.createclass.get('Class_name').value,
					classType: this.createclass.get('Settings').value,
					trainer: this.createclass.get('Captain_name').value,
					startDate: this.createclass.get('Date_from').value,
					endDate: this.createclass.get('Date_to').value,
					capacity: this.createclass.get('Maximum_Capacity').value,
					Schedule: this.daysshulade,
					subscriptions: this.subscriptions,
					courseId: this.courseId,
					is_published: true
				};
				this.daysshulade = [];
			}
		} else if (this.isshulda === 1) {
			if (this.daysshulade.length === 0) {
				return this._toastr.error(
					'Please fill Date data to create Class',
					'Error'
				);
			} else {
				this.classs = {
					branchId: this.createclass.get('branch_name').value,
					name: this.createclass.get('Class_name').value,
					classType: this.createclass.get('Settings').value,
					trainer: this.createclass.get('Captain_name').value,
					startDate: this.createclass.get('Date_from').value,
					capacity: this.createclass.get('Maximum_Capacity').value,
					Schedule: this.daysshulade,
					subscriptions: this.subscriptions,
					courseId: this.courseId,
					is_published: true
				};
			}
		}
		this._httpService
			.postData(
				`${environment.apiEndPoint}/vendor/fitness/class/`,
				{
					client_id: environment.clientId,
					client_secret: environment.clientSecret,
					access_token: this.access_token
				},
				this.classs
			)
			.subscribe(
				resp => {
					if (resp['code'] === '10000') {
						swal('Class is created', 'Thanks', 'success');
						this.setTimeSlotsClassormGroup.reset();
						this.createclass.reset();
					}
				},
				error => {
					//  const errphone = error['error']['error']['message'].split(',')
					// if (error['error']['code'] === "90000"){
					//   const errphone = error['error']['error']['message'].split(',')[0].split('(');
					//   if ( errphone[1] === "1062" ){
					//     console.log(errphone[1])
					//     swal('Trainer phone is already used in system', 'Thanks', 'error');
					//       }
					//    }
					if (error['error']['code'] === '10001') {
						swal('please enter all data', 'error', 'error');
					}
					swal('Class is Not created', 'Thanks', 'error');
					console.log(error, 'ss');
					// console.log(error['error']['error']['message'].split(','), 'error is');
				}
			);
		console.log(this.classs);
		this.daysshulade = [];
		this.subscriptions = [];
		this.setTimeSlotsClassormGroup.reset();
		this.createclass.reset();
		this.f1class = false;
		this.f2class = true;
	}
	removeclassAdded(areaIndex) {
		this.subscriptions.splice(areaIndex, 1);
	}
	addantoher() {
		this.createclass.reset();
		this.setTimeSlotsClassormGroup.reset();
	}
	Finish() {
		this.courseId = '';
		this.router.navigate(['g/list_of_courses']);
  }

  keyPress(event: any){

    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
    }

  }

  showCreateBranch() : void {
    this.dialog.open( CreateBranchPopupComponent , {
       position: {
         top: '10px',
         right: '80px'
       },
       height: '98%',
       width: '140vm',
      panelClass: 'full-screen-modal',
    })

}

getDateFrom(){
 this.getDateF = this.createclass.get('Date_from').value;
 console.log(this.getDateF);
 if ( this.getDateF !== '' && this.getDateT !== '') {
  this.subDateF = new Date(this.getDateF);
  this.subDateT = new Date(this.getDateT);
  this.diffTime = this.subDateT.getTime() - this.subDateF.getTime();
  this.diffDays = Math.ceil(this.diffTime / (1000 * 60 * 60 * 24)) ;
  console.log( this.diffDays);
  if (this.diffDays < 0) {
    swal('End Date Must be After Start Date', 'Ops', 'warning');
    this.going = false;
   }
   else {
    this.going = true;
    if (this.diffDays <= 27) {
      this.zzzzzzz = this.DurationWeek;
    }
    else if (this.diffDays <= 89 ) {
      this.zzzzzzz = this.DurationMonth;
    }
    else if (this.diffDays <= 179) {
      this.zzzzzzz = this.DurationMonths;
    }
    else if (this.diffDays <= 363) {
      this.zzzzzzz = this.DurationSemi
    }
    else {
      this.zzzzzzz = this.DurationAnnual;
    }
   }
 }
 else  if ( this.getDateF === '' || this.getDateT === '') {
  this.going = false;
 }
}
getDateTo(){
  this.getDateT = this.createclass.get('Date_to').value;
  console.log(this.getDateT);
  if (this.getDateF === '' ) {
       swal('Please Enter Strat Date', 'Ops', 'warning');
  }
  else if ( this.getDateF !== '' && this.getDateT !== '') {

   this.subDateF = new Date(this.getDateF);
   this.subDateT = new Date(this.getDateT);
   this.diffTime = this.subDateT.getTime() - this.subDateF.getTime();
   this.diffDays = Math.ceil(this.diffTime / (1000 * 60 * 60 * 24)) ;
   console.log( this.diffDays);
   if (this.diffDays < 0) {
    swal('End Date Must be After Start Date', 'Ops', 'warning');
    this.going = false;
   }
   else {
    this.going = true;
    if (this.diffDays <= 27) {
      this.zzzzzzz = this.DurationWeek;
    }
    else if (this.diffDays <= 89 ) {
      this.zzzzzzz = this.DurationMonth;
    }
    else if (this.diffDays <= 179) {
      this.zzzzzzz = this.DurationMonths;
    }
    else if (this.diffDays <= 363) {
      this.zzzzzzz = this.DurationSemi
    }
    else {
      this.zzzzzzz = this.DurationAnnual;
    }
   }
  }
  else  if ( this.getDateF === '' || this.getDateT === '') {
    this.going = false;
   }
 }

}


