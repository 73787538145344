import { GetProfileService } from './../../../_services/get-profile.service';
import { Component, OnInit, ErrorHandler } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService, ErrorHandlerService } from '../../../_services';
import { ToastrService } from 'ngx-toastr';
import { _switch } from 'rxjs-compat/operator/switch';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import swal from 'sweetalert2';

@Component({
	selector: 'app-guest-list-item',
	templateUrl: './guest-list-item.component.html',
	styleUrls: ['./guest-list-item.component.scss']
})
export class GuestListItemComponent implements OnInit {
	currentGuest;
	reservationHistoryData;
	notes;
	targetID;
	isLoading = true;
	isLoadingReservations = true;
	throughIcons = { App: 'fa-mobile', WalkIn: 'fa-male', Call: 'fa-phone' };
	noContent = false;
	id = this.route.snapshot.paramMap.get('id');
	category: any;
	tourtable: boolean;
	resttable: boolean;

	constructor(
		private route: ActivatedRoute,
		private httpService: HttpService,
		private toastr: ToastrService,
		private errorHandlerServices: ErrorHandlerService,
		private getProfileService: GetProfileService
	) {
		this.getProfileService.getProfile().subscribe((man: any) => {
			this.category = man.vendor.category_id;
			if (this.category === 2) {
				console.log('tour', this.category);
				this.tourtable = true;
				this.resttable = false;
			} else if (this.category === 1) {
				this.resttable = true;
				this.tourtable = false;

				console.log('rest', this.category);
			}
		});
	}

	ngOnInit() {
		// get user data
		this.httpService
			.getData(`${environment.apiEndPoint}/vendor/userbadge/`, {
				user_id: this.id
			})
			.subscribe(currentGuest => {
				console.log(currentGuest);
				this.isLoading = false;
				this.currentGuest = currentGuest['guest'];
				this.targetID = currentGuest['guest']['id'];
				// get notes
				this.httpService
					.getData(`${environment.apiEndPoint}/vendor/guestnote/`, {
						guest_id: this.targetID
					})
					.subscribe(
						notes => (this.notes = notes['note']['text']),
						error => {
							this.errorHandlerServices.handleError(error);
						}
					);
				// get reservation history here
				this.httpService
					.getData(`${environment.apiEndPoint}/vendor/reservation/filter/`, {
						page: '1',
						page_size: '100',
						user_id: this.targetID,
						sort_type: 'desc',
						sort_column: 'from_time'
					})
					.subscribe(
						reservationHistoryData => {
							this.isLoadingReservations = false;
							const reservationData = reservationHistoryData['reservations'];
							console.log(reservationData);
							if (reservationData === undefined || !reservationData.length) {
								return (this.noContent = !this.noContent);
							}
							this.reservationHistoryData = reservationData;
						},
						error => {
							this.isLoadingReservations = false;
							this.noContent = true;
							// this.errorHandlerServices.handleError(error, 'There is a problem in reservation history');
						}
					);
			});
	}
	loadData() {
		this.httpService
			.getData(`${environment.apiEndPoint}/vendor/userbadge/`, {
				user_id: this.id
			})
			.subscribe(currentGuest => {
				console.log(currentGuest);
				this.isLoading = false;
				this.currentGuest = currentGuest['guest'];
				this.targetID = currentGuest['guest']['id'];
				// get notes
				this.httpService
					.getData(`${environment.apiEndPoint}/vendor/guestnote/`, {
						guest_id: this.targetID
					})
					.subscribe(
						notes => (this.notes = notes['note']['text']),
						error => {
							this.errorHandlerServices.handleError(error);
						}
					);
			});
	}

	changeGuestCategory(id, catNum) {
		const changedData = {
			user_id: id,
			badge_id: catNum
		};
		this.getProfileService.setGuestInfo(changedData).subscribe((data: any) => {
			console.log(data, 'dsadsadasdasdasdasdas');
			if (data.code === '10000') {
				swal('Info Are updated', 'done', 'success');
				this.loadData();
			}
		});
	}
	saveNote(message) {
		// TODO make a service of post message here
		const noteMessage = {
			guest_id: this.targetID,
			note: message
		};
		this.httpService
			.postData(
				`${environment.apiEndPoint}/vendor/guestnote/`,
				{ guest_id: this.targetID },
				noteMessage
			)
			.map(response => {
				switch (response['code']) {
					case '10000':
						Swal('Done', 'Saved successfully', 'success');
						break;
				}
			})
			.subscribe(
				next => {
					// get notes
					this.httpService
						.getData(`${environment.apiEndPoint}/vendor/guestnote/`, {
							guest_id: this.targetID
						})
						.subscribe(notes => (this.notes = notes['note']['text']));
				},
				error => {
					// this.errorHandlerServices.handleError(error, 'Message Not Posted, Try Again Later');
				}
			);
	}
}
