import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private route: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		// logged in so return true

		if (localStorage.getItem('currentUser')) {
			return true;
		}
		// not logged in so redirect to login page with the return url
		this.route.navigate(['/login'], { queryParams: { returnUrl: state.url } });
		return false;
	}
}
