import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-create-manage-user-role',
	templateUrl: './create-manage-user-role.component.html',
	styleUrls: ['./create-manage-user-role.component.scss']
})
export class CreateManageUserRoleComponent implements OnInit {
	roles = [
		{ id: 1, name: 'All' },
		{ id: 2, name: 'Global Settings' },
		{ id: 3, name: 'Manage users' },
		{ id: 4, name: 'Events List' },
		{ id: 5, name: 'Create Reservations' },
		{ id: 6, name: 'Financial' },
		{ id: 7, name: 'Reservations' }
	];

	user: any = {};
	constructor() {}

	ngOnInit() {}

	getSelectedStore(value: any) {}

	getSelectedRole(value: any) {}

	createuser(user) {
		console.log(user);
	}
}
