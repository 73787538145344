import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ConcertsListService } from '../../../../_services';

@Component({
	selector: 'app-c-d-sold-tickets',
	templateUrl: './c-d-sold-tickets.component.html',
	styleUrls: ['./c-d-sold-tickets.component.scss']
})
export class CDSoldTicketsComponent implements OnInit {
	// Pie
	public pieChartLabels: string[] = ['1st Show', '1nd Show', '3rd Show'];
	public pieChartData: number[] = [100, 800, 100];
	public pieChartType = 'pie';
	eventname: any;
	eventid: any;
	event_sold_tickets: any[] = [];
	recent_reservations: any = [];
	event_id: any;
	eventdata: any;
	dataSource;
	loadtikects = false;
	@ViewChild(MatPaginator)
	paginator: MatPaginator;
	@ViewChild(MatSort)
	sort: MatSort;

	displayedColumns = ['fromTime', 'toTime', 'soldTickets', 'totalTickets'];

	kkk: any[] = [];
	constructor(private clservuve: ConcertsListService) {}

	ngOnInit() {
		this.clservuve.getBranch().subscribe((data: any) => {
			this.eventname = data['events'];
		});
	}
	eventchange() {
		this.clservuve.gettikectsold(this.event_id).subscribe((data: any) => {
			this.eventdata = data['shows'];
			this.loadtikects = true;
			this.dataSource = new MatTableDataSource(this.eventdata);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
