import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { CRReservationsDetailsComponent } from './c-r-reservations-details/c-r-reservations-details.component';

@Component({
	selector: 'app-c-r-reservations-data',
	templateUrl: './c-r-reservations-data.component.html',
	styleUrls: ['./c-r-reservations-data.component.scss']
})
export class CRReservationsDataComponent implements OnInit {
	constructor(private dialog: MatDialog) {}
	// tslint:disable-next-line: member-ordering
	datas = ['mostafa ', ' akram'];
	ngOnInit() {}

	search() {
		console.log('Search Function');
	}
	/**
	 *  Open Create Reservation Details Dialog
	 */
	openDialogConcertEventDetails() {
		// creating an instance of MatDialogConfig, which will configure the dialog with a set of default behaviors
		const dialogConfig = new MatDialogConfig();
		// means that the user will not be able to close the dialog just by clicking outside of it
		dialogConfig.autoFocus = true;
		// the focus will be set automatically on the first form field of the dialog
		dialogConfig.autoFocus = true;
		dialogConfig.data = { branches: this.datas };
		this.dialog.open(CRReservationsDetailsComponent, dialogConfig);
	}
}
