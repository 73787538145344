import { Component, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormGroupDirective,
	NgForm,
	Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ConcertsListService } from './../../../_services/concerts.service';
import { TourListService, HttpService } from './../../../_services';

import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
@Component({
	selector: 'app-concerts-check-in',
	templateUrl: './concerts-check-in.component.html',
	styleUrls: ['./concerts-check-in.component.scss']
})
export class ConcertsCheckInComponent implements OnInit {
  eventname: any;
  data :any;
  eventid : any;
  selectedclasss: any;
  venue: any;
  datetime: any;
  shows: any;
  showid:any;
  getData: any;
  userlist: any;
  userid: any;
  fristname: any;
  lastname: any;
  showcontant: boolean;
  phone: string;
  datas: any;
  seatss: any;
  date_id;
  zone: any [] = [];

  date: any;
	constructor(
    private router: Router,
    private clservuve: ConcertsListService,
    private _http: HttpService,
    private _toaster: ToastrService,
    private tourListService: TourListService,
  ) {}
	ngOnInit() {
    this.clservuve.getBranch().subscribe((data: any) => {
      this.eventname = data['events'];
      // this.isLoading = false;1
      console.log(this.eventname);
      // this.courseTable();
      // this.sportsCount = this.sportList.length;
    });

  }
  gotoseats() {
    this.router.navigate(['/seats']);

  }
  getdataevent(id){
    console.log(id)
    this.selectedclasss = id;
		// console.log('yes1', this.selectedclasss);
		for (let i = 0; i < this.eventname.length; i++) {
			if (this.eventname[i].guid === id) {
				//   console.log("yes");
				this.venue = this.eventname[i].venue;
         this.datetime = this.eventname[i].eventDates;
         console.log('afaflafa' , this.eventname[i])

			}
		}
    console.log('sfsafasfas', this.datetime);

    this.showid ='';
    this.date_id = "";
  }

  myFilter = (d: Date): boolean => {
		const showDates = this.datetime
			.split(',')
			.filter(function(el) {
				return el !== '';
			});
		return (
			showDates.findIndex(
				showDate => d.toDateString() === new Date(showDate).toDateString()
			) >= 0
		);
  };

  getSelectedConcertDate(date: any) {
		const targetDate =
			new Date(date).getFullYear() +
			'-' +
			(new Date(date).getMonth() + 1) +
			'-' +
			new Date(date).getDate();
		const params = {
			event_guid: this.selectedclasss,
			date: targetDate
		};
		this._http
			.getData(`${environment.apiEndPoint}/vendor/concert/show/`, params)
			.subscribe(
				res => {
					this.shows = res['shows'];
					console.log(this.shows, 'shpw');
				},
				error => {
					if (error['error']['code'] === '15005') {
						return this._toaster.error(error['error']['detail'], 'Error');
					}
				}
			);
  }
  showiddata(id){
    console.log(id , 'fasfsas')
    const params = {
			show_guid: id,
		};
    this._http
			.getData(`${environment.apiEndPoint}/vendor/concert/map/`, params)
			.subscribe(
				res => {
          this.zone = res['map'];
          localStorage.setItem('ev', JSON.stringify(this.zone));

					console.log(res, 'shpw');
				},
				error => {
          console.log(error , 'error')
          this._toaster.error('Error in Get data' , 'Error');

				}
			);

  }
}
