import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { environment } from './../../../../environments/environment';
import { TourListService, HttpService } from '../../../_services';
import { SportsListService } from './../../../_services/sports.service';


@Component({
	selector: 'app-list-of-courses',
	templateUrl: './list-of-courses.component.html',
	styleUrls: ['./list-of-courses.component.scss']
})
export class ListOfCoursesComponent implements OnInit {
	@ViewChild(MatPaginator)
	paginator: MatPaginator;
	@ViewChild(MatSort)
  sort: MatSort;
  branches: any;
  trainner: any;
  selectedtrainner: any ;
  selectedcouress: any;
  selectedTypeId: any ;
  selectedbranchId: any;
  coursss: any;
	toursCount: number;
	isLoading = true;
	displayedColumns = [
		'name',
		'branches',
		'trainees',
		'classes',
		'trainers',
		'minimum_cost',
		'View'
	];
	filterObject = {
		courseId: '',
		branchId: ''
	};
	// dataSource: MatTableDataSource<{}>;
	dataSource: MatTableDataSource<{}>;
	sportList: any = [];
	sportsCount: number;
	constructor(private router: Router, private Sports: SportsListService, private _http: HttpService) {}

	ngOnInit() {
    this.getBranch();
		this.isLoading = true;
		this.Sports.loadSports().subscribe((data: any) => {
			this.sportList = data['courses'];
			this.isLoading = false;
			console.log(this.sportList);
			this.courseTable();
			this.sportsCount = this.sportList.length;
		});
		// setTimeout(() => {
		//   window.location.reload();
		// }, 30000);
	}
	courseTable() {
		// Matrial Datatable angular
		this.dataSource = new MatTableDataSource(this.sportList);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		// console.log(this.dataSource);
	}
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	setcourseType(course) {
		if (course === undefined || course === '') {
			console.log('_----', '------_');
			this.filterObject.courseId = '';
		} else {
			this.filterObject.courseId = course;
		}
		console.log('ZZZZ', this.filterObject.courseId);
	}
	setbranch(branch) {
		this.filterObject.branchId = branch;
		console.log('ZZZZ', this.filterObject.branchId);
	}
	// settrain(train) {
	// 	this.filterObject.trainerId = train;
	// 	console.log('ZZZZ', this.filterObject.trainerId);
	// }
	viewsportData(cousteid) {
		console.log(cousteid, 'id course');
		this.router.navigate(['g/list_of_courses/event', cousteid]);
		// this.tourdetails=data.tours;
	}
	createevent() {
		this.router.navigate(['g/list_of_courses/create_course']);
	}
	search() {
		 this.isLoading = true;
		 this.Sports.filterSports(this.filterObject).subscribe(tour => {
		  	this.sportList = tour['courses'];
     this.sportsCount = this.sportList.length;
    console.log(tour , 'ss')
		  this.courseTable();
		   this.isLoading = false;
		});
		console.log(this.filterObject);
  }
// Filling Branch Drop Down List

  getBranch() {
		this._http
			.getData(
				`${environment.apiEndPoint}/vendor/fitness/branch/`
				// {
				// 	client_id: environment.clientId,
				// 	client_secret: environment.clientSecret,
				// 	access_token: this.access_token
				// }
			)
			.subscribe(bran => (this.branches = bran['branches']));
	}

// Filling Course Name Drop Dpwn List

tripDateChange() {
  // console.log(this.selectedbranchId, ' guid Branch');

  this.Sports.getBranch(this.selectedbranchId).subscribe((barnch: any) => {
    // this.isLoading = false;
    this.coursss = barnch.courses;
    // this.times = Date.trips.packages;
    // console.log('tour dates', this.coursss);
  });
  this.selectedTypeId = '';
}

// Filling Trainer Drop Down List

listtrainner() {
  // console.log(this.selectedcouress, 's');
  const data = {
    branchId: this.selectedbranchId,
    courseId: this.selectedcouress
  };
  this.Sports.gettrainner(data).subscribe((tran: any) => {
    this.trainner = tran.Trainers;
    // this.capacity = this.TimeF[0].remainCapacity;
    // console.log('tour datesfafafsafafsafsafsafasfaafa', this.trainner);
  });
  this.selectedTypeId = '';
}

}


export interface CoursesElement {
	name: string;
	Branch: string;
	Classes: number;
	Tickets_sold: string;
	Trainers: number;
	Price_Range: number;
}
