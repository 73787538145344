import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-concerts-reservations',
	templateUrl: './concerts-reservations.component.html',
	styleUrls: ['./concerts-reservations.component.scss']
})
export class ConcertsReservationsComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
