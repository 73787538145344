import swal from 'sweetalert2';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from './../../../../_services/http.service';
import { environment } from './../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material';
import { ToursService } from '../../../../_services';
import * as znznValidators from '../../../../_models';
import { UploadimgService } from '../../../../_services';

@Component({
	selector: 'app-new-trainer',
	templateUrl: './new-trainer.component.html',
	styleUrls: ['./new-trainer.component.scss']
})
export class NewTrainerComponent implements OnInit {
	secondFormGroup: FormGroup;
	selectedFile: any;
	imageName: any;
	imagesSources: any[] = [];
	trainersdata: any[] = [];
	access_token = JSON.parse(localStorage.getItem('currentUser'))[
		'access_token'
	];
	constructor(
		private _fb: FormBuilder,
		private uploadimgService: UploadimgService,
		private _httpService: HttpService
	) {}

	ngOnInit() {
		this.secondFormGroup = this._fb.group({
			Trainer_name: [''],
			About_Trainer: [''],
			Phone: [
				'',
				Validators.compose([
					Validators.pattern(/^-?([0-9]\d*)?$/),
					Validators.maxLength(15)
				])
			],
			addImage: ['']
		});
	}

	selectFile(event) {
		this.selectedFile = event.target.files;
		if (event.target.files && event.target.files[0] && event !== undefined) {
			const file = event.target.files[0];
			this.imageName = file['name'];
			const reader = new FileReader();
			reader.readAsDataURL(file); // read file as data url
			reader.onload = onload => {
				// called once readAsDataURL is completed
				this.imagesSources.push(onload.target['result']);
			};
		}
	}
	onSubmit(f) {
    if (f.valid){
      const path = 'sports/trainer';
      const file1 = this.selectedFile.item(0);
      console.log('POP', file1);
      this.uploadimgService.uploadFile(file1, path);
      const trainer = {
        name: this.secondFormGroup.get('Trainer_name').value,
        phone: this.secondFormGroup.get('Phone').value,
        about: this.secondFormGroup.get('About_Trainer').value,
        photo: this.imageName
      };
      this._httpService
        .postData(
          `${environment.apiEndPoint}/vendor/fitness/trainer/`,
          {
            client_id: environment.clientId,
            client_secret: environment.clientSecret,
            access_token: this.access_token
          },
          trainer
        )
        .subscribe(
          resp => {
            if (resp['code'] === '10000') {
              swal('Trainer is created', 'Thanks', 'success');
            }
            console.log(resp);
            this.trainersdata.push(trainer);
            this.secondFormGroup.reset();
          },
          error => {
            //  const errphone = error['error']['error']['message'].split(',')
            if (error['error']['code'] === '90000') {
              const errphone = error['error']['error']['message']
                .split(',')[0]
                .split('(');
              if (errphone[1] === '1062') {
                console.log(errphone[1]);
                swal(
                  'Trainer phone is already used in system',
                  'Thanks',
                  'error'
                );
              }
            } else if (error['error']['code'] === '10001') {
              swal('please enter all data', 'error', 'error');
            } else {
              swal('Trainer is Not created', 'Thanks', 'error');
            }
            console.log(error, 'ss');
            // console.log(error['error']['error']['message'].split(','), 'error is');
          }
        );
      console.log(trainer, 'branch info');
      console.log(this.trainersdata);
      // this.secondFormGroup.reset();
    }
      else {
        swal('please enter all data', 'error', 'error');
      }
  }

  keyPress(event: any){

    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
    }

  }

}
