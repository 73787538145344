import { BranchNameValiator } from './../../../../../_validators/branch-name.validator';
import { Component, OnInit, Input ,Inject} from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HttpService, ConcertsListService } from '../../../../../_services';
import { environment } from '../../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery';
import swal from 'sweetalert2';
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,

} from '@angular/material';declare var jQuery: any;

@Component({
	selector: 'app-c-e-venues',
	templateUrl: './c-e-venues.component.html',
	styleUrls: ['./c-e-venues.component.scss']
})
export class CEVenuesComponent implements OnInit {
	access_token = JSON.parse(localStorage.getItem('currentUser'))[
		'access_token'
	];
	favoriteRule = 'Number of Seats';
	map: any;
	eventId: any;
	f_createVenue: FormGroup;
	mode: any;
	// @Input() createdEventId: string;
	showsz = false;
	firstFormGroup: FormGroup;
	f_baseCreateVenue: FormGroup;
	isLinear;
	zones: FormArray;
	seats: any;
	rule;
	zone_id: any;
	dataName: any = 'Zaabtane Create Seats';
	screen: any = 'Noor Adsl';
	time = Date.now().toString();

	// rows: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'O', 'L', 'S', 'W', 'X', 'Y', 'Z'];
	// cols: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
	rows: string[] = [];
	cols: number[] = [];
	reserved: string[] = [];
	selected: any[] = [];
	show = false;
	showL = true;
	all = false;
	seatname: string[] = [];
	name: string;
	daos: any[] = [];
	zone: any;
	price: any;
	Seatadd: any;
	rowname: any;
	selectedrow: any[] = [];
	rowss: number;
	colss: number;
	datasend: any[] = [];
	selected3: any[] = [];
	row_cols: any[] = [];
	colspush: any[] = [];
	rowspush: any[] = [];
	rowsselected: any[] = [];
	areas_addes: any[] = [];
	zonessend: any;
	zonesdata: any;
	zone_ids: any;
  createdEventId: string;
	constructor(
		private _http: HttpService,
		private _fb: FormBuilder,
		private _toaser: ToastrService,
		private _router: Router,
    private consert: ConcertsListService,
    public dialog: MatDialog,

	) {
		// if (this.eventId === undefined) {
		// 	this._toaser.info('Event Must Created First Before Venue', 'Info');
		// }
	}

	ngOnInit() {
    this.createdEventId = localStorage.getItem('eventid')
		console.log(this.createdEventId, 'event id');
		this.firstFormGroup = this._fb.group({
			number_of_columns_map: ['', Validators.compose([Validators.required])],
			number_of_rows_map: ['', Validators.compose([Validators.required])]
		});
		this.f_baseCreateVenue = this._fb.group({
			name: ['', Validators.compose([])],
			color: ['', Validators.compose([])],
			number_of_seats: ['', Validators.compose([])],
			capacity: ['', Validators.compose([])],
			cost_per_seat: ['', Validators.compose([])],
			num_of_row: ['', Validators.compose([])],
			num_of_cols: ['', Validators.compose([])],
			coordinates: ['', Validators.compose([])]
			// image: ['', Validators.compose([Validators.required])]
		});
		this.f_createVenue = this._fb.group({
			zones: this._fb.array([])
		});

	}
	createAnotherVenue() {
		// return this._fb.group({
		// 	name: [this.f_baseCreateVenue.controls['name'].value],
		// 	color: [this.f_baseCreateVenue.controls['color'].value],
		// 	number_of_seats: [
		// 		this.f_baseCreateVenue.controls['number_of_seats'].value
		// 	],
		// 	// capacity: [this.f_baseCreateVenue.controls['capacity'].value || 0],
		// 	cost_per_seat: [
		// 		this.f_baseCreateVenue.controls['cost_per_seat'].value || 0
		// 	],
		// 	image: [
		// 		new Date().toISOString() +
		// 			this.f_baseCreateVenue.controls['image'].value.substr(12)
		// 	]
		// });
		const form = this.f_baseCreateVenue;
		const name = form.get('name');
		const color = form.get('color');
		const seats_number = form.get('number_of_seats');
		const cost_number = form.get('cost_per_seat');
		const row = form.get('num_of_row');
		const cols = form.get('num_of_cols');
		const coordinates = form.get('coordinates');
		console.log(color, ' aa');

		this.areas_addes.push({
			name: name.value,
			color: color.value || 0,
			seats_number: seats_number.value,
			cost: cost_number.value,
			number_of_rows: row,
			number_of_columns: cols,
			coordinates: coordinates
			// photo: this.imageName
		});
		console.log(this.areas_addes);
	}
	addAnotherVenue() {
		// if (this.f_baseCreateVenue.valid) {
		// 	this.zones = this.f_createVenue.get('zones') as FormArray;

		// 	// return this.zones.push(this.createAnotherVenue());
		// }
		// return this._toaser.warning('Please Fill Data First', 'Warn');
		const form = this.f_baseCreateVenue;
		const name = form.get('name');
		const color = form.get('color');
		const seats_number = form.get('number_of_seats');
		const cost_number = form.get('cost_per_seat');
		const capacity = form.get('capacity');
		const row = form.get('num_of_row');
		const cols = form.get('num_of_cols');
		const coordinates = form.get('coordinates');
		this.areas_addes.push({
			name: name.value,
			color: color.value || '#00000',
			number_of_seats: seats_number.value,
			cost_per_seat: cost_number.value,
			number_of_rows: row.value,
			number_of_columns: cols.value,
			capacity: capacity.value,
			image: 'z1Image.jpg',
			coordinates: coordinates.value
		});

		console.log(this.areas_addes, 'zones');

		// form.reset();
	}
	sendzones() {
    const form = this.f_baseCreateVenue;
		const name = form.get('name');
		const color = form.get('color');
		const seats_number = form.get('number_of_seats');
		const cost_number = form.get('cost_per_seat');
		const capacity = form.get('capacity');
		const row = form.get('num_of_row');
		const cols = form.get('num_of_cols');
		const coordinates = form.get('coordinates');
		this.areas_addes.push({
			name: name.value,
			color: color.value || '#00000',
			number_of_seats: seats_number.value,
			cost_per_seat: cost_number.value,
			number_of_rows: row.value,
			number_of_columns: cols.value,
			capacity: capacity.value,
			image: 'z1Image.jpg',
			coordinates: coordinates.value
		});

		console.log(this.areas_addes, 'zones');
		this.zonessend = {
			event_id: localStorage.getItem('eventid'),
			zones: this.areas_addes
		};
		console.log(this.zonessend, 'zonesend');
		this.consert.addZOns(this.zonessend).subscribe(
			create => {
				swal('Zone Are Created!', '', 'success');
				console.log(create);
				this.getzone();
			},
			error => {
				console.log(error);
			}
		);
	}
	deleteSpecificVenue(jQueryindex) {
		this.areas_addes.splice(jQueryindex, 1);
		console.log(this.areas_addes, 'deteteetafa');
	}

	changeMapImage(event) {
		if (
			event.target['files'] &&
			event.target['files'][0] &&
			event !== undefined
		) {
			const file = event.target['files'][0];
			const reader = new FileReader();
			reader.readAsDataURL(file); // read file as data url
			reader.onload = onload => {
				// called once readAsDataURL is completed
				this.map = onload.target['result'];
			};
		}
	}

	get getVenueFormValues() {
		return this.f_createVenue.controls;
	}
	createVenue() {
		if (this.eventId === undefined) {
			return this._toaser.error(
				'Event Must Created First Before Venue',
				'Error'
			);
		}
		const zones = [];
		this.f_createVenue['zones']['controls'].forEach((zone: any) => {
			const currentZone = {
				name: zone.value['name'] || '',
				color: zone.value['color'] || '',
				number_of_seats: zone.value['number_of_seats'] || '',
				capacity: zone.value['capacity'] || '',
				cost_per_seat: zone.value['cost_per_seat'] || '',
				image: zone.value['image'] || ''
			};
			zones.push(currentZone);
		});
		const payload = {
			event_id: this.eventId,
			zones: [zones]
		};
		this.consert.addZone(payload).subscribe(
			create => {
				swal('tour Are Created!', '', 'success');
				console.log(create);
				console.log(create['tour']['id'], 'sfafmaklfasf');

				// this.tourid = create['tour']['id'];
				// localStorage.setItem('tour', create['tour']['id']);
				// stepper.next();
			},
			error => {
				console.log(error);
			}
		);

		console.log(JSON.stringify(payload));
		// this._http
		// 	.postData(`jQuery{environment.apiEndPoint}/vendor/concert/zone`, {}, payload)
		// 	.subscribe(resp => {
		// 		if (resp['code'] === '10000') {
		// 			this._toaser.success(`Venue was Created`, 'Success');
		// 			this._router.navigate(['/concerts/events']);
		// 		}
		// 	});
	}

	getzone() {
		this.consert.getzones(localStorage.getItem('eventid')).subscribe(
			create => {
				console.log(create);
        this.zonesdata = create['zones'];
        console.log(this.zonesdata , '2ololololoolo')
			},
			error => {
				console.log(error);
			}
		);
	}

	getStatus(seatPos: string) {
		//  this.datasend.push(seatPos)
		// // console.log(seatPos , 's')

		if (this.reserved.indexOf(seatPos) !== -1) {
			return 'reserved';
		} else if (this.selected.indexOf(seatPos) !== -1) {
			//  console.log(this.selected , 'ssfaffa')
			return 'selected';
		}
	}
	done() {
		const form = this.f_baseCreateVenue;
		const row = form.get('num_of_row');
		const cols = form.get('num_of_cols');
		this.rows = [];
		this.cols = [];
		this.row_cols = [];

		for (let x = 0; x < this.colss; x++) {
			this.cols[x] = x;
			// this.colspush.push(this.cols)
		}
		// console.log(this.cols , 'sss')

		for (let x = 0; x < this.rowss; x++) {
			// this.rows[x] = x.toString();
			this.rows[
				x
			] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.charAt(x);

			// this.rowspush.push(this.rows)
		}

		// tslint:disable-next-line: no-shadowed-variable
		for (const row of this.rows) {
			// console.log(row , 'afafafa')
			for (const col of this.cols) {
				//  console.log(col + '-' , row, 'fafsafasfaak')
				this.row_cols.push({
					name: row + '-' + col,
					state_id: 1,
					index: 2,
					color: '#000000'
				});
			}
		}

		// document.getElementById('success').innerHTML = "Form is validated Successfully";
		console.log('row + cols', this.row_cols);
	}

	// clear handler
	clearSelected() {
		this.selected = [];
		this.reserved = [];
		this.rowname = '';
		this.price = '';
		this.name = '';
		this.zone = '';
	}
	seatClicked(seatPos: string) {
		// console.log(seatPos , ',z,z,z,z,z,z')
		const index = this.selected.indexOf(seatPos);
		if (index !== -1) {
			// seat already selected, remove
			this.selected.splice(index, 1);

			for (const o of this.row_cols) {
				if (o.name === seatPos) {
					o.state_id = 1;
				}
			}
			this.selected3.splice(index, 1);
			console.log(this.selected, '222');
		} else {
			// push to selected array only if it is not reserved
			if (this.reserved.indexOf(seatPos) === -1) {
				this.selected.push(seatPos);
				for (const o of this.row_cols) {
					if (o.name === seatPos) {
						o.state_id = 2;
					}
				}
				//     this.row_cols.push({
				//       name : seatPos,
				//      sate_id : 2,
				//      color : 'red',
				//      // index : index + 1
				//  })
				// this.selected.push({
				//   name : seatPos ,
				//   sate_id : 1,
				//   color : 'red'

				// });
				for (let p = 0; p <= this.row_cols.length; p++) {
					// console.log(p)
					// if(this.row_cols[p].name === this.selected3[p].name){
					//   console.log(this.row_cols[p].name , 'a')
					// }
				}
			}
		}
		console.log('Seats Aviable', this.selected);
		console.log('will send', this.row_cols);
	}
	seatresved(seatPos: string) {
		const index = this.reserved.indexOf(seatPos);
		if (index !== -1) {
			// seat already selected, remove
			this.reserved.splice(index, 1);
		} else {
			// push to selected array only if it is not reserved
			if (this.selected.indexOf(seatPos) === -1) {
				this.reserved.push(seatPos);
			}
		}
		// console.log("Seats Blocked", this.reserved)
	}
	// Buy button handler
	showSelected() {
		this.show = false;
		this.showL = true;
		this.all = false;
	}
	showresved() {
		this.show = true;
		this.showL = false;
		this.all = false;
	}
	send() {
		alert('Selected Seats:' + this.selected + '\nRow Name:' + this.name);
		this.seatname.push(this.name);
		this.daos.push({
			rowname: this.name,
			seats: this.row_cols
		});
		// this.row_cols = []

		console.log('seats', this.name, 'seatsz', this.selected);
		console.log(this.daos, 'KALAK');
	}
	showall() {
		this.all = true;
		this.show = false;
		this.showL = false;
	}
	showme(s) {
		console.log('s', s);
		for (let o = 0; o <= this.daos.length; o++) {
			if (this.daos[o]['rowname'] === s) {
				for (let k = 0; k < this.daos[o].seats.length; k++) {
					// console.log(this.daos[o].seats[k].name, 'sz')
					if (this.daos[o].seats[k].state === 2) {
						this.selected.push(this.daos[o].seats[k].name);
					}
				}
			}
		}
	}
	setzoneid(id) {
		console.log(id);
		this.zone_ids = id;
	}
	sendzone() {
		// for (let i = 0; i < this.daos.length; i++) {
		//   if (this.daos[i].selected === true) {
		//     this.selectedrow.push({
		//       price: this.price + 'EGP',
		//       zone: this.zone,
		//       area: this.daos[i]
		//     });
		//   }
		//   console.log(this.selectedrow, 'Zone IS');
		// }
		const seatsdata = {
			event_id: this.createdEventId,
			zones: [
				{
					guid: this.zone_ids,
					seats: this.row_cols
				}
			]
		};
            console.log(seatsdata , ' mflka')
		this.consert.addSeats(seatsdata).subscribe(
			create => {
				console.log(create);
        console.log(seatsdata, 'data will send to api');
        swal('Seats Are Created','Add New Row And Cloumn To Create Again','success')
        // this.zonesdata = create['zones']
			},
			error => {
				console.log(error);
			}
    );
    this.row_cols = [];
    this.showSelected();
    this.clearSelected();
    this.rows = [];
    this.cols = [];
    this.rowss = null;
    this.colss =  null;
  }

  openseats(): void {
		const form = this.f_baseCreateVenue;
		const row = form.get('num_of_row');
		const cols = form.get('num_of_cols');
		const name = form.get('name');

		this.dialog.open(createSeats, {
      // position: {
      //   top: '10px',
      //   right: '80px',
      // },
      // height: '98%',
      // width: '140vw',
      // panelClass: 'full-screen-modal',
			width: '40vw',
      height: '80%',
      data : [{
        Zone: this.zonesdata ,
        Event_id :this.createdEventId,
        number_of_rows: row.value,
        number_of_columns: cols.value,
        name : name.value
      }]
    });
    form.reset();
    this.areas_addes = [];
  }
	ngOnDestroy(): void {
		localStorage.removeItem('eventid');
	}
}

@Component({
	selector: 'app-seats',
	templateUrl: 'create-seats.html',
	styleUrls: ['./c-e-venues.component.scss']
})
export class createSeats implements OnInit {
  rows: string[] = [];
	cols: number[] = [];
	reserved: string[] = [];
	selected: any[] = [];
	show = false;
	showL = true;
	all = false;
	seatname: string[] = [];
	name: string;
	daos: any[] = [];
	zone: any;
	price: any;
	Seatadd: any;
	rowname: any;
	selectedrow: any[] = [];
	rowss: number;
	colss: number;
	datasend: any[] = [];
	selected3: any[] = [];
	row_cols: any[] = [];
	colspush: any[] = [];
	rowspush: any[] = [];
	rowsselected: any[] = [];
	areas_addes: any[] = [];
	zonessend: any;
	zonesdata: any;
  zone_ids: any;
  zone_id : any;
	// @Input() createdEventId: string;
  zonedeatils: any;
  zoneid: any;
  createdEventId: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any = [],
    public dialogRef: MatDialogRef<createSeats>,
    private consert: ConcertsListService,

	) {
    this.zonedeatils = data;
  }

	ngOnInit() {
    this.createdEventId = localStorage.getItem('eventid')
    console.log(this.createdEventId , 'event id');
    console.log(this.zonedeatils , 'zone data')


    this.done();
    for (let i = 0; i <= this.zonedeatils[0]['Zone'].length; i++) {
			if (this.zonedeatils[0]['Zone'][i]['name'] === this.zonedeatils[0]['name']) {
				  console.log("yes");
				this.zoneid = this.zonedeatils[0]['Zone'][i]['guid'];
      }
    }

  }

	onNoClick(): void {
		this.dialogRef.close();
  }



  getStatus(seatPos: string) {
		//  this.datasend.push(seatPos)
		// // console.log(seatPos , 's')

		if (this.reserved.indexOf(seatPos) !== -1) {
			return 'reserved';
		} else if (this.selected.indexOf(seatPos) !== -1) {
			//  console.log(this.selected , 'ssfaffa')
			return 'selected';
		}
	}
	done() {
		this.rows = [];
		this.cols = [];
		this.row_cols = [];

		for (let x = 0; x < this.zonedeatils[0].number_of_rows; x++) {
			this.cols[x] = x;
			// this.colspush.push(this.cols)
		}
		// console.log(this.cols , 'sss')

		for (let x = 0; x < this.zonedeatils[0].number_of_columns; x++) {
			// this.rows[x] = x.toString();
			this.rows[
				x
			] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.charAt(x);

			// this.rowspush.push(this.rows)
		}

		// tslint:disable-next-line: no-shadowed-variable
		for (const row of this.rows) {
			// console.log(row , 'afafafa')
			for (const col of this.cols) {
				//  console.log(col + '-' , row, 'fafsafasfaak')
				this.row_cols.push({
					name: row + '-' + col,
					state_id: 1,
					index: 2,
					color: '#000000'
				});
			}
		}

		// document.getElementById('success').innerHTML = "Form is validated Successfully";
		console.log('row + cols', this.row_cols);
	}

	// clear handler
	clearSelected() {
		this.selected = [];
		this.reserved = [];
		this.rowname = '';
		this.price = '';
		this.name = '';
		this.zone = '';
	}
	seatClicked(seatPos: string) {
		// console.log(seatPos , ',z,z,z,z,z,z')
		const index = this.selected.indexOf(seatPos);
		if (index !== -1) {
			// seat already selected, remove
			this.selected.splice(index, 1);

			for (const o of this.row_cols) {
				if (o.name === seatPos) {
					o.state_id = 1;
				}
			}
			this.selected3.splice(index, 1);
			console.log(this.selected, '222');
		} else {
			// push to selected array only if it is not reserved
			if (this.reserved.indexOf(seatPos) === -1) {
				this.selected.push(seatPos);
				for (const o of this.row_cols) {
					if (o.name === seatPos) {
						o.state_id = 2;
					}
				}
				//     this.row_cols.push({
				//       name : seatPos,
				//      sate_id : 2,
				//      color : 'red',
				//      // index : index + 1
				//  })
				// this.selected.push({
				//   name : seatPos ,
				//   sate_id : 1,
				//   color : 'red'

				// });
				for (let p = 0; p <= this.row_cols.length; p++) {
					// console.log(p)
					// if(this.row_cols[p].name === this.selected3[p].name){
					//   console.log(this.row_cols[p].name , 'a')
					// }
				}
			}
		}
		console.log('Seats Aviable', this.selected);
		console.log('will send', this.row_cols);
	}
	seatresved(seatPos: string) {
		const index = this.reserved.indexOf(seatPos);
		if (index !== -1) {
			// seat already selected, remove
			this.reserved.splice(index, 1);
		} else {
			// push to selected array only if it is not reserved
			if (this.selected.indexOf(seatPos) === -1) {
				this.reserved.push(seatPos);
			}
		}
		// console.log("Seats Blocked", this.reserved)
	}
	// Buy button handler
	showSelected() {
		this.show = false;
		this.showL = true;
		this.all = false;
	}
	showresved() {
		this.show = true;
		this.showL = false;
		this.all = false;
	}
	send() {
		alert('Selected Seats:' + this.selected + '\nRow Name:' + this.name);
		this.seatname.push(this.name);
		this.daos.push({
			rowname: this.name,
			seats: this.row_cols
		});
		// this.row_cols = []

		console.log('seats', this.name, 'seatsz', this.selected);
		console.log(this.daos, 'KALAK');
	}
	showall() {
		this.all = true;
		this.show = false;
		this.showL = false;
	}
	showme(s) {
		console.log('s', s);
		for (let o = 0; o <= this.daos.length; o++) {
			if (this.daos[o]['rowname'] === s) {
				for (let k = 0; k < this.daos[o].seats.length; k++) {
					// console.log(this.daos[o].seats[k].name, 'sz')
					if (this.daos[o].seats[k].state === 2) {
						this.selected.push(this.daos[o].seats[k].name);
					}
				}
			}
		}
	}
	setzoneid(id) {
		console.log(id);
		this.zone_ids = id;
	}
	sendzone() {
		// for (let i = 0; i < this.daos.length; i++) {
		//   if (this.daos[i].selected === true) {
		//     this.selectedrow.push({
		//       price: this.price + 'EGP',
		//       zone: this.zone,
		//       area: this.daos[i]
		//     });
		//   }
		//   console.log(this.selectedrow, 'Zone IS');
    // }

		const seatsdata = {
			event_id: this.zonedeatils[0].Event_id,
			zones: [
				{
					guid: this.zoneid,
					seats: this.row_cols
				}
			]
		};
            console.log(seatsdata , ' mflka')
		this.consert.addSeats(seatsdata).subscribe(
			create => {
				console.log(create);
        console.log(seatsdata, 'data will send to api');
        swal('Seats Are Created','Add New Row And Cloumn To Create Again','success')
        this.dialogRef.close();
        // this.zonesdata = create['zones']
			},
			error => {
				console.log(error);
			}
    );
    this.row_cols = [];
    this.showSelected();
    this.clearSelected();
    this.rows = [];
    this.cols = [];
    this.rowss = null;
    this.colss =  null;
    this.zonedeatils = [];
  }

}
