import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-c-d-event-list',
	templateUrl: './c-d-event-list.component.html',
	styleUrls: ['./c-d-event-list.component.scss']
})
export class CDEventListComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
