export const environment = {
	production: true,

	apiEndPoint: 'http://prod.zabatnee.com/api',
	// apiEndPoint: 'http://staging.zabatnee.com/api',
	clientId: '3xc443jG39RJkE2JBx1YuoWcLHwz98UkBZoYHXFm',
	clientSecret:
		'nSkSvunanEVAxjf1yL7R2aKUm72kwl1yJta7Q9QDt0zgb2M2tDj8InW0587UU8yocUWh5Dr2CE5mh41ogVoNnmbtArs7RDsScU2hsAZVHryyEoigkVMxSStVXXkrCiQ3',
	AWS: {},
	httpHeader: { 'content-type': 'application/json' },
	googleMapsApiEndPoint: 'https://maps.googleapis.com/maps/api/geocode/json',
	googleMapsApiKey: 'AIzaSyD1uXumOcpOZ5iLZXJe2lwEzn-NT0cw2tw'
};
