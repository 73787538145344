import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { MouseEvent } from '@agm/core';
import { SportsListService, UploadimgService } from '../../../../../_services';
import {
	FormBuilder,
	FormGroup,
	Validators,
	FormControl,
	FormArray
} from '@angular/forms';

@Component({
	selector: 'app-trainer-details',
	templateUrl: './trainer-details.component.html',
	styleUrls: ['./trainer-details.component.scss']
})
export class TrainerDetailsComponent implements OnInit {
  id: string;
  trainer: any;
  edit = true;
	constructor(
    private route: ActivatedRoute,
		private sports: SportsListService,
    private uploadimgService: UploadimgService,
    private _formBuilder: FormBuilder,

  ) {}

	ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.sports.getTrainerDetails(this.id).subscribe((data: any) => {
      console.log(data)
      this.trainer = data.Trainer;
    });

  }
  editada() {
		this.edit = !this.edit;
	}
}
