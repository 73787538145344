import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter'
})
export class FilterPipe implements PipeTransform {
	/**
	 * filtering or searching table by one or multiple columns data
	 * @param items            data on which is applied for filters the table’s columns.
	 * @param field            filter key.
	 * @param value            input filter text
	 */
	transform(items: any[], field: string, value: string): any[] {
		// determines whether no values in an Array.
		if (!items) {
			return [];
		}
		// no key to search with
		if (!field || !value) {
			return items;
		}
		if (field !== undefined && value !== undefined) {
			// iterate over the items in the array and return array with matched value
			return items.filter(item =>
				item[field].toLowerCase().includes(value.toLowerCase())
			);
		}
	}
}
