import { Component, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormGroupDirective,
	NgForm,
	Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ConcertsListService } from './../../../../_services/concerts.service';
import { TourListService, HttpService } from './../../../../_services';

import { environment } from '../../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

@Component({
	selector: 'app-concerts-create-reservation',
	templateUrl: './concerts-create-reservation.component.html',
	styleUrls: ['./concerts-create-reservation.component.scss']
})
export class ConcertsCreateReservationComponent implements OnInit {
  zone: any [] = [];

  data :any;
  eventname: any;
  eventid : any;
  selectedclasss: any;
  venue: any;
  datetime: any;
  shows: any;
  showid:any;
  getData: any;
  userlist: any;
  userid: any;
  fristname: any;
  lastname: any;
  showcontant: boolean;
  phone: string;
  datas: any;
  seatss: any;
  date_id;
  date: any;
  constructor(
    private router: Router,
    private clservuve: ConcertsListService,
    private _http: HttpService,
    private _toaster: ToastrService,
    private tourListService: TourListService,


    ) {}

  ngOnInit() {
      this.data = ['makram','akram','sasasa']
      // this.isLoadingInsights = true;
      this.clservuve.getBranch().subscribe((data: any) => {
        this.eventname = data['events'];
        // this.isLoading = false;1
        console.log(this.eventname);
        // this.courseTable();
        // this.sportsCount = this.sportList.length;
      });


  }

  gotoseats() {
    this.router.navigate(['/seats']);

  }
  getdataevent(id){
    console.log(id)
    this.selectedclasss = id;
		// console.log('yes1', this.selectedclasss);
		for (let i = 0; i < this.eventname.length; i++) {
			if (this.eventname[i].guid === id) {
				//   console.log("yes");
				this.venue = this.eventname[i].venue;
			   this.datetime = this.eventname[i].eventDates;
			}
		}
    console.log('sfsafasfas', this.datetime);

    this.showid ='';
    this.date_id = "";
  }

  myFilter = (d: Date): boolean => {
		const showDates = this.datetime
			.split(',')
			.filter(function(el) {
				return el !== '';
			});
		return (
			showDates.findIndex(
				showDate => d.toDateString() === new Date(showDate).toDateString()
			) >= 0
		);
  };

  getSelectedConcertDate(date: any) {
		const targetDate =
			new Date(date).getFullYear() +
			'-' +
			(new Date(date).getMonth() + 1) +
			'-' +
			new Date(date).getDate();
		const params = {
			event_guid: this.selectedclasss,
			date: targetDate
		};
		this._http
			.getData(`${environment.apiEndPoint}/vendor/concert/show/`, params)
			.subscribe(
				res => {
					this.shows = res['shows'];
					console.log(this.shows, 'shpw');
				},
				error => {
					if (error['error']['code'] === '15005') {
						return this._toaster.error(error['error']['detail'], 'Error');
					}
				}
			);
  }
  showiddata(id){
    console.log(id , 'fasfsas')
    const params = {
			show_guid: id,
		};
    this._http
			.getData(`${environment.apiEndPoint}/vendor/concert/map/`, params)
			.subscribe(
				res => {
          this.zone = res['map'];
          localStorage.setItem('ev', JSON.stringify(this.zone));

					console.log(res, 'shpw');
				},
				error => {
          console.log(error , 'error')
          this._toaster.error('Error in Get data' , 'Error');

				}
			);

  }
  numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
  }
  searchPhoneExists(phone) {
		const testRegular = /^[a-zA-Z]+$/;
		if (testRegular.test(this.phone)) {
			swal('attention', 'the phone shloud be number And Not Null', 'error');
		} else if (phone === null || phone === '') {
			swal(' phone', 'Enter phone', 'error');
		} else {
			this.tourListService.getPhoneExsist(phone).subscribe(phones => {
				if (phones.code === '10000') {
					this.showcontant = true;
					this.getData = phones;
					this.userlist = phones.user;
          this.userid = this.userlist.id;
          this.seatss= JSON.parse(localStorage.getItem('seats'));
          console.log(this.seatss , 'fanfan')
			 console.log('user phone', this.userlist);
					this.fristname = this.userlist.first_name;
					this.lastname = this.userlist.last_name;

				} else if (phones.code === '10010') {
					this.fristname = '';
          this.lastname = '';
          this.seatss= JSON.parse(localStorage.getItem('seats'));
          console.log(this.seatss , 'fanfan')
					this.showcontant = true;
				}
			});
		}
  }

  createresevation() {


		// swal(this.fristname + this.phone.substr(1) || '' , this.lastname , 'info')
		this.tourListService.getPhoneExsist(this.phone).subscribe((phone: any) => {
			this.getData = phone;
			this.userlist = phone.user;
			//   console.log(phone, 'zz');
			if (phone.code === '10000') {
				this.datas = {
					user_id: this.userid,
          show_id: this.showid,
          seats : this.seatss,
          payment_method_id:1

				};
				console.log('data', this.datas);
			} else if (phone.code === '10010') {
				this.datas = {
					firstName: this.fristname,
					lastName: this.lastname,
					mobile: this.phone.substr(1) || '',
          show_id: this.showid,
          seats : this.seatss,
          payment_method_id:1
				};
        console.log('var data', this.datas);
			}
			this.clservuve.createReservation(this.datas).subscribe(
				create => {
          console.log(create, 'sata');
          localStorage.removeItem('ev');
          // localStorage.removeItem('seats');
          swal('Reservation is Created','Thanks','success')
				},
				error => {
					const errorStatus = error.error.code;
					switch (errorStatus) {
            case this.datas.seats = null:
            swal('Please Select All Seats', 'Oops...', 'error');
            break;
						case '10001':
							swal('Please provide missing parameter(s)', 'Oops...', 'error');
							break;
						case '11047':
							swal('Time Is left Cant Selected It', 'Oops...!', 'error');
							break;
					}
				}
			);
    });
  	}


}
