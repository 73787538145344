import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { ToursService, TourListService } from '../../../../_services';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
	selector: 'app-trip-details',
	templateUrl: './trip-details.component.html',
	styleUrls: ['./trip-details.component.scss']
})
export class TripDetailsComponent implements OnInit {
	slots: any = [];
	finaldates: any = [];
	id: string;
	startdate: any;
	viewtrips = false;
	reservations: any;
	viewuser = false;
	tour_name: any;
	constructor(
		private route: ActivatedRoute,
		private tours: ToursService,
		public dialog: MatDialog
	) {}
	ngOnInit() {
		this.id = this.route.snapshot.paramMap.get('id');

		this.loadDate();
	}
	setDate2(date) {
		const dateISO = new Date(date);
		this.startdate =
			dateISO.getFullYear() +
			'-' +
			(dateISO.getMonth() + 1) +
			'-' +
			dateISO.getDate();
		console.log('apapppapap ', this.startdate);
	}
	myFilter = (d: Date): boolean => {
		const showDates = this.finaldates.filter(function(el) {
			return el !== '';
		});
		return (
			showDates.findIndex(
				showDate => d.toDateString() === new Date(showDate).toDateString()
			) >= 0
		);
	};
	changeDate() {
		this.reservations = [];
		const data = {
			tourid: this.id,
			date: this.startdate
		};
		this.tours.getTimeslotsbyDate(data).subscribe((DATA: any) => {
			if (DATA.timeSlots.length === 0) {
				swal("there's no slots in this Date!", '', 'error');
				this.viewtrips = true;
				this.viewuser = true;
			} else if (DATA.timeSlots.length > 0) {
				this.viewtrips = true;
				this.viewuser = true;
				this.slots = DATA.timeSlots;
				// if(this.slots[0].status === 1){
				//   console.log('a7a')
				// }
				// else if (this.slots[0].status === 2){
				//   console.log('a7a 2')
				// }
			}
			console.log('Solts dates', DATA);
		});
	}
	loadDate() {
		this.tours.getTourDetails(this.id).subscribe((data: any) => {
			const solt = data.tours[0].tripDetails[0].slots.length;
			this.tour_name = data.tours[0].name;
			for (let z = 0; z < solt; z++) {
				this.finaldates.push(data.tours[0].tripDetails[0].slots[z].start_date);
			}
			this.slots = this.finaldates;
			console.log('Trip Dates', this.slots);
		});
	}
	viewActions(slotid) {
		this.tours.getReservationsPerSlot(slotid).subscribe((response: any) => {
			if (response.users.length === 0) {
				this.viewuser = true;
				this.viewtrips = true;
				swal('there are no Reserations in this Date!', '', 'error');
			} else if (response.users.length > 0) {
				this.viewuser = true;
				this.reservations = response.users;
			}
			console.log('slots users', response);
		});
	}
	cancel(guid) {
		const x = guid;
		// tslint:disable-next-line: no-use-before-declare
		const dialogRef = this.dialog.open(CancelComponent, {
			width: '40vw',
			data: { x }
		});
	}
}

@Component({
	selector: 'app-cancel',
	templateUrl: 'cancel.dialog.html'
})
export class CancelComponent implements OnInit {
	CancelR;
	constructor(
		private tos: TourListService,
		public dialogRef: MatDialogRef<CancelComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	onNoClick(): void {
		this.dialogRef.close();
	}
	ngOnInit() {}

	cancel() {
		const reseon = {
			slots: [this.data.x],
			reason: this.CancelR
		};
		this.tos.cancelTrips(reseon).subscribe((response: any) => {
			console.log('slots users', response);
			this.dialogRef.close();
		});
		console.log(reseon, 'ssfaf');
	}
}
