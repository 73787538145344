import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../_services';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ReservationDetailsComponent } from './reservation-details/reservation-details.component';
import { CreateReservationComponent } from './create-reservation/create-reservation.component';
import { AnonymousReservationComponent } from './anonymous-reservation/anonymous-reservation.component';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { ErrorHandlerService } from './../../../_services/error-handler.service';
import { log } from 'util';
import swal from 'sweetalert2';
import { TourListService } from '../../../_services';

@Component({
  selector: 'app-reservations-restaurant',
  templateUrl: './reservations-restaurant.component.html',
  styleUrls: ['./reservations-restaurant.component.scss']
})
export class ReservationsRestaurantComponent implements OnInit {
  periodList = ['Week', 'Month', 'Quarter', 'Year'];
  throughList = ['App', 'Call', 'WalkIn'];
  statusList = ['Pending', 'Completed', 'Canceled'];
  timeList = ['UPCOMING', 'PAST'];
  throughIcons = { App: 'fa-mobile', WalkIn: 'fa-male', Call: 'fa-phone' };
  noReservations = false;
  reservationInsights;
  fullReservationInsights;
  reservations;
  branches = [];
  currentPeriodInsight = 'Week';
  sort_type = 'desc';
  /**pagination */
  pageSize = 10;
  currentPage = 1;
  totalItems = 160;

  resetAllDropDownList = {
    period: '',
    through: '',
    status: '',
    past_upcoming: '',
    time: '',
    branch: '',
    date: ''
  };
  searchQueryParameter = {
    page: '1',
    page_size: '10',
    sort_type: 'desc',
    sort_column: 'from_time',
    branch_id: '',
    period: '',
    through: '',
    reservation_date: '',
    status: '',
    past_upcoming: '',
    mobile: '',
    wf_status: ''
  };
  dateObject = new Date();
  date =
    this.dateObject.getFullYear() +
    '-' +
    (this.dateObject.getMonth() + 1) +
    '-' +
    this.dateObject.getDate();
  mobile = '';
  mobileError: boolean;
  isLoadingInsights = true;
  isLoadingReservations = true;
  filterQueryParamsTemp: {
    page: any;
    page_size: string;
    sort_type: string;
    sort_column: string;
    status: string;
    wf_status: string;
    reservation_date: string;
    past_upcoming: string;
  };
  constructor(
    private httpService: HttpService,
    private router: Router,
    private dialog: MatDialog,
    private errorHandlerServices: ErrorHandlerService,
    private tourListService: TourListService
  ) { }

  ngOnInit() {
    this.getReservationRestaurantInsights();
    this.getReservationRestaurant({
      page: '1',
      page_size: '10',
      sort_type: 'desc',
      sort_column: 'from_time'
    });
    this.getBranchesNames();
  }
  pageChange(event) {
    this.currentPage = event;
    this.searchQueryParameter.page = this.currentPage.toString();

    this.httpService
      .getData(
        `${environment.apiEndPoint}/vendor/reservation/filter/`,
        this.searchQueryParameter
      )
      .subscribe(
        reservation => {
          console.log(reservation);
          this.isLoadingReservations = false;
          this.noReservations = false;
          if (
            reservation.hasOwnProperty('reservations') &&
            !reservation['reservations'].length
          ) {
            return (this.noReservations = !this.noReservations);
          }
          this.reservations = reservation['reservations'];
        },
        error => {
          this.isLoadingReservations = !this.isLoadingReservations;
          this.noReservations = !this.noReservations;
          // this.errorHandlerServices.handleError(error, 'There is a problem in reservation history');
        }
      );
  }
  /**
   * get restaurant Insights
   */
  getReservationRestaurantInsights() {
    this.httpService
      .getData(`${environment.apiEndPoint}/vendor/reservation/insights/`, {
        client_id: environment.clientId
      })
      .pipe(map(insights => insights['insights']))
      .subscribe(reservationInsights => {
        console.log(reservationInsights, 's');
        this.fullReservationInsights = reservationInsights;
        this.reservationInsights = reservationInsights[0];
        this.isLoadingInsights = !this.isLoadingInsights;
      });
  }
  /**
   * get restaurant Insights based on specific period
   * @param period      it can be week[default], Month, Quarter and Year
   */
  getReservationRestaurantInsightsByPeriod(period: string) {
    for (let i = 0; i < this.fullReservationInsights.length; i++) {
      if (this.fullReservationInsights[i]['period'] === period) {
        this.reservationInsights = this.fullReservationInsights[i];
      }
    }
  }
  /**
   * Summary. get all reservation data when the page load
   *
   *  Search Parameters
   *  ['branch_id', 'period', 'status','through', 'page', 'page_size', 'mobile', 'user_id',
   *  'reservation_date', 'wf_status', 'past_upcoming', 'sort_type', 'sort_column']
   * @link http://staging.zabatnee.com/api/vendor/reservation/filter/
   */
  getReservationRestaurant(searchQueryParameter?: Object) {
    this.isLoadingReservations = true;
    this.httpService
      .getData(
        `${environment.apiEndPoint}/vendor/reservation/filter/`,
        searchQueryParameter
      )
      .subscribe(
        reservation => {
          console.log(reservation);
          this.isLoadingReservations = false;
          this.noReservations = false;
          if (
            reservation.hasOwnProperty('reservations') &&
            !reservation['reservations'].length
          ) {
            return (this.noReservations = !this.noReservations);
          }
          this.reservations = reservation['reservations'];
          // for (let i of this.reservations) {
          //   i.res_list_actions.push({ name: "Confirm" }, { name: "Reject" })
          //   console.log(i)
          // }
          this.totalItems = reservation['total_pages'];
          this.currentPage = reservation['current_page'];
        },
        error => {
          this.isLoadingReservations = !this.isLoadingReservations;
          this.noReservations = !this.noReservations;
          // this.errorHandlerServices.handleError(error, 'There is a problem in reservation history');
        }
      );
  }
  /**
   * get Branches Names
   */
  getBranchesNames() {
    const access_token = JSON.parse(localStorage.getItem('currentUser'))[
      'access_token'
    ];
    this.httpService
      .getData(`${environment.apiEndPoint}/vendor/restaurant/branch/`, {
        is_active: 1
      })
      .subscribe(branches => {
        this.branches = branches['branches'];
      });
  }

  /**
   * getReservationRestaurant
   * sorting element based on specific sorting type
   * sorting by user name even asc, dec
   */
  sortingBy(sortColumn: string) {
    this.sort_type = this.sort_type === 'asc' ? 'desc' : 'asc';
    this.getReservationRestaurant({
      page: '1',
      page_size: '10',
      sort_type: this.sort_type,
      sort_column: sortColumn
    });
  }
  /**
   * Filter Reservation based on specific criteria
   * @param sortColumn      it can be [ 'from_time', ]
   * @param wfStatus
   * @param reservationDate
   * @param pastUpcoming
   * @param status
   */
  filterReservations(
    sortColumn = '',
    wfStatus = '',
    reservationDate = '',
    pastUpcoming = '',
    status = ''
  ) {
    /*mostafa edit
     */
    if (this.currentPage !== 1) {
      this.currentPage = 1;
    }

    this.resetInputs();
    this.filterQueryParamsTemp = {
      page: this.currentPage,
      page_size: '10',
      sort_type: 'desc',
      sort_column: sortColumn,
      status: status,
      wf_status: wfStatus,
      reservation_date: reservationDate,
      past_upcoming: pastUpcoming
    };
    this.httpService
      .getData(
        `${environment.apiEndPoint}/vendor/reservation/filter/`,
        this.filterQueryParamsTemp
      )
      .subscribe(
        reservation => {
          console.log(reservation);
          this.isLoadingReservations = false;
          this.noReservations = false;
          // mostafa edit
          this.searchQueryParameter.sort_type = this.filterQueryParamsTemp.sort_type;
          this.searchQueryParameter.past_upcoming = this.filterQueryParamsTemp.past_upcoming;
          this.searchQueryParameter.reservation_date = this.filterQueryParamsTemp.reservation_date;
          this.searchQueryParameter.status = this.filterQueryParamsTemp.status;
          this.searchQueryParameter.sort_column = this.filterQueryParamsTemp.sort_column;
          this.searchQueryParameter.wf_status = this.filterQueryParamsTemp.wf_status;
          // edit with motafa
          // if (
          //   reservation.hasOwnProperty('reservations') &&
          //   !reservation['reservations'].length
          // ) {
          //   return (this.noReservations = !this.noReservations);
          // }
          this.reservations = reservation['reservations'];
          this.totalItems = reservation['total_pages'];
          this.currentPage = reservation['current_page'];
        },
        error => {
          this.isLoadingReservations = !this.isLoadingReservations;
          this.noReservations = !this.noReservations;
          // this.errorHandlerServices.handleError(error, 'There is a problem in reservation history');
        }
      );
  }
  /**
   * Quick Search
   */
  search() {
    this.noReservations = false;
    this.getReservationRestaurant(this.searchQueryParameter);
  }
  /**
   * Clear Search field and make it in previous state
   */
  resetSearch() {
    this.noReservations = false;
    this.searchQueryParameter = {
      page: '1',
      page_size: '10',
      sort_type: 'desc',
      sort_column: 'from_time',
      branch_id: '',
      period: '',
      through: '',
      reservation_date: '',
      status: '',
      past_upcoming: '',
      mobile: '',
      wf_status: ''
    };
    this.getReservationRestaurant(this.searchQueryParameter);
    this.resetInputs();
  }
  resetInputs() {
    // reset dropDown List
    for (const key in this.resetAllDropDownList) {
      if (this.resetAllDropDownList.hasOwnProperty(key)) {
        this.resetAllDropDownList[key] = null;
      }
    }
    // reset Mobile
    this.mobile = '';
  }
  /**
   * onSelectSpecificReservationUser
   * route to specific selected user
   * @param reservationId     the id of target user/guest
   */
  onSelectSpecificReservationUser(reservationId: string) {
    this.router.navigate(['/guestList', reservationId]);
  }

  setBranch(branch) {
    this.searchQueryParameter.branch_id = branch;
  }

  setPeriod(period) {
    this.searchQueryParameter.period = period;
  }

  setThrough(through) {
    this.searchQueryParameter.through = through;
  }

  getDate(date) {
    const dateISO = new Date(date);
    this.searchQueryParameter.reservation_date =
      dateISO.getFullYear() +
      '-' +
      (dateISO.getMonth() + 1) +
      '-' +
      dateISO.getDate();
  }

  // SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSss
  setStatus(status) {
    this.searchQueryParameter.status = status;
  }

  setTime(past_upcoming) {
    this.searchQueryParameter.past_upcoming = past_upcoming;
  }
  setMobile() {
    this.searchQueryParameter.mobile = this.mobile;
  }
  // SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSsss

  checkMobile() {
    const regNumber = /^\d+$/;
    const regString = /^[a-zA-Z]+$/;
    if (regNumber.test(this.mobile)) {
      this.mobileError = false;
      this.searchQueryParameter.mobile = this.mobile;
    }
    if (regString.test(this.mobile) && this.mobile.length) {
      this.mobileError = true;
    }
  }

  /**
   *  Open Reservation Details Dialog
   *  @param targetReservation
   */
  openDialogDetails(targetReservation) {
    // creating an instance of MatDialogConfig, which will configure the dialog with a set of default behaviors
    let dialogConfig = new MatDialogConfig();
    dialogConfig = {

      width: '40vw',
      height: '80%'
    };
    // means that the user will not be able to close the dialog just by clicking outside of it
    dialogConfig.disableClose = true;
    // the focus will be set automatically on the first form field of the dialog
    dialogConfig.autoFocus = true;
    // passing data to dialog


    dialogConfig.data = targetReservation;
    this.dialog.open(ReservationDetailsComponent, dialogConfig);
  }
  /**
   *  Open Create Reservation Details Dialog
   */
  openDialogCreateReservation() {
    // creating an instance of MatDialogConfig, which will configure the dialog with a set of default behaviors
    let dialogConfig = new MatDialogConfig();
    dialogConfig = {

      width: '40vw',
      height: '80%'
    };		// means that the user will not be able to close the dialog just by clicking outside of it
    dialogConfig.autoFocus = true;
    // the focus will be set automatically on the first form field of the dialog
    dialogConfig.autoFocus = true;
    dialogConfig.data = { branches: this.branches };
    this.dialog.open(CreateReservationComponent, dialogConfig);
  }
  /**
   *  Open AnonymousCheckIn user Details Dialog
   */
  openDialogAnonymousCheckIn() {
    // creating an instance of MatDialogConfig, which will configure the dialog with a set of default behaviors
    let dialogConfig = new MatDialogConfig();
    dialogConfig = {

      width: '40vw',
      height: '80%'
    };		// means that the user will not be able to close the dialog just by clicking outside of it
    dialogConfig.autoFocus = true;
    // the focus will be set automatically on the first form field of the dialog
    dialogConfig.autoFocus = true;
    dialogConfig.data = { branches: this.branches };
    this.dialog.open(AnonymousReservationComponent, dialogConfig);
  }
  updateReservationConfirmation(flag, reservation, i) {
    this.isLoadingReservations = true;
    this.reservations = reservation;
    const data = {
      reservationId: reservation.res_list_guid,
      action_id: ''
    };
    if (flag === 'confirm') {
      // data.action_id = reservation.res_list_actions[1].guid;
      data.action_id = i
    } else if (flag === 'reject') {
      // data.action_id = reservation.res_list_actions[0].guid;
      data.action_id = i
    } else if (flag === 'Checkin') {
      // data.action_id = reservation.res_list_actions[0].guid;
      data.action_id = i
    } else if (flag === 'Checkout') {
      // data.action_id = reservation.res_list_actions[0].guid;
      data.action_id = i

    }
    console.log('Data: ', i, data);
    this.tourListService
      .updateReservationConfirmation(data)
      .subscribe((updateflag: any) => {
        this.isLoadingReservations = false;
        console.log('are update is', updateflag);
        swal(' Updated', 'Action Is Updated', 'success');
        this.search();
      });
  }
}
