import { Component, OnInit } from '@angular/core';
import { HttpService } from './../../../_services';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-dashboard-restaurant',
	templateUrl: './dashboard-restaurant.component.html',
	styleUrls: ['./dashboard-restaurant.component.scss']
})
export class DashboardRestaurantComponent implements OnInit {
	period = ['Week', 'Month', 'Quarter', 'Year'];
	isLoadingInsights = true;
	branches;
	insights;
	topTransactions;
	topReservations;
	noContentTopTransactions = false;
	noContentTopReservation = false;

	constructor(private httpService: HttpService) {}

	ngOnInit() {
		/*get restaurant branches names*/
		this.getDashboardBranches();
		/*get restaurant statistics entity*/
		this.getDashboardInsights();
	}

	getDashboardBranches() {
		const access_token = JSON.parse(localStorage.getItem('currentUser'))[
			'access_token'
		];
		this.httpService
			.getData(`${environment.apiEndPoint}/vendor/restaurant/branch/`, {
				client_id: environment.clientId,
				client_secret: environment.clientSecret,
				access_token: access_token
			})
			.subscribe(branches => (this.branches = branches['branches']));
	}

	getDashboardInsights(period?) {
		const params = period != null ? { period: period } : { period: 'Month' };
		this.noContentTopReservation = false;
		this.noContentTopTransactions = false;
		this.httpService
			.getData(`${environment.apiEndPoint}/vendor/insights/entity/`, params)
			.subscribe(insights => {
				this.isLoadingInsights = false;
				this.insights = insights['insights'];
				this.topTransactions = insights['insights']['topTransactions'];
				this.topReservations = insights['insights']['topReservations'];
				if (
					insights['insights'].hasOwnProperty('topTransactions') &&
					!insights['insights']['topTransactions'].length
				) {
					this.noContentTopTransactions = !this.noContentTopTransactions;
				}
				if (
					insights['insights'].hasOwnProperty('topReservations') &&
					!insights['insights']['topReservations'].length
				) {
					this.noContentTopReservation = !this.noContentTopReservation;
				}
			});
	}
	selectedPeriod(period) {
		this.isLoadingInsights = true;
		this.getDashboardInsights(period);
	}
}
