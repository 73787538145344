import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ConcertsListService {
	constructor(private http: HttpClient) {}

	getBranch() {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/concert/event/ `, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(event => {
					// console.log('<><><><><><>', branch);
					return event;
				})
			);
	}

	// staging.zabatnee.com/api/vendor/concert/insights/

	getinsghtis(queryParams?: object) {
		let params = new HttpParams();
		for (const key in queryParams) {
			if (queryParams.hasOwnProperty(key) && queryParams[key] !== '') {
				if (!params.has(key)) {
					params = params.set(key, queryParams[key]);
					// console.log("dp",params);
				}
			}
		}
		return this.http.get(
			`${environment.apiEndPoint}/vendor/concert/insights/?${params.toString()}`
		);
	}

	gettikectsold(id) {
		return this.http
			.get<any>(
				`${
					environment.apiEndPoint
				}/vendor/concert/show/tickets/?event_guid=${id}`,
				{
					headers: new HttpHeaders(`${environment.httpHeader}`)
				}
			)
			.pipe(
				map(event => {
					//  console.log('<><><><><><>', event);
					return event;
				})
			);
	}

	// add zones
	addZone(zones?: object) {
		let params = new HttpParams();
		for (const key in zones) {
			if (zones.hasOwnProperty(key) && zones[key] !== '') {
				if (!params.has(key)) {
					params = params.set(key, zones[key]);
					// console.log("dp",params);
				}
			}
		}
		return this.http.post(`${environment.apiEndPoint}/vendor/concert/zone`, {
			zones
		});
	}

	addZOns(data) {
		return this.http
			.post<any>(`${environment.apiEndPoint}/vendor/concert/zone`, data)
			.pipe(
				map(trips => {
					console.log('TTTT', trips);
					return trips;
				})
			);
	}

	addSeats(data) {
		return this.http
			.post<any>(`${environment.apiEndPoint}/vendor/concert/seat`, data)
			.pipe(
				map(trips => {
					console.log('TTTT', trips);
					return trips;
				})
			);
	}
	getzones(id) {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/vendor/concert/zone/?event_id=${id}`,
				{
					headers: new HttpHeaders(`${environment.httpHeader}`)
				}
			)
			.pipe(
				map(event => {
					//  console.log('<><><><><><>', event);
					return event;
				})
			);
  }
  createReservation(data) {
		return this.http
			.post<any>(`${environment.apiEndPoint}/vendor/concert/reservation`, data)
			.pipe(
				map(res => {
					console.log('TTTT', res);
					return res;
				})
			);
	}
}
