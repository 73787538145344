import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
	ToursService,
	ConcertsListService,
	TourListService
} from '../../../../_services';
@Component({
	selector: 'app-c-e-events-data',
	templateUrl: './c-e-events-data.component.html',
	styleUrls: ['./c-e-events-data.component.scss']
})
export class CEEventsDataComponent implements OnInit {
	@Input()
	events;
	@Output()
	switchEventStatus = new EventEmitter();
	eventNameFilter;
	citites: any;
	filet_object = {
		venue_id: '',
		district_id: '',
		date: '',
		upcomingPastStatus: ''
	};
	constructor(private router: Router, private serv: ToursService) {}

	ngOnInit() {
		this.serv.loadCities().subscribe((data: any) => {
			this.citites = data.cities;
		});
	}

	goToEventDetails(event: any) {
		console.log(event, 'Event AAAAAAA');
		localStorage.setItem('event', JSON.stringify(event));
		this.router.navigate(['/concerts/events/', event.guid]);
	}

	getSelectedConcertVenue(value: any) {}

	getSelectedConcertCity(value: any) {
		console.log(value);
		this.filet_object.district_id = value;
		console.log(this.filet_object, 'afafafsaf');
	}

	setDate(date: any) {
		const dateISO = new Date(date);
		if (dateISO.getMonth() + 1 >= 9) {
			this.filet_object.date =
				dateISO.getFullYear() +
				'-' +
				(dateISO.getMonth() + 1) +
				'-' +
				dateISO.getDate();
			//     console.log("apapppapap ",this.res_to)
		} else {
			this.filet_object.date =
				dateISO.getFullYear() +
				'-' +
				'0' +
				(dateISO.getMonth() + 1) +
				'-' +
				dateISO.getDate();
		}
	}

	getSelectedConcertStatus(value: any) {
		this.filet_object.upcomingPastStatus = value;
	}

	search() {
		console.log(this.filet_object, 'afafafsaf');
	}
}
