import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToursService } from '../../../../_services/tours.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';

@Component({
  selector: 'app-reservation-details',
  templateUrl: './reservation-details.component.html',
  styleUrls: ['./reservation-details.component.scss']
})
export class ReservationDetailsComponent implements OnInit {
  // get a reference to this data object in ReservationDetailsComponent
  // by using the MAT_DIALOG_DATA injectable
  targetReservation: any;
  constructor(
    private dialogRef: MatDialogRef<ReservationDetailsComponent>, private _toastr: ToastrService,
    private service: ToursService,

    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.targetReservation = data;
  }

  ngOnInit() {

    console.log(this.targetReservation)
  }

  /**
   * close the dialog
   */
  close() {
    this.dialogRef.close();
  }
  copyToClipboard(item) {
    console.log(item)
    this._toastr.success('links copied.', 'sucess');
    var formattedString = item.join('\n');
    console.log(formattedString)

    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (formattedString));
      e.preventDefault();
      document.removeEventListener('copy', null);
      // console.log(document , item , e)
    });
    document.execCommand('copy');
  }
  notititle: any;
  notidesc: any;
  loader = false;
  send(t, d, id) {
    this.loader = true;
    console.log(t, d);
    var body = {
      "title": t,
      "body": d,
      "reservation_guid": id,
      "category_id": 1
    }
    this.service.sendnotify(body).subscribe(data => {
      console.log(data)
      this.loader = false;
      swal('Notifaction Was Send', 'done', 'success');
      this.dialogRef.close();

    }, err => {
      swal('Notifaction Was Not Send', '', 'error');

    })

  }

}
