import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-c-r-total-reservations',
	templateUrl: './c-r-total-reservations.component.html',
	styleUrls: ['./c-r-total-reservations.component.scss']
})
export class CRTotalReservationsComponent implements OnInit {
	periodList = ['Week', 'Month', 'Quarter', 'Year'];
	currentPeriodInsight = 'Week';
	constructor() {}

	ngOnInit() {}

	getSelectedConcertDateTo(value: any) {}

	getSelectedConcertDateFrom(value: any) {}
}
