import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import swal from 'sweetalert2';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TourListService, ToursService } from '../../../_services';

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'tour-reservationDetails.component.html',
  styleUrls: ['./tour-reservation.component.scss']
})
export class TourReservationDetailsComponent {
  packagedetailS;
  res_package_participants = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<TourReservationDetailsComponent>,
    private service: ToursService
  ) {
    console.log('p', data);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  notititle: any;
  notidesc: any;
  send(t, d, id) {
    console.log(t, d);
    var body = {
      "title": t,
      "body": "d",
      "reservation_guid": id,
      "category_id": 1
    }
    this.service.sendnotify(body).subscribe(data => {
      console.log(data)
      swal('Notifaction Was Send', 'done', 'success');
    }, err => {
      swal('Notifaction Was Not Send', '', 'error');

    })

  }
}
@Component({
  selector: 'app-create-reservation-dialog',
  templateUrl: 'create-reservation.component.html',
  styleUrls: ['./tour-reservation.component.scss']
})
export class CreateReservationTourComponent implements OnInit {
  getData: any;
  isLoading: boolean;
  userlist: any;
  userid: any;
  createReservationTour: any;
  fristname: any;
  lastname: any;
  selectedTourId: any;
  DataF: any;
  times: any;
  selectedTourDate: any;
  TimeF: any;
  tours: any;
  capacity: any;
  selectedresvPackage: any;
  dateListP: any;
  packageList: any;
  selectedTypeId: any;
  seatsAvailable: any = [];
  seats: any;
  phone: any;
  selecteTripId: any;
  tripTime: any;
  notes: string;
  similar: boolean;
  isLoadingtour: boolean;
  show: boolean;
  pakname: any;
  selectedTypename: any;
  ma: any;
  sfa: any;
  datas: any;
  startdate: string;
  minDate = new Date(new Date());

  constructor(
    public dialogRef: MatDialogRef<CreateReservationTourComponent>,
    private tourListService: TourListService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any = []
  ) { }

  ngOnInit() { }

  searchPhoneExists(phone) {
    const testRegular = /^[a-zA-Z]+$/;

    if (testRegular.test(this.phone)) {
      swal('attention', 'the phone shloud be number', 'error');
    } else if (phone === null || phone === '') {
      swal(' phone', 'Enter phone', 'error');
    } else {
      this.tourListService.getPhoneExsist(phone).subscribe(phone => {
        if (phone.code === '10000') {
          this.getData = phone;
          this.userlist = phone.user;
          this.userid = this.userlist.id;
          console.log('user phone', this.userlist);
          this.fristname = this.userlist.first_name;
          this.lastname = this.userlist.last_name;
        } else if (phone.code === '10010') {
          this.toastr.error('User mobile not found in system!', 'Oops!');
        }
      });
    }
  }
  setDate6(date) {
    const dateISO = new Date(date);
    this.startdate =
      dateISO.getFullYear() +
      '-' +
      (dateISO.getMonth() + 1) +
      '-' +
      dateISO.getDate();
    console.log('apapppapap ', this.startdate);
  }
  myFilter = (d: Date): boolean => {
    const showDates = this.DataF.filter(function (el) {
      return el !== '';
    });
    return (
      showDates.findIndex(
        showDate => d.toDateString() === new Date(showDate).toDateString()
      ) >= 0
    );
  };
  lestDateTrip() {
    this.tourListService.getDate(this.selectedTourId).subscribe((Date: any) => {
      this.isLoading = false;
      this.DataF = Date.trips.slots;
      this.times = Date.trips.packages;
      console.log('tour dates', Date);
    });
  }

  tripDateChange() {
    //    console.log("Tour ID", this.selectedTourId);
    this.lestDateTrip();
  }

  listTimeSlots() {
    const data = {
      tourid: this.selectedTourId,
      date: this.startdate
    };
    this.tourListService.getTimeSlots(data).subscribe((Time: any) => {
      this.isLoading = false;
      this.TimeF = Time.timeSlots;
      this.capacity = this.TimeF[0].remainCapacity;
      //   console.log("tour datesfafafsafafsafsafsafasfaafa", this.TimeF);
    });
  }

  listPackageType(packageId) {
    this.tourListService
      .getDate(this.selectedTourId)
      .subscribe((Datew: any) => {
        this.isLoading = false;
        this.DataF = Datew.trips.slots;
        this.times = Datew.trips.packages;
        console.log('tour dates', this.times);
      });
    this.selectedresvPackage = packageId;
    console.log('yes1', this.selectedresvPackage);
    for (let i = 0; i < this.times.length; i++) {
      if (this.times[i].id === packageId) {
        //   console.log("yes");
        this.packageList = this.times[i].participants;
      }
      console.log('sfsafasfas', this.packageList);
    }
  }
  typeName(typeId) {
    this.selectedTypeId = typeId;
    //   console.log("fa ID: ", this.selectedTypeId);
  }

  addSeats() {
    //   console.log("selectedTypeId ID: ", this.selectedTypeId);
    if (this.seatsAvailable.length > 0) {
      for (let i = 0; i < this.seatsAvailable.length; i++) {
        if (this.seatsAvailable[i].type_id === this.selectedTypeId.id) {
          swal('You have Selected this type before!', '', 'error');
          //    this.CreateReservationTourComponent.seats = '';
          this.seatsAvailable.splice(i, 1);
        }
      }
      this.seatsAvailable.push({
        type_id: this.selectedTypeId.id,
        number: this.seats,
        name: this.selectedTypeId.name
      });

      if (this.seats > this.capacity) {
        swal('Number Of Capctiy less than Seats', '', 'error');
      }
    } else {
      this.seatsAvailable.push({
        type_id: this.selectedTypeId.id,
        number: this.seats,
        name: this.selectedTypeId.name
      });

      this.capacity = this.capacity - this.seats;
    }

    console.log('Seats added', this.seatsAvailable);
  }
  removeItem(i: number): void {
    this.seatsAvailable.splice(i, 1);
    this.capacity = this.seatsAvailable[i].number + this.capacity;
  }

  createTour() {
    if (this.seatsAvailable.length === 0) {
      swal(
        'please add seats before create reservation',
        'select add button',
        'warning'
      );
    } else {
      this.tourListService
        .getPhoneExsist(this.phone)
        .subscribe((phone: any) => {
          this.getData = phone;
          this.userlist = phone.user;
          //   console.log(phone, 'zz');
          if (phone.code === '10000') {
            this.datas = {
              user_id: this.userid,
              res_slot_id: this.tripTime,
              res_no_of_people: this.seatsAvailable,
              res_notes: this.notes || ''
            };
            console.log('data', this.datas);
          } else if (phone.code === '10010') {
            this.datas = {
              user_first_name: this.fristname,
              user_last_name: this.lastname,
              user_mobile: this.phone.substr(1) || '',
              res_slot_id: this.tripTime,
              res_no_of_people: this.seatsAvailable,
              res_notes: this.notes || ''
            };
            console.log('var data', this.datas);
          }

          this.tourListService.createReservtionTour(this.datas).subscribe(
            create => {
              swal('Reservation Are Created!', '', 'success');
              this.dialogRef.close();
            },
            error => {
              const errorStatus = error.error.code;
              switch (errorStatus) {
                case '10001':
                  swal(
                    'Please provide missing parameter(s)',
                    'Oops...',
                    'error'
                  );
                  break;
                case '11047':
                  swal('Time Is left Cant Selected It', 'Oops...!', 'error');
                  break;
              }
            }
          );
        });
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.capacity = this.capacity;
    } else if (this.seats > this.capacity) {
      swal('The capacity is less than seats ', '', 'error');
    }
  }
}

@Component({
  selector: 'app-tour-reservation',
  templateUrl: './tour-reservation.component.html',
  styleUrls: ['./tour-reservation.component.scss']
})
export class TourReservationComponent implements OnInit {
  //   Drop items //
  periodList = [
    {
      id: '',
      name: 'Period (ALL)'
    },
    {
      id: '1',
      name: 'Week'
    },
    {
      id: '2',
      name: 'Month'
    },
    {
      id: '3',
      name: 'Quarter'
    },
    {
      id: '4',
      name: 'Year'
    }
  ];
  channels = [
    {
      id: '',
      name: 'Through (ALL)'
    },
    {
      id: 0,
      name: 'App'
    },
    {
      id: 1,
      name: 'Call'
    }
  ];
  statusList = [
    {
      id: '',
      name: 'Status (ALL)'
    },

    {
      id: '0',
      name: 'Pending'
    },
    {
      id: '1',
      name: 'Completed'
    },
    {
      id: '2',
      name: 'Canceled'
    },
    {
      id: '6',
      name: 'Rejected'
    },
    {
      id: '5',
      name: 'Confirm'
    },
    {
      id: '',
      name: ''
    }
  ];
  timeList = [
    {
      id: '',
      name: 'Time (ALL)'
    },
    {
      id: '0',
      name: 'UPCOMING'
    },
    {
      id: '1',
      name: 'PAST'
    },
    {
      id: '3',
      name: ''
    }
  ];
  //    Define Vaules
  filterObject = {
    page: '1',
    tour_id: '',
    status: '',
    through_id: '',
    page_size: '10',
    trip_date: '',
    trip_time: '',
    package_id: '',
    state_id: '',
    mobile_number: '',
    wf_status: '',
    sort_column: '',
    sort_type: ''
  };
  pageSize = 10;
  tourid;
  public sortType = '';
  public sortColumn = '';
  selectChannelId;
  selectedStatusId;
  selectMobile;
  slots = [];
  res_from = '2019-01-01';
  res_to = '2019-02-02';
  allinstights;
  tours;
  selectedTourId;
  DataF: any;
  selectedTourDate: any;
  TimeF: any;
  tripTime: any;
  times: any;
  selectedresvPackage: any;
  isLoading = true;
  getData: any[];
  pageNum: any;
  reservations: any[];
  reservationDetails;
  packagedetailS: any;
  showtourReservationDetails: boolean;
  dataSource: any;
  displayedColumns = [
    'res_list_user',
    'res_list_tour_name',
    'res_list_date',
    'res_list_package',
    'res_list_time_from',
    'res_list_number_of_persons',
    'res_list_price',
    'res_list_channel',
    'res_list_wf_status',
    'res_list_actions'
  ];
  unavailability = {
    startDate: '2017-11-04T22:00:00.000Z',
    endDate: '2017-11-24T22:00:00.000Z'
  };
  unavailabilityForm: FormGroup;
  mobile;
  public currentPage2: any;
  public totalItems2: any;
  public maxSize2 = 10;
  minDate: any;
  selectedStatusName: any;
  TYS: any[];
  selectedStateId: string;
  DDDE: number;
  mml: string;
  public now: Date = new Date();
  isLoadingtour: boolean;
  totalItem: any;
  totalItems: any;
  show: boolean;
  buttonName: string;
  noReservations = false;
  @ViewChild(MatSort)
  sort: MatSort;
  startdate: string;

  dateObject = new Date();
  date =
    this.dateObject.getFullYear() +
    '-' +
    (this.dateObject.getMonth() + 1) +
    '-' +
    this.dateObject.getDate();
  constructor(
    private tourListService: TourListService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.isLoadingtour = true;
    //   console.log("SZ",(this.now.toISOString().substr(0,10)  ))  ;
    this.tourListService
      .getTourData(this.res_from, this.res_to)
      .subscribe((data: any) => {
        this.isLoading = false;
        this.allinstights = [data.insights];
      });
    this.tourListService.loadTour().subscribe((tour: any) => {
      this.isLoadingtour = true;
      this.tours = tour.tours;
      this.isLoadingtour = false;
      //     console.log("tour data", this.tours);
    });
    this.getTourResrvation();
  }
  //   format date calanader
  setDate(date) {
    const dateISO = new Date(date);
    if (dateISO.getMonth() + 1 >= 9) {
      this.res_from =
        dateISO.getFullYear() +
        '-' +
        (dateISO.getMonth() + 1) +
        '-' +
        dateISO.getDate();
      //     console.log("apapppapap ",this.res_to)
    } else {
      this.res_from =
        dateISO.getFullYear() +
        '-' +
        '0' +
        (dateISO.getMonth() + 1) +
        '-' +
        dateISO.getDate();
    }
    //     console.log("apapppapap ",this.res_to)
  }
  setDate2(date) {
    const dateISO = new Date(date);
    if (dateISO.getMonth() + 1 >= 9) {
      this.res_to =
        dateISO.getFullYear() +
        '-' +
        (dateISO.getMonth() + 1) +
        '-' +
        dateISO.getDate();
      //     console.log("apapppapap ",this.res_to)
    } else {
      this.res_to =
        dateISO.getFullYear() +
        '-' +
        '0' +
        (dateISO.getMonth() + 1) +
        '-' +
        dateISO.getDate();
    }
    //     console.log("apapppapap ",this.res_to)
  }
  //   submmit date in insigths
  SubmitDate() {
    this.isLoading = true;
    this.tourListService
      .getTourData(this.res_from, this.res_to)
      .subscribe((data: any) => {
        this.allinstights = [data.insights];
        this.isLoading = false;
      });
  }
  //   get tour date  and pacakages
  lestDateTrip() {
    this.tourListService.getDate(this.selectedTourId).subscribe((Date: any) => {
      this.DataF = Date.trips.slots;
      this.times = Date.trips.packages;
      this.minDate = this.DataF;
      this.isLoading = false;

      console.log('tour dates', Date);
      //    console.log("tour calander", this.DataF);
    });
  }
  tripDateChange() {
    //    console.log("Tour ID", this.selectedTourId);
    this.lestDateTrip();
  }
  setDate6(date) {
    const dateISO = new Date(date);
    this.startdate =
      dateISO.getFullYear() +
      '-' +
      (dateISO.getMonth() + 1) +
      '-' +
      dateISO.getDate();
    console.log('apapppapap ', this.startdate);
    this.filterObject.trip_date = this.startdate;
    console.log(this.selectedTourDate, 's 0', this.startdate, '  p l ');
  }
  myFilter = (d: Date): boolean => {
    const showDates = this.DataF.filter(function (el) {
      return el !== '';
    });
    return (
      showDates.findIndex(
        showDate => d.toDateString() === new Date(showDate).toDateString()
      ) >= 0
    );
  };
  //   get time slots
  listTimeSlots() {
    const data = {
      tourid: this.selectedTourId,
      date: this.startdate
    };
    this.tourListService.getTimeSlots(data).subscribe((Time: any) => {
      this.TimeF = Time.timeSlots;
      this.isLoading = false;

      //      console.log("tour dates", this.TimeF);
    });
  }
  //   get tour reservations
  getTourResrvation() {
    this.pageNum = 1;
    this.tourListService
      .getReservationTour({
        page: this.pageNum,
        page_size: '10'
        // sort_type: 'asc'
      })
      .subscribe(reservation => {
        console.log('AZ', reservation);
        this.reservations = reservation['reservations'];
        this.totalItems = reservation['total_pages'];
        this.pageNum = reservation['current_page'];
      });
  }
  //   open dialog for get tour reservation details
  openDialog(tourid): void {
    this.isLoading = true;
    this.tourListService
      .getTourReservationDetails(tourid)
      .subscribe((Details: any) => {
        this.reservationDetails = Details.reservations;
        this.isLoading = false;
        //      console.log("z safaD", this.reservationDetails);
        //     console.log("toud id", tourid);
        this.dialog.open(TourReservationDetailsComponent, {
          width: '40vm',
          data: this.reservationDetails
        });
      });
  }
  //   open dialoge create
  openCreate(): void {
    //     console.log("tour data", this.tours);
    this.dialog.open(CreateReservationTourComponent, {
      width: '40vw',
      height: '80%',
      data: this.tours
    });
  }

  //   set filter tour
  settour(tour) {
    this.filterObject.tour_id = tour;
    //    console.log("ZZZZ",this.filterObject.tour_id)
  }
  //   set filter date
  // setdate(date) {
  // }
  //   set filter time
  settime(time) {
    this.filterObject.trip_time = time;
  }
  //   set filter through
  setThrough(through) {
    this.filterObject.through_id = through;
  }
  //   set filter package
  setPackage(pack) {
    this.filterObject.package_id = pack;
  }
  //   set filter state
  setstauts(state) {
    this.filterObject.state_id = state;
  }
  setPastUP(PastUP) {
    this.filterObject.status = PastUP;
  }
  setMobile() {
    console.log('ADA', this.filterObject.mobile_number);
  }
  //   search button
  search() {
    if (this.mobile !== '' || this.mobile !== undefined) {
      this.filterObject.mobile_number = this.mobile;
    }
    if (this.filterObject.mobile_number === undefined) {
      this.filterObject.mobile_number = '';
    }
    if (this.pageNum !== '1') {
      this.filterObject.page = '1';
      this.filterObject.state_id = '';
      this.filterObject.status = '';
      this.filterObject.sort_type = '';
      this.filterObject.wf_status = '';
      this.filterObject.sort_column = '';
      this.tourListService
        .getReservationTour(this.filterObject)
        .subscribe(reservation => {
          console.log('Serach parmater', reservation);
          this.reservations = reservation['reservations'];
          this.pageNum = reservation['current_page'];
          this.pageSize = 10;
          this.totalItems = reservation['total_pages'];
          if (this.reservations.length === 0) {
            this.noReservations = true;
          } else if (this.reservations.length > 0) {
            this.noReservations = false;
          }
        });
    } else {
      console.log(this.filterObject, ' filter');
      this.tourListService
        .getReservationTour(this.filterObject)
        .subscribe(reservation => {
          console.log('Serach parmater', reservation);
          this.reservations = reservation['reservations'];
          this.pageNum = reservation['current_page'];
          this.pageSize = 10;
          this.totalItems = reservation['total_pages'];
          if (this.reservations.length === 0) {
            this.noReservations = true;
          } else if (this.reservations.length > 0) {
            this.noReservations = false;
          }
        });
    }
  }
  //   restsearh button
  resetSearch() {
    this.noReservations = false;
    this.filterObject = {
      page: '1',
      tour_id: '',
      status: '',
      through_id: '',
      page_size: '10',
      trip_date: '',
      trip_time: '',
      package_id: '',
      state_id: '',
      mobile_number: '',
      wf_status: '',
      sort_column: '',
      sort_type: ''
    };

    this.tourListService
      .getReservationTour({
        page: '1',
        page_size: '10'
      })
      .subscribe(reservation => {
        this.reservations = reservation['reservations'];
        this.pageNum = 1;
        this.totalItems = reservation['total_pages'];
        this.clearData();
      });
    this.pageNum = 1;
  }
  //   clear all objects
  clearData() {
    this.selectChannelId = '';
    this.selectedresvPackage = '';
    this.selectedStatusName = '';
    this.startdate = '';
    this.selectedTourId = '';
    this.mobile = '';
    this.selectedStateId = '';
    this.tripTime = '';
    this.startdate = '';
    this.selectedTourDate = '';
  }
  //   update actions button
  updateReservationConfirmation(flag, reservation) {
    this.isLoading = true;
    this.reservations = reservation;
    const data = {
      reservationId: reservation.res_list_guid,
      action_id: ''
    };
    if (flag === 'confirm') {
      data.action_id = reservation.res_list_actions[0].guid;
    } else if (flag === 'reject') {
      data.action_id = reservation.res_list_actions[1].guid;
    } else if (flag === 'Checkin') {
      data.action_id = reservation.res_list_actions[0].guid;
    } else if (flag === 'Checkout') {
      data.action_id = reservation.res_list_actions[0].guid;
    }
    console.log('Data: ', data);
    this.tourListService
      .updateReservationConfirmation(data)
      .subscribe((updateflag: any) => {
        console.log('are update is', updateflag);
        swal(' Updated', 'Action Is Updated', 'success');
        this.isLoading = false;
        this.search();
      });
  }
  //   quick filter items
  quickSearch(
    status = '',
    wfStatus = '',
    sortColumn = '',
    sort_type = '',
    pastUpcoming = '',
    trip_date = ''
  ) {
    this.isLoading = !this.isLoading;
    this.clearData();
    if (this.filterObject.page !== '1') {
      this.pageNum = '1';
      this.filterObject.tour_id = '';
      this.filterObject.trip_date = '';
      this.filterObject.mobile_number = '';
      this.tourListService
        .getReservationTour({
          page: this.pageNum,
          page_size: '10',
          sort_type: sort_type,
          sort_column: sortColumn,
          state_id: this.statusList[status].id,
          wf_status: wfStatus,
          status: this.timeList[pastUpcoming].name,
          trip_date: trip_date
        })
        .subscribe(reservation => {
          console.log('AZ', reservation);
          this.filterObject.state_id = this.statusList[status].id;
          this.filterObject.status = this.timeList[pastUpcoming].name;
          this.filterObject.sort_type = sort_type;
          this.filterObject.wf_status = wfStatus;
          this.filterObject.sort_column = sortColumn;
          this.filterObject.trip_date = trip_date;

          this.reservations = reservation['reservations'];
          this.pageNum = reservation['current_page'];
          this.totalItems = reservation['total_pages'];
          this.isLoading = !this.isLoading;
          //    console.log(status,"1",wfStatus,"2",sortColumn,"3",sort_type,"4",pastUpcoming,"5")
        });
    } else {
      this.pageNum = '1';
      this.filterObject.tour_id = '';
      this.filterObject.trip_date = '';
      this.filterObject.mobile_number = '';
      this.tourListService
        .getReservationTour({
          page: this.pageNum,
          page_size: '10',
          sort_type: sort_type,
          sort_column: sortColumn,
          state_id: this.statusList[status].id,
          wf_status: wfStatus,
          status: this.timeList[pastUpcoming].name,
          trip_date: trip_date
        })
        .subscribe(reservation => {
          console.log('AZ', reservation);
          this.filterObject.state_id = this.statusList[status].id;
          this.filterObject.status = this.timeList[pastUpcoming].name;
          this.filterObject.sort_type = sort_type;
          this.filterObject.wf_status = wfStatus;
          this.filterObject.sort_column = sortColumn;
          this.filterObject.trip_date = trip_date;

          this.reservations = reservation['reservations'];
          this.pageNum = reservation['current_page'];
          this.totalItems = reservation['total_pages'];
          this.isLoading = !this.isLoading;
          //    console.log(status,"1",wfStatus,"2",sortColumn,"3",sort_type,"4",pastUpcoming,"5")
        });
    }
  }
  //   paganations
  pageChange(event) {
    this.pageNum = event;
    this.filterObject.page = this.pageNum;
    console.log(event);
    this.isLoading = true;
    console.log(this.filterObject, 'data before sent');
    this.tourListService
      .getReservationTour(this.filterObject)
      .subscribe(reservation => {
        console.log('wz', reservation);
        this.reservations = reservation['reservations'];
        //    this.totalItems = reservation['total_pages'];
        //    this.pageNum = reservation['current_page'];
        this.isLoading = false;
      });
  }

  geustProfile(reservationId: string) {
    this.router.navigate(['/guestList', reservationId]);
  }
}
