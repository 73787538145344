import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HttpService } from '../../../../_services';
import { environment } from './../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ToursService, UploadimgService } from '../../../../_services';
import { DatePipe } from '@angular/common';
import { MatStepper } from '@angular/material';
class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
	selector: 'app-c-e-create-event',
	templateUrl: './c-e-create-event.component.html',
	styleUrls: ['./c-e-create-event.component.scss']
})
export class CECreateEventComponent implements OnInit {
	// http://bit.ly/2PPWshM observe to complete steps
	createdEventId: any;
  goto: any;

	// constructor() {}

	// ngOnInit() {}

	// getCreatedEventId($event , stepper:MatStepper) {
  //   console.log($event , 'sendis')
  //   this.createdEventId = $event;
  //   this.goto = stepper.next();
  // }

  access_token = JSON.parse(localStorage.getItem('currentUser'))[
		'access_token'
	];
	logo: any;
	gallery: any[] = [];
	showIndex;
	workflows: any[] = [];
	createEventFrom: FormGroup;
	newschedule: any;
	eventTypes: any;
	newscheduleTimes: FormArray;
	eventId: any;
	// @Output() createdEventId = new EventEmitter();
	currentShow = {};
	schedule: any[] = [];
	show = false;
  img_o: FileList;
  pipe = new DatePipe('en-US');
	imageSrc: string | ArrayBuffer;
  selectedFile: ImageSnippet;
  image_name: string;
  selectedgallary : ImageSnippet;
  gallarylist : File;
  gallaryfile: File;
  image_nameC : string;
  selectedFileC: ImageSnippet;
  coverfile: File;

	constructor(
		private _http: HttpService,
		private _fb: FormBuilder,
		private _toaser: ToastrService,
		private uploadimgService: UploadimgService
	) {
		this.gallery = [];
	}

	ngOnInit() {
		this.createEventFrom = this._fb.group({
			name: ['', Validators.compose([Validators.required])],
			description: ['', Validators.compose([Validators.required])],
			age_from: ['', Validators.compose([Validators.required])],
			age_to: ['', Validators.compose([Validators.required])],
			dressCode_id: ['', Validators.compose([Validators.required])],
			workflow_id: ['', Validators.compose([Validators.required])],
			schedule: this._fb.array([this.createNewSepecificShow()])
		});
		// get Workflows
		this._http
			.getData(`${environment.apiEndPoint}/vendor/listworkflows/`, {
				client_id: environment.clientId,
				client_secret: environment.clientSecret,
				access_token: this.access_token
			})
			.pipe(
				map(resp => {
					const workflows = resp['workflows'];
					this.workflows = [];
					for (let i = 0; i < workflows.length; i++) {
						this.workflows.push({
							name: workflows[i]['name'],
							guid: workflows[i]['guid']
						});
					}
				})
			)
			.subscribe();
		this._http
			.getData(`${environment.apiEndPoint}/vendor/concert/event/type`)
			.subscribe(resp => {
				if (resp['code'] === '10000') {
					this.eventTypes = resp['types'];
				}
			});
	}
	createNewSepecificShow(): FormGroup {
		return this._fb.group({
			date: ['', Validators.compose([Validators.required])],
			dateto: ['', Validators.compose([Validators.required])],
			shows: this._fb.array([this.createNewSepecificShowTime()])
		});
	}
	createNewSepecificShowTime(): FormGroup {
		return this._fb.group({
			from_time: ['', Validators.compose([Validators.required])],
			to_time: ['', Validators.compose([Validators.required])]
		});
	}

	addNewSepecificShow(): void {
		this.newschedule = this.createEventFrom.get('schedule') as FormArray;
		this.newschedule.push(this.createNewSepecificShow());
	}
	deleteSpecificShow($index) {
		const shows = this.createEventFrom.controls['schedule']['controls'];
		if (shows.length !== 1) {
			return shows.splice($index, 1);
		}
		return this._toaser.warning('Must be at least one show', 'Warn');
	}
	addNewSepecificShowTimes($index) {
		const schedule = this.createEventFrom.get('schedule') as FormArray;
		this.newscheduleTimes = schedule.controls[$index].get('shows') as FormArray;
		this.newscheduleTimes.push(this.createNewSepecificShowTime());
		if (this.newscheduleTimes.controls.length >= 5) {
			this._toaser.warning('Must be at least one cant be more than 5', 'Warn');
			this.show = true;
		} else {
			this.show = false;
		}
	}
	deleteSpecificShowTimeSlot($index, $innerIndex) {
		this.show = false;
		const schedule = this.createEventFrom.get('schedule') as FormArray;
		const currentShow = schedule.controls[$index].get('shows') as FormArray;
		if (currentShow.controls.length !== 1) {
			return currentShow.controls.splice($innerIndex, 1);
		}
		return this._toaser.warning('Must be at least one show', 'Warn');
	}

	addImageToGallery(event) {
		if (
			event.target['files'] &&
			event.target['files'][0] &&
			event !== undefined
		) {
			const file = event.target['files'][0];
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = onload => {
				this.gallery.push(onload.target['result']);
			};
		}
	}
	changeImage(event) {
		if (
			event.target['files'] &&
			event.target['files'][0] &&
			event !== undefined
		) {
			const file = event.target['files'][0];
			const reader = new FileReader();
			reader.readAsDataURL(file); // read file as data url
			reader.onload = onload => {
				// called once readAsDataURL is completed
				this.logo = onload.target['result'];
			};
		}
	}

	get f_creatEvent() {
		return this.createEventFrom.controls;
	}

	adddate2() {
		console.log('aaaa');
		this.f_creatEvent['schedule']['controls'].forEach(show => {
			const dateISO = show.value['date'];
			const dateISO2 = show.value['dateto'];

			const date =
				dateISO.getFullYear() +
				'-' +
				(dateISO.getMonth() + 1) +
				'-' +
				dateISO.getDate();

			const date2 =
				dateISO2.getFullYear() +
				'-' +
				(dateISO2.getMonth() + 1) +
				'-' +
				dateISO2.getDate();
			this.currentShow = {
				from_date: date,
				to_date: date2,
				shows: show.value['shows']
			};
			this.schedule.push(this.currentShow);
		});
	}
	adddate() {
    console.log('fsafasfafas');

		this.f_creatEvent['schedule']['controls'].forEach(show => {
      console.log(show.value['shows'] , 'akamafa')

      const dateISO = show.value['date'];
      if(dateISO=== "" ||dateISO===undefined ||show.value['shows'][0]['from_time']===''){
        this._toaser.error('Please Fill Date For Event Correct' , 'error')
      }
      else{

			const date =
				dateISO.getFullYear() +
				'-' +
				(dateISO.getMonth() + 1) +
				'-' +
        dateISO.getDate();

			this.currentShow = {
				date: date,
				shows: show.value['shows']
			};
      this.schedule.push(this.currentShow);
    }
		});
	}
	selectFile(imageInput: any) {
		// this.img_o = event.target.files;
    // console.log(this.img_o[0].name);
    // const now = Date.now();
    // const myFormattedDate = this.pipe.transform(now, 'short');
    //  console.log(myFormattedDate)

		//   // var k = this.img_o[0].name;
		//   // var s = k + myFormattedDate + '.jpg';
		//   // console.log(s, "lfa")
		// if (event.target.files && event.target.files[0]) {
		// 	const file = event.target.files[0];
		// 	const reader = new FileReader();
		// 	reader.onload = e => (this.imageSrc = reader.result);
		// 	reader.readAsDataURL(file);
		// 	const path = 'concert/event/resimage';
    //   let file1 = this.img_o.item(0);
    //   console.log(file1 , 's')
		// 	console.log('POP', file1);
		// 	// this.uploadimgService.uploadFile(file1, path);
    // }

    const file: File = imageInput.files[0];
    console.log(file)
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);

    console.log(
      this.selectedFile)
     var cardName = Date.now();
     console.log(this.selectedFile , 'fafas')
    var sazvz = this.dataURLtoFile(this.selectedFile.src, cardName + '.jpg');
    this.image_name= sazvz.name
    console.log(sazvz.name ,'gfafafa');
    const path = 'concert/event/resimage';
      // let file1 = this.img_o.item(0);
      // console.log(file1 , 's')
			// console.log('POP', file1);
			this.uploadimgService.uploadFile(sazvz, path);
    });


    reader.readAsDataURL(file);
  }
  dataURLtoFile(dataurl, filename) {
    console.log("data in image", dataurl, filename);
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/ )[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

selectgalary(imageInput : any) {
  // this.galleryImages = event.target.files;
  // console.log(this.galleryImages, 'gala');
  // for (let i = 0; i < this.galleryImages.length; i++) {
  // 	//   this.g1 = this.galleryImages[i].name;
  // 	//   this.g1 = Date.now();
  // 	// this.file = this.dataURLtoFile(
  // 	// 	this.galleryImages[i].name,
  // 	// 	Date.now() + '_' + i + '.jpg'
  // 	// );

  // 	this.gallery.push(this.galleryImages.item(i).name);
  // }
  // console.log(this.gallery, 's');
  // if (event.target.files && event.target.files[0]) {
  // 	const file = event.target.files[0];
  // 	const reader = new FileReader();
  // 	reader.onload = e => (this.imageSrcs = reader.result);
  // 	reader.readAsDataURL(file);
  // }
  for (var i = 0; i < imageInput.files.length; i++) {
    console.log(i)
  const file: File = imageInput.files[i];
  console.log(file  , 's')
  const reader = new FileReader();
  reader.addEventListener('load', (event: any) => {
    this.selectedgallary = new ImageSnippet(event.target.result, file);
    console.log(this.selectedgallary)
   var cardName = Date.now();
  //  console.log(this.selectedgallary , 'fafas')
  this.gallaryfile = this.dataURLtoFile(this.selectedgallary.src, cardName + '_' + i + '.jpg');
  this.gallery.push(this.gallaryfile.name );

  // // this.image_nameC= this.coverfile.name
  console.log(this.gallery ,'gfafafa');
  const path = 'concert/event/gallery';
    // let file1 = this.img_o.item(0);
    // console.log(file1 , 's')
    // console.log('POP', file1);
    this.uploadimgService.uploadFile(this.gallaryfile, path);
  });
  reader.readAsDataURL(file);

}
}


selectCover(imageInput : any) {
  // this.img_c = event.target.files;
  // //   this.img_2 = this.img_c[0].name;
  // //   this.img_2 = Date.now() + '.jpg';
  // console.log(this.img_c[0].name, 'Wfa');
  // if (event.target.files && event.target.files[0]) {
  // 	const file = event.target.files[0];
  // 	const reader = new FileReader();
  // 	reader.onload = e => (this.imageSrcc = reader.result);
  // 	reader.readAsDataURL(file);
  // }
  const file: File = imageInput.files[0];
  console.log(file)
  const reader = new FileReader();

  reader.addEventListener('load', (event: any) => {
    this.selectedFileC = new ImageSnippet(event.target.result, file);
  console.log(this.selectedFileC)
   var cardName = Date.now();
   console.log(this.selectedFileC , 'fafas')
  this.coverfile = this.dataURLtoFile(this.selectedFileC.src, cardName + '.jpg');
  this.image_nameC= this.coverfile.name
  console.log(this.coverfile.name ,'gfafafa');
  const path = 'concert/event/card';
    // let file1 = this.img_o.item(0);
    // console.log(file1 , 's')
  	// console.log('POP', file1);
  	this.uploadimgService.uploadFile(this.coverfile, path);
  });
  reader.readAsDataURL(file);
}
	creatEvent(stepper: MatStepper) {
    // if(this.image_name === undefined){
    //   this._toaser.error('Please Add Image map For Event')
    // }else if (this.schedule.length === 0){
    //   this._toaser.error('Please Add Event Date')
    // } else{
		const payload = {
			name: this.f_creatEvent['name'].value,
			description: this.f_creatEvent['description'].value,
			venue_id: 'dd0a3f99f3c64733b2b557ff3249c238',
			dressCode_id: this.f_creatEvent['dressCode_id'].value,
			age_from: this.f_creatEvent['age_from'].value,
			age_to: this.f_creatEvent['age_to'].value,
			card: this.image_nameC,
			card_small: 'cardSmall.jpg',
			gallery: this.gallery,
			workflow_id: this.f_creatEvent['workflow_id'].value,
			eventType_id: '0104eac8-d622-4ad8-b63c-fcaa2d107731',
			res_image: this.image_name,
			schedule: this.schedule
		};
		console.log(payload, 'fafsafasfasfas');

		// this._http
		// 	.postData(`${environment.apiEndPoint}/vendor/concert/event`, {}, payload)
		// 	.subscribe(resp => {
		// 		// { "code": "10000", "detail": "Success.", "event": { "id": "4c4956ab-6aa5-43a8-95cb-6f75be8e3dfc" } }
		// 		if (resp['code'] === '10000') {
		// 			this._toaser.success(
		// 				`${payload['name']} Event was Created`,
		// 				'new Event'
    //       );
		// 			this.eventId = resp['event']['id'];
    //       // this.createdEventId.emit(this.eventId);
    //       // localStorage.setItem(this.eventId , 'eventid')
    //       localStorage.setItem('eventid' , this.eventId)
    //       stepper.next();
		// 			console.log(this.eventId, 'fafasfn');
    //     }
    //   },
    //   error => {
    //         this._toaser.error('Error' ,'Try Agian')
    //   		  console.log(error , 'afafmaklfanf')
		// 	});
  // }
}
}
