import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { GetProfileService } from '../../_services';
@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
	error = '';
	loading = false;
	returnUrl: string;
	userProfile: any;
	profileForm: FormGroup;
	isLoginError = false;
	Fn: any;
	Ln: any;
	em: any;
	constructor(
		private getProfile: GetProfileService,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		this.getProfile.userprofile().subscribe((data: any) => {
			// console.log(data , 'd')
			this.userProfile = data.user;
			// this.Fn = data.user.first_name;
			// this.Ln = data.user.last_name;
			// this.em = data.user.email;
		});
	}
	onSubmit() {
		this.getProfile.EditUserPrfoile(this.userProfile).subscribe(
			data => {
				if (data.code === '10000') {
					Swal('Done', 'Data Are Updated', 'success');
				}
			},
			error => {
				Swal('Oops...', 'Error Data!', 'error');
			}
		);
	}
}
