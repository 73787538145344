import { NgModule } from '@angular/core';
import {
	MatAutocompleteModule,
	MatBadgeModule,
	MatButtonModule,
	MatCardModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDatepickerModule,
	MatDialogModule,
	MatDividerModule,
	MatExpansionModule,
	MatFormFieldModule,
	MatGridListModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatMenuModule,
	MatNativeDateModule,
	MatPaginatorModule,
	MatSelectModule,
	MatSidenavModule,
	MatSlideToggleModule,
	MatProgressBarModule,
	MatSortModule,
	MatTableModule,
	MatToolbarModule,
	MatTooltipModule,
	MatStepperModule,
	MatRadioModule,
	MatTabsModule,
	MatButtonToggleModule,
	MatProgressSpinnerModule,
	MatSnackBarModule
} from '@angular/material';

import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

@NgModule({
	imports: [
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatIconModule,
		MatSelectModule,
		MatInputModule,
		MatListModule,
		MatSidenavModule,
		MatToolbarModule,
		MatMenuModule,
		MatFormFieldModule,
		MatTableModule,
		MatPaginatorModule,
		MatAutocompleteModule,
		MatProgressBarModule,
		MatSortModule,
		MatDatepickerModule,
		MatExpansionModule,
		MatDividerModule,
		MatDialogModule,
		MatNativeDateModule,
		MatBadgeModule,
		MatExpansionModule,
		MatProgressSpinnerModule,
		MatSlideToggleModule,
		MatExpansionModule,
		MatSlideToggleModule,
		MatChipsModule,
		MatGridListModule,
		MatTooltipModule,
		MatStepperModule,
		MatRadioModule,
		MatTabsModule,
		MatButtonToggleModule,
		MatSnackBarModule
	],
	exports: [
		CdkTableModule,
		CdkTreeModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatIconModule,
		MatSelectModule,
		MatInputModule,
		MatListModule,
		MatSidenavModule,
		MatToolbarModule,
		MatMenuModule,
		MatFormFieldModule,
		MatProgressBarModule,
		MatTableModule,
		MatPaginatorModule,
		MatAutocompleteModule,
		MatSortModule,
		MatDatepickerModule,
		MatProgressSpinnerModule,
		MatExpansionModule,
		MatDividerModule,
		MatDialogModule,
		MatNativeDateModule,
		MatBadgeModule,
		MatExpansionModule,
		MatSlideToggleModule,
		MatExpansionModule,
		MatSlideToggleModule,
		MatChipsModule,
		MatGridListModule,
		MatTooltipModule,
		MatStepperModule,
		MatRadioModule,
		MatTabsModule,
		MatButtonToggleModule,
		MatSnackBarModule
	]
})
export class AppMaterialModule {}
