import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import {  HttpService } from '../../../_services';
import { environment } from './../../../../environments/environment';
import { SportsListService } from './../../../_services/sports.service';
import { ErrorHandlerService } from './../../../_services/error-handler.service';


@Component({
	selector: 'app-show-date',
	templateUrl: 'show-datetime.html',
	styleUrls: ['./attendence.component.scss']
})

//  /=/=/=/=/=/=/=/  Date Time Class Copmponent (Popup)    /=/=/=/=/=/=/=/  //

export class ShowdatetimeComponent implements OnInit {
 DateTimeObject = {
  res_id : ''
 }
 TimeData : any;
  constructor(	@Inject(MAT_DIALOG_DATA) public data: any,
   public dialogRef: MatDialogRef<ShowdatetimeComponent>,
   private router: Router, private Sports: SportsListService, private _http: HttpService,
   private errorHandling: ErrorHandlerService){
     console.log(this.data , 'asdasdasd123');
   }
   ngOnInit() {
   this.DateTimeObject = {
      res_id : this.data
     }
    this.Sports.getCourseDateTime(this.data).subscribe(
      create => {
        this.TimeData = create['attendance'];
        console.log(this.TimeData);
      },
      error => {
        console.log(error  , ' ');
        const errorStatus = error.status;
        const errorDetails = error.error.detail;
        if (error) {
          swal(errorDetails, 'Oops...', 'error');
        }
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
	selector: 'app-attendence-ship',
	templateUrl: 'create-attendence.html',
	styleUrls: ['./attendence.component.scss']
})

export class CreateattendenceComponent implements OnInit {
  mobile;
  inviteFormGroup: FormGroup;
  customer_name;
  phoneNum : number ;
  checkNumber = false;
  UserData : any;
  disableInput = false;
  addInvObject = {
    "subsc" : "",
    "first_name": "",
    "last_name": "",
    "phone": ""
  }
  checkPhoneExist = {
    "subsc_Id" : '',
    "mobile" : ''
  }
	constructor(	private _fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any ,
    public dialogRef: MatDialogRef<CreateattendenceComponent>,
    private router: Router, private Sports: SportsListService, private _http: HttpService,
    private errorHandling: ErrorHandlerService
	) {
    console.log(data)
  }

	ngOnInit() {
    this.inviteFormGroup = this._fb.group({
      First_Name: [''],
      Second_Name: [''],
      Phone: [
				'',
				Validators.compose([
					Validators.pattern(/^-?([0-9]\d*)?$/),
					Validators.maxLength(15)
				])
			],
    });
  }

  searchPhoneExists(phone){
    console.log(phone);
    if (phone === undefined ) {
      this.checkNumber = false;
       swal('Please Enter Phone Number', 'Ops', 'warning');
       this.inviteFormGroup.controls.First_Name.setValue('');
       this.inviteFormGroup.controls.Second_Name.setValue('');
       this.inviteFormGroup.controls.First_Name.disable();
       this.inviteFormGroup.controls.Second_Name.disable();
        }
      else if ( phone.length < 11) {
      console.log(phone);
        this.checkNumber = false;
        swal('Please Enter  11 number', 'error', 'error');
        this.inviteFormGroup.controls.First_Name.setValue('');
        this.inviteFormGroup.controls.Second_Name.setValue('');
        this.inviteFormGroup.controls.First_Name.disable();
        this.inviteFormGroup.controls.Second_Name.disable();      }
      else {
       this.checkNumber = true;
       this.checkPhoneExist.mobile = phone;
       this.Sports.checkNumberExist(this.checkPhoneExist).subscribe(phones => {
       this.UserData =	phones['Invitee'];
       this.inviteFormGroup.controls.First_Name.setValue(this.UserData.firstName);
       this.inviteFormGroup.controls.Second_Name.setValue(this.UserData.lastName);
       this.inviteFormGroup.controls.First_Name.disable();
       this.inviteFormGroup.controls.Second_Name.disable();
			}, error => {
        console.log(error  , ' ');
        const errorStatus = error.status;
        const errorDetails = error.error.detail;
        this.inviteFormGroup.controls.First_Name.setValue('');
        this.inviteFormGroup.controls.Second_Name.setValue('');
        swal(errorDetails, 'Oops...', 'error');
        this.inviteFormGroup.controls.First_Name.enable();
        this.inviteFormGroup.controls.Second_Name.enable();
      });

     }
     console.log( this.checkPhoneExist);
  }


	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	onNoClick(): void {
		this.dialogRef.close();
	}
	onSubmit(f) {
		if (f.valid){

      this.addInvObject = {
        "subsc" : this.data ,
        "first_name" : this.inviteFormGroup.get('First_Name').value ,
        "last_name" : this.inviteFormGroup.get('Second_Name').value ,
        "phone" : this.inviteFormGroup.get('Phone').value
      }
      console.log(this.addInvObject);
      this.Sports.addInvit(this.addInvObject).subscribe(
        create => {
          if ( create.code === '10000' ) {
            swal(' Done', 'Done', 'success');
          }
        },
        error => {
          console.log(error  , ' ');
          const errorStatus = error.status;
          const errorDetails = error.error.detail;
          if (error) {
            swal(errorDetails, 'Oops...', 'error');
          }
        }
      );
      f.reset();   // reset the reactive form after confirm
    }
    else {
      swal('Please Enter All Data', 'error', 'error');
    }

	}
}



// /=/=/=/=/=/=/=/ Show Attendance Class ( Popup )  /=/=/=/=/=/=/ //


@Component({
	selector: 'app-show-attancde-ship',
	templateUrl: 'show-attendence.html',
	styleUrls: ['./attendence.component.scss']
})
export class ShowattendenceComponent implements OnInit {

  datass: any;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any ,
    public dialogRef: MatDialogRef<ShowattendenceComponent>,
    private router: Router, private Sports: SportsListService, private _http: HttpService
  ) {}

	ngOnInit() {

    console.log(this.data);

    this.Sports.getInvitData(this.data).subscribe(
      create => {
        this.datass = create['Invitees'] ;
      console.log(this.datass);
      },
      error => {
        console.log(error  , ' ');
        const errorStatus = error.status;
        const errorDetails = error.error.detail;
        swal(errorDetails, 'error', 'error');
      }
    );
  }
	onNoClick(): void {
		this.dialogRef.close();
  }
}


// /=/=/=/=/=/=/=/ Attendance Class Component  /=/=/=/=/=/=/ //
@Component({
	selector: 'app-attendence',
	templateUrl: './attendence.component.html',
	styleUrls: ['./attendence.component.scss']
})

export class AttendenceComponent implements OnInit {
	name: string;
  showData = false;
  showTable = false;
  PhoneNum : number;
  PhoneFilter: any;
  StudentsList: any = [];
  StudentsCount: number;
  addInvitationGuid = '';
  showInvitationGuid = '';

  filterObject = {
		courseId: '',
		branchId: '',
    trainerId: '',
    classId: '',
    durationId: '',
    phone: '',
    isActive: '',
    page: '',
  };
  constructor(public dialog: MatDialog,
    private router: Router, private Sports: SportsListService, private _http: HttpService
    ) {}

	ngOnInit() {
  }


  numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	showdata() {
    if ( this.PhoneFilter === undefined ){
      swal('Please Enter Phone Number', 'warning', 'warning');
    }
    else if ( this.PhoneFilter.length < 11 ){
      swal('Please Enter Phone Number with 11 Number ', 'warning', 'warning');
    }
    else{
      this.filterObject.phone = this.PhoneFilter;
      console.log(this.PhoneFilter, 'ssssssss');
      this.Sports.ReservationList(this.filterObject).subscribe(course => {
		   	this.StudentsList = course['reservations'];
       // this.StudentsCount = this.StudentsList.length;
       console.log(this.StudentsList)

       if (this.StudentsList.length === 0) {
        swal('There is no Data for This Phone Number', 'warning', 'warning');
        this.showData = false;
       }
       else {
        this.showData = true;
       }
		});

    }


	}
	addInvitation(x): String {
    //     console.log("tour data", this.tours);
    this.addInvitationGuid = x;
    console.log(this.addInvitationGuid , '123');
		this.dialog.open(CreateattendenceComponent, {
			width: '40vw',
			height: '60%',
			data: x
    });
    return this.addInvitationGuid;
	}

	showAttan(x): String {
    //     console.log("tour data", this.tours);
    this.showInvitationGuid = x;
		this.dialog.open(ShowattendenceComponent, {
			width: '40vw',
			height: '80%',
			data: x
    });
    return this.showInvitationGuid;
  }
  getPhone(Phone){
    const charCode = Phone.which ? Phone.which : Phone.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
  }

  checkAttendance(UserId){
    const ss = {
      res :  UserId
    }
    this.Sports.markAttendance(ss).subscribe(
      create => {
        console.log(create, 'sata');
        if( create.code === '10000'){
          swal('Checked In', 'Done', 'success');
        }
      },
      error => {
        console.log(error  , ' ')
        const errorStatus = error.status;
        const errorDetails = error.error.detail;
        switch (errorStatus) {
          case '10001':
            swal('Please provide missing parameter(s)', 'Oops...', 'error');
            break;
          case '11047':
            swal('Time Is left Cant Selected It', 'Oops...!', 'error');
            break;
            case 400 :
              swal(errorDetails, 'Oops...!', 'error');
            break;
        }
      }
    );
  }

  showDataTAble(id) : void{
    this.dialog.open(ShowdatetimeComponent, {
			width: '40vw',
      height: '50%',
      data : id

  })
}

}
