import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private _http: HttpClient) { }

  setParameters(queryParameters) {
    let params = new HttpParams();
    for (const key in queryParameters) {
      if (queryParameters.hasOwnProperty(key) && queryParameters[key] !== '') {
        if (!params.has(key)) {
          params = params.set(key, queryParameters[key]);
        }
      }
    }
    return params;
  }
  /**
   * @param  {string} url                  link of target end point
   * @param  {object} queryParameters      Query that want to parse in the url
   */
  getData(url: string, queryParameters?: object, header?): Observable<any> {
    const params = this.setParameters(queryParameters);
    return this._http.get(`${url}?${params.toString()}`, header);
  }

  /**
   * @param  {string} url                  link of target end point
   * @param  {object} queryParameters      Query that want to parse in the url
   * @param  {any} body?                   payload that you want to send and it's optional
   */
  postData(url: string, queryParameters?: object, body?: any) {
    const params = this.setParameters(queryParameters);
    return this._http.post(url, body).pipe(
      map(data => {
        return data;
      })
    );
  }

  deleteData(url: string, queryParameters?: object, body?: any) {
    const params = this.setParameters(queryParameters);
    console.log(params)

    return this._http.delete(url, body).pipe(
      map(data => {
        console.log(data)
        return data;
      })
    );
  }
}
