import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { MouseEvent } from '@agm/core';
import { SportsListService, UploadimgService } from '../../../../_services';
import {
	FormBuilder,
	FormGroup,
	Validators,
	FormControl,
	FormArray
} from '@angular/forms';
@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
  id: string;
  course: any;
  selectbranchname :any ;
  branchasss: any ;
  branch_details: any;
  selecttrainner : any ;
  trainnersdata: any;
  edit = true;
  fitclasses: any;
  select_classes : any;
  ts_FormGroup: FormGroup;
	currentWeekDays = [];
  allWeekDays = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
  ];
  remainWeekDays = [];
	newTimeSlot: any;

  fitclass_details: any;
  changedDaySelectedTimeSlot: any;

  get timeslots() {
		return this.ts_FormGroup.get('timeslots') as FormArray;
	}
  constructor(
    private route: ActivatedRoute,
		private sports: SportsListService,
    private uploadimgService: UploadimgService,
    private _formBuilder: FormBuilder,

  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.sports.getEventDetails(this.id).subscribe((data: any) => {
      console.log(data)
      this.course = data.course;
      this.branchasss = this.course['branches']
      this.fitclasses = this.course['fitClasses']

    });
    this.ts_FormGroup = this._formBuilder.group({
			timeslots: this._formBuilder.array([])
		})

  }
  editada() {
		this.edit = !this.edit;
	}
  branchname(id){
    // this.branch_details = [];
    console.log(id)
    this.selectbranchname = id;
    for( let o = 0  ;  o < this.branchasss.length  ; o++){
        if ( this.branchasss[o].guid === id  ){
          this.branch_details = this.branchasss[o]
          console.log(this.branch_details , 'datda for this barnch')
        }
    }
  }
  trainnername(id){
    console.log(id)
    for( let o = 0  ;  o < this.branch_details['trainers'].length  ; o++){
      if ( this.branch_details['trainers'][o].guid === id  ){
        this.trainnersdata = this.branch_details['trainers'][o]
        console.log(this.trainnersdata , 'datda for this barnch')
      }
  }
  }
  selectclass(id){
    this.fitclass_details = [];
    this.ts_FormGroup['controls']['timeslots']['controls'] = []
    this.select_classes = id;
    for( let o = 0  ;  o < this.fitclasses.length  ; o++){
        if ( this.fitclasses[o].guid === id  ){
          this.fitclass_details = this.fitclasses[o]
          console.log(this.fitclass_details , 'datda for this class')
          const _slots = this.fitclass_details['schedule'];
					const _day_names = [];
					// const timeslotsFGArray = this.ts_FormGroup.controls['timeslots']['controls'];
					_slots.forEach(slot => {
						_day_names.push(slot['day_name']);
					});
					this.currentWeekDays = _day_names;
					for (let i = 0; i < this.allWeekDays.length; i++) {
						if (!(_day_names.indexOf(this.allWeekDays[i]) > -1)) {
							this.remainWeekDays.push(this.allWeekDays[i]);
						}
					}
					for (let i = 0; i < _slots.length; i++) {
						this.timeslots.push(
							this._formBuilder.group({
								day_name: [
									{ value: _slots[i]['day'], disabled: true }
								],
								working_hours_from: [
									{
										value: _slots[i]['from_time'],
										disabled: this.edit
									}
								],
								working_hours_to: [
									{
										value: _slots[i]['to_time'],
										disabled: this.edit
									}
								],
								// interval: [
								// 	{ value: _slots[i]['interval'] - 1, disabled: this.edit }
								// ],
								id: [_slots[i]['id']],
								day_id: [_slots[i]['day_id']]
							})
						);
					}
        }
    }
  }
	getDaySelectedTimeSlot(event) {
		this.changedDaySelectedTimeSlot = event.value;
	}

	saveTimeSlotChange(currentSlot) {
		// {time_from: "12:00", time_to: "23:00", interval: 2, day_id: 2}
		// 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
    let id = undefined;
    console.log(currentSlot.value , 'fsafsa')

    console.log(this.fitclass_details['schedule'] , 'daz')

		const days_per_id = [
			{ day_id: 1, day_name: 'Sunday' },
			{ day_id: 2, day_name: 'Monday' },
			{ day_id: 3, day_name: 'Tuesday' },
			{ day_id: 4, day_name: 'Wednesday' },
			{ day_id: 5, day_name: 'Thursday' },
			{ day_id: 6, day_name: 'Friday' },
			{ day_id: 7, day_name: 'Saturday' }
		];
		for (let i = 0; i < days_per_id.length; i++) {
			if (days_per_id[i]['day_name'] === this.changedDaySelectedTimeSlot) {
				id = days_per_id[i]['day_id'];
			}
		}

    const slotObj = {
			time_from: currentSlot.value['working_hours_from'].slice(0, 5),
			time_to: currentSlot.value['working_hours_to'].slice(0, 5),
			day_id: currentSlot.value['day_id']
		};
		const newSlotObj = {
			slots: [
				{
					// branch_id: this.currentBranch.branch_id,
					days: [
						{
							interval: currentSlot.value['interval'] + 1,
							working_hours_from: currentSlot.value['working_hours_from'].slice(
								0,
								5
							),
							working_hours_to: currentSlot.value['working_hours_to'].slice(
								0,
								5
							),
							day_id: id
						}
					]
				}
			]
		};
  }
  addTimeSlot() {
		if (this.timeslots.length < 7) {
			this.newTimeSlot = true;
			this.timeslots.push(
				this._formBuilder.group({
					day_name: [''],
					working_hours_from: [''],
					working_hours_to: [''],
					interval: ['']
				})
			);
		}
  }
  editViewHandler() {
		if (this.edit) {

			Object.keys(this.ts_FormGroup.controls).forEach(key => {
				this.ts_FormGroup.get(key).enable();
			});
			Object.keys(this.ts_FormGroup.controls['timeslots']['controls']).forEach(
				(key, index) => {
					this.ts_FormGroup.controls['timeslots']['controls'][index][
						'controls'
					]['day_name'].disable();
				}
			);
		}
		if (!this.edit) {

			Object.keys(this.ts_FormGroup.controls).forEach(key => {
				this.ts_FormGroup.get(key).disable();
			});
		}
		this.edit = !this.edit;




	}
}
