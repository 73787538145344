import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class TourDashboardService {
	constructor(private http: HttpClient) {}

	getTourData(res_from, res_to) {
		const dates = 'date_from=' + res_from + '&' + 'date_to=' + res_to;
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/tourinsights/?${dates}`, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(dash => {
					return dash;
				})
			);
	}
}
