import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-c-d-recent-reservation',
	templateUrl: './c-d-recent-reservation.component.html',
	styleUrls: ['./c-d-recent-reservation.component.scss']
})
export class CDRecentReservationComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
