import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import swal from 'sweetalert2';
import { TourDashboardService } from '../../../_services';

@Component({
	selector: 'app-dashboard-tour',
	templateUrl: './dashboard-tour.component.html',
	styleUrls: ['./dashboard-tour.component.scss']
})
export class DashboardTourComponent implements OnInit {
	@ViewChild(MatPaginator)
	paginator: MatPaginator;
	@ViewChild(MatSort)
	sort: MatSort;
	isLoginError = false;
	res_data = '2019-01-01';
	res_to = '2019-05-12';

	allInsight: any;
	public datenow;
	displayedColumns = [
		'Tour_name',
		'Tickets_sold',
		'Start_date',
		'End_date',
		'Capacity'
	];
	newdate = [];
	transaction: any;
	dataSource: any;
	error = '';
	isLoading = true;
	returnUrl: string;
	DashDetilas: any[];
	public tour: any[];
	public ok: any;
	public ww: any;
	ik = [];
	no_of_trips: any;
	no_of_tickets: any;
	no_of_customers: any;
	revenue: any;
	public topreservation: any[];

	constructor(
		private tourDashboard: TourDashboardService,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		this.tourDashboard
			.getTourData(this.res_data, this.res_to)
			.subscribe((data: any) => {
				this.DashDetilas = data.insights;
				this.no_of_trips = data.insights.no_of_trips;
				this.no_of_customers = data.insights.no_of_customers;
				this.no_of_tickets = data.insights.no_of_tickets;
				this.revenue = data.insights.revenue;
				this.topreservation = data.insights.topReservations;

				this.tour = data.insights.tours;

				for (let w = 0; w < this.tour.length; w++) {
					this.ww = String(this.tour[w].Tour_name);
					this.ik.push(this.ww);
				}
				this.allInsight = data.insights.topReservations;
				this.transaction = data.insights.topTransactions;
				this.isLoading = false;

				for (let z = 0; z < this.allInsight.length; z++) {
					this.datenow = String(this.allInsight[z].res_list_time_from);
					this.datenow = this.datenow.substr(0, this.datenow.indexOf(' '));
					this.newdate.push(this.datenow);
				}
				this.tourtable();
			});
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	tourtable() {
		// Matrial Datatable angular
		this.dataSource = new MatTableDataSource(this.tour);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;

		//  console.log("",this.dataSource)
	}

	SubmitDate() {
		if (this.res_to < this.res_data) {
			swal('The Time is not correct', 'error', 'error');
		} else {
			this.isLoading = true;
			this.tourDashboard
				.getTourData(this.res_data, this.res_to)
				.subscribe((data: any) => {
					this.DashDetilas = data.insights;
					this.no_of_trips = data.insights.no_of_trips;
					this.no_of_customers = data.insights.no_of_customers;
					this.no_of_tickets = data.insights.no_of_tickets;
					this.revenue = data.insights.revenue;
					this.isLoading = false;

					console.log('data is found ', this.DashDetilas);
					this.tour = data.insights.tours;
					for (let w = 0; w < this.tour.length; w++) {
						this.ww = String(this.tour[w].Tour_name);
						this.ik.push(this.ww);
					}
					this.allInsight = data.insights.topReservations;
					console.log('top', this.allInsight);

					this.transaction = data.insights.topTransactions;
					this.topreservation = data.insights.topReservations;

					console.log('top Transactions', this.transaction);

					for (let z = 0; z < this.allInsight.length; z++) {
						this.datenow = String(this.allInsight[z].res_list_time_from);
						this.datenow = this.datenow.substr(0, this.datenow.indexOf(' '));
						this.newdate.push(this.datenow);
					}

					this.tourtable();
				});
		}
	}
}
