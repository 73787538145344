import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private http: HttpClient) { }

  login(data): Observable<any> {
    // const credentials = 'username=' + username + '&password=' + password;

    const payload = new HttpParams()
      .set('client_id', environment.clientId)
      .set('client_secret', environment.clientSecret)
      .set('username', data.username)
      .set('password', data.password)
      .set('grant_type', 'password')

    // `${environment.apiEndPoint}/auth/token/?client_id=${environment.clientId
    //   }&client_secret=${environment.clientSecret
    //   }&grant_type=password&${credentials}`,
    //   { headers: new HttpHeaders(`${environment.httpHeader}`
    return this.http
      .post(
        `${environment.apiEndPoint}/auth/token/`, data,
        {
          headers: new HttpHeaders(`${environment.httpHeader}`)
        }
      )
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user['access_token']) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            console.log(user)
          }
          return user;
        })
      );
  }

  postCars(data) {
    return this.http
      .post<any>(
        `http://prod.zabatnee.com/api/vendor/tour/webreservation/`,
        data
      )
      .pipe(
        map(trips => {
          console.log('"TTTT"', trips);
          return trips;
        })
      );
  }

  // logout
  logout() {
    localStorage.removeItem('currentUser');
  }
}
