import { Directive, Input } from '@angular/core';
import { Validator, FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector:
		'[maxNumberValidator][formControlName],[maxNumberValidator][formControl],[maxNumberValidator][ngModel]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: MaxNumberValidatorDirective,
			multi: true
		}
	]
})
export class MaxNumberValidatorDirective implements Validator {
	@Input()
	maxNumberValidator: number;

	constructor() {}

	validate(c: FormControl): { [key: string]: any } {
		const v = c.value;
		return v > this.maxNumberValidator ? { maxNumberValidator: true } : null;
	}
}
