import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { GetProfileService } from '../../../_services';
@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
	public newPassword;
	public oldPassword;
	public confirmPassword;
	constructor(private getprofile: GetProfileService) {}

	ngOnInit() {}
	updatePassword() {
		if (this.newPassword !== this.confirmPassword) {
			Swal('Not Matching', 'Please Enter Password Correct ', 'error');
		} else {
			this.getprofile
				.UpdatePassword(this.oldPassword, this.newPassword)
				.subscribe(
					data => {
						if (data.code === '10000') {
							Swal('Done', 'Password Are Updated', 'success');
						}
					},
					error => {
						if (error.error.code === '10013') {
							Swal(
								'ReChanage It',
								'Invalid new password, must be at least 6 character and contains at least 1 capital letter and 1 digit',
								'error'
							);
						}
					}
				);
		}
	}
}
