import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ToursService } from '../../../_services';

@Component({
	selector: 'app-tour-home',
	templateUrl: './tour-home.component.html',
	styleUrls: ['./tour-home.component.scss']
})
export class TourHomeComponent implements OnInit {
	@ViewChild(MatPaginator)
	paginator: MatPaginator;
	@ViewChild(MatSort)
	sort: MatSort;
	tourTypeList = [
		{ id: '', name: 'All Types' },
		{ id: 3, name: 'Fixed Dates' },
		{ id: 2, name: 'Repeatable' }
	];
	displayedColumns = [
		'name',
		'meetingPoint',
		'slots',
		'start_date',
		'end_date',
		'Tickets_sold',
		'View'
	];
	/*, 'slots', 'slots', 'tripDetails'*/
	dataSource: MatTableDataSource<{}>;
	tour: any;
	toursCount: any;
	toursList: any[];
	end_date: any;
	start_date: any;
	listdate: any;
	list: any = [];
	datesList: any = [];
	dates: any = [];
	finaldates: any;
	filterObject = {
		trip_type: '',
		tour_start: '',
		status: ''
	};
	isLoading = true;
	tourdetails: any;
	TourType;
	startdate;
	constructor(private tours: ToursService, private router: Router) {}
	ngOnInit() {
		this.tours.loadTour().subscribe((data: any) => {
			this.toursList = data['tours'];
			// localStorage.setItem('tour', JSON.stringify(this.toursList));
			// console.log(JSON.stringify(this.toursList))
			this.isLoading = false;

			this.toursCount = this.toursList.length;
			// console.log("data",this.toursList)
			this.tourTable();

			for (let i = 0; i < this.toursList.length; i++) {
				//  console.log("enddate");
				if (this.toursList[i].tripDetails != null) {
					// console.log("length", this.toursList[i].tripDetails.length);
					this.end_date = this.toursList[i].tripDetails.length;
					//  tripDetails[0].slots[0].start_date
					this.listdate = this.toursList[i].tripDetails[0].slots.length;
					for (let x = 0; x < this.listdate; x++) {
						this.list.push(
							this.toursList[i].tripDetails[0].slots[x].start_date
						);
					}
					this.datesList.push(this.toursList[i].tripDetails);
				}
			}
			for (let y = 0; y < this.datesList.length; y++) {
				this.dates.push(this.datesList[y].length);
			}
			this.finaldates = this.list;
		});
	}

	myFilter = (d: Date): boolean => {
		const showDates = this.finaldates.filter(function(el) {
			return el !== '';
		});
		return (
			showDates.findIndex(
				showDate => d.toDateString() === new Date(showDate).toDateString()
			) >= 0
		);
	};
	search() {
		this.isLoading = true;
		this.tours.filterTour(this.filterObject).subscribe(tour => {
			console.log('Serach parmater', tour);
			this.toursList = tour['tours'];
			this.toursCount = this.toursList.length;
			this.tourTable();
			this.isLoading = false;
		});
	}
	tourTable() {
		// Matrial Datatable angular
		this.dataSource = new MatTableDataSource(this.toursList);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		console.log(this.dataSource);
	}
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	setTourType(TourType) {
		this.filterObject.trip_type = TourType;
		console.log('ZZZZ', this.filterObject.trip_type);
	}
	setTDate(Date) {
		this.filterObject.tour_start = Date;
		console.log('ZZZZ', this.filterObject.tour_start);
	}
	setDate2(date) {
		const dateISO = new Date(date);
		this.startdate =
			dateISO.getFullYear() +
			'-' +
			(dateISO.getMonth() + 1) +
			'-' +
			dateISO.getDate();
		console.log('apapppapap ', this.startdate);
		this.filterObject.tour_start = this.startdate;
		console.log('ZZZZ', this.filterObject.tour_start);
	}
	viewTourDetails(tourid) {
		this.router.navigate(['/tripDetails', tourid]);
		// this.tourdetails=data.tours;
	}
	viewTourData(tourid) {
		this.router.navigate(['/tour/tourDetails', tourid]);
		// this.tourdetails=data.tours;
	}
	createTour() {
		this.router.navigate(['tour/tourCreate']);
	}
}
