import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { SportsListService } from '../../../_services';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
const Deeemeo: PeriodicElement[] = [
	{
		Branch: 'Zamalek',
		name: 'Cross fit',
		Capacity: 1.0079,
		Tickets_sold: '12/20'
	},
	{
		Branch: 'Giza',
		name: 'Kick Boxing course',
		Capacity: 4.0026,
		Tickets_sold: '6/30'
	},
	{ Branch: 'hram', name: 'Cross fit', Capacity: 6.941, Tickets_sold: '32/40' },
	{
		Branch: 'tharir',
		name: 'Kick Boxing course',
		Capacity: 9.0122,
		Tickets_sold: '6/30'
	},
	{ Branch: 'cairo', name: 'kartia', Capacity: 10.811, Tickets_sold: '12/6' },
	{ Branch: 'ss', name: 'fosfas', Capacity: 12.0107, Tickets_sold: '8/9' },
	{ Branch: '', name: 'mzsafa', Capacity: 14.0067, Tickets_sold: '55/5' },
	{ Branch: '', name: 'zzzzzz', Capacity: 15.9994, Tickets_sold: '95/4' },
	{ Branch: 'bolaq', name: 'fsafsa', Capacity: 18.9984, Tickets_sold: '22/8' },
	{ Branch: 'home', name: 'va', Capacity: 20.1797, Tickets_sold: '12/5' }
];
@Component({
	selector: 'app-dashboard-sprots',
	templateUrl: './dashboard-sprots.component.html',
	styleUrls: ['./dashboard-sprots.component.scss']
})
export class DashboardSprotsComponent implements OnInit {
	displayedColumns: string[] = ['name', 'Branch', 'Tickets_sold', 'Capacity'];
	dataSource: any;
	@ViewChild(MatPaginator)
	paginator: MatPaginator;
	@ViewChild(MatSort)
	sort: MatSort;
	brancharea: string[] = ['Zamalek', 'Giza', 'Tagamoa'];
	amount: number[] = [350, 450, 100];
	doughnutChartType: any = 'pie';
	scio: any[] = [];
	res_data_from = '' ;
	res_date_to = '';
	number_of_courses: any;
	number_of_subscriptions: any;
	renewals: any;
	revenue: any;
	isLoading: boolean;
	courses: any[] = [];
	top_reservations: any[] = [];
	branchs: any[] = [];
  trainners: any[] = [];
  trainerValue = '';
  branchValue = '';

	// filterObject = {
	// 	branch: '',
	// 	trainer: ''
  // };

  filterdate = {
    from : '',
    to: '',
    branch: '',
		trainer: ''
  }

	constructor(private sports: SportsListService) {}

	ngOnInit() {
		this.isLoading = true;
		this.sports.getDashboard().subscribe((data: any) => {
			this.isLoading = false;
			this.number_of_courses = data.dashboard.number_of_courses;
			this.number_of_subscriptions = data.dashboard.number_of_subscriptions;
      // this.renewals = data.insights.no_of_tickets;
      this.renewals = 2
			this.revenue = data.dashboard.revenue;
			this.courses = data.dashboard.courses;
			this.top_reservations = data.dashboard.top_reservations;
			console.log(data, 'data is');
			this.coursetable();
		});

		this.getbranchs();
		this.gettrainner();

		this.scio.push({
			amout: this.amount,
			brancharea: this.brancharea
		});
		// console.log(this.scio, 'al3ab yala');
	}
	coursetable() {
		// Matrial Datatable angular
		this.dataSource = new MatTableDataSource(this.courses);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;

		console.log('', this.dataSource);
	}
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
	// Doughnut
	public chartClicked(e: any): void {
		console.log(e);
	}
	public chartHovered(e: any): void {
		console.log(e);
	}

	SubmitDate() {
    console.log(this.res_data_from, this.res_date_to);
    console.log(this.branchValue, this.trainerValue);
if ( this.res_data_from === '' &&  this.res_date_to === '' && this.branchValue === '' && this.trainerValue === ''  ) {
  swal('Please Choose Date To Filter', 'warning', 'warning');
}

 else if (this.res_data_from !== '' &&  this.res_date_to === ''  ){
 	swal('Please Enter The End Date', 'warning', 'warning');
 }
 else if (this.res_data_from === '' &&  this.res_date_to !== ''  ){

  swal('Please Enter The Start Date', 'warning', 'warning');

}

else {

  this.filterdate = {
    from : this.res_data_from,
    to : this.res_date_to,
    branch : this.branchValue,
		trainer : this.trainerValue
  }

  this.sports.getDashboardData(this.filterdate).subscribe((data: any) => {
    this.number_of_courses = data.dashboard.number_of_courses;
    this.number_of_subscriptions = data.dashboard.number_of_subscriptions;
    // this.renewals = data.insights.no_of_tickets;
    this.renewals = 2
    this.revenue = data.dashboard.revenue;
  });
}
}
  // }

	// search() {
	// 	console.log(this.filterObject, 'send');
	// 	this.isLoading = true;
	// 	this.sports.getdashboardis(this.filterObject).subscribe((data: any) => {
	// 		this.isLoading = false;
	// 		this.number_of_courses = data.dashboard.number_of_courses;
	// 		this.number_of_subscriptions = data.dashboard.number_of_subscriptions;
  //     // this.renewals = data.insights.no_of_tickets;
  //     this.renewals = 2
	// 		this.revenue = data.dashboard.revenue;
	// 		this.courses = data.dashboard.courses;
	// 		this.top_reservations = data.dashboard.top_reservations;
	// 		console.log(data, 'data is');
	// 		this.coursetable();
	// 	});
	// }


	getbranchs() {
		this.sports.getAllbranchs().subscribe((data: any) => {
			this.branchs = data.branches;
		});
	}

	gettrainner() {
		this.sports.getAlltrainner().subscribe((data: any) => {
			this.trainners = data.Trainers;
		});
	}
	setbarnch(id) {
	 this.branchValue=	this.filterdate.branch = id;
	}
	settrainner(id) {
	this.trainerValue=	this.filterdate.trainer = id;
	}


}
export interface PeriodicElement {
	name: string;
	Branch: string;
	Capacity: number;
	Tickets_sold: string;
}
