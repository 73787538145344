import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MouseEvent } from '@agm/core';
import { imgSrcToBlob } from 'blob-util';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import { MatStepper } from '@angular/material';

import {
  HttpService,
  ToursService,
  UploadimgService
} from '../../../../_services';
import { AnimationStyleNormalizer } from '@angular/animations/browser/src/dsl/style_normalization/animation_style_normalizer';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'app-tour-create',
  templateUrl: './tour-create.component.html',
  styleUrls: ['./tour-create.component.scss']
})
export class TourCreateComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = false;
  public show = false;
  public buttonName: any = 'Show';
  citites: any[];
  Area: any;
  Tour = {};
  SavedInfo = {};
  card: string;
  CoverImage: any;
  selectedFiles: any;
  imgcover: any;
  cardImg: any;
  selecCover: any;
  gallery: any[] = [];
  galleryImages: FileList;
  img_o: FileList;
  img_c: FileList;
  imageSrc: string | ArrayBuffer;
  imageSrcc: string | ArrayBuffer;
  imageSrcs: string | ArrayBuffer;
  img1: any;
  img_2: any;
  g1: any;
  itmex: number;
  tourid: any;
  tripsAndPackages = {};
  tours: any[] = [];
  Days: any[] = [];
  packagescostlist: any[] = [];
  dayOption = 'SD';
  tripflag: string;
  trip: {
    tour_id: any;
    trip_type_id: number;
    start_date: any;
    end_date: any;
    from: any;
    to: any;
    trip_days_no: any;
    off_days_number: any;
    max_capacity: any;
    min_capacity: any;
  };
  tripdaily: {
    tour_id: any;
    trip_type_id: number;
    start_date: any;
    end_date: any;
    from: any;
    to: any;
    trip_days_no: any;
    off_days_number: any;
    max_capacity: any;
    min_capacity: any;
  };
  start_date: any;
  start_time: any;
  enddate: any;
  end_time: any;
  days: any;
  offdays: any;
  min: any;
  max: any;
  weekDays: any;
  array: any = [];
  numofTrips: any = [];
  time: any[] = [];
  daysnum: any = '';
  fromtime: any = [];
  totime: any = [];
  DaysForm;
  selectedDays;
  tripday: any;
  trips: any;
  day_id: any[] = [];
  startdate: any;
  costForm: {};
  costlist: any[];
  name: any;
  cost: any;
  check: any;
  myForm: any;
  packagename: any;
  desc: any;
  packages: any[] = [];
  info: { Instructions: any; Exclusions: any; Inclusions: any };
  Instructions: any;
  Exclusions: any;
  Inclusions: any;
  tripI: { Tour_id: any; Instructions: any; Exclusions: any; Inclusions: any };
  alltrip: any;
  paymentMethods: any;
  viewcancelation: boolean;
  maxcollection: number;
  mincollection: number;
  flagcollection: boolean;
  flagcollectionnot: boolean;
  zerocollection: boolean;
  msgcollectionzero: string;
  haspaymentId: number;
  msgcollectionnotZero: string;
  hasPayments: any;
  collection_requirement: any;
  PoliciesToSave = [];
  daysofWeek = [];
  periodmsg: boolean;
  perioderrorMsg: string;
  dedutionmsg: boolean;
  deductionerrorMsg: string;
  deductiond: any;
  periodd: any;
  dayss: any[] = [];
  Policies: any[] = [];
  cancelationForm;
  workflow_id: any;
  payment_method_id: any;
  Tourid: any;
  dataWF: {};
  policyWithId: any;
  close = true;
  closepack = false;
  closeinfo = false;
  daysz: any;
  checkToUpload: any;
  file: File;
  zoom = 8;
  lat = 30.038710488299724;
  lng = 31.21996034750498;
  lang: number;
  latng: number;
  marker: { latitude: any; longitude: any };
  latitude = 30.0594838;
  longitude = 31.223359;
  weekDaysww: any;
  undisable = null;
  daysofWeeks: any[] = [];
  selectedFile: ImageSnippet;
  image_name: string;
  sazvz: File;
  coverfile: File;
  gallaryfile: File;
  image_nameC: string;
  selectedFileC: ImageSnippet;
  selectedgallary: ImageSnippet;
  gallarylist: File;
  image_nameG: string;
  values = [{ 'id': 1 }, { 'id': 2 }, { 'id': 3 }, { 'id': 4 }, { 'id': 5 }, { 'id': 6 }, { 'id': 7 }, { 'id': 8 },
  { 'id': 9 }, { 'id': 10 }, { 'id': 11 }, { 'id': 12 }, { 'id': 13 }, { 'id': 14 }, { 'id': 15 }, { 'id': 16 }, { 'id': 17 },
  { 'id': 18 }, { 'id': 19 }, { 'id': 20 }, { 'id': 21 }, { 'id': 22 }, { 'id': 23 }, { 'id': 24 }, { 'id': 25 }, { 'id': 26 },
  { 'id': 27 }, { 'id': 28 }, { 'id': 29 }, { 'id': 30 }]
  extend: any;


  constructor(
    private _fb: FormBuilder,
    private httpService: HttpService,
    private TourService: ToursService,
    private uploadimgService: UploadimgService,
    private router: Router
  ) { }
  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.buttonName = 'Hide';
    } else {
      this.buttonName = 'Show-Maps';
    }
  }

  ngOnInit() {
    this.firstFormGroup = this._fb.group({
      tourname: ['', Validators.required],
      Touroverview: ['', Validators.required],
      Tourprogram: ['', Validators.required],
      cityid: ['', Validators.required],
      areaid: ['', Validators.required],
      MeetingPoint: ['', Validators.required],
      extend: [false]
      //   CoverImage:['',Validators.required]
    });

    this.TourService.loadCities().subscribe((data: any) => {
      this.citites = data.cities;
    });
    this.getWeekDays();
    this.getPayment();
  }

  mapClicked($event: MouseEvent) {
    const latitude = $event['coords']['lat'];
    const longitude = $event['coords']['lng'];
    this.latitude = latitude;
    this.longitude = longitude;

    this.marker = {
      latitude: this.latitude.toString(),
      longitude: this.longitude.toString()
    };
    //  console.log(this.markers)
    console.log('lat', this.marker);
  }
  Loadarea() {
    this.TourService.loadArea(
      this.firstFormGroup.get('cityid').value
    ).subscribe((data: any) => {
      this.Area = data.distircts;
    });
  }
  checkresev(e) {
    console.log(e)
    if (e.checked) {
      this.extend = true;
    }
    else if (e.checked == false) {
      this.extend = false;

    }
  }
  selectFile(imageInput: any) {
    // this.img_o = event.target.files;
    // console.log(this.img_o[0].name);
    // //   this.img1 = this.img_o[0].name;
    // //   this.img1 = Date.now() + '.jpg';
    // //   console.log(this.img1, "lfa")
    // if (event.target.files && event.target.files[0]) {
    // 	const file = event.target.files[0];
    // 	const reader = new FileReader();
    // 	reader.onload = e => (this.imageSrc = reader.result);
    // 	reader.readAsDataURL(file);
    // }

    const file: File = imageInput.files[0];
    console.log(file)
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      console.log(this.selectedFile)
      var cardName = Date.now();
      console.log(this.selectedFile, 'fafas')
      this.sazvz = this.dataURLtoFile(this.selectedFile.src, cardName + '.jpg');
      this.image_name = this.sazvz.name
      console.log(this.sazvz.name, 'gfafafa');
      // const path = 'tour/card';
      //   // let file1 = this.img_o.item(0);
      //   // console.log(file1 , 's')
      // 	// console.log('POP', file1);
      // 	this.uploadimgService.uploadFile(this.sazvz, path);
    });
    reader.readAsDataURL(file);
  }

  selectCover(imageInput: any) {
    // this.img_c = event.target.files;
    // //   this.img_2 = this.img_c[0].name;
    // //   this.img_2 = Date.now() + '.jpg';
    // console.log(this.img_c[0].name, 'Wfa');
    // if (event.target.files && event.target.files[0]) {
    // 	const file = event.target.files[0];
    // 	const reader = new FileReader();
    // 	reader.onload = e => (this.imageSrcc = reader.result);
    // 	reader.readAsDataURL(file);
    // }
    const file: File = imageInput.files[0];
    console.log(file)
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.selectedFileC = new ImageSnippet(event.target.result, file);
      console.log(this.selectedFileC)
      var cardName = Date.now();
      console.log(this.selectedFileC, 'fafas')
      this.coverfile = this.dataURLtoFile(this.selectedFileC.src, cardName + '.jpg');
      this.image_nameC = this.coverfile.name
      console.log(this.coverfile.name, 'gfafafa');
      // const path = 'tour/card';
      //   // let file1 = this.img_o.item(0);
      //   // console.log(file1 , 's')
      // 	// console.log('POP', file1);
      // 	this.uploadimgService.uploadFile(this.sazvz, path);
    });
    reader.readAsDataURL(file);
  }
  selectgalary(imageInput: any) {
    // this.galleryImages = event.target.files;
    // console.log(this.galleryImages, 'gala');
    // for (let i = 0; i < this.galleryImages.length; i++) {
    // 	//   this.g1 = this.galleryImages[i].name;
    // 	//   this.g1 = Date.now();
    // 	// this.file = this.dataURLtoFile(
    // 	// 	this.galleryImages[i].name,
    // 	// 	Date.now() + '_' + i + '.jpg'
    // 	// );

    // 	this.gallery.push(this.galleryImages.item(i).name);
    // }
    // console.log(this.gallery, 's');
    // if (event.target.files && event.target.files[0]) {
    // 	const file = event.target.files[0];
    // 	const reader = new FileReader();
    // 	reader.onload = e => (this.imageSrcs = reader.result);
    // 	reader.readAsDataURL(file);
    // }
    for (var i = 0; i < imageInput.files.length; i++) {
      console.log(i)
      const file: File = imageInput.files[i];
      console.log(file, 's')
      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        this.selectedgallary = new ImageSnippet(event.target.result, file);
        console.log(this.selectedgallary)
        var cardName = Date.now();
        //  console.log(this.selectedgallary , 'fafas')
        this.gallaryfile = this.dataURLtoFile(this.selectedgallary.src, cardName + '_' + i + '.jpg');
        this.gallery.push(this.gallaryfile.name);

        // // this.image_nameC= this.coverfile.name
        console.log(this.gallery, 'gfafafa');
        const path = 'tour/gallery';
        // let file1 = this.img_o.item(0);
        // console.log(file1 , 's')
        // console.log('POP', file1);
        this.uploadimgService.uploadFile(this.gallaryfile, path);
      });
      reader.readAsDataURL(file);

    }
  }
  dataURLtoFile(dataurl, filename) {
    console.log("data in image", dataurl, filename);
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  Friststep(stepper: MatStepper) {
    // console.log(this.gallery , 'sssfaa')
    if ((this.marker = null)) {
      swal(
        'please select meeting point location from google map!',
        '',
        'error'
      );
    }
    else if (this.image_name === undefined || this.image_name === null) {
      swal('please select image card!', '', 'error');
    }
    else if (this.image_nameC === undefined || this.image_nameC === null) {
      swal('please select image smal card!', '', 'error');
    } else {
      const path = 'tour/card';
      // const file1 = this.img_o.item(0);
      // console.log('POP', file1);
      this.uploadimgService.uploadFile(this.sazvz, path);
      // const file = this.img_c.item(0);
      // console.log('POP2', file);
      this.uploadimgService.uploadFile(this.coverfile, path);
      // for (let i = 0; i < this.galleryImages.length; i++) {
      // 	const path2 = 'tour/gallery';
      // 	// const file23 = this.galleryImages.item(i);
      // 	// console.log('POsP', file23);
      // 	this.uploadimgService.uploadFile(this.gallaryfile, path2);
      // }
      const data = [
        {
          name: this.firstFormGroup.get('tourname').value,
          desc: this.firstFormGroup.get('Touroverview').value,
          program: this.firstFormGroup.get('Tourprogram').value,
          district_id: this.firstFormGroup.get('areaid').value,
          card: this.image_name,
          small_card: this.image_nameC,
          extend: this.extend,
          // card: Date.now() + '.jpg',
          // small_card: Date.now + '.jpg',
          //   itex:this.gallery.length,
          gallery: this.gallery,
          meeting_point: {
            name: this.firstFormGroup.get('MeetingPoint').value,
            lat: this.latitude.toString(),
            long: this.longitude.toString()
          }
        }
      ];

      this.TourService.addBasicInfoTour(data).subscribe(
        create => {
          swal('tour Are Created!', '', 'success');
          console.log(create);
          console.log(create['tour']['id'], 'sfafmaklfasf');

          this.tourid = create['tour']['id'];
          localStorage.setItem('tour', create['tour']['id']);
          stepper.next();
        },
        error => {
          console.log(error);
          swal(error.detail, 'error');
        }
      );

      console.log('all', data);
    }
  }

  addtrip(trip, flag) {
    console.log('action1');
    if (this.start_date === this.enddate) {
      if (this.end_time < this.start_time || this.end_time === this.start_time) {
        swal('Start Time Cant be Greater Than or Equal End Time', 'error');
        this.start_time = null;
        this.end_time = null;
      }
    } else {
      console.log('action2');
      this.undisable = true;
      this.tripflag = 'trip';
      if (flag === 'days') {
        console.log('action3');

        this.trip = {
          tour_id: localStorage.tour,
          trip_type_id: 3,
          start_date: this.start_date,
          end_date: this.enddate,
          from: this.start_time,
          to: this.end_time,
          trip_days_no: this.days,
          off_days_number: this.offdays,
          max_capacity: this.min,
          min_capacity: this.max
        };
        this.tripdaily = this.trip;
        console.log('days', this.tripdaily);
        this.close = false;
        this.closepack = true;
      }
    }

  }
  getWeekDays() {
    this.TourService.getWeekDays().subscribe((data: any) => {
      //   console.log(data,'week')
      this.weekDays = data.days;

      for (let i = 0; i < this.weekDays.length; i++) {
        this.daysofWeek.push({
          id: this.weekDays[i].id,
          name: this.weekDays[i].name,
          selected: false,
          isSet: false
        });
      }
    });
    this.TourService.getWeekDayscancel().subscribe((data: any) => {
      //   console.log(data,'week')
      this.weekDaysww = data.days;
      console.log(this.weekDaysww, '2ololol');

      for (let i = 0; i < this.weekDaysww.length; i++) {
        this.daysofWeeks.push({
          id: this.weekDaysww[i].id,
          name: this.weekDaysww[i].name,
          selected: false,
          isSet: false
        });
      }
    });
  }
  getDaysNum(daysNum) {
    this.array.length = daysNum;
    this.daysnum = daysNum;
    console.log('array', this.array);
    console.log('zmzmzmzm', this.daysnum);
    this.gettimeslots();
  }

  gettimeslots() {
    this.numofTrips.length = '';
  }

  getTime(flag, time, index) {
    console.log('timee', time);
    this.time = time;
    console.log(this.daysnum, 's');
    if (flag === 'fromtime') {
      for (let i = 0; i < this.daysnum; i++) {
        if (i === index) {
          this.fromtime[i] = time;
          console.log('fromtimeddd', this.fromtime[i].viewModel);
        }
      }
    } else if (flag === 'totime') {
      for (let i = 0; i < this.daysnum; i++) {
        if (i === index) {
          this.totime[i] = time;
          console.log('totimeddd', this.totime[i].viewModel);
        }
      }
    }
    //   console.log("fromtime", this.fromtime);
    //   console.log("totime",this.totime);
  }

  pushtimearray() {
    for (let i = 0; i < this.daysnum; i++) {
      this.numofTrips[i] = {
        from: this.fromtime[i].viewModel,
        to: this.totime[i].viewModel,
        name: 'Morning Trip'
      };
    }
    console.log('i', this.numofTrips);
  }

  addDay(tour) {
    this.tripflag = 'trips';
    this.pushtimearray();
    this.selectedDays = [];
    this.DaysForm = {};
    this.array.fromtime = this.fromtime;
    this.array.totime = this.totime;
    console.log('item', this.array);
    for (let i = 0; i < this.weekDays.length; i++) {
      if (this.weekDays[i].selected === true) {
        //   $scope.weekDays[i].disabled = true;
        this.selectedDays.push({
          day_id: this.weekDays[i].id,
          day_name: this.weekDays[i].name
        });
        this.day_id.push(this.weekDays[i].id);
      }
    }
    console.log('array of days', this.day_id);
    this.Days.push({
      daysNum: this.selectedDays
    });
    this.trips = {
      tour_id: localStorage.tour,
      trip_type_id: 2,
      day_ids: this.day_id,
      start_date: this.startdate,
      end_date: this.enddate,
      times: this.numofTrips,
      max_capacity: this.min,
      min_capacity: this.max
    };
    console.log('tripss in rdays', this.trips);
    console.log('trips', this.trips);
    this.tripday = this.trips;
    console.log('days', this.Days);
    this.close = false;
    this.closepack = true;
  }

  addPackages() {
    this.costForm = {};

    if (this.packagescostlist.length < 5) {
      if (this.check === undefined) {
        this.packagescostlist.push({
          type: this.name,
          Cost: this.cost,
          isCounted: false
        });
      } else {
        this.packagescostlist.push({
          type: this.name,
          Cost: this.cost,
          isCounted: this.check
        });
      }
      console.log(this.packagescostlist, 'pd');

      this.costlist = this.packagescostlist;
    } else {
      swal('You have Already Five Packages!', '', 'error');
    }

    this.name = '';
    this.cost = '';

    //  then here send the packages and add them to the big packages list
  }
  // FIXME:expected an assignment or function call
  addPackagelist() {
    console.log('list length', this.costlist.length);
    // this.packagescostlist.length;

    if (this.packagename === undefined || this.desc === undefined) {
      swal('please enter missing parameters', '', 'error');
    } else if (this.packagescostlist.length < 1) {
      //     console.log("list length", $scope.packagescostlist.length);
      //       console.log("list length", $scope.costlist.length);

      swal('please enter missing parameters', '', 'error');
    } else {
      this.packages.push({
        package_name: this.packagename,
        desc: this.desc,
        participants: this.packagescostlist
      });
      this.packagescostlist = [];
      console.log(this.packages, 'DD');
    }
    if (this.tripflag === 'trip') {
      //     console.log("tripppppq111", $scope.tripdaily);
      this.tripsAndPackages = {
        tour_id: this.tripdaily.tour_id,
        trip_type_id: 3,
        start_date: this.tripdaily.start_date,
        end_date: this.tripdaily.end_date,
        from: this.tripdaily.from,
        to: this.tripdaily.to,
        trip_days_no: this.tripdaily.trip_days_no,
        off_days_number: this.tripdaily.off_days_number,
        max_capacity: this.tripdaily.max_capacity,
        min_capacity: this.tripdaily.min_capacity,
        packages: this.packages
      };
      this.closepack = false;
      this.closeinfo = true;
      console.log('spl', this.tripsAndPackages);
    } else if (this.tripflag === 'trips') {
      //    console.log("trips", $scope.trips);
      this.tripsAndPackages = {
        tour_id: this.tripday.tour_id,
        trip_type_id: 2,
        day_ids: this.tripday.day_ids,
        start_date: this.tripday.start_date,
        end_date: this.tripday.end_date,
        times: this.tripday.times,
        max_capacity: this.tripday.max_capacity,
        min_capacity: this.tripday.min_capacity,
        packages: this.packages
      };
      this.closepack = false;
      this.closeinfo = true;
      console.log('tripskbira', this.tripsAndPackages);
    }
    this.packagename = '';
    this.desc = '';
  }
  addInfo() {
    if (
      this.Instructions === undefined ||
      this.Inclusions === undefined ||
      this.Exclusions === undefined
    ) {
      this.Instructions = "";
      this.Inclusions = ""
      this.Exclusions = ""
    }
    else {
      //    $scope.info = info;
      //   $scope.Instructions = info.Instructions;
      //   this.Exclusions = info.Exclusions;

      //   this.Inclusions = info.Inclusions;
      //   console.log("test", this.Instructions, this.Exclusions, this.Inclusions);
      this.Instructions = this.Instructions.replace(/(?:\r\n|\r|\n)/g, '|');
      this.Exclusions = this.Exclusions.replace(/(?:\r\n|\r|\n)/g, '|');
      this.Inclusions = this.Inclusions.replace(/(?:\r\n|\r|\n)/g, '|');
    }
    console.log('test', this.Instructions, this.Exclusions, this.Inclusions);
    this.info = {
      Instructions: this.Instructions,
      Exclusions: this.Exclusions,
      Inclusions: this.Inclusions
    };
    console.log('testttt', this.info);
    this.addAllTrip();

  }
  addAllTrip() {
    this.tripI = {
      Tour_id: localStorage.tour,
      Instructions: this.Instructions,
      Exclusions: this.Exclusions,
      Inclusions: this.Inclusions
    };
    this.alltrip = this.tripI;
    console.log('all trip', this.tripI);
    this.closeinfo = false;
  }
  deletePackage = function (item) {
    const index = this.packages.indexOf(item);
    this.packages.splice(index, 1);
  };
  submitTrip() {
    this.TourService.postTrips(this.tripsAndPackages).subscribe(
      create => {
        console.log('tripadd', create);
        this.TourService.postAddInfo(this.alltrip).subscribe(
          addd => {
            console.log('tbacadd', addd);
            swal('Trips Sucsses Addded', ':D', 'success');
          },
          error => {
            console.log(error, 'error trip');
          }
        );
      },
      error => {
        console.log(error.detail, 'error trip');
        swal('error', 'error');

      }
    );

  }
  getPayment() {
    this.TourService.getPaymentMethod().subscribe((data: any) => {
      this.paymentMethods = data.payment_methods;
      //  console.log('payment',this.paymentMethods);
    });
  }
  changepayment(paymentid) {
    console.log('payment id', paymentid);
    if (paymentid === 1) {
      //  pay on arrival
      this.viewcancelation = false;
      this.mincollection = 0;
      this.maxcollection = 0;
      this.collection_requirement = 0;
      this.flagcollection = true;
      this.zerocollection = true;
      this.flagcollectionnot = false;
      this.msgcollectionzero = 'the collection must be zero';
      this.haspaymentId = 2;
      console.log('tt', this.mincollection, this.maxcollection);
    } else {
      //  2 credit card
      this.viewcancelation = true;
      this.mincollection = 1;
      this.zerocollection = false;
      this.collection_requirement = '';
      this.maxcollection = 100;
      this.flagcollection = false;
      this.flagcollectionnot = true;
      this.msgcollectionnotZero = "the collection can't be zero";
      this.haspaymentId = 1;
      console.log('tt', this.mincollection, this.maxcollection);
    }
    console.log('payment after if', this.haspaymentId);
    this.TourService.getWorkFlowPayment(this.haspaymentId).subscribe(
      (data: any) => {
        //  console.log('data',data);
        this.hasPayments = data.workflows;
      }
    );
  }
  AddTourPolicy(policyy) {
    if (this.periodd === undefined) {
      this.periodmsg = true;
      this.perioderrorMsg = 'please enter period';
    } else if (this.deductiond === undefined) {
      this.dedutionmsg = true;
      this.deductionerrorMsg = 'please enter deduction';
    } else {
      this.periodmsg = false;
      this.dedutionmsg = false;
      const policy = {};
      this.dayss = [];
      for (let j = 0; j < this.daysofWeeks.length; j++) {
        if (this.daysofWeeks[j].selected === true) {
          this.dayss.push({
            period: this.periodd,
            deduction: this.deductiond,
            day_name: this.daysofWeeks[j].name,
            day_id: this.daysofWeeks[j].id
          });
          this.Policies.push({
            period: this.periodd,
            deduction: this.deductiond,
            day_id: this.daysofWeeks[j].id.toString(),
            id: ''
          });
        }
        console.log(this.dayss, ',vzvz');
        console.log(this.Policies, ',sz');
      }
      this.PoliciesToSave.push({ days: this.dayss });
      console.log(this.PoliciesToSave, ',ww');
      policyy = {};
      policyy.periodd = '';
      policyy.deductiond = '';
      this.cancelationForm = {};
      this.periodd = '';
      this.deductiond = '';
    }
  }
  removePolicy(index) {
    this.PoliciesToSave.splice(index, 1);
    this.Policies.splice(index, 1);
    this.dayss.splice(index, 1);
  }

  finish() {
    if (this.viewcancelation === true) {
      console.log('length', this.Policies.length);
      if (this.Policies === [] || this.Policies.length === 0) {
        swal('Please add cancelation policies!', '', 'error');
      } else if (
        this.workflow_id === undefined ||
        this.collection_requirement === undefined ||
        this.payment_method_id === undefined
      ) {
        swal('Please add missing parameters!', '', 'error');
      } else {
        console.log('localstorage', localStorage.tour);
        this.Tourid = localStorage.tour;
        console.log('item policyyy', this.Policies.length);
        console.log('item policyyy', this.Policies[0]);
        this.dataWF = {
          payment_method_id: this.payment_method_id,
          collection_requirements: parseInt(this.collection_requirement),
          workflow_id: this.workflow_id,
          policies: this.Policies
        };
      }
    } else {
      console.log('length', this.Policies.length);
      if (
        this.workflow_id === undefined ||
        this.collection_requirement === undefined ||
        this.payment_method_id === undefined
      ) {
        swal('Please add missing parameters!', '', 'error');
      } else if (this.collection_requirement !== 0) {
        console.log('valid collection', this.collection_requirement);
        swal('Please enter valid value for collection!', '', 'error');
      } else {
        console.log('localstorage', localStorage.tour);
        this.Tourid = localStorage.tour;
        this.dataWF = {
          payment_method_id: this.payment_method_id,
          collection_requirements: parseInt(this.collection_requirement),
          workflow_id: this.workflow_id
        };
      }
    }
    console.log('data', this.dataWF, 'tourid', this.tourid);
    this.TourService.postWorkFlow(this.dataWF, this.tourid).subscribe(
      create => {
        console.log('tripadd', create);
        swal('Tour Are Created', 'Enjoy', 'success');
        this.router.navigate(['/tours']);
      },
      error => {
        console.log(error, 'error WF');
      }
    );
    localStorage.removeItem('tour');
  }
  checknumber(ss) {
    if (ss > 100) {
      swal('cant set number > 100 le ', 'error', 'error');
    }
  }
}

interface Marker {
  lat: String;
  lng: String;
}
