import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-c-d-insights',
	templateUrl: './c-d-insights.component.html',
	styleUrls: ['./c-d-insights.component.scss']
})
export class CDInsightsComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
