import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  ToursService,
  HttpService,
  UploadimgService
} from '../../../../_services';
import { environment } from './../../../../../environments/environment';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { MatCheckboxChange } from '@angular/material';
// import { google } from '@agm/core/services';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { MatChipInputEvent } from '@angular/material';

import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-branch-details',
  templateUrl: './branch-details.component.html',
  styleUrls: ['./branch-details.component.scss']
})
export class BranchDetailsComponent implements OnInit, OnDestroy {
  remainWeekDays = [];
  changedDaySelectedTimeSlot: any;
  access_token = JSON.parse(localStorage.getItem('currentUser'))[
    'access_token'
  ];
  isEditable = true;
  isOptional = true;
  bi_FormGroup: FormGroup;
  areas_FormGroup: FormGroup;
  ts_FormGroup: FormGroup;
  weekDays = [];
  allWeekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  timeIntrenval: any[] = [
    { key: '15 Min', value: 0 },
    { key: '30 Min', value: 4 },
    { key: '1 Hrs', value: 1 },
    { key: '2 Hrs', value: 2 },
    { key: '3 Hrs', value: 3 },
    { key: '4 Hrs', value: 5 },
    { key: '5 Hrs', value: 6 },
    { key: '6 Hrs', value: 7 }];
  showsveimg = false;
  cancelshow = false;
  spinner = false;
  editphone = true;
  editView = true;
  currentBranch: any;
  branch_phones: any;
  branch_areas: any[] = [];
  payment_methods: any;
  slots: any;
  cities: any;
  paymentMethods: any;
  distircts: any;
  workflows: any;
  cancelationpolicy: any;
  workflow: any;
  currentWeekDays = [];
  timeslotsFGArray: any;
  newTimeSlot: any;
  newUpdatedPone: any;
  temp_branch_phones: any;
  phoneExist: boolean;
  areas_addes: any = {};
  imageName: any;
  daysofWeeks: any[] = [];

  selectedFile: any;
  imagesSources = [];
  areaname: any;
  editarea: any[] = [];
  viewCancelation: boolean;
  maxcollection: number;
  mincollection: number;
  flagcollection: boolean;
  flagcollectionnot: boolean;
  zerocollection: boolean;
  msgcollectionzero: string;
  haspaymentId: number;
  msgcollectionnotZero: string;
  hasPayments: any;
  collection_requirement: any;
  policiesToSave = [];
  daysofWeek = [];
  periodMsg: boolean;
  perioderrorMsg: string;
  dedutionmsg: boolean;
  deductionerrorMsg: string;
  deductiond: any;
  periodd: any;
  dayss: any[] = [];
  policies: any[] = [];
  cancelationForm;
  workflow_id: any;
  payment_method_id: any;
  Tourid: any;
  dataWF: {};
  policyWithId: any;
  selectedPayment: any;
  imgSrc: string;
  areass: any[] = [];
  newareaList: any[] = [];
  workfolow: any;
  work_id: any;
  imgupdate: any;
  selectedFiles: any;
  imagesSourcesupdate: any;
  editArealogo: any[] = [];
  selectedFilew: any;
  imageNameup: any;
  areaaddtable: any[] = [];
  editField: string;
  imgSrcsx: any;
  imagesSourcesw: any = [];
  cancelactionpolist: any[] = [];
  policiesww: any[] = [];
  weekDaysww: any[] = [];
  pays: any;
  work_ids: any;
  latitude: number;
  StringAddress: any;
  longitude: number;
  zoom = 8;
  CoordObject = {
    latlng: ' ',
    key: 'AIzaSyD1uXumOcpOZ5iLZXJe2lwEzn-NT0cw2tw'
  };

  address: string;
  private geoCoder;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild('serchCode') public input: ElementRef;
  getLati: any;
  getLong: any;
  addreesString: any;
  facebook = false;
  insta = false;
  whats = false;
  mob = false;
  Em = false;
  newBranchContactdata = false;
  task = {
    name: 'Select All',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: 'Facebook', completed: false, },
      { name: 'Instagram', completed: false },
      { name: 'Whats app', completed: false },
      { name: 'Mobile', completed: false }
    ]
  };
  contacts = {
    contact_methods: []
  }
  allComplete: boolean = false;
  addnewslotstab = false;

  get timeslots() {
    return this.ts_FormGroup.get('timeslots') as FormArray;
  }
  constructor(
    private _formBuilder: FormBuilder,
    private _httpService: HttpService,
    private tours: ToursService,
    private _toastr: ToastrService,
    private uploadimgService: UploadimgService,
    private _router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpClient
  ) {
    this.currentBranch = JSON.parse(localStorage.getItem('branch'));
    console.log(this.currentBranch, 'sfasfsa');
  }

  ngOnInit() {
    this.setDataForCurrentBranch();
    this.getData();
    this.getWeekDaysss();
    this.newBranchId = this.currentBranch.branch_id;

    this.tours.getContactDataBranch(this.currentBranch.branch_id).subscribe(data => {
      console.log(data['contacts'])
      if (JSON.stringify(data['contacts']) == '{}') {
        this.newBranchContactdata = true;
        // window.alert('sa')

      }
      else {
        // window.alert('fsafsaa')
        this.contacts = data['contacts'];

        // fa
        this.contact_person = this.contacts['contact_person'];
        for (let i of this.contacts.contact_methods) {
          if (i == 1) {
            this.facebook = true;
          }
          if (i == 2) {
            this.insta = true;
          }
          if (i == 3) {
            this.whats = true;
          }
          if (i == 4) {
            this.mob = true;
          } if (i == 5) {
            this.Em = true;
          }

        }
      }



    })
    this.latitude = +this.currentBranch['latitude'];
    this.longitude = +this.currentBranch['longitude'];
    this.CoordObject.latlng = '' + this.latitude + ',' + this.longitude + '';
    console.log(this.CoordObject, 'nnnnnnn');
    this.tours.getAddreesFromGoogleCoord(this.latitude, this.longitude).subscribe(
      create => {
        console.log(create);
        this.StringAddress = create.results[0].formatted_address;
        console.log(this.StringAddress, 'String Addrees');
      },
      error => {
        console.log(error);
        //  alert('erroooooooooooooooooooe');
      }
    );
    console.log(this.latitude + ' ' + this.longitude, 'Goole Maps');
    this.bi_FormGroup = this._formBuilder.group({
      placeName: [
        { value: this.currentBranch['branch_name'], disabled: this.editView },
        Validators.compose([Validators.minLength(2)])
      ],
      city: [
        {
          value: this.currentBranch['branch_city']['name'],
          disabled: this.editView
        }
      ],
      has_note: [{
        value: this.currentBranch['has_note'],
        disabled: this.editView
      }],
      note: [{
        value: this.currentBranch['note'],
        disabled: this.editView
      }],
      payment_threshold: [{
        value: this.currentBranch['payment_threshold'],
        disabled: this.editView
      }],
      area: [
        { value: this.currentBranch['branch_district']['name'], disabled: true }
      ],
      address: [
        { value: this.currentBranch['branch_address'], disabled: this.editView }
      ],
      StringAddress: [
        { value: this.StringAddress, disabled: true }
      ],
      phone: [
        { value: this.branch_phones, disabled: this.editView },
        Validators.compose([
          Validators.pattern(/^-?([0-9]\d*)?$/),
          Validators.maxLength(10)
        ])
      ]
    });
    this.areas_FormGroup = this._formBuilder.group({
      payment: [{ value: '', disabled: this.editView }],
      workflow: [{ value: '' }],
      branchAreaName: [{ value: '', disabled: this.editView }],
      branchAreaNoOfTable: [
        { value: '', disabled: this.editView },
        Validators.compose([Validators.pattern(/^-?([0-9]\d*)?$/)])
      ],
      branchAreaNoOfSeats: [{ value: '', disabled: this.editView }],
      branchAreaCostPerSeats: [
        { value: '', disabled: this.editView },
        Validators.compose([Validators.pattern(/^-?([1-9]\d*)?$/)])
      ],
      addImage: [{ value: '', disabled: this.editView }],
      areaname: [{ value: '', disabled: this.editView }],
      notabel: [{ value: '', disabled: this.editView }],
      seatnumber: [{ value: '', disabled: this.editView }],
      costperseat: [{ value: '', disabled: this.editView }]
    });
    this.ts_FormGroup = this._formBuilder.group({
      timeslots: this._formBuilder.array([])
    });
    this.setTimeSlotsEDFormGroup = this._formBuilder.group({
      // fromcontrollers
      working_hours_from: ['', Validators.required],
      working_hours_to: ['', Validators.required],
      interval: ['', Validators.required]
    });
    this.setTimeSlotsSDFormGroup = this._formBuilder.group({
      timeslots: this._formBuilder.array([
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        }),
        this._formBuilder.group({
          working_hours_from: [''],
          working_hours_to: [''],
          interval: ['']
        })
      ])
    });
    // this.getPayment();
    this.getWeekDays();

    // load Places Autocomplete

  }

  GoogleMapsAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["address"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }


  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }



  setDataForCurrentBranch() {
    this.branch_phones = this.currentBranch['branch_phones'];
    this.branch_areas = this.currentBranch['branch_areas'];
    this.payment_methods = this.currentBranch['payment_methods'];
    this.pays = this.payment_methods[0].id;
    console.log(this.pays, 'pay is');
    this.temp_branch_phones = this.branch_phones;
    if (this.pays === 1) {
      this.haspaymentId = 2;
    }
    if (this.pays === 2) {
      this.haspaymentId = 1;
    }
    this.tours.getWorkFlowPayment(this.haspaymentId).subscribe((data: any) => {
      console.log('data', data);
      this.hasPayments = data.workflows;
    });
  }
  getData() {
    this.getSelectedWorkFlow();
    // get cities
    this._httpService
      .getData(`${environment.apiEndPoint}/vendor/city`, {
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
        access_token: this.access_token
      })
      .subscribe(cities => (this.cities = cities['cities']));
    // get payment_methods
    this._httpService
      .getData(`${environment.apiEndPoint}/payment/methods`)
      .subscribe(
        paymentMethods =>
          (this.paymentMethods = paymentMethods['payment_methods'])
      ); // {"id":1,"name":"Pay on Arrival"},
    // get Days
    this._httpService
      .getData(`${environment.apiEndPoint}/daysofweek/`, {
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
        access_token: this.access_token
      })
      .pipe(
        map(resp => {
          resp['days'].forEach(day => {
            this.weekDays.push(day['name']);
          });
        })
      )
      .subscribe();
    // http://staging.zabatnee.com/api/vendor/timeslot/branch/7131cdc2-a6bd-40e1-9cca-f2bfd38afb91/
    this._httpService
      .getData(
        `${environment.apiEndPoint}/vendor/timeslot/branch/${this.currentBranch.branch_id
        }/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        }
      )
      // {"slots":[{"id":2057,"working_hours_from":"07:00:00","working_hours_to":"23:00:00","interval":2,"day_id":1,"day_name":"Sunday"}],
      // "code":"10000","detail":"Success."}
      .subscribe(slots => {
        console.log(slots, 'slots yala');
        if (slots['slots'].length == 0) {
          this.addnewslotstab = true;
          console.log('no slotst')
        }
        else if (slots['slots'].length > 300) {
          this._toastr.error('Error when created time slots', ' error ');
          this.addnewslotstab = false;

        } else {
          const _slots = slots['slots'];
          this.addnewslotstab = false;

          const _day_names = [];
          // const timeslotsFGArray = this.ts_FormGroup.controls['timeslots']['controls'];
          _slots.forEach(slot => {
            _day_names.push(slot['day_name']);
          });
          this.currentWeekDays = _day_names;
          for (let i = 0; i < this.allWeekDays.length; i++) {
            // if (!(_day_names.indexOf(this.allWeekDays[i]) > -1)) {
            // 	this.remainWeekDays.push(this.allWeekDays[i]);
            // }
            // if (!(_day_names.indexOf(this.allWeekDays[i]) > -1)) {
            this.remainWeekDays.push(this.allWeekDays[i]);
            // }
          }
          for (let i = 0; i < _slots.length; i++) {
            this.timeslots.push(
              this._formBuilder.group({
                day_name: [
                  { value: _slots[i]['day_name'], disabled: this.editView }
                ],
                working_hours_from: [
                  {
                    value: _slots[i]['working_hours_from'],
                    disabled: this.editView
                  }
                ],
                working_hours_to: [
                  {
                    value: _slots[i]['working_hours_to'],
                    disabled: this.editView
                  }
                ],
                interval: [
                  { value: _slots[i]['interval'], disabled: this.editView }
                ],
                id: [_slots[i]['id']],
                day_id: [_slots[i]['day_id']]
              })
            );
          }
        }
      });
    // get workflows
    // http://staging.zabatnee.com/api/vendor/workflow/branch/
    this._httpService
      .getData(
        `${environment.apiEndPoint}/vendor/workflow/branch/${this.currentBranch.branch_id
        }/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        }
      )
      // {"code":"10000","detail":"Success.","workflow":{"id":8,"name":"No Confirmation + Payment","guid":"4db418ab-2bf7-469b-88c8-dd835150468e","hasPayment":true,"state":25,"category":1}}
      .subscribe(
        workflow => (
          console.log(workflow, 'gfsaasgaga'),
          (this.workflow = workflow['name']),
          (this.work_ids = workflow['workflow']['guid']),
          console.log(this.work_ids, this.workflow, 'gfsaasgaga')
        )
      );
    // console.log(this.workflow , 'sfsafasfasfsafasfa')
    // get cancellation poloicy
    // http://staging.zabatnee.com/api/vendor/cancelationpolicy/branch/a081caab-9f59-4d0d-b226-cba8194c7eff/
    this._httpService
      .getData(
        `${environment.apiEndPoint}/vendor/cancelationpolicy/branch/${this.currentBranch.branch_id
        }/`
        // {
        // 	client_id: environment.clientId,
        // 	client_secret: environment.clientSecret,
        // 	access_token: this.access_token
        // }
      )
      // {"code":"10000","detail":"Success.","policies":[]}
      // this.cancelationpolicy = cancelationpolicy['policies']
      .subscribe(
        cancelationpolicy =>
          (this.cancelactionpolist = cancelationpolicy['policies'])
      );
  }
  getSelectedCity(city_id) {
    // get Days
    this._httpService
      .getData(`${environment.apiEndPoint}/distirct/city/${city_id}/`, {
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
        access_token: this.access_token
      })
      .subscribe(distircts => {
        // {"name":"Down Town","cityname":"Cairo","id":4}
        if (distircts['distircts'] && distircts['distircts'].length) {
          console.log(this.distircts)
          this.bi_FormGroup.controls['area'].enable();
          return (this.distircts = distircts['distircts']);
        }
        this.distircts = [];
        this.bi_FormGroup.controls['area'].disable();
        this._toastr.warning(
          'Sorry There are no areas in this city',
          'no areas'
        );
      });
  }
  // convenience getter for easy access to form fields
  get f_areas() {
    return this.areas_FormGroup.controls;
  }
  addNewPhone() {
    return (this.branch_phones[this.branch_phones.length] = '');
  }
  checkExistingPhone(phone) {
    // http://staging.zabatnee.com/api/vendor/phoneexists/12332312312/
    this._httpService
      .getData(`${environment.apiEndPoint}/vendor/phoneexists/${phone}/`)
      .subscribe(resp => {
        if (resp['code'] === '10034') {
          this.phoneExist = true;
        } else if (resp['code'] === '10016') {
          this._toastr.error('Phone already exists in the system.', 'Error');
          this.phoneExist = false;
        }
      });
  }
  getWeekDaysss() {
    this.tours.getWeekDayscancel().subscribe((data: any) => {
      //   console.log(data,'week')
      this.weekDaysww = data.days;
      // console.log(this.weekDaysww, '2ololol');

      for (let i = 0; i < this.weekDaysww.length; i++) {
        this.daysofWeeks.push({
          id: this.weekDaysww[i].id,
          name: this.weekDaysww[i].name,
          selected: false,
          isSet: false
        });
      }
    });
  }

  removePhone(phoneIndex) {
    this.temp_branch_phones.splice(phoneIndex, 1);
  }
  updatePhone(phoneIndex) {
    let currentPhone = this.bi_FormGroup.controls['phone'].value;
    if (!(typeof currentPhone === 'object')) {
      currentPhone = currentPhone.replace('+', '');
      this.checkExistingPhone(currentPhone);
      setTimeout(() => {
        if (this.phoneExist) {
          const reg = /^\d+$/;
          if (reg.test(currentPhone)) {
            this.temp_branch_phones[phoneIndex] = currentPhone;
            this._toastr.success('mobile phone added successfully', 'Success');
          } else if (!reg.test(currentPhone)) {
            this._toastr.error('mobile phone must be number', 'Error');
          }
        }
      }, 1000);
    } else {
      this._toastr.warning('please change somthing before update it', 'Warn');
    }
  }

  saveBasicInfo(formValue) {
    const placName = formValue['placeName'];
    let district_id = formValue['area'];
    const address = formValue['address'];
    let note
    const has_note = formValue['has_note'];
    if (has_note == true) {
      note = formValue['note'];
    }
    else {
      note = '';
    }
    if (!formValue.hasOwnProperty('area')) {
      district_id = this.currentBranch['branch_district']['id'];
    }
    const actualPhones = [];
    for (let i = 0; i < this.branch_phones.length; i++) {
      if (!(this.branch_phones[i] === '')) {
        actualPhones.push(this.branch_phones[i]);
      }
    }
    /**
     * {
     *     "branch_name":"Confirmation + No payment",
     *     "branch_address":"Al worood st. Round Square.",
     *     "branch_district_id":2,
     *     "branch_phones":["01321321321","01222223333"]}
     * */
    const newData = {
      branch_name: placName,
      branch_address: address,
      branch_district_id: district_id,
      has_note: has_note,
      note: note,
      branch_phones: actualPhones,
      longitude: this.longitude,
      latitude: this.latitude,
      threshold: formValue['payment_threshold']
    };
    console.log(newData,);
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/branch/${this.currentBranch['branch_id']
        }/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        newData
      )
      .subscribe(resp => {
        if (resp['code'] === '10000') {
          this._toastr.success('Data updated successfully.', 'Done');
        }
      });
  }
  // *********************************
  //  Areas
  // *********************************
  getSelectedPaymentMethod(value) {
    console.log('selected payment', value);
  }
  getSelectedWorkFlow() {
    // get cities
    this._httpService
      .getData(
        `${environment.apiEndPoint}/vendor/workflow/branch/${this.currentBranch.branch_id
        }/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        }
      )
      .subscribe(
        cities => (
          (this.workfolow = cities['workflow']['name']),
          (this.work_id = cities['workflow']['guid'])
        )
      );
  }

  addTimeSlot() {
    // if (this.timeslots.length < 7) {
    this.newTimeSlot = true;
    this.timeslots.push(
      this._formBuilder.group({
        day_name: [''],
        working_hours_from: [''],
        working_hours_to: [''],
        interval: ['']
      })
    );
    // }
  }
  getDaySelectedTimeSlot(event) {
    this.changedDaySelectedTimeSlot = event.value;
  }
  deleteimeSlotChange(currentSlot) {
    let id = undefined;

    const days_per_id = [
      { day_id: 1, day_name: 'Sunday' },
      { day_id: 2, day_name: 'Monday' },
      { day_id: 3, day_name: 'Tuesday' },
      { day_id: 4, day_name: 'Wednesday' },
      { day_id: 5, day_name: 'Thursday' },
      { day_id: 6, day_name: 'Friday' },
      { day_id: 7, day_name: 'Saturday' }
    ];
    for (let i = 0; i < days_per_id.length; i++) {
      if (days_per_id[i]['day_name'] === this.changedDaySelectedTimeSlot) {
        id = days_per_id[i]['day_id'];
      }
    }
    // console.log(this.ts_FormGroup.controls['timeslots']['controls'][0]['controls']['day_name'].disable());
    const slotObj = {
      interval: currentSlot.value['interval'] + 1,
      time_from: currentSlot.value['working_hours_from'].slice(0, 5),
      time_to: currentSlot.value['working_hours_to'].slice(0, 5),
      day_id: currentSlot.value['day_id']
    };
    const newSlotObj = {
      slots: [
        {
          branch_id: this.currentBranch.branch_id,
          days: [
            {
              interval: currentSlot.value['interval'] + 1,
              working_hours_from: currentSlot.value['working_hours_from'].slice(
                0,
                5
              ),
              working_hours_to: currentSlot.value['working_hours_to'].slice(
                0,
                5
              ),
              day_id: id
            }
          ]
        }
      ]
    };

    if (id !== undefined) {
      return this._httpService
        .deleteData(
          `${environment.apiEndPoint}/vendor/timeslot/`,
          {
            client_id: environment.clientId,
            client_secret: environment.clientSecret,
            access_token: this.access_token
          },
          newSlotObj
        )
        .subscribe(resp => {
          if (resp['code'] === '10000') {
            this._toastr.success('Time Slot Updated', 'Done');
            location.reload();


          }
        });
    }

    return this._httpService
      .deleteData(
        `${environment.apiEndPoint}/vendor/timeslot/${currentSlot.value['id']
        }/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        slotObj
      )
      .subscribe(resp => {
        if (resp['code'] === '10000') {
          this._toastr.success('Time Slot Updated', 'Done');
          location.reload();

        }
      });
  }
  saveTimeSlotChange(currentSlot) {
    // {time_from: "12:00", time_to: "23:00", interval: 2, day_id: 2}
    // 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'
    let id = undefined;

    const days_per_id = [
      { day_id: 1, day_name: 'Sunday' },
      { day_id: 2, day_name: 'Monday' },
      { day_id: 3, day_name: 'Tuesday' },
      { day_id: 4, day_name: 'Wednesday' },
      { day_id: 5, day_name: 'Thursday' },
      { day_id: 6, day_name: 'Friday' },
      { day_id: 7, day_name: 'Saturday' }
    ];
    for (let i = 0; i < days_per_id.length; i++) {
      if (days_per_id[i]['day_name'] === this.changedDaySelectedTimeSlot) {
        id = days_per_id[i]['day_id'];
      }
    }
    // console.log(this.ts_FormGroup.controls['timeslots']['controls'][0]['controls']['day_name'].disable());
    const slotObj = {
      interval: currentSlot.value['interval'],
      time_from: currentSlot.value['working_hours_from'].slice(0, 5),
      time_to: currentSlot.value['working_hours_to'].slice(0, 5),
      day_id: currentSlot.value['day_id']
    };
    const newSlotObj = {
      slots: [
        {
          branch_id: this.currentBranch.branch_id,
          days: [
            {
              interval: currentSlot.value['interval'],
              working_hours_from: currentSlot.value['working_hours_from'].slice(
                0,
                5
              ),
              working_hours_to: currentSlot.value['working_hours_to'].slice(
                0,
                5
              ),
              day_id: id
            }
          ]
        }
      ]
    };

    if (id !== undefined) {
      return this._httpService
        .postData(
          `${environment.apiEndPoint}/vendor/timeslot/`,
          {
            client_id: environment.clientId,
            client_secret: environment.clientSecret,
            access_token: this.access_token
          },
          newSlotObj
        )
        .subscribe(resp => {
          if (resp['code'] === '10000') {
            this._toastr.success('Time Slot Updated', 'Done');
          }
        });
    }

    return this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/timeslot/${currentSlot.value['id']
        }/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        slotObj
      )
      .subscribe(resp => {
        if (resp['code'] === '10000') {
          this._toastr.success('Time Slot Updated', 'Done');
        }
      });
  }
  editViewHandler() {
    if (this.editView) {
      this.GoogleMapsAutocomplete();
      Object.keys(this.bi_FormGroup.controls).forEach(key => {
        if (key !== 'area') {
          this.bi_FormGroup.get(key).enable();
        }
      });
      Object.keys(this.areas_FormGroup.controls).forEach(key => {
        this.areas_FormGroup.get(key).enable();
      });
      Object.keys(this.ts_FormGroup.controls).forEach(key => {
        this.ts_FormGroup.get(key).enable();
      });
      Object.keys(this.ts_FormGroup.controls['timeslots']['controls']).forEach(
        (key, index) => {
          this.ts_FormGroup.controls['timeslots']['controls'][index][
            'controls'
          ]['day_name'].disable();
        }
      );
    }
    if (!this.editView) {
      Object.keys(this.bi_FormGroup.controls).forEach(key => {
        if (key !== 'area') {
          this.bi_FormGroup.get(key).disable();
        }
      });
      Object.keys(this.areas_FormGroup.controls).forEach(key => {
        this.areas_FormGroup.get(key).disable();
      });
      Object.keys(this.ts_FormGroup.controls).forEach(key => {
        this.ts_FormGroup.get(key).disable();
      });
    }
    this.editView = !this.editView;

    const form = this.areas_FormGroup;
    const cost_per_seat = form.get('branchAreaCostPerSeats');

    if (this.currentBranch['payment_methods'][0]['id'] === 1) {
      const kaka = '0';
      this.areas_FormGroup.controls['branchAreaCostPerSeats'].setValue(kaka);
      this.areas_FormGroup.controls['branchAreaCostPerSeats'].disable();
    }

    if (this.currentBranch['payment_methods'][0]['id'] === 2) {
      if (cost_per_seat.value === 0) {
        this._toastr.error('cant set the cost = Zero ', 'error');
      }
    }
  }
  ngOnDestroy() {
    localStorage.removeItem('branch');
  }
  selectFile(event) {
    this.selectedFile = event.target.files;
    if (event.target.files && event.target.files[0] && event !== undefined) {
      const file = event.target.files[0];
      this.imageName = file['name'];
      const reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = onload => {
        // called once readAsDataURL is completed
        this.imagesSources.push(onload.target['result']);
        this.imgSrc = onload.target['result'];
      };
      // this.selectedFiles.push(targetFile);
      // const fileee = Array.from(targetFile);
      // this.selectedFiles.push(fileee);
    }
  }
  selectFilet(event, index) {
    // console.log(index , 'img')
    // this.selectedFiles = event.target.files;
    //     if (this.branch_areas[index]['photo'] === true){
    //     }.
    this.editArealogo[index] = true;
    this.showsveimg = false;

    this.selectedFilew = event.target.files;
    if (event.target.files && event.target.files[0] && event !== undefined) {
      const file = event.target.files[0];
      this.imageNameup = file['name'];
      const reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = onload => {
        // called once readAsDataURL is completed
        this.imagesSourcesw.push(onload.target['result']);
        console.log(this.imagesSourcesw, 's');
        this.imgSrcsx = onload.target['result'];
        this.branch_areas[index]['photo'] = this.imageNameup;
        // console.log(this.imgSrc, 'ss')
      };
      if (this.imageNameup.length > 25) {
        swal(
          'Your image name is so long please rename it and try again',
          'error',
          'warning'
        );
      } else {
        const path = 'branch/area/card';
        const file1 = this.selectedFilew.item(0);
        console.log('POP', file1);
        this.uploadimgService.uploadFile(file1, path);
        // if (this.imgSrc !== undefined) {
        // 	this.branch_areas[index]['photo'] = this.imageNameup;
        // }
        this.areass.push({
          guid: this.branch_areas[index]['guid'],
          photo: this.imageNameup
        });

        console.log(this.areass, 'have index');
      }
      // this.areass.push({
      //   guid: this.branch_areas[index]['guid'],
      //   photo: this.imageNameup
      // });
      // if (this.editArealogo[index] === true) {
      // 	console.log(this.imageNameup, 'img' + index);

      // 	if (this.imgSrc !== undefined) {
      // 		this.branch_areas[index]['photo'] = this.imageNameup;
      // 	}

      // } else {
      // 	this.areass.push({
      // 		guid: this.branch_areas[index]['guid'],
      // 		photo: ''
      // 	});
      // 	console.log(index);

      // 	// swal(this.branch_areas[index]['name'] , 'Is Edit thanks' , 'success')
      // 	console.log(this.areass, 'no index');
      // }
      // if (event.target.files && event.target.files[0] && event !== undefined) {
      // 	const file = event.target.files[0];
      // 	this.imageName = file['name'];
      // 	const reader = new FileReader();
      // 	reader.readAsDataURL(file); // read file as data url
      // 	reader.onload = onload => {
      // 		// called once readAsDataURL is completed
      // 		this.imagesSourcesupdate.push(onload.target['result']);
      // 		this.imgupdate = onload.target['result'];
      //   };
      //   const f_image = '';
      //   this.branch_areas[index]['cost_per_seat'] = f_image  ;

      // 	// this.selectedFiles.push(targetFile);
      // 	// const fileee = Array.from(targetFile);
      // 	// this.selectedFiles.push(fileee);
      // }
    }
  }
  removeAreaAdded(areaIndex) {
    this.areas_addes.splice(areaIndex, 1);
  }
  addAreas() {
    const form = this.areas_FormGroup;
    const name = form.get('branchAreaName');
    const cost_per_seat = form.get('branchAreaCostPerSeats');
    const seats_number = form.get('branchAreaNoOfSeats');
    const tables_number = form.get('branchAreaNoOfTable');
    // selectedFile
    if (name.value === '' || name.value === null) {
      swal('please add area Name', 'error', 'error');
    } else if (tables_number.value === '' || tables_number.value === null) {
      swal('please add table number', 'error', 'error');
    } else if (seats_number.value === '' || seats_number.value === null) {
      swal('please add seats number', 'error', 'error');
    } else if (this.imageName === undefined || this.imageName === null) {
      swal('please add image', 'error', 'error');
    } else {
      const path = 'branch/area/card';
      const file1 = this.selectedFile.item(0);
      console.log('POP', file1);
      this.uploadimgService.uploadFile(file1, path);
      this.areas_addes = {
        area_name: name.value,
        cost_per_seat: cost_per_seat.value || 0,
        seats_number: seats_number.value,
        tables_number: tables_number.value,
        photo: this.imageName,
        branch_id: this.currentBranch.branch_id
      };

      this._httpService
        .postData(
          `${environment.apiEndPoint}/vendor/restaurant/branch/area/`,
          {
            client_id: environment.clientId,
            client_secret: environment.clientSecret,
            access_token: this.access_token
          },
          this.areas_addes
        )
        .subscribe(resp => {
          if (resp['code'] === '10000') {
            this._toastr.success('Data updated successfully.', 'Done');
            this.areaaddtable.push(this.areas_addes);
          }
        });
      seats_number.reset();
      tables_number.reset();
      name.reset();
      this.imageName = null;
      cost_per_seat.reset();

      console.log(this.areas_addes, 'area added');
    }
    // this._httpService
    // .getData(`${environment.apiEndPoint}/vendor/restaurant/branch/${this.currentBranch.branch_id}/`, {
    //   client_id: environment.clientId,
    //   client_secret: environment.clientSecret,
    //   access_token: this.access_token
    // })
    // .subscribe(distircts => {
    //   // {"name":"Down Town","cityname":"Cairo","id":4}
    //         console.log(distircts)
    // });
  }

  updateSpecificArea(area, index) {
    /**
    cost_per_seat: 12
    guid: "31d80f7b-e673-4a33-86fb-f702e5a0a271"
    name: "Outdoor"
    photo: "https://zabatnee-backend-storage.s3.amazonaws.com/branch/area/card/1534164061973.jpg?AWSAccessKeyId=AKIAIRJ3BOTT2NB3ODSQ&Expires=1543181388&Signature=1CXFREH704j24jgoBi6Lzptkipw%3D"
    seats_number: 401
    tables_number: 12
    */
    if (this.editArealogo[index] === true) {
      console.log(this.imageNameup, 'img' + index);

      if (this.imgSrc !== undefined) {
        this.branch_areas[index]['photo'] = this.imgSrc;
      }
      this.areass.push({
        guid: this.branch_areas[index]['guid'],
        photo: this.imageNameup
      });
      console.log(this.areass, 'have index');
    } else {
      this.areass.push({
        guid: this.branch_areas[index]['guid'],
        photo: this.branch_areas[index]['photo']
      });
      console.log(index);

      // swal(this.branch_areas[index]['name'] , 'Is Edit thanks' , 'success')
      console.log(this.areass, 'no index');
    }
  }
  deletearea(a) {
    const index = this.branch_areas.indexOf(a);
    this.branch_areas.splice(index, 1);
  }

  // getPayment() {
  // 	this.tours.getPaymentMethod().subscribe((data: any) => {
  // 		this.paymentMethods = data.payment_methods;
  // 	});
  // 	this.tours.getWorkFlowPayment(this.haspaymentId).subscribe((data: any) => {
  // 		this.hasPayments = data.workflows;
  // 	});
  // }
  getWeekDays() {
    this.tours.getWeekDays().subscribe((data: any) => {
      // console.log(data,'week')
      this.weekDays = data.days;

      for (let i = 0; i < this.weekDays.length; i++) {
        this.daysofWeek.push({
          id: this.weekDays[i].id,
          name: this.weekDays[i].name,
          selected: false,
          isSet: false
        });
      }
    });
  }
  changePayment(paymentid) {
    if (paymentid === 1) {
      // pay on arrival
      this.viewCancelation = false;
      this.haspaymentId = 2;
      // this._toastr.warning('should set the cost = Zero', 'error');
      for (let x = 0; x < this.branch_areas.length; x++) {
        if (this.branch_areas[x]['cost_per_seat'] !== 0) {
          swal('please set the cost in all areas = 0', 'thanks', 'warning');
        }
      }
      if (this.areas_FormGroup.controls['branchAreaCostPerSeats'].value !== 0) {
        // this._toastr.warning('should set the cost = Zero', 'error');
        const kaka = '0';
        this.areas_FormGroup.controls['branchAreaCostPerSeats'].setValue(kaka);
        this.areas_FormGroup.controls['branchAreaCostPerSeats'].disable();
      }
      // console.log("tt", this.mincollection, this.maxcollection);
    } else {
      // 2 credit card
      this.viewCancelation = true;
      this.haspaymentId = 1;
      this._toastr.warning(
        'your payment is credit card check ur number agian',
        'thanks'
      );
      this.areas_FormGroup.controls['branchAreaCostPerSeats'].setValue(1);
      this.areas_FormGroup.controls['branchAreaCostPerSeats'].enable();
      if (this.areas_FormGroup.controls['branchAreaCostPerSeats'].value === 0) {
        this._toastr.warning(
          'your payment is credit card check ur number agian',
          'thanks'
        );
        // this.areas_FormGroup.controls['branchAreaCostPerSeats'].setValue(1);
        // this.areas_FormGroup.controls['branchAreaCostPerSeats'].enable();
      }
    }
    this.tours.getWorkFlowPayment(this.haspaymentId).subscribe((data: any) => {
      this.hasPayments = data.workflows;
    });
  }
  AddRestaurantPolicy() {
    if (this.periodd === undefined) {
      this.periodMsg = true;
      this.perioderrorMsg = 'please enter period';
    } else if (this.deductiond === undefined) {
      this.dedutionmsg = true;
      this.deductionerrorMsg = 'please enter deduction';
    } else {
      this.periodMsg = false;
      this.dedutionmsg = false;
      const policyObj = {};
      this.dayss = [];
      for (let j = 0; j < this.daysofWeek.length; j++) {
        if (this.daysofWeek[j].selected === true) {
          this.dayss.push({
            period: this.periodd,
            deduction: this.deductiond,
            day_name: this.daysofWeek[j].name,
            day_id: this.daysofWeek[j].id
          });
          this.policiesww.push({
            period: this.periodd,
            deduction: this.deductiond,
            day_name: this.daysofWeeks[j].name,
            day_id: this.daysofWeeks[j].id.toString()
          });
        }
      }
      this.policiesToSave.push({ days: this.dayss });
      const policyy = {};
      policyy['periodd'] = '';
      policyy['deductiond'] = '';
      this.cancelationForm = {};
      this.periodd = '';
      this.deductiond = '';
      console.log(this.policiesToSave);
      // console.log(this.policies, 'a7a');
    }
  }
  removePolicy(index) {
    this.policiesToSave.splice(index, 1);
    this.policies.splice(index, 1);
    this.dayss.splice(index, 1);
    this.policiesww = [];
  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
  }
  updateList(id: number, property: any, event: any) {
    // if (this.editArealogo[id] === true) {
    // 	console.log(this.imageNameup, 'img' + id);

    // 	if (this.imgSrc !== undefined) {
    // 		this.branch_areas[id]['photo'] = this.imgSrc;
    // 	}
    // 	this.areass.push({
    // 		guid: this.branch_areas[id]['guid'],
    // 		photo: this.imageNameup
    //   });
    //   this.branch_areas[id][property] = editField;

    //   console.log(this.areass, 'have index');
    // }
    // console.log(editField , 's 0 ' , property , 'sfa' )
    // if(property === 'cost_per_seat'){
    // 	let x ;
    // 	if (x !== Number){
    // 		console.log('a7a')
    // 	}
    // }

    // if(x === number){
    // }
    const editField = event.target.textContent;
    console.log(this.branch_areas[id]['photo'], 'pho');
    // if ( this.branch_areas[id]['photo'] !== id){
    //   this.branch_areas[id]['photo'] = '';
    //   console.log('a7a' , this.branch_areas[id])

    // }
    this.branch_areas[id][property] = editField;
    console.log(this.branch_areas, 'push');
  }

  saveArea(formValue) {
    // for (let x = 0 ; x < this.branch_areas.length ; x++){
    // 	if(this.branch_areas[x]['cost_per_seat'] !== Number){
    // 	}

    // }
    for (let i = 0; i < this.cancelactionpolist.length; i++) {
      if (
        this.cancelactionpolist[i].period === undefined ||
        this.cancelactionpolist[i].deduction === undefined
      ) {
        swal('please provide missing parameters!', '', 'error');
      } else {
        this.policies.push({
          policy_id: this.cancelactionpolist[i].id,
          day: this.cancelactionpolist[i].day,
          // tslint:disable-next-line: radix
          period: parseInt(this.cancelactionpolist[i].period),
          // tslint:disable-next-line: radix
          deduction: parseInt(this.cancelactionpolist[i].deduction),
          day_id: this.cancelactionpolist[i].day_id
        });
      }
    }

    if (this.areas_FormGroup.get('payment').value === 2) {
      this.dataWF = {
        payment_method_id: this.areas_FormGroup.get('payment').value,
        collection_requirements: '5',
        workflow_id: this.areas_FormGroup.get('workflow').value,
        policies: this.policiesww
      };
    } else {
      this.dataWF = {
        payment_method_id: this.areas_FormGroup.get('payment').value,
        collection_requirements: '0',
        workflow_id: this.areas_FormGroup.get('workflow').value
      };
    }
    for (let k = 0; k < this.branch_areas.length; k++) {
      if (this.branch_areas[k].photo.length > 25) {
        this.newareaList[k] = {
          area_name: this.branch_areas[k].name,
          // tslint:disable-next-line: radix
          cost_per_seat: parseInt(this.branch_areas[k].cost_per_seat),
          // 'photo': this.branch_areas[k].photo,
          photo: '',
          seats_number: this.branch_areas[k].seats_number,
          tables_number: this.branch_areas[k].tables_number,
          guid: this.branch_areas[k].guid
        };
      } else {
        this.newareaList[k] = {
          area_name: this.branch_areas[k].name,
          // tslint:disable-next-line: radix
          cost_per_seat: parseInt(this.branch_areas[k].cost_per_seat),
          // 'photo': this.branch_areas[k].photo,
          photo: this.branch_areas[k].photo,
          seats_number: this.branch_areas[k].seats_number,
          tables_number: this.branch_areas[k].tables_number,
          guid: this.branch_areas[k].guid
        };
        if (this.newareaList[k].guid === this.branch_areas[k].guid) {
          console.log('thanls');
        }
      }
    }
    const cancelation = {
      policies: [
        {
          branch_id: this.currentBranch.branch_id,
          days: this.policiesww
        }
      ]
    };
    console.log(this.work_ids, 'sz');
    console.log(this.areas_FormGroup.get('workflow').value, 'sz');
    console.log(this.areas_FormGroup.get('payment').value, 'sz');
    let mama;
    mama = this.areas_FormGroup.get('workflow').value;
    const kkk = mama.value;
    console.log(kkk, 'fasjlfkasfnmkl');
    if (kkk === '') {
      this.areas_FormGroup.controls['workflow'].setValue(this.work_ids);
    }

    const finaledit = {
      areas: this.newareaList,
      payment_method_id:
        this.areas_FormGroup.get('payment').value ||
        this.currentBranch['payment_methods'][0]['id'],
      workflow_guid: this.areas_FormGroup.get('workflow').value,
      cancelation_policies: [{ days: this.policies }],
      branch_id: this.currentBranch.branch_id
    };
    console.log(finaledit, 'data will send to service');
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/deposite/update/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        finaledit
      )
      .subscribe(resp => {
        console.log(resp);
        const x = resp['code'];
        if (x === '10000') {
          this.newareaList = [];
          this.areass = [];
          this._toastr.success('Data updated successfully.', 'Done');

          this._router.navigate(['/R/branches']);
        } else if (x === '11042') {
          // console.log("no deposite");
          swal('Branch areas should not have deposite!', '', 'error');
        } else if (x === '11043') {
          // console.log("work with pay");
          swal('Choose workflow with payment!', '', 'error');
        } else if (x === '11041') {
          // console.log("areas with opay");
          swal('Branch areas should have deposite!', '', 'error');
        } else if (x === '11044') {
          // console.log("wf with no pay");
          swal('Choose workflow without payment!', '', 'error');
        }
        this._httpService
          .postData(
            `${environment.apiEndPoint}/vendor/cancelationpolicy/`,
            {
              client_id: environment.clientId,
              client_secret: environment.clientSecret,
              access_token: this.access_token
            },
            cancelation
            // tslint:disable-next-line: no-shadowed-variable
          )
          // tslint:disable-next-line: no-shadowed-variable
          .subscribe(resp => {
            console.log(resp, 'sssss');
            if (resp['code'] === '10000') {
              // this._route.navigate(['/R/branches']);
              // this._toastr.success('Canclation is done', 'Welcome');
              // this._router.navigate(['/R/branches']);
            }
          });
      });
  }
  editPolicy(a) {
    console.log(a, ' ssf  ');
    if (a === '') {
      swal('Please enter missing paramters!', '', 'error');
    }
    for (let i = 0; i < a.length; i++) {
      if (a[i].period === '' || a[i].deduction === '') {
        swal('Please enter missing paramters!', '', 'error');
      } else {
        swal('changes has been done', 'done', 'success');
      }
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checknumber(ss) {
    if (ss > 100) {
      swal('cant set number > 100 le ', 'error', 'error');
    }
  }

  mapClicked($event: MouseEvent) {
    // {"coords":{"lat":30.163218889001502,"lng":30.161410466523535}}
    console.log($event)
    const latitude = $event['coords']['lat'];
    const longitude = $event['coords']['lng'];
    this.latitude = latitude;
    this.longitude = longitude;
  }
  googlepluscode(event: any) {
    this.spinner = true;
    const plusCode = this.input.nativeElement.value;
    this.tours.getGooglePulsCode(plusCode).subscribe((data: any) => {
      console.log(data.plus_code.geometry.location.lat);
      // console.log();
      this.spinner = false;
      this.latitude = data.plus_code.geometry.location.lat;
      this.longitude = data.plus_code.geometry.location.lng;
      this.zoom = 15;
    });
  }

  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
    console.log(this.task.subtasks)

  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    console.log(this.task.subtasks)

    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;

  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => t.completed = completed);
    console.log(this.task.subtasks)
  }
  selectFacebook(e: MatCheckboxChange) {
    console.log(e)
    if (e.checked == true) {
      this.contacts['contact_methods'].push(1)
    }
    else if (e.checked == false) {
      const index = this.contacts.contact_methods.indexOf(1);

      if (index >= 0) {
        this.contacts.contact_methods.splice(index, 1);
      }
      console.log(this.contacts.contact_methods);

    }
  }
  selectInstagram(e: MatCheckboxChange) {
    console.log(e)
    if (e.checked == true) {
      this.contacts['contact_methods'].push(2)
    }
    else if (e.checked == false) {
      const index = this.contacts.contact_methods.indexOf(2);

      if (index >= 0) {
        this.contacts.contact_methods.splice(index, 1);
      }
      console.log(this.contacts.contact_methods);

    }
  }
  selectWhatsapp(e: MatCheckboxChange) {
    console.log(e)
    if (e.checked == true) {
      this.contacts['contact_methods'].push(3)
    }
    else if (e.checked == false) {
      const index = this.contacts.contact_methods.indexOf(3);

      if (index >= 0) {
        this.contacts.contact_methods.splice(index, 1);
      }
      console.log(this.contacts.contact_methods);

    }
  }
  selectMob(e: MatCheckboxChange) {
    console.log(e)
    if (e.checked == true) {
      this.contacts['contact_methods'].push(4)
    }
    else if (e.checked == false) {
      const index = this.contacts.contact_methods.indexOf(4);

      if (index >= 0) {
        this.contacts.contact_methods.splice(index, 1);
      }
      console.log(this.contacts.contact_methods);

    }
  }
  selectEm(e: MatCheckboxChange) {
    console.log(e)
    if (e.checked == true) {
      this.contacts['contact_methods'].push(5)
    }
    else if (e.checked == false) {
      const index = this.contacts.contact_methods.indexOf(5);

      if (index >= 0) {
        this.contacts.contact_methods.splice(index, 5);
      }
      console.log(this.contacts.contact_methods);

    }
  }

  contact_person: any[] = [];
  contact_methods: any[] = [];

  Textpersong;
  addPerson(ss) {
    console.log(ss)
    this.contact_person.push(ss);
    this.Textpersong = ''
  }
  removedate(data: any) {
    console.log(data)
    const index = this.contact_person.indexOf(data);

    if (index >= 0) {
      this.contact_person.splice(index, 1);
    }
    console.log(this.contact_person);

  }

  saveContact(data) {
    if (this.newBranchContactdata == true) {
      console.log(this.contacts['contact_from'].slice(-3))
      if (this.contacts['contact_from'].length == 8) {
        this.contacts['contact_from'] = this.contacts['contact_from']

      }
      else {
        this.contacts['contact_from'] = this.contacts['contact_from'] + ':00'

      }
      if (this.contacts['contact_to'].length == 8) {
        this.contacts['contact_to'] = this.contacts['contact_to']

      }
      else {
        this.contacts['contact_to'] = this.contacts['contact_to'] + ':00'

      }


      this.contacts['branch_guid'] = this.currentBranch.branch_id;
      this.contacts['contact_from'] = this.contacts['contact_from']
      this.contacts['contact_to'] = this.contacts['contact_to']
      this.contacts['contact_person'] = this.contact_person;



      console.log(data)
      console.log(this.contacts['contact_methods'])
      this.tours.addContactDataBranch(data).subscribe(data => {
        console.log(data);
        swal('Contact Data Was send', '', 'success');
      }, err => {
        console.log(err)
        swal('Contact Data Was not send', '', 'error');

      })

    } else {
      console.log(this.contacts['contact_from'].length)

      console.log(this.contacts['contact_from'].slice(-3))
      if (this.contacts['contact_from'].length == 8) {
        this.contacts['contact_from'] = this.contacts['contact_from']

      }
      else {
        this.contacts['contact_from'] = this.contacts['contact_from'] + ':00'

      }
      if (this.contacts['contact_to'].length == 8) {
        this.contacts['contact_to'] = this.contacts['contact_to']

      }
      else {
        this.contacts['contact_to'] = this.contacts['contact_to'] + ':00'

      }


      this.contacts['branch_guid'] = this.currentBranch.branch_id;
      this.contacts['contact_from'] = this.contacts['contact_from']
      this.contacts['contact_to'] = this.contacts['contact_to']
      this.contacts['contact_person'] = this.contact_person;



      console.log(data)
      console.log(this.contacts['contact_methods'])
      this.tours.editContactDataBranch(data).subscribe(data => {
        console.log(data);
        swal('Contact Data Was send', '', 'success');
      }, err => {
        console.log(err)
        swal('Contact Data Was not send', '', 'error');

      })

    }

  }

  DaysNames: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  weekDayss = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  DaysISONames: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  daypush: any[] = [];
  setTimeSlotsSDFormGroup: FormGroup;
  setTimeSlotsEDFormGroup: FormGroup;
  newBranchId
  selectedd: any;
  getSelectedInterval(selectedInterval) {
    this.setTimeSlotsEDFormGroup.controls['interval'].setValue(
      (this.selectedd = selectedInterval)
    );
    console.log(this.selectedd, ' intet');

    // this.tours.getPaymentMethod().subscribe((data: any) => {
    // 	this.paymentMethods = data.payment_methods;
    // });
    // this.tours.getWorkFlowPayment(this.haspaymentId).subscribe((data: any) => {
    // 	this.hasPayments = data.workflows;
    // });
  }
  addTimeslotsnewdays(formValue) {
    const working_hours_from = formValue['working_hours_from'];
    const working_hours_to = formValue['working_hours_to'];
    const interval = formValue['interval'];
    const days = [];
    for (let index = 0; index < this.DaysNames.length; index++) {
      days.push({
        id: index + 1,
        number: index + 1,
        name: this.DaysNames[index],
        iso: this.DaysISONames[index],
        working_hours_from: working_hours_from,
        working_hours_to: working_hours_to,
        interval: interval,
        day_id: index + 1
      });
    }
    this.daypush.push(days);
    console.log(this.daypush, 'days');

    const slots = {
      slots: [
        {
          branch_id: this.newBranchId || '',
          days: days
        }
      ]
    };
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/timeslot/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        slots
      )
      .subscribe(res => {
        console.log('asdasd', res);
        if (res['code'] === '10000') {
          // stepper.next();
          this.setTimeSlotsEDFormGroup.reset()
          this._toastr.success('Add New Slots')


        }
      });
  }
  addTimeslotsnew(formValue) {
    const timeslotsArr = formValue['timeslots'];
    const days = [];
    const regTime = /^\d{1,2}:\d{2}([ap]m)?$/;

    for (let index = 0; index < this.DaysNames.length; index++) {
      // regular expression to match required time format
      const working_hours_from = timeslotsArr[index]['working_hours_from'];
      const working_hours_to = timeslotsArr[index]['working_hours_to'];
      const interval = timeslotsArr[index]['interval'];
      if (
        working_hours_from !== '' &&
        regTime.test(working_hours_from) &&
        working_hours_to !== '' &&
        regTime.test(working_hours_to) &&
        interval !== ''
      ) {
        days.push({
          id: index + 1,
          number: index + 1,
          name: this.DaysNames[index],
          iso: this.DaysISONames[index],
          working_hours_from: working_hours_from,
          working_hours_to: working_hours_to,
          interval: interval,
          day_id: index + 1
        });
        this.daypush.push(days);
        console.log(this.daypush, 'days');
      }
    }
    if (this.newBranchId === '') {
      return this._toastr.error('you must create branch first', 'ERROR');
    }
    const slots = {
      slots: [
        {
          branch_id: this.newBranchId || '',
          days: days
        }
      ]
    };
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/timeslot/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        slots
      )
      .subscribe(res => {
        console.log('asdasd', res);
        this.setTimeSlotsSDFormGroup.reset()
        this._toastr.success('Add New Slots')

        // stepper.next();
      });
    // stepper.next();
  }
  addTimeSlotsSpecifiDays(formValue) {
    const timeslotsArr = formValue['timeslots'];
    const days = [];
    const regTime = /^\d{1,2}:\d{2}([ap]m)?$/;

    for (let index = 0; index < this.DaysNames.length; index++) {
      // regular expression to match required time format
      const working_hours_from = timeslotsArr[index]['working_hours_from'];
      const working_hours_to = timeslotsArr[index]['working_hours_to'];
      const interval = timeslotsArr[index]['interval'];
      if (
        working_hours_from !== '' &&
        regTime.test(working_hours_from) &&
        working_hours_to !== '' &&
        regTime.test(working_hours_to) &&
        interval !== ''
      ) {
        days.push({
          id: index + 1,
          number: index + 1,
          name: this.DaysNames[index],
          iso: this.DaysISONames[index],
          working_hours_from: working_hours_from,
          working_hours_to: working_hours_to,
          interval: interval,
          day_id: index + 1
        });
        this.daypush.push(days);
        console.log(this.daypush, 'days');
      }
    }
    if (this.newBranchId === '') {
      return this._toastr.error('you must create branch first', 'ERROR');
    }
    const slots = {
      slots: [
        {
          branch_id: this.newBranchId || '',
          days: days
        }
      ]
    };
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/timeslot/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        slots
      )
      .subscribe(res => {
        console.log('asdasd', res);
        // stepper.next();
        this._toastr.success('time slots was added')

      });
    // stepper.next();
  }
  addTimeSlotsEveryDay(formValue) {
    // {working_hours_from: "01:01", working_hours_to: "01:01", interval: 2}
    const working_hours_from = formValue['working_hours_from'];
    const working_hours_to = formValue['working_hours_to'];
    const interval = formValue['interval'];
    const days = [];
    for (let index = 0; index < this.DaysNames.length; index++) {
      days.push({
        id: index + 1,
        number: index + 1,
        name: this.DaysNames[index],
        iso: this.DaysISONames[index],
        working_hours_from: working_hours_from,
        working_hours_to: working_hours_to,
        interval: interval,
        day_id: index + 1
      });
    }
    this.daypush.push(days);
    console.log(this.daypush, 'days');

    const slots = {
      slots: [
        {
          branch_id: this.newBranchId || '',
          days: days
        }
      ]
    };
    this._httpService
      .postData(
        `${environment.apiEndPoint}/vendor/timeslot/`,
        {
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
          access_token: this.access_token
        },
        slots
      )
      .subscribe(res => {
        console.log('asdasd', res);
        if (res['code'] === '10000') {
          // stepper.next();
          this._toastr.success('time slots was added')
        }
      });
    // stepper.next();
  }
}
