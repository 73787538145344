import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class BranchNameValiator {
	/* static */ checkBranchName(
		control: AbstractControl
	): { [key: string]: boolean } | null {
		if (control.value) {
			const value = control.value.trim().toLowerCase();
			return { validateBranchName: true };
		}
		return null;
	}
}
