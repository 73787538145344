import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-manage-user',
	templateUrl: './manage-user.component.html',
	styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit {
	users = [
		{
			name: 'Ahmed Saaed',
			email: 'ahmedsaaed@gmail.com',
			phone: '+020100000000',
			role: 'Owner',
			store: 'All',
			access:
				'Financials, Events List, Reservations, Create Reservations, Manage Users, ...'
		},
		{
			name: 'Mostafa Akram',
			email: 'mostafaakram000@gmail.com',
			phone: '+020114804783',
			role: 'Point of sale',
			store: 'Opera',
			access: 'Reservations, Create Reservations'
		},
		{
			name: 'omar hammad',
			email: 'ohamad@noor.net',
			phone: '+020108552222',
			role: 'backend',
			store: 'Virgin Megastore',
			access: 'Financials'
		},
		{
			name: 'shady hafaz',
			email: 'shady@gmail.com',
			phone: '+020885522200',
			role: 'Mangaer',
			store: 'Opera, Cairo Stadium',
			access: 'Create Reservations'
		}
	];
	constructor() {}

	ngOnInit() {}
}
