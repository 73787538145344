import { Component, OnInit, ViewChild } from '@angular/core';
import { GetProfileService } from '../../../_services';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  SavedInfo: any;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;
  dataSource: MatTableDataSource<{}>;

  displayedColumns = [
    'name',
    'date_created',
    'comment',
    'rating'
  ];
  isLoading = true;
  reviews: any;
  stars = [
    {
      id: 1,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 2,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 3,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 4,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 5,
      icon: 'star',
      class: 'star-gray star-hover star'
    }

  ];
  selectedRating = 0;
  constructor(private getProfileService: GetProfileService,		private router: Router,

  ) { }

  ngOnInit() {
    this.selectStar(5);
    this.getProfileService.getBasicInforest().subscribe((data: any) => {
      this.SavedInfo = data.restaurant;

      this.reviews = data.restaurant.reviews;


      this.dataSource = new MatTableDataSource(this.reviews);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.isLoading = false;
      console.log(this.reviews)
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectStar(value): void {

    // prevent multiple selection
    if (this.selectedRating === 0) {

      this.stars.filter((star) => {

        if (star.id <= value) {

          star.class = 'star-gold star';

        } else {

          star.class = 'star-gray star';

        }

        return star;
      });

    }
  }
  onSelectSpecificReservationUser(reservationId: string) {
		this.router.navigate(['/guestList', reservationId]);
	}
}
