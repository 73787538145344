import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-seats-show',
	templateUrl: './seats-show.component.html',
	styleUrls: ['./seats-show.component.scss']
})
export class SeatsShowComponent implements OnInit {
	coords: any;
	zonesdata: any[] = [];
	rows: string[] = [];
	cols: number[] = [];
	reserved: any[] = [];
	selected: any[] = [];
	show = false;
	showL = true;
	all = false;
	seatname: string[] = [];
	name: string;
	daos: any[] = [];
	zone: any;
	price: any;
	Seatadd: any;
	rowname: any;
	selectedrow: any[] = [];
	rowss: number;
	colss: number;
	datasend: any[] = [];
	selected3: any[] = [];
	row_cols: any[] = [];
	colspush: any[] = [];
	rowspush: any[] = [];
	rowsselected: any[] = [];
	areas_addes: any[] = [];
	zonessend: any;
	seatsssss: any;
	rese: any[] = [];
	Showseats = false;
	Showmab = true;
  currentEvent: any;
	constructor(private router: Router) {}

	ngOnInit() {
		this.zonesdata = [
			{
				name: '302',
				coords: '139,47,333,85',
				seats: [
					{ name: 'A-0', state: 1 },
					{ name: 'A-1', state: 1 },
					{ name: 'A-2', state: 1 },
					{ name: 'A-3', state: 1 },
					{ name: 'A-4', state: 1 },
					{ name: 'A-5', state: 1 },
					{ name: 'A-6', state: 1 },
					{ name: 'A-7', state: 1 },
					{ name: 'A-8', state: 1 },
					{ name: 'A-9', state: 1 },
					{ name: 'A-10', state: 1 },
					{ name: 'A-11', state: 1 },
					{ name: 'A-12', state: 1 },
					{ name: 'A-13', state: 1 },
					{ name: 'A-14', state: 1 },
					{ name: 'A-15', state: 1 },
					{ name: 'A-16', state: 1 },
					{ name: 'A-17', state: 1 },
					{ name: 'A-18', state: 1 },
					{ name: 'A-19', state: 1 },
					{ name: 'A-20', state: 1 },
					{ name: 'A-21', state: 1 },
					{ name: 'A-22', state: 1 },
					{ name: 'A-23', state: 1 },
					{ name: 'A-24', state: 1 },
					{ name: 'A-25', state: 1 },
					{ name: 'A-26', state: 1 },
					{ name: 'A-27', state: 1 },
					{ name: 'A-28', state: 1 },
					{ name: 'A-29', state: 1 },
					{ name: 'B-0', state: 1 },
					{ name: 'B-1', state: 1 },
					{ name: 'B-2', state: 1 },
					{ name: 'B-3', state: 1 },
					{ name: 'B-4', state: 1 },
					{ name: 'B-5', state: 1 },
					{ name: 'B-6', state: 1 },
					{ name: 'B-7', state: 1 },
					{ name: 'B-8', state: 1 },
					{ name: 'B-9', state: 1 },
					{ name: 'B-10', state: 1 },
					{ name: 'B-11', state: 1 },
					{ name: 'B-12', state: 1 },
					{ name: 'B-13', state: 1 },
					{ name: 'B-14', state: 1 },
					{ name: 'B-15', state: 1 },
					{ name: 'B-16', state: 1 },
					{ name: 'B-17', state: 1 },
					{ name: 'B-18', state: 1 },
					{ name: 'B-19', state: 1 },
					{ name: 'B-20', state: 1 },
					{ name: 'B-21', state: 1 },
					{ name: 'B-22', state: 1 },
					{ name: 'B-23', state: 1 },
					{ name: 'B-24', state: 1 },
					{ name: 'B-25', state: 1 },
					{ name: 'B-26', state: 1 },
					{ name: 'B-27', state: 1 },
					{ name: 'B-28', state: 1 },
					{ name: 'B-29', state: 1 },
					{ name: 'C-0', state: 1 },
					{ name: 'C-1', state: 2 },
					{ name: 'C-2', state: 2 },
					{ name: 'C-3', state: 2 },
					{ name: 'C-4', state: 1 },
					{ name: 'C-5', state: 1 },
					{ name: 'C-6', state: 1 },
					{ name: 'C-7', state: 1 },
					{ name: 'C-8', state: 1 },
					{ name: 'C-9', state: 1 },
					{ name: 'C-10', state: 1 },
					{ name: 'C-11', state: 1 },
					{ name: 'C-12', state: 1 },
					{ name: 'C-13', state: 1 },
					{ name: 'C-14', state: 2 },
					{ name: 'C-15', state: 2 },
					{ name: 'C-16', state: 2 },
					{ name: 'C-17', state: 2 },
					{ name: 'C-18', state: 1 },
					{ name: 'C-19', state: 1 },
					{ name: 'C-20', state: 1 },
					{ name: 'C-21', state: 1 },
					{ name: 'C-22', state: 1 },
					{ name: 'C-23', state: 1 },
					{ name: 'C-24', state: 1 },
					{ name: 'C-25', state: 1 },
					{ name: 'C-26', state: 1 },
					{ name: 'C-27', state: 1 },
					{ name: 'C-28', state: 1 },
					{ name: 'C-29', state: 1 },
					{ name: 'D-0', state: 1 },
					{ name: 'D-1', state: 2 },
					{ name: 'D-2', state: 2 },
					{ name: 'D-3', state: 2 },
					{ name: 'D-4', state: 1 },
					{ name: 'D-5', state: 1 },
					{ name: 'D-6', state: 1 },
					{ name: 'D-7', state: 1 },
					{ name: 'D-8', state: 1 },
					{ name: 'D-9', state: 1 },
					{ name: 'D-10', state: 1 },
					{ name: 'D-11', state: 1 },
					{ name: 'D-12', state: 1 },
					{ name: 'D-13', state: 1 },
					{ name: 'D-14', state: 2 },
					{ name: 'D-15', state: 2 },
					{ name: 'D-16', state: 2 },
					{ name: 'D-17', state: 2 },
					{ name: 'D-18', state: 1 },
					{ name: 'D-19', state: 1 },
					{ name: 'D-20', state: 1 },
					{ name: 'D-21', state: 1 },
					{ name: 'D-22', state: 1 },
					{ name: 'D-23', state: 1 },
					{ name: 'D-24', state: 1 },
					{ name: 'D-25', state: 1 },
					{ name: 'D-26', state: 1 },
					{ name: 'D-27', state: 1 },
					{ name: 'D-28', state: 1 },
					{ name: 'D-29', state: 1 },
					{ name: 'E-0', state: 1 },
					{ name: 'E-1', state: 2 },
					{ name: 'E-2', state: 2 },
					{ name: 'E-3', state: 2 },
					{ name: 'E-4', state: 1 },
					{ name: 'E-5', state: 1 },
					{ name: 'E-6', state: 1 },
					{ name: 'E-7', state: 1 },
					{ name: 'E-8', state: 1 },
					{ name: 'E-9', state: 1 },
					{ name: 'E-10', state: 1 },
					{ name: 'E-11', state: 1 },
					{ name: 'E-12', state: 1 },
					{ name: 'E-13', state: 1 },
					{ name: 'E-14', state: 2 },
					{ name: 'E-15', state: 2 },
					{ name: 'E-16', state: 2 },
					{ name: 'E-17', state: 2 },
					{ name: 'E-18', state: 1 },
					{ name: 'E-19', state: 1 },
					{ name: 'E-20', state: 1 },
					{ name: 'E-21', state: 1 },
					{ name: 'E-22', state: 1 },
					{ name: 'E-23', state: 1 },
					{ name: 'E-24', state: 1 },
					{ name: 'E-25', state: 1 },
					{ name: 'E-26', state: 1 },
					{ name: 'E-27', state: 1 },
					{ name: 'E-28', state: 1 },
					{ name: 'E-29', state: 1 }
				],
				row: 23,
				col: 5
			},
			{ name: '301', coords: '99,47,124,86' },
			{ name: '303', coords: '350,46,375,85' },
			{ name: '201', coords: '99,93,123,131' },
			{
				name: '202',
				coords: '139,92,334,132',
				row: 23,
				col: 5,
				seats: [
					{ name: 'A-0', state: 1 },
					{ name: 'A-1', state: 1 },
					{ name: 'A-2', state: 1 },
					{ name: 'A-3', state: 1 },
					{ name: 'A-4', state: 1 },
					{ name: 'A-5', state: 1 },
					{ name: 'A-6', state: 1 },
					{ name: 'A-7', state: 1 },
					{ name: 'A-8', state: 1 },
					{ name: 'A-9', state: 1 },
					{ name: 'A-10', state: 1 },
					{ name: 'A-11', state: 1 },
					{ name: 'A-12', state: 1 },
					{ name: 'A-13', state: 1 },
					{ name: 'A-14', state: 1 },
					{ name: 'A-15', state: 1 },
					{ name: 'A-16', state: 1 },
					{ name: 'A-17', state: 1 },
					{ name: 'A-18', state: 2 },
					{ name: 'A-19', state: 2 },
					{ name: 'A-20', state: 2 },
					{ name: 'A-21', state: 1 },
					{ name: 'A-22', state: 2 },
					{ name: 'B-0', state: 1 },
					{ name: 'B-1', state: 1 },
					{ name: 'B-2', state: 2 },
					{ name: 'B-3', state: 1 },
					{ name: 'B-4', state: 1 },
					{ name: 'B-5', state: 1 },
					{ name: 'B-6', state: 1 },
					{ name: 'B-7', state: 1 },
					{ name: 'B-8', state: 2 },
					{ name: 'B-9', state: 2 },
					{ name: 'B-10', state: 2 },
					{ name: 'B-11', state: 1 },
					{ name: 'B-12', state: 1 },
					{ name: 'B-13', state: 1 },
					{ name: 'B-14', state: 1 },
					{ name: 'B-15', state: 1 },
					{ name: 'B-16', state: 1 },
					{ name: 'B-17', state: 1 },
					{ name: 'B-18', state: 2 },
					{ name: 'B-19', state: 1 },
					{ name: 'B-20', state: 1 },
					{ name: 'B-21', state: 2 },
					{ name: 'B-22', state: 2 },
					{ name: 'C-0', state: 1 },
					{ name: 'C-1', state: 1 },
					{ name: 'C-2', state: 1 },
					{ name: 'C-3', state: 2 },
					{ name: 'C-4', state: 1 },
					{ name: 'C-5', state: 1 },
					{ name: 'C-6', state: 1 },
					{ name: 'C-7', state: 1 },
					{ name: 'C-8', state: 2 },
					{ name: 'C-9', state: 2 },
					{ name: 'C-10', state: 2 },
					{ name: 'C-11', state: 1 },
					{ name: 'C-12', state: 1 },
					{ name: 'C-13', state: 1 },
					{ name: 'C-14', state: 1 },
					{ name: 'C-15', state: 1 },
					{ name: 'C-16', state: 1 },
					{ name: 'C-17', state: 2 },
					{ name: 'C-18', state: 2 },
					{ name: 'C-19', state: 2 },
					{ name: 'C-20', state: 2 },
					{ name: 'C-21', state: 2 },
					{ name: 'C-22', state: 2 },
					{ name: 'D-0', state: 1 },
					{ name: 'D-1', state: 1 },
					{ name: 'D-2', state: 1 },
					{ name: 'D-3', state: 1 },
					{ name: 'D-4', state: 2 },
					{ name: 'D-5', state: 1 },
					{ name: 'D-6', state: 1 },
					{ name: 'D-7', state: 1 },
					{ name: 'D-8', state: 2 },
					{ name: 'D-9', state: 2 },
					{ name: 'D-10', state: 2 },
					{ name: 'D-11', state: 1 },
					{ name: 'D-12', state: 1 },
					{ name: 'D-13', state: 1 },
					{ name: 'D-14', state: 1 },
					{ name: 'D-15', state: 1 },
					{ name: 'D-16', state: 1 },
					{ name: 'D-17', state: 1 },
					{ name: 'D-18', state: 2 },
					{ name: 'D-19', state: 2 },
					{ name: 'D-20', state: 1 },
					{ name: 'D-21', state: 1 },
					{ name: 'D-22', state: 2 },
					{ name: 'E-0', state: 1 },
					{ name: 'E-1', state: 1 },
					{ name: 'E-2', state: 1 },
					{ name: 'E-3', state: 1 },
					{ name: 'E-4', state: 1 },
					{ name: 'E-5', state: 1 },
					{ name: 'E-6', state: 1 },
					{ name: 'E-7', state: 1 },
					{ name: 'E-8', state: 1 },
					{ name: 'E-9', state: 1 },
					{ name: 'E-10', state: 1 },
					{ name: 'E-11', state: 1 },
					{ name: 'E-12', state: 1 },
					{ name: 'E-13', state: 3 },
					{ name: 'E-14', state: 3 },
					{ name: 'E-15', state: 3 },
					{ name: 'E-16', state: 1 },
					{ name: 'E-17', state: 1 },
					{ name: 'E-18', state: 1 },
					{ name: 'E-19', state: 1 },
					{ name: 'E-20', state: 1 },
					{ name: 'E-21', state: 1 },
					{ name: 'E-22', state: 1 }
				]
			},
			{ name: '203', coords: '350,93,376,132' }
    ];
    console.log(this.zonesdata , 'area is')
  this.currentEvent= JSON.parse(localStorage.getItem('ev'));
  console.log(this.currentEvent , 'sasa')

	}
	getStatus(seatPos: string) {
		//  this.datasend.push(seatPos)
		// // console.log(seatPos , 's')

		if (this.reserved.indexOf(seatPos) !== -1) {
			return 'reserved';
		} else if (this.selected.indexOf(seatPos) !== -1) {
			//  console.log(this.selected , 'ssfaffa')
			return 'selected';
		}
	}
	done() {
		// const form = this.f_baseCreateVenue;
		// const row = form.get('num_of_row');
		// const cols = form.get('num_of_cols')
		this.rows = [];
		this.cols = [];
		this.row_cols = [];

		for (let x = 0; x < this.colss; x++) {
			this.cols[x] = x;
			// this.colspush.push(this.cols)
		}
		// console.log(this.cols , 'sss')

		for (let x = 0; x < this.rowss; x++) {
			// this.rows[x] = x.toString();
			this.rows[
				x
			] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789'.charAt(
				x
			);

			// this.rowspush.push(this.rows)
		}

		// tslint:disable-next-line: no-shadowed-variable
		for (const row of this.rows) {
			// console.log(row , 'afafafa')
			for (const col of this.cols) {
				//  console.log(col + '-' , row, 'fafsafasfaak')
				this.row_cols.push({
					name: row + '-' + col,
					state: 1
				});
			}
		}

		// document.getElementById('success').innerHTML = 'Form is validated Successfully';
		console.log('row + cols', this.row_cols);
	}

	// clear handler
	clearSelected() {
		this.selected = [];
		this.reserved = [];
		this.rowname = '';
		this.price = '';
		this.name = '';
		this.zone = '';
	}
	seatClicked(seatPos: string) {
		// console.log(seatPos , ',z,z,z,z,z,z')
		const index = this.selected.indexOf(seatPos);
		if (index !== -1) {
			// seat already selected, remove
			this.selected.splice(index, 1);

			for (const o of this.row_cols) {
				if (o.name === seatPos) {
					o.state = 1;
				}
			}
			this.selected3.splice(index, 1);
			console.log(this.selected, '222');
		} else {
			if (this.reserved.indexOf(seatPos) === -1) {
				this.selected.push(seatPos);
				for (const o of this.row_cols) {
					if (o.name === seatPos) {
						o.state = 2;
					}
				}
				for (let p = 0; p <= this.row_cols.length; p++) {}
			}
		}
		console.log('Seats Aviable', this.selected);
		console.log('will send', this.row_cols);
	}
	seatresved(seatPos: string) {
		const index = this.reserved.indexOf(seatPos);
		if (index !== -1) {
			// seat already selected, remove
			this.reserved.splice(index, 1);
		} else {
			// push to selected array only if it is not reserved
			if (this.selected.indexOf(seatPos) === -1) {
				this.reserved.push(seatPos);
			}
		}
		// tslint:disable-next-line: prefer-const
		for (let k of this.currentEvent.zones) {
			console.log(this.seatsssss, 'saf');
			if (this.seatsssss === k.name) {
				console.log('true');
				// tslint:disable-next-line: prefer-const
				for (let i of k.seats) {
					//  console.log(i)
					// tslint:disable-next-line: prefer-const
					for (let m of this.reserved) {
						// console.log(k.seats)
						if (i.name === m) {
							console.log(i.state, 'afa');
							this.rese.push(i.guid);
						}
					}
				}
			}
		}
		console.log(this.rese, 'resavsvas');
		localStorage.setItem('seats', JSON.stringify(this.rese));
		this.rese = [];

		console.log('Seats Blocked', this.reserved);
	}
	// Buy button handler
	showSelected() {
		this.show = false;
		this.showL = true;
		this.all = false;
	}
	showresved() {
		this.show = true;
		this.showL = false;
		this.all = false;
	}
	send() {
		alert('Selected Seats:' + this.selected + '\nRow Name:' + this.name);
		this.seatname.push(this.name);
		this.daos.push({
			rowname: this.name,
			seats: this.row_cols
		});
		JSON.stringify(this.daos);

		// this.row_cols = []

		console.log('seats', this.name, 'seatsz', this.selected);
		console.log(this.daos, 'KALAK', JSON.stringify(this.daos));
	}
	showall() {
		this.all = true;
		this.show = false;
		this.showL = false;
	}
	showme(s) {
		this.seatsssss = s;
		this.selected = [];
		console.log('s', s);
		for (let o = 0; o <= this.currentEvent.zones.length; o++) {
			if (this.currentEvent.zones[o].name === s) {
				// tslint:disable-next-line: prefer-for-of
				for (let k = 0; k < this.currentEvent.zones[o].seats.length; k++) {
					// console.log(this.daos[o].seats[k].name, 'sz')
					if (this.currentEvent.zones[o].seats[k].state === 2) {
						this.selected.push(this.currentEvent.zones[o].seats[k].name);
					} else if (this.currentEvent.zones[o].seats[k].state === 3) {
						this.reserved.push(this.currentEvent.zones[o].seats[k].name);
					}
				}
			}
		}
	}
	sendzone() {
		// for (let i = 0; i < this.daos.length; i++) {
		//   if (this.daos[i].selected === true) {
		//     this.selectedrow.push({
		//       price: this.price + 'EGP',
		//       zone: this.zone,
		//       area: this.daos[i]
		//     });
		//   }
		//   console.log(this.selectedrow, 'Zone IS');
		// }
		for (let i of this.zonesdata) {
			if (i.selected === true) {
				this.zonesdata.slice(i);
				i = {
					name: i.name,
					coords: i.coords,
					seats: this.row_cols
				};
				console.log(i, 'fsafsafkamf');
				this.zonesdata.push(i);
			}
		}
		console.log(this.zonesdata, 'zzzvavava');
	}
	openmab() {
		this.Showmab = true;
		this.Showseats = false;
	}
	shows(s, w) {
		this.showall();
		this.Showseats = true;
		this.Showmab = false;
		this.reserved = [];
		this.rows = [];
		this.cols = [];
		console.log(s, w);
		this.rows = [];
		this.cols = [];
		this.row_cols = [];

		this.colss = s;
		this.rowss = w;

		for (let x = 0; x < this.colss; x++) {
			this.cols[x] = x;
			// this.colspush.push(this.cols)
		}
		// console.log(this.cols , 'sss')

		for (let x = 0; x < this.rowss; x++) {
			// this.rows[x] = x.toString();
			this.rows[
				x
			] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.charAt(x);

			// this.rowspush.push(this.rows)
		}

		// tslint:disable-next-line: no-shadowed-variable
		for (const row of this.rows) {
			// console.log(row , 'afafafa')
			for (const col of this.cols) {
				//  console.log(col + '-' , row, 'fafsafasfaak')
				this.row_cols.push({
					name: row + '-' + col,
					state: 1
				});
			}
		}

		// document.getElementById('success').innerHTML = 'Form is validated Successfully';
		console.log('row + cols', this.row_cols);
	}

	gotoapp() {
		this.router.navigate(['/login']);
  }

  ngOnDestroy() {
		localStorage.removeItem('ev');
	}
}
