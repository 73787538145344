import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
@Component({
	selector: 'app-c-r-reservations-details',
	templateUrl: './c-r-reservations-details.component.html',
	styleUrls: ['./c-r-reservations-details.component.scss']
})
export class CRReservationsDetailsComponent implements OnInit {
	targetReservation: any;
	constructor(
		private dialogRef: MatDialogRef<CRReservationsDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) data
	) {
		this.targetReservation = data;
		console.log(this.targetReservation);
	}

	ngOnInit() {}

	/**
	 * close the dialog
	 */
	close() {
		this.dialogRef.close();
	}
}
