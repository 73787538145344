import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { HttpService } from '../../../_services';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-branches-restaurant',
	templateUrl: './branches-restaurant.component.html',
	styleUrls: ['./branches-restaurant.component.scss']
})
export class BranchesRestaurantComponent implements OnInit {
	isLoadingBranches = true;
	branches: any[];
	branchesLength: number;
	searchString: string;
	noContent = false;
	constructor(private _httpService: HttpService, private _router: Router) {}

	ngOnInit() {
		this.getBranchesData();
	}
	/**
	 * take the returned branches object and parse it to the view
	 */
	getBranchesData() {
		const access_token = JSON.parse(localStorage.getItem('currentUser'))[
			'access_token'
		];
		this._httpService
			.getData(`${environment.apiEndPoint}/vendor/restaurant/branch/`, {
				client_id: environment.clientId,
				client_secret: environment.clientSecret,
				access_token: access_token
			})
			.subscribe(branches => {
				this.isLoadingBranches = !this.isLoadingBranches;
				if (
					branches.hasOwnProperty('branches') &&
					!branches['branches'].length
				) {
					return (this.noContent = !this.noContent);
				}
				this.branches = branches['branches'];
				console.log(this.branches, ' data of branchs');
				this.branchesLength = branches['branches'].length;
			});
	}
	/**
	 * change the Activation State of the branch
	 */
	switchActivation(branch_id: string) {
		this._httpService
			.postData(
				`${
					environment.apiEndPoint
				}/vendor/restaurant/branch/${branch_id}/switchactivation/`
			)
			.subscribe(response => {
				switch (response['code']) {
					case '10000':
						Swal('Done', 'Saved successfully', 'success');
						break;
				}
				this.getBranchesData();
				this.isLoadingBranches = !this.isLoadingBranches;
			});
	}
	onSelectBranchItem(branch) {
		localStorage.setItem('branch', JSON.stringify(branch));
		const currentBranchId = branch['branch_id'];
		this._router.navigate(['/R/branches', currentBranchId]);
	}
}
