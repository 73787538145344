import { Component, OnInit } from '@angular/core';
import { HttpService, ErrorHandlerService } from '../../_services';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-guest-list',
	templateUrl: './guest-list.component.html',
	styleUrls: ['./guest-list.component.scss']
})
export class GuestListComponent implements OnInit {
	isLoading = true;
	guestData: any[];
	searchString: string;
	noContent = false;
	/**pagination */
	pageSize = 10;
	currentPage = 1;
	totalItems = 1;
	isLoadingBranches = true;
	constructor(
		private httpService: HttpService,
		private router: Router,
		private errorHandlerServices: ErrorHandlerService
	) {}

	ngOnInit() {
		this.getGuestList({ page: '1', page_size: '10' });
	}
	pageChange(event) {
		this.currentPage = event;
		this.getGuestList({ page: this.currentPage, page_size: '10' });
	}
	getGuestList(queryParameter?: Object) {
		this.httpService
			.getData(`${environment.apiEndPoint}/vendor/userbadge/`, queryParameter)
			.subscribe(
				data => {
					this.isLoading = false;
					this.isLoadingBranches = false;
					const guests = data['guests'];
					if (guests === undefined || !guests.length) {
						return (this.noContent = !this.noContent);
					}
					this.guestData = guests;
					this.currentPage = data['current_page'];
					this.totalItems = this.pageSize * data['total_pages'];
				},
				error => this.errorHandlerServices.handleError(error)
			);
	}
	onSelectGuestItem(guest) {
		// console.log(JSON.stringify(guest));
		this.router.navigate(['/guestList', guest.id]);
	}
}
