import { Injectable } from '@angular/core';
import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// add authorization header with jwt token if available
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));

		if (currentUser && currentUser.access_token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${currentUser.access_token}`
				}
			});
		}

		return next.handle(request);
	}
}
