import { Directive, Input } from '@angular/core';
import { Validators, FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector:
		'[minNumberValidator][formControlName],[minNumberValidator][formControl],[minNumberValidator][ngModel]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: MinNumberValidatorDirective,
			multi: true
		}
	]
})
export class MinNumberValidatorDirective implements Validators {
	@Input()
	minNumberValidator: number;
	constructor() {}
	validate(c: FormControl): { [key: string]: any } {
		const v = c.value;
		return v < this.minNumberValidator ? { minNumberValidator: true } : null;
	}
}
